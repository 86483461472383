import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  // List,
  ListItemText,
  Typography,
  ListItemButton,
  Button,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import DashboardIcon from '@mui/icons-material/Dashboard'

import ProfileClient from './ProfileClient'
import DashboardClient from './DashboardClient'

function Items({ setCurrentTab, currentTab }) {
  const items = [
    {
      href: '1',
      title: 'Information client',
      icon: (
        <PersonOutlineIcon
          sx={{
            width: '1.25rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
    {
      href: '2',
      title: 'Tableau de bord du client',
      icon: (
        <DashboardIcon
          sx={{
            width: '1.25rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
  ]
  const handleItemClick = (value) => {
    setCurrentTab(value)
  }

  return (
    <>
      {items.map((item) => (
        <Grid item xs={12} md={4} key={item.title} p={0}>
          <ListItemButton
            selected={item.href === currentTab}
            onClick={() => {
              handleItemClick(item.href)
            }}
            sx={{
              p: 0,
              color: 'black.black50',
              borderLeft: '2px solid #f5f5f5',
              '&:before': {
                bottom: '0px',
                content: '""',
                width: '100%',
                height: item.href === currentTab ? '4px' : '0px',
                position: 'absolute',
                transition: 'all 0.3s ease 0s',
                backgroundColor: 'mysensPalette.lightBlue',
              },
            }}
          >
            <Box
              sx={{
                py: 1,
                px: 2,
                display: 'flex',
              }}
            >
              {item.icon}
              <ListItemText>
                <Typography
                  variant="body2"
                  sx={{
                    ml: '0.512rem',
                    color: 'black.black75',
                  }}
                >
                  {item.title}
                </Typography>
              </ListItemText>
            </Box>
          </ListItemButton>
        </Grid>
      ))}
    </>
  )
}

Items.propTypes = {
  setCurrentTab: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
}

const currentPanel = (value, clientDetail) => {
  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }
  const account = {
    firstName: clientDetail['Nom Complet'].split(' ')[0],
    lastName: clientDetail['Nom Complet'].split(' ')[1],
    email: clientDetail.Email,
    phoneNumber: clientDetail.Tel,
    profileRole: clientDetail["Titre D'emploi"],
  }
  if (account.company) {
    const company = [
      {
        key: 'companyName',
        value: clientDetail?.Entreprise,
      },
      {
        key: 'Address',
        value: clientDetail.company.companyAddress,
      },
      {
        key: 'Place',
        value: `${JSON.parse(clientDetail.company.city).name}, ${
          JSON.parse(clientDetail.company.region).name
        }, ${JSON.parse(clientDetail.company.country).name}`,
      },
      {
        key: "Type d'entreprise",
        value: clientDetail.company.companyType.name,
      },
      {
        key: "Type d'industrie",
        value: clientDetail.company.industryType.name,
      },
      {
        key: "Nombre d'embloyés",
        value: clientDetail.company.numberEmployee.name,
      },
      // {
      //   key: 'Spécialization',
      //   value: clientDetail.company.specialization.name,
      // },
      {
        key: 'Type du client',
        value: clientDetail.company.typeOfClient.name,
      },
      {
        key: 'Date de création',
        value: `${new Date(
          clientDetail.company.creationDate,
        ).toLocaleDateString('en-Us', options)}`,
      },
    ]
    account.company = company
  }
  switch (value) {
    case '1':
      return <ProfileClient account={account} />
    case '2':
      return (
        <DashboardClient
          companyId={clientDetail.company && clientDetail.company.id}
        />
      )

    default:
      return <ProfileClient account={account} />
  }
}

export default function ClientDetail({ handleBackDetail, clientDetail }) {
  const [currentTab, setCurrentTab] = useState('1')

  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: 'white.white100',
          transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          boxShadow:
            'rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px',
          borderRadius: '8px',
        }}
      >
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            p: 0,
            m: 0,
          }}
        >
          <Button
            fullWidth
            variant="mysensContained"
            onClick={handleBackDetail}
            sx={{
              height: '100%',
              borderRadius: 0,
              background: 'transparent',
              color: 'black.black75',
              '&:hover': {
                backgroundColor: '#f5f5f5',
              },
            }}
          >
            <ArrowBackIcon
              sx={{
                width: '1.25rem',
                color: 'black.black75',
              }}
            />
            <Typography
              variant="body2"
              sx={{
                ml: 1,
              }}
            >
              Précédent
            </Typography>
          </Button>
        </Grid>

        <Items setCurrentTab={setCurrentTab} currentTab={currentTab} />
      </Grid>

      <Box mt={2}>{currentPanel(currentTab, clientDetail)}</Box>
    </>
  )
}

ClientDetail.propTypes = {
  handleBackDetail: PropTypes.func,
  clientDetail: PropTypes.object,
}
