import PropTypes from 'prop-types'
import { Autocomplete, TextField, Grid, IconButton } from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'
import { useForm, Controller } from 'react-hook-form'

export default function RecommendationFilter({
  industries,
  maturities,
  dimensions,
  recommendations,
  rows,
  setRows,
  setFilter,
}) {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  })

  const onSubmit = (data) => {
    setFilter({
      type: data.type,
      dimension: data.dimension,
      maturity: data.maturity,
      industry: data.industry,
    })
    if (data.type || data.dimension || data.maturity || data.industry) {
      const filteredRows = recommendations
        .filter((row) => {
          if (data.industry) {
            return JSON.parse(row.industries).find(
              (indust) => indust.id === data.industry.id,
            )
          }
          return row
        })
        .filter((row) => {
          if (data.maturity) {
            return JSON.parse(row.maturities).find(
              (matur) => matur.id === data.maturity.id,
            )
          }
          return row
        })
        .filter((row) => {
          if (data.type === 'Globale') {
            return row.dimensions === '[]'
          }
          if (data.type === 'Spécifique') {
            if (data.dimension) {
              return JSON.parse(row.dimensions).find(
                (dim) => dim.id === data.dimension.id,
              )
            }
            return row.dimensions !== '[]'
          }
          return row
        })

      setRows(filteredRows)
    } else if (rows.length < recommendations.length) {
      setRows(recommendations)
    }
  }
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Grid item xs={12} sm={11}>
        <Grid container spacing={2} alignItems="center">
          {/* Dimensions Type */}
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              control={control}
              name="type"
              render={({ field: { onChange } }) => (
                <Autocomplete
                  options={['Spécifique', 'Globale']}
                  getOptionLabel={(option) => option}
                  onChange={(event, values) => {
                    onChange(values)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Type"
                      helperText={errors.type?.message}
                      error={!!errors.type}
                    />
                  )}
                />
              )}
            />
          </Grid>
          {/* /Dimensions Type */}
          {/* Dimensions */}
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              control={control}
              name="dimension"
              render={({ field: { onChange } }) => (
                <Autocomplete
                  disabled={watch('type') === 'Globale'}
                  options={dimensions}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, values) => {
                    onChange(values || null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Dimension"
                      helperText={errors.dimensions?.message}
                      error={!!errors.dimensions}
                    />
                  )}
                />
              )}
            />
          </Grid>
          {/* /Dimensions */}
          {/* Maturity */}
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              control={control}
              name="maturity"
              render={({ field: { onChange } }) => (
                <Autocomplete
                  options={maturities}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, values) => {
                    onChange(values || null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Maturité"
                      helperText={errors.maturity?.message}
                      error={!!errors.maturity}
                    />
                  )}
                />
              )}
            />
          </Grid>
          {/* /Maturity */}

          {/* Industry  */}
          <Grid item xs={12} sm={6} md={3}>
            <Controller
              control={control}
              name="industry"
              render={({ field: { onChange } }) => (
                <Autocomplete
                  options={industries}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, values) => {
                    onChange(values || null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Type d'industrie"
                      helperText={errors.industry?.message}
                      error={Boolean(errors.industry)}
                    />
                  )}
                />
              )}
            />
          </Grid>
          {/* /Industry */}
        </Grid>
      </Grid>

      {/* FilterButton */}
      <Grid item xs={12} sm={1}>
        <IconButton type="submit">
          <TuneIcon />
        </IconButton>
      </Grid>

      {/* /FilterButton */}
    </Grid>
  )
}

RecommendationFilter.propTypes = {
  rows: PropTypes.array,
  recommendations: PropTypes.array,
  setRows: PropTypes.func,
  maturities: PropTypes.array,
  dimensions: PropTypes.array,
  industries: PropTypes.array,
  setFilter: PropTypes.func,
}
