import { Routes, Route, Navigate } from 'react-router-dom'
import { useAuth, roles } from './modules/auth/AuthProvider'
import Layout from './components/Layout'
import ProfileLayout from './components/ProfileLayout'
import Company from './screens/company/Company'
import RegisterCompany from './screens/company/RegisterCompany'
import CompanyProvider from './modules/company/companyProvider'
import AssessmentSwitcher from './screens/QA/AssessmentSwitcher'
import AuthHome from './screens/intro/AuthHome'
import ScoreAI from './screens/score/ScoreAI'
import AdminQuestions from './screens/adminQuestions/Questions'
import DimensionProvider from './modules/dimension/DimensionProvider'
import EnvDimension from './screens/QA/dimensions/EnvDimension'
import Strategy from './screens/QA/dimensions/Strategy'
import Culture from './screens/QA/dimensions/Culture'
import DataGov from './screens/QA/dimensions/DataGov'
import Process from './screens/QA/dimensions/Process'
import Techno from './screens/QA/dimensions/Techno'
import Ethic from './screens/QA/dimensions/Ethic'
import Services from './screens/QA/dimensions/Services'
import Cyberscurity from './screens/QA/dimensions/Cyberscurity'
import Recommendations from './screens/recommendation/Recommendations'
import WhatIsMySens from './screens/home/WhatIsMySens'
import KnowledgeLibrary from './screens/home/KnowledgeLibrary'
import AssessmentProvider from './modules/assessment/AssessmentProvider'
import PADashbard from './screens/Dashboard/PADashboard'
import Clients from './screens/clients/Clients'
import TopRecommendations from './screens/insights/TopRecommendations'
import Thematics from './screens/thematics/Thematics'
import Tags from './screens/tags/Tags'
import UseCases from './screens/useCases/UseCases'
import TopUseCases from './screens/opportunities/TopUseCases'
import PersonalInfo from './screens/user/Personalnfo'
import EditGoal from './screens/user/EditGoal'
import Password from './screens/user/Password'
import DeleteAccount from './screens/user/DeleteAccount'
import TagsProvider from './modules/tags/TagsProvider'
import MaturityProvider from './modules/maturity/MaturityProvider'
import IndustryProvider from './modules/industry/IndustryProvider'
import Evaluation from './screens/evaluation/Evaluation'

export default function AuthenticatedApp() {
  const { account } = useAuth()
  return (
    <Routes>
      {account.role.includes('client') &&
        account.privileges &&
        account.privileges.includes('recommendations') && (
          <Route
            path="/toprecommendations"
            element={
              <Layout>
                <DimensionProvider>
                  <MaturityProvider>
                    <AssessmentProvider>
                      <TopRecommendations />
                    </AssessmentProvider>
                  </MaturityProvider>
                </DimensionProvider>
              </Layout>
            }
          />
        )}

      {account.role.includes('client') &&
        account.privileges &&
        account.privileges.includes('usecases') && (
          <Route
            path="/topUseCases"
            element={
              <Layout>
                <DimensionProvider>
                  <AssessmentProvider>
                    <TopUseCases />
                  </AssessmentProvider>
                </DimensionProvider>
              </Layout>
            }
          />
        )}

      {account.role.includes('client') &&
        account.privileges &&
        account.privileges.includes('assessment') && (
          <>
            <Route
              path="/myscoreai"
              element={
                <Layout>
                  <DimensionProvider>
                    <MaturityProvider>
                      <AssessmentProvider>
                        <ScoreAI />
                      </AssessmentProvider>
                    </MaturityProvider>
                  </DimensionProvider>
                </Layout>
              }
            />
            <Route
              path="/dimensions"
              element={
                <Layout>
                  <DimensionProvider>
                    <AssessmentProvider>
                      <AssessmentSwitcher />
                    </AssessmentProvider>
                  </DimensionProvider>
                </Layout>
              }
            />

            <Route
              path="/dimensions/env"
              element={
                <Layout>
                  <DimensionProvider>
                    <AssessmentProvider>
                      <EnvDimension />
                    </AssessmentProvider>
                  </DimensionProvider>
                </Layout>
              }
            />
            <Route
              path="/dimensions/strategy"
              element={
                <Layout>
                  <DimensionProvider>
                    <AssessmentProvider>
                      <Strategy />
                    </AssessmentProvider>
                  </DimensionProvider>
                </Layout>
              }
            />
            <Route
              path="/dimensions/culture"
              element={
                <Layout>
                  <DimensionProvider>
                    <AssessmentProvider>
                      <Culture />
                    </AssessmentProvider>
                  </DimensionProvider>
                </Layout>
              }
            />
            <Route
              path="/dimensions/datagov"
              element={
                <Layout>
                  <DimensionProvider>
                    <AssessmentProvider>
                      <DataGov />
                    </AssessmentProvider>
                  </DimensionProvider>
                </Layout>
              }
            />
            <Route
              path="/dimensions/process"
              element={
                <Layout>
                  <DimensionProvider>
                    <AssessmentProvider>
                      <Process />
                    </AssessmentProvider>
                  </DimensionProvider>
                </Layout>
              }
            />
            <Route
              path="/dimensions/techno"
              element={
                <Layout>
                  <DimensionProvider>
                    <AssessmentProvider>
                      <Techno />
                    </AssessmentProvider>
                  </DimensionProvider>
                </Layout>
              }
            />
            <Route
              path="/dimensions/ethic"
              element={
                <Layout>
                  <DimensionProvider>
                    <AssessmentProvider>
                      <Ethic />
                    </AssessmentProvider>
                  </DimensionProvider>
                </Layout>
              }
            />
            <Route
              path="/dimensions/services"
              element={
                <Layout>
                  <DimensionProvider>
                    <AssessmentProvider>
                      <Services />
                    </AssessmentProvider>
                  </DimensionProvider>
                </Layout>
              }
            />

            <Route
              path="/dimensions/cyberscurity"
              element={
                <Layout>
                  <DimensionProvider>
                    <AssessmentProvider>
                      <Cyberscurity />
                    </AssessmentProvider>
                  </DimensionProvider>
                </Layout>
              }
            />

            {/* <Route
            path="/dimensions/finances"
            element={
              <Layout>
                <DimensionProvider>
                  <Finances />
                </DimensionProvider>
              </Layout>
            }
          /> */}
          </>
        )}

      {(account.role.includes(roles.Client.value) ||
        account.role.includes('client')) && (
        <>
          <Route
            path="/company"
            element={
              <Layout>
                <CompanyProvider>
                  <Company />
                </CompanyProvider>
              </Layout>
            }
          />
          <Route
            path="/registerCompany"
            element={
              <Layout>
                <CompanyProvider>
                  <RegisterCompany />
                </CompanyProvider>
              </Layout>
            }
          />
          <Route
            path="/profile/companyGoal"
            element={
              <Layout>
                <ProfileLayout>
                  <EditGoal />
                </ProfileLayout>
              </Layout>
            }
          />
        </>
      )}
      {(account.role.includes(roles.PowerUser.value) ||
        account.role.includes('poweruser') ||
        account.role.includes(roles.Admin.value) ||
        account.role.includes('admin')) && (
        <>
          <Route
            path="/useCases"
            element={
              <Layout>
                <DimensionProvider>
                  <IndustryProvider>
                    <UseCases />
                  </IndustryProvider>
                </DimensionProvider>
              </Layout>
            }
          />
          <Route
            path="/thematics"
            element={
              <Layout>
                <DimensionProvider>
                  <Thematics />
                </DimensionProvider>
              </Layout>
            }
          />
          <Route
            path="/tags"
            element={
              <Layout>
                <DimensionProvider>
                  <TagsProvider>
                    <Tags />
                  </TagsProvider>
                </DimensionProvider>
              </Layout>
            }
          />
          <Route
            path="/paDashboard"
            element={
              <Layout>
                <AssessmentProvider>
                  <MaturityProvider>
                    <PADashbard />
                  </MaturityProvider>
                </AssessmentProvider>
              </Layout>
            }
          />
          <Route
            path="/clients"
            element={
              <Layout>
                <AssessmentProvider>
                  <MaturityProvider>
                    <Clients />
                  </MaturityProvider>
                </AssessmentProvider>
              </Layout>
            }
          />
          <Route
            path="/adminQuestions"
            element={
              <Layout>
                <DimensionProvider>
                  <IndustryProvider>
                    <AdminQuestions />
                  </IndustryProvider>
                </DimensionProvider>
              </Layout>
            }
          />
          <Route
            path="recommendation/recommendations"
            element={
              <Layout>
                <DimensionProvider>
                  <TagsProvider>
                    <MaturityProvider>
                      <IndustryProvider>
                        <Recommendations />
                      </IndustryProvider>
                    </MaturityProvider>
                  </TagsProvider>
                </DimensionProvider>
              </Layout>
            }
          />

          <Route
            path="evaluations"
            element={
              <Layout>
                <DimensionProvider>
                  <IndustryProvider>
                    <MaturityProvider>
                      <Evaluation />
                    </MaturityProvider>
                  </IndustryProvider>
                </DimensionProvider>
              </Layout>
            }
          />
        </>
      )}

      {(account.role.includes('client') ||
        account.role.includes('poweruser') ||
        account.role.includes('admin')) && (
        <>
          <Route
            path="/profile/personalInformation"
            element={
              <Layout>
                <ProfileLayout>
                  <PersonalInfo />
                </ProfileLayout>
              </Layout>
            }
          />
          <Route
            path="/profile/companyProfile"
            element={
              <Layout>
                <ProfileLayout>
                  <IndustryProvider>
                    <CompanyProvider>
                      <Company />
                    </CompanyProvider>
                  </IndustryProvider>
                </ProfileLayout>
              </Layout>
            }
          />

          <Route
            path="/profile/password"
            element={
              <Layout>
                <ProfileLayout>
                  <Password />
                </ProfileLayout>
              </Layout>
            }
          />
          <Route
            path="/profile/deleteAccount"
            element={
              <Layout>
                <ProfileLayout>
                  <DeleteAccount />
                </ProfileLayout>
              </Layout>
            }
          />
        </>
      )}

      <Route
        path="/whatismysensai"
        element={
          <Layout>
            <WhatIsMySens />
          </Layout>
        }
      />

      <Route
        path="/knowledgelibrary"
        element={
          <Layout>
            <KnowledgeLibrary />
          </Layout>
        }
      />

      <Route
        path="/"
        element={
          <Layout>
            <AuthHome />
          </Layout>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}
