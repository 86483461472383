import {
  memo,
  useMemo,
  useContext,
  createContext,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { getAssessment } from './api'
import { useAuth } from '../auth/AuthProvider'

const AssessmentContext = createContext()
AssessmentContext.displayName = 'AssessmentContext'

const AssessmentProvider = memo(({ children }) => {
  const { account } = useAuth()
  const assessment = useRef([])
  const [assessmentDetail, setAssessmentDetail] = useState([])
  const [assessmentDetailLoading, setAssessmentDetailLoading] = useState(false)
  const [assessmentDetailError, setAssessmentDetailError] = useState(false)
  const [selectedDimensions, setSelectedDimensions] = useState([])
  const [next, setNext] = useState(false)
  const [activeStep, setAtiveStep] = useState(0)
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    if (account.role === 'client') {
      setAssessmentDetailLoading(true)
      setAssessmentDetailError(false)
      getAssessment(account.id)
        .then((res) => {
          setAssessmentDetail(res)
          setAssessmentDetailLoading(false)
        })
        .catch((error) => {
          if (error.message && error.message === 'not found') {
            setAssessmentDetail([])
            setAssessmentDetailLoading(false)
          } else {
            setAssessmentDetailError(true)
          }
        })
    }
  }, [account.id, account.role])

  const handleNext = useCallback(() => {
    setNext(true)
  }, [])

  const nextStep = useCallback(() => {
    setAtiveStep(activeStep + 1)
  }, [activeStep])

  const value = useMemo(
    () => ({
      selectedDimensions,
      setSelectedDimensions,
      next,
      handleNext,
      activeStep,
      nextStep,
      assessment,
      assessmentDetail,
      setAssessmentDetail,
      assessmentDetailLoading,
      assessmentDetailError,
      questions,
      setQuestions,
    }),
    [
      selectedDimensions,
      setSelectedDimensions,
      next,
      handleNext,
      activeStep,
      nextStep,
      assessment,
      assessmentDetail,
      setAssessmentDetail,
      assessmentDetailLoading,
      assessmentDetailError,
      questions,
      setQuestions,
    ],
  )

  return (
    <AssessmentContext.Provider value={value}>
      {children}
    </AssessmentContext.Provider>
  )
})
export default AssessmentProvider
export function useAssessment() {
  const context = useContext(AssessmentContext)
  if (context === undefined) {
    throw new Error('useAssessment must be used within a AssessmentProvider')
  }
  return context
}

AssessmentProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
