import { useEffect, useState } from 'react'
import CompanyGoal from './CompanyGoal'
import Assessment from './Assessment'
import { getCompanyGoal } from '../../modules/companyGoal/api'
import { useAuth } from '../../modules/auth/AuthProvider'
import FullPageSpinner from '../../components/FullPageSpinner'

export default function AssessmentSwitcher() {
  const { company } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [isCompanyGoal, setIsCompanyGoal] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    getCompanyGoal(company.id)
      .then(() => {
        setIsCompanyGoal(false)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        if (err.message === 'CompanyGoal not found') {
          setIsCompanyGoal(true)
        }
      })
  }, [company.id])

  if (isLoading) {
    return <FullPageSpinner />
  }
  return isCompanyGoal ? <CompanyGoal /> : <Assessment />
}
