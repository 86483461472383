import { useState, useEffect, useCallback } from 'react'
import {
  // useNavigate,
  useLocation,
} from 'react-router-dom'
import {
  Grid,
  Button,
  CircularProgress,
  Typography,
  Alert,
  Stack,
} from '@mui/material'
import AuthLayout from '../../modules/auth/components/AuthLayout'
import Login from '../../modules/auth/components/Login'
import Register from '../../modules/auth/components/Register'
import ForgotPasswrod from '../../modules/auth/components/ForgotPassword'
import ValidateAccount from '../../modules/auth/components/ValidateAccount'
import { useAuth } from '../../modules/auth/AuthProvider'
import { useAlert } from '../../modules/alert/AlertProvider'

export default function Auth() {
  const location = useLocation()
  const [signIn, setSignIn] = useState(
    !new URLSearchParams(location.search).get('validation'),
  )
  const [validation, setValidation] = useState(
    new URLSearchParams(location.search).get('validation'),
  )

  const [signUp, setSignUp] = useState(false)
  const [forget, setForget] = useState(false)

  const [username, setUsername] = useState(
    new URLSearchParams(location.search).get('email'),
  )
  const [loading, setLoading] = useState(false)
  const [registerError, setRegisterError] = useState(false)
  const [credentialsError, setCredentialsError] = useState(false)
  const [addressMail, setAddressMail] = useState('')
  const { showAlert } = useAlert()
  const [counter, setCounter] = useState(60)
  const [waiting, setWaiting] = useState(false)
  const {
    login,
    registerUser,
    resentCode,
    verifyUser,
    account,
    forgotPasswrod,
  } = useAuth()

  const handleLogin = (data) => {
    setUsername(data.username)
    setLoading(true)
    login(data)
      .then((accountData) => {
        if (!accountData.is_verified) {
          setSignIn(false)
          setValidation(true)
          resentCode(data.username)
        }

        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        if (error.detail === 'LOGIN_BAD_CREDENTIALS') {
          setCredentialsError(true)
        }
      })
  }

  const handleRegister = (data) => {
    setAddressMail(data.email)
    setLoading(true)
    registerUser(data)
      .then(() => {
        showAlert(
          'Votre compte a été crée avec succès, nous allons vous envoyer un mail pour vérifier votre compte',
          'success',
        )
        setLoading(false)
        setSignUp(false)
        setCounter(60)
        resentCode(data.email)
        setValidation(true)
      })
      .catch((error) => {
        if (error.detail === 'REGISTER_USER_ALREADY_EXISTS') {
          setRegisterError(true)
        } else {
          showAlert('Une erreur est survenue, Veuillez réessayer', 'error')
        }
        setLoading(false)
      })
  }

  const handleConfirmUser = ({ email, code }) => {
    setLoading(true)
    verifyUser({ email, code })
      .then((result) => {
        if (result.success === 'False') {
          showAlert(
            'Le code de vérification est incorrect, Veuillez réessayer',
            'error',
          )
        } else {
          showAlert(
            'Congratulation! Votre compte a été vérifié avec succès',
            'success',
          )
          setValidation(false)
          setSignIn(true)
        }
      })
      .catch((e) => {
        if (e.status) {
          switch (e.status) {
            case 422:
              showAlert(
                'Le code de vérification est incorrect, Veuillez réessayer',
                'error',
              )
              break
            case 400:
            case 500:
              showAlert(
                'Le code de vérification est expiré, Veuillez demander un nouveau code',
              )
              break
            default:
              showAlert('Une erreur est survenue, Veuillez réessayer', 'error')
              break
          }
        } else {
          showAlert(
            'Le code de vérification est expiré, Veuillez demander un nouveau code',
          )
        }
      })
    setLoading(false)
  }
  const handleResentCode = useCallback(
    (email) => {
      setWaiting(true)
      resentCode(email)
        .then(() => {
          showAlert('Code de confirmation renvoyé avec succès', 'success')
          setWaiting(false)
          setCounter(30)
        })
        .catch(() => {
          setWaiting(false)
          showAlert('une erreur est survenue', 'error')
        })
    },
    [resentCode, showAlert],
  )

  const handleForgetPassword = (data) => {
    setLoading(true)
    forgotPasswrod(data.username)
      .then(() => {
        setCredentialsError(false)
        setForget(false)
        setValidation(false)
        setSignUp(false)
        setSignIn(true)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setCredentialsError(false)
    setRegisterError(false)
  }, [signIn, signUp, forget])

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])

  return (
    <AuthLayout>
      {signUp && (
        <Register submit={handleRegister}>
          {registerError && (
            <Stack
              sx={{
                width: '100%',
              }}
              mb={2}
            >
              <Alert severity="error">
                Utilisateur existe déja , Veuillez réessayer avec autre adress
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 600,
                    cursor: 'pointer',
                    display: 'flex',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={() => {
                    setSignUp(false)
                    setSignIn(true)
                    setForget(false)
                  }}
                >
                  Déjà exist ? Login
                </Typography>
              </Alert>
            </Stack>
          )}
          <Button
            fullWidth
            type="submit"
            variant="mysensContained"
            sx={{ mt: '16px', mb: '24px' }}
          >
            {loading && (
              <CircularProgress
                color="secondary"
                size={20}
                sx={{ marginRight: '1rem' }}
              />
            )}
            S&apos;inscrire
          </Button>
        </Register>
      )}

      {validation && (
        <ValidateAccount
          email={username}
          submit={(data) => {
            const email =
              addressMail ||
              account?.email ||
              new URLSearchParams(location.search).get('email')
            handleConfirmUser({ email, code: data.code })
          }}
        >
          <Button
            disabled={counter > 0}
            size="small"
            fullWidth
            sx={{ mt: 2 }}
            onClick={() => {
              const email = addressMail || account?.email
              handleResentCode(email)
            }}
          >
            {waiting && (
              <CircularProgress
                color="secondary"
                size={20}
                sx={{ marginRight: '1rem' }}
              />
            )}
            Renvoyer le code
            {counter > 0 && (
              <Typography sx={{ m: 0, pl: 2, color: 'red' }}>
                00:{counter >= 10 ? counter : `0${counter}`}
              </Typography>
            )}
          </Button>

          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="mysensContained"
              sx={{ mt: '16px', mb: '24px' }}
            >
              {loading && (
                <CircularProgress
                  color="secondary"
                  size={20}
                  sx={{ marginRight: '1rem' }}
                />
              )}
              Valider
            </Button>
          </Grid>
        </ValidateAccount>
      )}

      {signIn && (
        <Login email={username} submit={handleLogin}>
          {credentialsError && (
            <Stack
              sx={{
                width: '100%',
              }}
              mb={2}
            >
              <Alert severity="error">
                Email ou mot de passe incorrect
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 600,
                    cursor: 'pointer',
                    display: 'flex',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={() => {
                    setSignUp(false)
                    setSignIn(false)
                    setForget(true)
                  }}
                >
                  mot de passe oublié ?
                </Typography>
              </Alert>
            </Stack>
          )}

          <Button
            type="submit"
            variant="mysensContained"
            fullWidth
            sx={{ mt: '16px', mb: '24px' }}
          >
            {loading && (
              <CircularProgress
                size={20}
                sx={{ color: 'white', marginRight: '1rem' }}
              />
            )}
            Connecter
          </Button>
        </Login>
      )}
      {forget && (
        <ForgotPasswrod email={username} submit={handleForgetPassword}>
          <Button
            type="submit"
            variant="mysensContained"
            fullWidth
            sx={{ mt: '16px', mb: '24px' }}
          >
            {loading && (
              <CircularProgress
                size={20}
                sx={{ color: 'white', marginRight: '1rem' }}
              />
            )}
            Envoyer
          </Button>
        </ForgotPasswrod>
      )}

      <Typography
        component="div"
        variant="body1"
        sx={{
          color: 'black.black75',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 2.5,
        }}
      >
        {signUp || forget
          ? 'Vous avez déja un compte?'
          : "Vous n'avez pas un compte?"}

        <Typography
          variant="body1"
          sx={{
            fontWeight: 600,
            cursor: 'pointer',
            paddingLeft: '8px',
            color: 'mysensPalette.lightBlue',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          onClick={() => {
            if (signUp || forget) {
              setSignUp(false)
              setSignIn(true)
              setForget(false)
            } else {
              setSignUp(true)
              setSignIn(false)
              setValidation(false)
            }
          }}
        >
          {signUp || forget ? 'Connecter' : 'Inscrivez-vous'}
        </Typography>
      </Typography>
    </AuthLayout>
  )
}
