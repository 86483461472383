import { Box, Typography, Grid } from '@mui/material'

function WhatIsMySensDesc() {
  return (
    <Box>
      <Typography variant="h5">What’s MySens.AI</Typography>
      <Box mt={2}>
        <Typography variant="body1">
          Assessment tool for AI maturity that helps organizations understand
          which strategically important areas need to be strengthened in order
          to successfully implement AI in operations – Ambition, Use cases,
          Data, Technology, Organization, Ecosystem, Expertise, Culture and
          Execution. The tool provides an overview of where your organization
          stands right now and sets the scene for identifying the optimal
          roadmap for your AI journey.
        </Typography>
      </Box>
      <Box mt={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Typography variant="body1">
              Assessment tool for AI maturity that helps organizations
              understand which strategically important areas need to be
              strengthened in order to successfully implement AI in operations –
              Ambition, Use cases, Data, Technology, Organization, Ecosystem,
              Expertise, Culture and Execution. The tool provides an overview of
              where your organization stands right now and sets the scene for
              identifying the optimal roadmap for your AI journey.
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                width="350px"
                height="200px"
                src="/images/whatismysensai/circle.png"
                alt="what is my sens ai"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

function MaturityIndex() {
  return (
    <Box mt={6}>
      <Box>
        <Typography variant="h5">MySens.ai Meta maturity Index</Typography>
        <Box mt={2}>
          <Typography variant="body1">
            The goal of the AI Maturity Model is to provide insight in your
            current competencies and ways to improve them. The AI Maturity Model
            both looks at the Analytical Capabilities and Business Adoption
            within your organization.
          </Typography>
          <Typography variant="body1" mt={1}>
            Indicate your maturity level across all the aspects of AI Maturity
            and receive an insight in the estimated maturity level of your
            organization.
          </Typography>
          <Typography variant="body1" mt={1}>
            Indicate your maturity level across all the aspects of AI Maturity
            and receive an insight in the estimated maturity level of your
            organization.
          </Typography>
        </Box>
      </Box>
      <Box mt={2}>
        <Typography
          variant="body1"
          sx={{
            fontSize: 20,
            color: 'primary.main',
          }}
        >
          Level of IA Driven Marutity Index
        </Typography>
        <Box mt={2} pl={2}>
          <Typography variant="body1">
            Digitalization - Connectivity - Optimisation - Adaptability
          </Typography>
          <Box mt={2}>
            <Box mt={1}>
              <Typography
                variant="body1"
                sx={{
                  color: '#d48a79',
                  fontWeight: 'bold',
                }}
              >
                Digitalization = AI preliminary + AI aware
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography
                variant="body1"
                sx={{
                  color: '#DB944B',
                  fontWeight: 'bold',
                }}
              >
                Connectivity = AI Ready + AI experimenter
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography
                variant="body1"
                sx={{
                  color: '#78a659',
                  fontWeight: 'bold',
                }}
              >
                Optimisation = AI Builder + AI Innovator
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography
                variant="body1"
                sx={{
                  color: '#4f84c1',
                  fontWeight: 'bold',
                }}
              >
                Adaptability = AI Achiever + AI Shaper
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box mt={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Box>
              <Typography variant="body1">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                Maecenas porttitor congue massa. Fusce posuere, magna sed
                pulvinar ultricies, purus lectus malesuada libero, sit amet
                commodo magna eros quis urna. Nunc viverra imperdiet enim
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography variant="body1">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                Maecenas porttitor congue massa. Fusce posuere, magna sed
                pulvinar ultricies, purus lectus malesuada libero, sit amet
                commodo magna eros quis urna. Nunc viverra imperdiet enim
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                width="350px"
                height="250px"
                src="/images/whatismysensai/keys.png"
                alt="Level of IA Driven Marutity Index"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box mt={4}>
        <Typography variant="body1">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Maecenas
          porttitor congue massa. Fusce posuere, magna sed pulvinar ultricies,
          purus lectus malesuada libero, sit amet commodo magna eros quis urna.
          Nunc viverra imperdiet enim
        </Typography>
        <Box
          mt={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            width="100%"
            height="350px"
            src="/images/whatismysensai/path.png"
            alt="Level of IA Driven Marutity Index"
          />
        </Box>
      </Box>
    </Box>
  )
}

export default function WhatIsMySens() {
  return (
    <Box>
      <WhatIsMySensDesc />
      <MaturityIndex />
    </Box>
  )
}
