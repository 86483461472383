import BusinessIcon from '@mui/icons-material/Business'
import ExtensionIcon from '@mui/icons-material/Extension'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import ListAltIcon from '@mui/icons-material/ListAlt'
import ComputerIcon from '@mui/icons-material/Computer'
import GavelIcon from '@mui/icons-material/Gavel'

import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService'
import BalanceIcon from '@mui/icons-material/Balance'
// import PaymentsIcon from '@mui/icons-material/Payments'
import SecurityIcon from '@mui/icons-material/Security'

export const dimensionsIcons = {
  'Organisation & Écosystème': (
    <BusinessIcon
      sx={{
        fontSize: 'inherit',
      }}
    />
  ),
  Stratégies: (
    <LightbulbIcon
      sx={{
        fontSize: 'inherit',
      }}
    />
  ),
  'Human & Culture': (
    <ExtensionIcon
      sx={{
        fontSize: 'inherit',
      }}
    />
  ),
  'Opération & Processus': (
    <ListAltIcon
      sx={{
        fontSize: 'inherit',
      }}
    />
  ),
  'Gouvernance de données': (
    <GavelIcon
      sx={{
        fontSize: 'inherit',
      }}
    />
  ),
  Technologies: (
    <ComputerIcon
      sx={{
        fontSize: 'inherit',
      }}
    />
  ),
  'Produits & Services': (
    <HomeRepairServiceIcon
      sx={{
        fontSize: 'inherit',
      }}
    />
  ),
  'Éthique & Impacts ESG': (
    <BalanceIcon
      sx={{
        fontSize: 'inherit',
      }}
    />
  ),
  Cybersécurité: (
    <SecurityIcon
      sx={{
        fontSize: 'inherit',
      }}
    />
  ),
  // Finances: (
  //   <PaymentsIcon
  //     sx={{
  //       fontSize: 'inherit',
  //     }}
  //   />
  // ),
}

// export const dimensionsIcons = {
//   env: (
//     <BusinessIcon
//       sx={{
//         fontSize: 'inherit',
//       }}
//     />
//   ),
//   strategy: (
//     <LightbulbIcon
//       sx={{
//         fontSize: 'inherit',
//       }}
//     />
//   ),
//   culture: (
//     <ExtensionIcon
//       sx={{
//         fontSize: 'inherit',
//       }}
//     />
//   ),
//   process: (
//     <ListAltIcon
//       sx={{
//         fontSize: 'inherit',
//       }}
//     />
//   ),
//   datagov: (
//     <GavelIcon
//       sx={{
//         fontSize: 'inherit',
//       }}
//     />
//   ),
//   techno: (
//     <ComputerIcon
//       sx={{
//         fontSize: 'inherit',
//       }}
//     />
//   ),
//   services: (
//     <HomeRepairServiceIcon
//       sx={{
//         fontSize: 'inherit',
//       }}
//     />
//   ),
//   ethic: (
//     <BalanceIcon
//       sx={{
//         fontSize: 'inherit',
//       }}
//     />
//   ),
//   finances: (
//     <PaymentsIcon
//       sx={{
//         fontSize: 'inherit',
//       }}
//     />
//   ),
// }
