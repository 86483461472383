import {
  useEffect,
  useState,
  memo,
  createContext,
  useMemo,
  useContext,
} from 'react'
import PropTypes from 'prop-types'
import { getIndustries } from './api'

const IndustryContext = createContext()
IndustryContext.displayName = 'IndustryContext'

const IndustryProvider = memo(({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [industries, setIndustries] = useState([])

  useEffect(() => {
    setIsError(false)
    setIsLoading(true)
    getIndustries()
      .then((res) => {
        setIndustries(res.data)
        setIsLoading(false)
      })
      .catch(() => {
        setIsError(true)
        setIsLoading(false)
      })
  }, [])

  const value = useMemo(
    () => ({ industries, isError, isLoading }),
    [industries, isError, isLoading],
  )

  return (
    <IndustryContext.Provider value={value}>
      {children}
    </IndustryContext.Provider>
  )
})

export default IndustryProvider
export function useIndustries() {
  const context = useContext(IndustryContext)
  if (context === undefined) {
    throw new Error('useIndustries must be used within a IndustryProvider')
  }
  return context
}

IndustryProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
