import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  CircularProgress,
  TextField,
  Autocomplete,
  FormHelperText,
  FormControl,
  InputLabel,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useAlert } from '../../alert/AlertProvider'
import { useAuth } from '../../auth/AuthProvider'
import { postRecommendation, updateRecommendation } from '../api'
import { useTags } from '../../tags/TagsProvider'
import { useCache } from '../../cache/CacheProvider'
import MultiSelect from '../../../components/MultiSelect'
import Editor from '../../../components/Editor'
import { completion } from '../../../utils/openapi'

export default function RecommendationForm({
  dimensions,
  maturities,
  industries,
  action,
  recommendationData,
  handleClose,
  setRecommendations,
  setRows,
}) {
  const { industryType, saveIndustryType } = useCache()
  const [loading, setLoading] = useState(false)
  const { showAlert } = useAlert()

  // const { dimensions } = useDimension()
  const { account } = useAuth()
  const { tags } = useTags()
  // const { maturities } = useMaturities()
  const [completionText, setCompletionText] = useState('')
  const [isFocused, setIsFocused] = useState(false)

  const {
    handleSubmit,
    control,
    watch,
    register,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: recommendationData?.name || '',
      content: recommendationData?.content || '',
      prerequisite: recommendationData?.prerequisite || '',
      type:
        recommendationData?.dimensions &&
        JSON.parse(recommendationData.dimensions).length > 0
          ? 'Recommedation spécifique au dimensions'
          : 'Recommedation globale',
      tags: recommendationData?.tags
        ? JSON.parse(recommendationData?.tags)
        : [],
      dimensions: recommendationData?.dimensions
        ? JSON.parse(recommendationData?.dimensions)
        : [],
      // maturityLevel: recommendationData?.maturity_level || null,
      maturities: recommendationData?.maturities
        ? JSON.parse(recommendationData?.maturities)
        : [],
      industries: recommendationData?.industries
        ? JSON.parse(recommendationData?.industries)
        : industryType || [],
      // companyIndustryType:
      //   recommendationData?.industries || industryType || null,
    },
  })
  useEffect(() => {
    if (isFocused && getValues('name')) {
      const name = getValues('name')
      setCompletionText('chargement ...')
      completion(name)
        .then((res) => {
          if (res.data) {
            const result = res.data.choices[0].text
            setCompletionText(result)
          }
        })
        .catch(() => {
          setCompletionText('pas de resultat')
        })

      setIsFocused(false)
    }
  }, [isFocused, getValues])

  const addRecommendation = (sendData) => {
    postRecommendation({
      ...sendData,
      created_by: account.id,
    })
      .then((res) => {
        setRows((prevRows) => [
          {
            // ...sendData,
            ...res.data,
            id: res.data.id,
            // maturity_level: sendData.maturityLevel,
            company_industry_type: sendData.industries,
          },
          ...prevRows,
        ])
        setRecommendations((prevQuestions) => [
          {
            // ...sendData,
            ...res.data,
            id: res.data.id,
            // maturity_level: sendData.maturityLevel,
            company_industry_type: sendData.industries,
          },
          ...prevQuestions,
        ])
        handleClose()
        showAlert('La recommendation a été ajouté avec success', 'success')
      })
      .catch(() => {
        showAlert("Une erreur s'est produite", 'error')
      })
  }

  const editRecommendation = (sendData) => {
    updateRecommendation(recommendationData.id, sendData)
      .then(() => {
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === recommendationData?.id
              ? {
                  ...sendData,
                  id: recommendationData?.id,
                  // maturity_level: sendData.maturityLevel,
                  company_industry_type: sendData.industries,
                }
              : row,
          ),
        )
        setRecommendations((prevQuestions) =>
          prevQuestions.map((row) =>
            row.id === recommendationData?.id
              ? {
                  ...sendData,
                  id: recommendationData?.id,
                  // maturity_level: sendData.maturityLevel,
                  company_industry_type: sendData.industries,
                }
              : row,
          ),
        )
        handleClose()
        showAlert('La recommendation a été modifié avec success', 'success')
      })
      .catch(() => {
        showAlert("Une erreur s'est produite", 'error')
      })
  }

  const onSubmit = (data) => {
    setLoading(true)
    const sendData = {
      ...data,
      name: data.name,
      content: data.content,
      prerequisite: data.prerequisite,
      tags: JSON.stringify(data.tags),
      dimensions:
        data.type === 'Recommedation spécifique au dimensions'
          ? JSON.stringify(data.dimensions)
          : JSON.stringify([]),
      maturities: JSON.stringify(data.maturities),
      // maturity_level_id: data.maturityLevel.id,
      industries: JSON.stringify(data.industries),
      // industries: JSON.stringify(data.industries),
    }

    if (action === 'add') {
      addRecommendation(sendData)
    }
    if (action === 'edit') {
      // if (recommendationData.created_by_id !== account.id) {
      //   addRecommendation(sendData)
      // } else {
      editRecommendation({
        ...recommendationData,
        ...sendData,
      })
      // }
    }

    saveIndustryType(data.industries)
    setLoading(false)
  }

  return (
    <Box
      sx={{
        boxShadow: 24,
        p: 4,
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <>
        <Box>
          {/* Name */}
          <Box>
            <TextField
              {...register('name', {
                required: {
                  value: true,
                  message: 'Veuillez saisir une valeur',
                },
              })}
              margin="normal"
              fullWidth
              id="name"
              label="Nom de la recommendation"
              name="name"
              autoComplete="name"
              autoFocus
              helperText={errors.name?.message}
              error={!!errors.name}
            />
          </Box>
          {/* /Name */}
          {/* Content */}
          <Box>
            <InputLabel
              htmlFor="content"
              sx={{
                mt: 1,
                ml: 1,
                mb: 1,
              }}
            >
              Description
            </InputLabel>
            <Controller
              name="content"
              control={control}
              rules={{
                required: 'Veuillez insérer le text',
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <Editor
                    initialValue={
                      recommendationData?.content || completionText || ''
                    }
                    placeholder="Insérer le nom et veuillez patienter quelques instans pour générer le contenu automatiquement"
                    onChange={onChange}
                    height={450}
                    onFocus={() => {
                      if (!value) {
                        setIsFocused(true)
                      } else {
                        setIsFocused(false)
                      }
                    }}
                  />
                </FormControl>
              )}
            />
            <Box pl={2}>
              <FormHelperText error={Boolean(errors.content)}>
                {errors.content?.message}
              </FormHelperText>
            </Box>
          </Box>
          {/* /Content */}
          {/* prerequisite */}

          <Box>
            <InputLabel
              htmlFor="prerequisite"
              sx={{
                mt: 1,
                ml: 1,
                mb: 1,
              }}
            >
              Prérequis
            </InputLabel>
            <Controller
              name="prerequisite"
              control={control}
              rules={{
                required: 'Veuillez insérer le text',
              }}
              render={({ field: { onChange } }) => (
                <FormControl fullWidth>
                  <Editor
                    initialValue={recommendationData?.prerequisite || ''}
                    onChange={onChange}
                    height={200}
                  />
                </FormControl>
              )}
            />
            <Box pl={2}>
              <FormHelperText error={Boolean(errors.prerequisite)}>
                {errors.prerequisite?.message}
              </FormHelperText>
            </Box>
          </Box>

          {/* /prerequisite */}
          {/* Type */}
          <Box mt={2}>
            <Controller
              control={control}
              name="type"
              rules={{
                required: 'Veuillez choisir une réponse',
              }}
              render={({ field: { onChange } }) => (
                <Autocomplete
                  defaultValue={
                    recommendationData?.dimensions &&
                    JSON.parse(recommendationData.dimensions).length > 0
                      ? 'Recommedation spécifique au dimensions'
                      : 'Recommedation globale'
                  }
                  options={[
                    'Recommedation spécifique au dimensions',
                    'Recommedation globale',
                  ]}
                  getOptionLabel={(option) => option}
                  onChange={(event, values) => {
                    onChange(values)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Type de recommendation"
                      placeholder="Type de recommendation"
                      helperText={errors.type?.message}
                      error={!!errors.type}
                    />
                  )}
                />
              )}
            />
          </Box>
          {/* /Type */}
          {/* Dimensions */}
          {watch('type') === 'Recommedation spécifique au dimensions' && (
            <Box mt={2}>
              <Controller
                control={control}
                name="dimensions"
                rules={{
                  required: 'Veuillez choisir une réponse',
                }}
                render={({ field: { onChange } }) => (
                  <MultiSelect
                    defaultValue={
                      recommendationData?.dimensions
                        ? JSON.parse(recommendationData?.dimensions)
                        : []
                    }
                    items={dimensions}
                    label="Dimensions"
                    selectAllLabel="Sélectionner Tous"
                    onChange={onChange}
                    helperText={errors.dimensions?.message}
                    error={Boolean(errors.dimensions)}
                  />
                )}
              />
            </Box>
          )}
          {/* /Dimensions */}
          {/* Tags */}
          <Box mt={2}>
            <Controller
              control={control}
              name="tags"
              rules={{
                required: 'Veuillez choisir une réponse',
              }}
              render={({ field: { onChange } }) => (
                <Autocomplete
                  defaultValue={
                    recommendationData?.tags
                      ? JSON.parse(recommendationData?.tags)
                      : []
                  }
                  multiple
                  options={tags}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, values) => {
                    onChange(values)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Métadonnées"
                      placeholder="Métadonnées"
                      helperText={errors.tags?.message}
                      error={!!errors.tags}
                    />
                  )}
                />
              )}
            />
          </Box>
          {/* /Tags */}
          {/* Maturity level */}
          <Box mt={2}>
            <Controller
              control={control}
              name="maturities"
              rules={{
                required: 'Veuillez choisir une réponse',
              }}
              render={({ field: { onChange } }) => (
                <MultiSelect
                  defaultValue={
                    recommendationData?.maturities
                      ? JSON.parse(recommendationData?.maturities)
                      : []
                  }
                  items={maturities}
                  // items={maturityLevels}
                  label="Niveau de maturité"
                  selectAllLabel="Sélectionner Tous"
                  onChange={onChange}
                />
              )}
            />
            <FormHelperText
              error={Boolean(errors.maturities)}
              sx={{
                pl: 2,
              }}
            >
              {errors.maturities?.message}
            </FormHelperText>
          </Box>
          {/* /Maturity level */}
          {/* industries */}
          <Box mt={2}>
            <Controller
              control={control}
              name="industries"
              rules={{
                required: 'Veuillez choisir une réponse',
              }}
              render={({ field: { onChange } }) => (
                <MultiSelect
                  defaultValue={
                    recommendationData?.industries
                      ? JSON.parse(recommendationData?.industries)
                      : industryType || []
                  }
                  items={industries}
                  label="Industries"
                  selectAllLabel="Sélectionner Tous"
                  onChange={onChange}
                />
              )}
            />
            <FormHelperText
              error={Boolean(errors.industries)}
              sx={{
                pl: 2,
              }}
            >
              {errors.industries?.message}
            </FormHelperText>
          </Box>
          {/* /industries */}
        </Box>

        <Button
          startIcon={
            loading ? (
              <CircularProgress size={20} />
            ) : (
              <AddIcon color="white" size="small" />
            )
          }
          type="submit"
          fullWidth
          disabled={loading}
          variant="mysensContained"
          sx={{ mt: 2 }}
        >
          Enregistrer
        </Button>
      </>
    </Box>
  )
}
RecommendationForm.propTypes = {
  dimensions: PropTypes.array,
  maturities: PropTypes.array,
  industries: PropTypes.array,
  action: PropTypes.string,
  recommendationData: PropTypes.object,
  handleClose: PropTypes.func,
  setRecommendations: PropTypes.func,
  setRows: PropTypes.func,
}
