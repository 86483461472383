/* eslint-disable no-useless-escape */
/* eslint-disable no-restricted-properties */

import { differenceInDays, isValid, isWeekend } from 'date-fns'
import { parsePhoneNumber } from 'libphonenumber-js'

export const mustDateBeGreaterOrEqualDate =
  (date2, label = '') =>
  (date1) =>
    date1 && date2 && differenceInDays(new Date(date1), new Date(date2)) >= 0
      ? `La date ne doit pas être inférieure à ${label || 'date2'}!`
      : undefined

export const mustNotBeWeekend = (value) =>
  value && isWeekend(new Date(value))
    ? 'Cette date et un jour de weekend!'
    : undefined

export const mustBeGreaterOrEqualToday = (value) =>
  value && differenceInDays(new Date(value), new Date()) < 0
    ? "La date ne doit pas être inférieure à aujourd'hui!"
    : undefined

export function composeValidators(...validators) {
  return (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined,
    )
}

export const required = (value) =>
  (!value && typeof value !== 'number') ||
  (Array.isArray(value) && value.length === 0)
    ? 'Ce champ est requis!'
    : undefined

export const mustDateBeGreaterOrEualThan = (value1) => (value2) => {
  if (!value1 || !value2) {
    return undefined
  }
  const v1 = new Date(value1)
  const v2 = new Date(value2)
  v1.setHours(0, 0, 0, 0)
  v2.setHours(0, 0, 0, 0)
  return differenceInDays(v2, v1) < 0
    ? 'La date doit être postérieure à la 1ère date!'
    : undefined
}

export const mustDateBeLessThan = (value1) => (value2) => {
  if (!value1 || !value2) {
    return undefined
  }
  const v1 = new Date(value1)
  const v2 = new Date(value2)
  v1.setHours(0, 0, 0, 0)
  v2.setHours(0, 0, 0, 0)
  return differenceInDays(v1, v2) >= 0
    ? 'Vous devez insérer une date valide'
    : undefined
}

export const mustBeCode = (value, length = 6) => {
  const regex = new RegExp(`^[0-9]{${length}}$`)
  return regex.test(value)
    ? undefined
    : 'Code invalid!, Veuillez insérer un code de 6 chiffre!'
}

export const mustBeEmail = (value) =>
  !value ||
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value,
  )
    ? undefined
    : 'E-mail incorrect!'

export const mustBeNumber = (value) =>
  Number.isNaN(Number(value)) ? 'Doit être un nombre!' : undefined

export const mustBeValidDate = (value) =>
  value && !isValid(new Date(value)) ? 'Date invalid!' : undefined

export const mustContainSpecialChar = (value) =>
  /[!@#$%^&*(),.?":{}|<>_\-\+]/.test(value)
    ? undefined
    : 'Doit avoir au moins un caractère spécial!'

export const mustBeLongerThan = (minLength) => (value) =>
  (value && value.length > minLength) || !value
    ? undefined
    : `Doit avoir plus de ${minLength} caractères!`

export const mustBeShorterThan = (maxLength) => (value) =>
  (value && value.length < maxLength) || !value
    ? undefined
    : `Doit avoir moins de ${maxLength} caractères!`

export const mustContainUpperCase = (value) =>
  /[A-Z]/.test(value)
    ? undefined
    : 'Doit avoir au moins un caractère majuscule!'

export const mustContainlowerCase = (value) =>
  /[a-z]/.test(value)
    ? undefined
    : 'Doit avoir au moins un caractère minuscule!'

export const mustBePassword = (value) => {
  const error = composeValidators(
    mustBeLongerThan(6),
    mustContainSpecialChar,
    mustContainUpperCase,
    mustContainlowerCase,
  )(value)
  return error ? `Mot de passe ${error.toLowerCase()}` : undefined
}

export const mustBeSecureUrl = (value) =>
  !value ||
  /^https:\/\/(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
    value,
  )
    ? undefined
    : 'Doit être une URL sécurisée (https)'

export const mustBeConfirmPassword = (password) => (value) =>
  value === password ? undefined : 'Les mots de passe ne correspondent pas'

export const mustBeZipCode = (value) =>
  !value || /^[0-9]{5}$/.test(value) ? undefined : 'Code postal invalid!'

export const mustBeLinkdInUrl = (value) =>
  !value || /^http(s)?:\/\/(www.)?linkedin.com\/.+$/.test(value)
    ? undefined
    : 'Doit être une URL LinkdIn!'

export const mustBeAPhoneNumber = (value) => {
  if (!value) return undefined

  const errorMessage = "Le numéro de téléphone n'est pas valide"

  try {
    return parsePhoneNumber(value).isPossible() ? undefined : errorMessage
  } catch {
    return errorMessage
  }
}

export const mustBeGreaterThan = (minValue) => (value) =>
  !value || (value && parseInt(value, 10) > minValue)
    ? undefined
    : `Doit être plus grand que ${minValue} !`

export const mustBeGreaterOrEqualThan = (minValue) => (value) =>
  !value || (value && parseInt(value, 10) >= minValue)
    ? undefined
    : `Doit être plus grand ou égal ${minValue} !`

export const mustBeLessThan = (maxValue) => (value) =>
  !value || (value && parseInt(value, 10) < maxValue)
    ? undefined
    : `Doit être plus petit que ${maxValue} !`

export const mustBeLessOrEqualThan = (maxValue) => (value) =>
  !value || (value && parseInt(value, 10) <= maxValue)
    ? undefined
    : `Doit être plus petit que ou égal ${maxValue} !`
