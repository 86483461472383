const COUNTRY_STATE_CITY_URL = 'https://api.countrystatecity.in/v1'
const COUNTRY_STATE_CITY_API_KEY =
  process.env.REACT_APP_COUNTRY_STATE_CITY_API_KEY

const headers = new Headers()
headers.append('X-CSCAPI-KEY', COUNTRY_STATE_CITY_API_KEY)

export async function getCountries() {
  const url = `${COUNTRY_STATE_CITY_URL}/countries`
  try {
    const data = await fetch(url, {
      method: 'GET',
      headers,
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then((result) => ({
        data: result,
      }))
    return data
  } catch (error) {
    throw new Error(error)
  }
}

export async function getStateByCountry(countryIsoCode) {
  const url = `${COUNTRY_STATE_CITY_URL}/countries/${countryIsoCode}/states`
  try {
    const data = await fetch(url, {
      method: 'GET',
      headers,
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then((result) => ({
        data: result,
      }))
    return data
  } catch (error) {
    throw new Error(error)
  }
}

export async function getCitiesByStateAndCountry(countryIsoCode, stateIsoCode) {
  const url = `${COUNTRY_STATE_CITY_URL}/countries/${countryIsoCode}/states/${stateIsoCode}/cities`
  try {
    const data = await fetch(url, {
      method: 'GET',
      headers,
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then((result) => ({
        data: result,
      }))
    return data
  } catch (error) {
    throw new Error(error)
  }
}
