import { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  CircularProgress,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Button,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { generatePassword } from '../../../utils/generatePassword'
import { mustBeEmail, mustBePassword } from '../../../utils/form-validators'
import { useAuth } from '../../auth/AuthProvider'
import { postClient } from '../api'
import { useAlert } from '../../alert/AlertProvider'

export default function CreateClient({ handleClose, setClients, setRows }) {
  const { showAlert } = useAlert()
  const { account } = useAuth()
  const [loading, setLoading] = useState(false)
  const [showPassword, setshowPassword] = useState(false)

  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
    setLoading(true)
    const clientPassword = {
      admin_mail: account.email,
      admin: account.id,
      password: data.password,
    }

    const client = {
      email: data.email,
      password: data.password,
      firstName: data.email,
      lastName: data.email,
      phoneNumber: '+1',
      jobTitle_id: account.jobTitle_id,
      role: 'client',
    }
    postClient(clientPassword, client)
      .then((res) => {
        handleClose()
        setLoading(false)
        setRows((prevRows) => [
          {
            id: res.data.id,
            'Nom Complet': `${client.firstName} ${client.lastName}`,
            Email: client.email,
            Tel: client.phoneNumber,
            Status: 'En attente',
          },
          ...prevRows,
        ])
        setClients((prev) => [
          {
            id: res.data.id,
            'Nom Complet': `${client.firstName} ${client.lastName}`,
            Email: client.email,
            Tel: client.phoneNumber,
            Status: 'En attent',
          },
          ...prev,
        ])
        showAlert('Le client à été ajouter avec success', 'success')
      })
      .catch(() => {
        setLoading(false)
      })
  }
  const handleGeneratePassword = () => {
    const password = generatePassword()
    setValue('password', password)
    setshowPassword(true)
  }

  return (
    <Box
      sx={{
        boxShadow: 24,
        p: 4,
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <>
        <Typography
          variant="h5"
          textAlign="center"
          component="h1"
          gutterBottom
          sx={{
            color: 'black.black75',
          }}
        >
          Créer un client
        </Typography>

        <Box mt={2}>
          <Box>
            <TextField
              {...register('email', {
                required: 'Veuillez insérer votre email',
                validate: {
                  email: (value) => mustBeEmail(value),
                },
              })}
              fullWidth
              id="email"
              label="Adresse Mail"
              name="email"
              autoComplete="email"
              helperText={errors.email?.message}
              error={!!errors.email}
            />
          </Box>

          <Box mt={2}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setshowPassword(!showPassword)}
                          color="primary"
                          edge="end"
                        >
                          {!showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...register('password', {
                    required: 'Veuillez insirer votre mot de passe',
                    validate: {
                      password: (value) => mustBePassword(value),
                    },
                  })}
                  fullWidth
                  name="password"
                  label="Mot de passe"
                  id="password"
                  InputLabelProps={{ shrink: Boolean(field.value) }}
                  helperText={errors.password?.message}
                  error={!!errors.password}
                />
              )}
            />
          </Box>
          <Button
            size="small"
            onClick={handleGeneratePassword}
            sx={{
              mt: 1,
            }}
          >
            Générer mot de passe
          </Button>
        </Box>

        <Button
          startIcon={
            loading ? (
              <CircularProgress size={20} />
            ) : (
              <AddIcon color="white" size="small" />
            )
          }
          type="submit"
          fullWidth
          disabled={loading}
          variant="mysensContained"
          sx={{ mt: 2 }}
        >
          Enregistrer
        </Button>
      </>
    </Box>
  )
}
CreateClient.propTypes = {
  handleClose: PropTypes.func,
  setRows: PropTypes.func,
  setClients: PropTypes.func,
}
