import errorsHandler from './errors-handler'

const apiURL = process.env.REACT_APP_API_URL

export const TOKENS_STORAGE_KEY = 'sciigoTokens'
export const ACCOUNT_STORAGE_KEY = 'sciigoAccount'
export const COMPANY_STORAGE_KEY = 'sciigoCompany'

export const setLocalStorageItem = (ITEM_STORAGE_KEY, item) =>
  window.localStorage.setItem(ITEM_STORAGE_KEY, JSON.stringify(item))

export const getLocalStorageItem = (ITEM_STORAGE_KEY) => {
  const item = window.localStorage.getItem(ITEM_STORAGE_KEY)
  return item ? JSON.parse(item) : null
}

function encodeUrl(url) {
  const splitedUrl = url.split('?')

  if (splitedUrl.length !== 2) {
    return url
  }

  const params = splitedUrl[1].split('&').map((item) => {
    const splitedItem = item.split('=')
    return `${splitedItem[0]}=${encodeURIComponent(splitedItem[1])}`
  })

  return `${splitedUrl[0]}?${params.join('&')}`
}

export default async function api(
  endpoint,
  { data, headers: customHeaders, isPrivate = true, ...customConfig } = {},
) {
  const { access_token: accessToken } =
    getLocalStorageItem(TOKENS_STORAGE_KEY) || {}
  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization:
        isPrivate && accessToken ? `Bearer ${accessToken}` : undefined,
      'Content-Type': data ? 'application/json' : undefined,
      ...customHeaders,
    },
    ...customConfig,
  }

  const url = encodeUrl(`${apiURL}/${endpoint}`)

  return fetch(url, config).then(async (response) => {
    // sign out from the app if has an expired token
    if (response.status === 401) {
      window.localStorage.clear()
      window.sessionStorage.clear()
      window.location.assign(window.location)
      return Promise.reject(new Error('Please re-authenticate.'))
    }

    const contentType = response.headers.get('content-type')

    let responseData =
      contentType && contentType.indexOf('application/json') !== -1
        ? await response.json()
        : await response.text()

    if (response.headers.has('x-total-count')) {
      responseData = {
        list: responseData,
        count: Number(response.headers.get('x-total-count')),
      }
    }
    return response.ok
      ? responseData
      : Promise.reject(errorsHandler(responseData))
  })
}
