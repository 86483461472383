// import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Menu,
  Typography,
} from '@mui/material'
import OtherHousesIcon from '@mui/icons-material/OtherHouses'
import AccountCircle from '@mui/icons-material/AccountCircle'
import ApartmentIcon from '@mui/icons-material/Apartment'
import TrackChangesOutlinedIcon from '@mui/icons-material/TrackChangesOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import { useAuth } from '../modules/auth/AuthProvider'

const menuItemsClient = [
  {
    href: '/',
    // tab: '1',
    title: 'Accueil',
    icon: (
      <OtherHousesIcon
        sx={{
          fontSize: 'inherit',
          color: 'inherit',
        }}
      />
    ),
  },
  {
    // tab: '1',
    href: '/profile/personalInformation',
    title: 'Profil',
    icon: (
      <AccountCircle
        sx={{
          fontSize: 'inherit',
          color: 'inherit',
        }}
      />
    ),
  },
  {
    // tab: '2',
    href: '/profile/companyProfile',
    title: "Profil d'entreprise",
    icon: (
      <ApartmentIcon
        sx={{
          fontSize: 'inherit',
          color: 'inherit',
        }}
      />
    ),
  },
  {
    // tab: '3',
    href: '/profile/companyGoal',
    title: "Objectifs d'entreprise",
    icon: (
      <TrackChangesOutlinedIcon
        sx={{
          fontSize: 'inherit',
          color: 'inherit',
        }}
      />
    ),
  },
]

const menuItemsPowerUser = [
  {
    href: '/',
    // tab: '1',
    title: 'Accueil',
    icon: (
      <OtherHousesIcon
        sx={{
          fontSize: 'inherit',
          color: 'inherit',
        }}
      />
    ),
  },
  {
    // tab: '1',
    href: '/profile/personalInformation',
    title: 'Profil',
    icon: (
      <AccountCircle
        sx={{
          fontSize: 'inherit',
          color: 'inherit',
        }}
      />
    ),
  },
  {
    // tab: '2',
    href: '/profile/companyProfile',
    title: "Profil d'entreprise",
    icon: (
      <ApartmentIcon
        sx={{
          fontSize: 'inherit',
          color: 'inherit',
        }}
      />
    ),
  },
]

export default function MenuComponent({ anchorEl, handleClose }) {
  const { logout, account } = useAuth()
  const navigate = useNavigate()

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={Boolean(anchorEl)}
      onClose={handleClose}
      onClick={handleClose}
      keepMounted
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,

          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
    >
      {account.role === 'client' &&
        menuItemsClient.map((item) => (
          // <MenuItem key={item.title} component={Link} to={item.href}>
          <MenuItem
            key={item.title}
            onClick={() => {
              navigate(item.href)
            }}
          >
            <ListItemIcon
              sx={{
                fontSize: '1.563rem',
                color: 'black.black75',
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText>
              <Typography
                variant="body1"
                sx={{
                  color: 'black.black75',
                }}
              >
                {item.title}
              </Typography>
            </ListItemText>
          </MenuItem>
        ))}

      {(account.role === 'admin' || account.role === 'poweruser') &&
        menuItemsPowerUser.map((item) => (
          // <MenuItem key={item.title} component={Link} to={item.href}>
          <MenuItem
            key={item.title}
            onClick={() => {
              navigate(item.href)
            }}
          >
            <ListItemIcon
              sx={{
                fontSize: '1.563rem',
                color: 'black.black75',
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText>
              <Typography
                variant="body1"
                sx={{
                  color: 'black.black75',
                }}
              >
                {item.title}
              </Typography>
            </ListItemText>
          </MenuItem>
        ))}
      <Divider />

      <MenuItem onClick={logout}>
        <ListItemIcon
          sx={{
            pl: '0.262rem',
            fontSize: '1.563rem',
            color: 'black.black75',
          }}
        >
          <LogoutIcon
            sx={{
              fontSize: 'inherit',
              color: 'inherit',
            }}
          />
        </ListItemIcon>
        <ListItemText>
          <Typography
            variant="body1"
            sx={{
              color: 'black.black75',
            }}
          >
            Se déconnecter
          </Typography>
        </ListItemText>
      </MenuItem>
    </Menu>
  )
}
MenuComponent.propTypes = {
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
}
