import PropTypes from 'prop-types'
import {
  Autocomplete,
  TextField,
  Grid,
  IconButton,
  //   Switch,
  //   FormControlLabel,
} from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'
import { useForm, Controller } from 'react-hook-form'

export default function EvaluationFilter({
  industries,
  evaluations,
  rows,
  setRows,
  setFilter,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  })

  const onSubmit = (data) => {
    setFilter({
      industry: data.industry,
      published: data.published,
    })

    if (data.industry || data.published) {
      const filteredRows = evaluations
        .filter((row) => {
          if (data.industry) {
            return row.industry.id === data.industry.id
          }
          return row
        })
        .filter((row) => {
          if (data.published) {
            return row.published === data.published.value
          }
          return row
        })

      setRows(filteredRows)
    } else if (rows.length < evaluations.length) {
      setRows(evaluations)
    }
  }
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Grid item xs={12} sm={11}>
        <Grid container spacing={2} alignItems="center">
          {/* Published */}
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              control={control}
              name="published"
              render={({ field: { onChange } }) => (
                <Autocomplete
                  options={[
                    {
                      name: 'Publier',
                      value: true,
                    },
                    {
                      name: 'En construction',
                      value: false,
                    },
                  ]}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, values) => {
                    onChange(values || null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Publier"
                      helperText={errors.published?.message}
                      error={Boolean(errors.published)}
                    />
                  )}
                />
              )}
            />
            {/* <Controller
              control={control}
              name="published"
              render={({ field: { onChange } }) => (
                <FormControlLabel
                  //   value="start"
                  control={<Switch color="primary" onChange={onChange} />}
                  label="Publier"
                  labelPlacement="start"
                />
              )}
            /> */}
          </Grid>
          {/* /published */}
          {/* Industry  */}
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              control={control}
              name="industry"
              render={({ field: { onChange } }) => (
                <Autocomplete
                  options={industries}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, values) => {
                    onChange(values || null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Type d'industrie"
                      helperText={errors.industry?.message}
                      error={Boolean(errors.industry)}
                    />
                  )}
                />
              )}
            />
          </Grid>
          {/* /Industry */}
        </Grid>
      </Grid>

      {/* FilterButton */}
      <Grid item xs={12} sm={1}>
        <IconButton type="submit">
          <TuneIcon />
        </IconButton>
      </Grid>
      {/* /FilterButton */}
    </Grid>
  )
}

EvaluationFilter.propTypes = {
  rows: PropTypes.array,
  evaluations: PropTypes.array,
  setRows: PropTypes.func,
  industries: PropTypes.array,
  setFilter: PropTypes.func,
}
