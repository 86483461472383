import api from '../../utils/api'

//= ====================================================
// Company
//= ====================================================

export async function postCompany(data) {
  const url = 'company/Company'
  try {
    const result = await api(url, { data }).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail)
  }
}

export async function editCompany(data) {
  try {
    const result = await api(`company/Company/${data.id}`, {
      data,
      method: 'PUT',
    })

    return { id: result, data: data.designation }
  } catch (error) {
    throw new Error(error)
  }
}

export async function getCompany(id) {
  const url = `company/Company/${id}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error)
  }
}

export async function getCompanyByUser(id) {
  const url = `company/CompanyUser/${id}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (e) {
    throw new Error(e.detail, e.detail)
  }
}
//= ====================================================
// Company Fields
//= ====================================================
export async function getCompanyType() {
  const url = 'company/CompanyType'
  try {
    const data = await api(url).then((response) => ({
      count: response.data.length,
      data: response.data,
    }))
    return data
  } catch (error) {
    throw new Error(error)
  }
}

// export async function getCompanyIndustryType() {
//   const url = 'company/CompanyIndustryType'
//   try {
//     const data = await api(url).then((response) => ({
//       count: response.data.length,
//       data: response.data,
//     }))
//     return data
//   } catch (error) {
//     throw new Error(error)
//   }
// }

export async function getCompanySpecialization() {
  const url = 'company/CompanySpecialization'
  try {
    const data = await api(url).then((response) => ({
      count: response.data.length,
      data: response.data,
    }))
    return data
  } catch (error) {
    throw new Error(error)
  }
}

export async function getCompanySize() {
  const url = 'company/CompanySize'
  try {
    const data = await api(url).then((response) => ({
      count: response.data.length,
      data: response.data,
    }))
    return data
  } catch (error) {
    throw new Error(error)
  }
}

export async function getCompanyNumberEmployee() {
  const url = 'company/CompanyNumberEmployee'
  try {
    const data = await api(url).then((response) => ({
      count: response.data.length,
      data: response.data,
    }))
    return data
  } catch (error) {
    throw new Error(error)
  }
}

export async function getCompanyTypeOfClient() {
  const url = 'company/CompanyTypeOfClient'
  try {
    const data = await api(url).then((response) => ({
      count: response.data.length,
      data: response.data,
    }))
    return data
  } catch (error) {
    throw new Error(error)
  }
}

export async function getCompanyRole() {
  const url = 'company/CompanyRole'
  try {
    const data = await api(url).then((response) => ({
      count: response.data.length,
      data: response.data,
    }))
    return data
  } catch (error) {
    throw new Error(error)
  }
}
