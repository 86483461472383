import { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  List,
  ListItemText,
  Typography,
  ListItemButton,
  Button,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import FullPageSpinner from '../../../../components/FullPageSpinner'
import { useDimension } from '../../../dimension/DimensionProvider'
import { dimensionsIcons } from '../../../../utils/icons'
import ConfirmAlert from '../../../../components/ConfirmAlert'
import { publishEvluation } from '../../api'
import { useAlert } from '../../../alert/AlertProvider'

export default function EvaluationLayout({
  value,
  setValue,
  currentEvaluation,
  evaluationDimensions,
  setEvaluations,
  setEvaluationsRows,
  setCurrentEvaluation,
  setEvaluationDimensions,
  loading,
}) {
  const { showAlert } = useAlert()
  const [open, setOpen] = useState({
    open: false,
    data: null,
  })
  const { handleSubmit, control } = useForm()
  const { dimensions } = useDimension()
  const [selectedDimensions, setSelectedDimensions] = useState(0)

  const handleClickOpen = (data) => {
    setOpen({
      open: true,
      data,
    })
  }

  const handleClose = () => {
    setOpen({
      open: false,
      data: null,
    })
  }

  const handleConfirmAlert = () => {
    const selectedDimensionsArray = dimensions
      .filter((dim) => open.data[dim.id])
      .map((dim) => dim.id)
    const sendData = {
      evaluation_id: currentEvaluation.id,
      dimension_ids: JSON.stringify(selectedDimensionsArray),
    }

    publishEvluation(sendData)
      .then(() => {
        showAlert("L'évaluation est publiée avec success", 'success')
        setCurrentEvaluation((prev) => ({
          ...prev,
          published: true,
        }))
        setEvaluations((prev) =>
          prev.map((item) => {
            if (item.id === currentEvaluation.id) {
              return {
                ...item,
                published: true,
              }
            }
            return item
          }),
        )
        setEvaluationsRows((prev) =>
          prev.map((item) => {
            if (item.id === currentEvaluation.id) {
              return {
                ...item,
                published: true,
              }
            }
            return item
          }),
        )
        setEvaluationDimensions((prev) => {
          const newEvaluationDimensions = prev
          selectedDimensionsArray.forEach((dim) => {
            newEvaluationDimensions[dim].selected = true
          })
          return newEvaluationDimensions
        })

        handleClose()
      })
      .catch(() => {
        showAlert('Une erreur est survenue veuillez réessayer', 'error')
      })
  }

  const handleSelectedDimensions = (checked) => {
    if (checked) {
      setSelectedDimensions((prev) => prev + 1)
    } else {
      setSelectedDimensions((prev) => prev - 1)
    }
  }

  const onSubmit = (data) => {
    handleClickOpen(data)
  }

  const items = dimensions.map((dimension) => ({
    id: dimension.id,
    href: `${dimension.name}`,
    title: dimension.name,
    icon: (
      <Box
        sx={{
          mr: 2,
          fontSize: '1.25rem',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {dimensionsIcons[dimension.name]}
      </Box>
    ),
  }))

  if (loading) {
    return <FullPageSpinner />
  }
  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          backgroundColor: 'white.white100',
          transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          boxShadow:
            'rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px',
          py: 1,
          borderRadius: '8px',
        }}
      >
        <List>
          {items.map((item) => (
            <Box key={item.title} mb={1}>
              <ListItemButton
                selected={item.href === value}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'black.black50',
                  '&:before': {
                    left: '0px',
                    width: item.href === value ? '4px' : '0px',
                    content: '""',
                    height: '100%',
                    position: 'absolute',
                    transition: 'all 0.3s ease 0s',
                    backgroundColor: 'mysensPalette.lightBlue',
                  },
                }}
              >
                <Controller
                  control={control}
                  name={`${item.id}`}
                  defaultValue={!!evaluationDimensions?.[item.id]?.selected}
                  render={({ field: { onChange, value: val } }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            currentEvaluation.published ||
                            !evaluationDimensions[item.id] ||
                            (evaluationDimensions[item.id] &&
                              evaluationDimensions[item.id].questions.length ===
                                0)
                          }
                          checked={val}
                          onChange={(event) => {
                            handleSelectedDimensions(event.target.checked)
                            onChange(event)
                          }}
                          sx={{
                            pr: 0,
                            '& svg': {
                              fontSize: '1.266rem',
                            },
                          }}
                        />
                      }
                    />
                  )}
                />

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    setValue(item.href)
                  }}
                >
                  {item.icon}
                  <ListItemText>
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'black.black75',
                      }}
                    >
                      {item.title}
                    </Typography>
                  </ListItemText>
                </Box>
              </ListItemButton>
            </Box>
          ))}
        </List>

        <Button
          fullWidth
          variant="mysensContained"
          type="submit"
          sx={{
            borderRadius: 0,
            textTransform: 'none',
          }}
          endIcon={<DoneIcon />}
          disabled={selectedDimensions === 0 || currentEvaluation.published}
        >
          Publier
        </Button>
      </Box>
      <ConfirmAlert
        title="Publier l'évaluation"
        description="Noter que si vous publier cette évaluation vous ne pouvez pas la modifier plus tard, voulez-vous confirmer cette opération"
        open={open.open}
        handleClose={handleClose}
        handleConfirmAlert={handleConfirmAlert}
      />
    </>
  )
}

EvaluationLayout.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  currentEvaluation: PropTypes.object,
  evaluationDimensions: PropTypes.object,
  setEvaluations: PropTypes.func,
  setEvaluationsRows: PropTypes.func,
  setCurrentEvaluation: PropTypes.func,
  setEvaluationDimensions: PropTypes.func,
  loading: PropTypes.bool,
}
