import { useState, useEffect } from 'react'
import FullPageSpinner from '../../components/FullPageSpinner'
import { useAuth } from '../../modules/auth/AuthProvider'
import { useAlert } from '../../modules/alert/AlertProvider'
import { getCompanyGoal } from '../../modules/companyGoal/api'
import WhiteBox from '../../components/WhiteBox'
import EditCompanyGoalForm from '../../modules/companyGoal/components/CompanyGoalForm'

export default function EditCompanyGoal() {
  const { company } = useAuth()
  const { showAlert } = useAlert()

  const [companyGoal, setCompanyGoal] = useState({
    score: 0,
    answers: {},
  })
  const [getLoading, setGetLoading] = useState(false)

  useEffect(() => {
    setGetLoading(true)
    getCompanyGoal(company.id)
      .then((res) => {
        const answers = JSON.parse(res.answers)
        setCompanyGoal({
          score: res.score,
          answers,
        })
        setGetLoading(false)
      })
      .catch((error) => {
        if (error.message !== 'CompanyGoal not found') {
          showAlert('Une erreur est survenue', 'error')
        }

        setGetLoading(false)
      })
  }, [company.id, showAlert])

  if (getLoading) {
    return <FullPageSpinner />
  }

  return (
    <WhiteBox title="Objectifs de votre entreprise">
      <EditCompanyGoalForm companyGoal={companyGoal} />
    </WhiteBox>
  )
}
