import api from '../../utils/api'

export async function getMaturityLevels() {
  const url = `maturity/maturityLevel`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}
