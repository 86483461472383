import { Box, Typography, Link, Container, Grid } from '@mui/material'

export default function Copyrights() {
  return (
    <Container
      sx={{
        py: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="body2" color="white.white75">
              ©{`${new Date().getFullYear()}`} Copyright SCiiĜO. Tous droits
              réservés.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <Box>
            <Typography component="div" variant="body2" color="white.white75">
              <Link href="/cookies">Cookie </Link> Préférences,{' '}
              <Link href="/privacy">Confidentialité</Link>, et{' '}
              <Link href="/terms">conditions</Link>.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
