import api from '../../utils/api'

export async function getQuestionsBydimension(dimensionId) {
  const url = `questions/QuestionsByDimension/${dimensionId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getQuestionsBySiigoByUserByDimension(
  userId,
  dimensionId,
) {
  const url = `questions/QuestionsBySiigoByUserByDimension/${userId}/${dimensionId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getQuestionsByUserAndSccigoByDimension(
  userId,
  dimensionId,
) {
  const url = `questions/QuestionsByUserAndSciigoByDimension/${userId}/${dimensionId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function postQuestion(id, data) {
  const url = `questions/Questions/${id}`
  try {
    const result = await api(url, { data }).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}
