import api from '../../utils/api'

export async function postUseCase(data) {
  const url = `useCases/useCases`
  try {
    const result = await api(url, { data }).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getuseCases() {
  const url = `useCases/useCases`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getUseCasesByuser(userId) {
  const url = `useCases/useCasesByUser/${userId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getUseCasesByuserAndSciigo(userId) {
  const url = `useCases/useCasesByUserAndSciigo/${userId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getUseCasesByuserByFaisabilityAndIndusty(
  userId,
  faisability,
  industry,
) {
  const url = `useCases/useCasesByUserByFaisabilityAndIndusty/${userId}/${faisability}/${industry}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function updateUseCase(id, data) {
  try {
    const result = await api(`useCases/useCases/${id}`, {
      data,
      method: 'PUT',
    })

    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}
