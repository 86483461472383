import { useState, useEffect, useRef } from 'react'
import {
  Button,
  Dialog,
  Pagination,
  DialogTitle,
  Typography,
  IconButton,
  Grid,
  Box,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import FullPageSpinner from '../../components/FullPageSpinner'
import FullPageErrorFallback from '../../components/FullPageErrorFallback'
import RecommendationCard from '../../modules/recommendation/components/RecommendationCard'
import RecommendationForm from '../../modules/recommendation/components/RecommendationForm'
import WhiteBox from '../../components/WhiteBox'
import { useAuth } from '../../modules/auth/AuthProvider'
import { getRecommendationsByuserAndSciigo } from '../../modules/recommendation/api'
import { escapeRegExp } from '../../utils/utilfc'
import QuickSearchToolbar from '../../components/QuickSearchToolbar'
import RecommendationFilter from '../../modules/recommendation/components/RecommendationFilter'
import { useDimension } from '../../modules/dimension/DimensionProvider'
import { useMaturities } from '../../modules/maturity/MaturityProvider'
import { useIndustries } from '../../modules/industry/IndustryProvider'

export default function Recommendations() {
  const { account } = useAuth()
  const { maturities } = useMaturities()
  const { dimensions } = useDimension()
  const { industries } = useIndustries()
  const [recommendations, setRecommendations] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(null)
  const [rows, setRows] = useState(recommendations)
  const [searchText, setSearchText] = useState('')
  const [dialog, setDialog] = useState({
    open: false,
    action: 'add',
    data: {},
  })
  const [page, setPage] = useState(1)
  const handlePaginationChange = (event, newValue) => {
    setPage(newValue)
  }

  const filter = useRef({
    type: '',
    dimension: null,
    maturity: null,
    industry: null,
  })

  const setFilter = (filterVal) => {
    filter.current = filterVal
  }

  const handleOpen = (action, data) => {
    setDialog({
      action,
      data,
      open: true,
    })
  }
  const handleClose = () => {
    setDialog({
      ...dialog,
      open: false,
    })
  }

  const requestSearch = (searchValue) => {
    setSearchText(searchValue)
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
    const filteredRows = recommendations.filter(
      (row) =>
        searchRegex.test(row.name) ||
        searchRegex.test(row.content) ||
        searchRegex.test(row.prerequisite),
    )
    setRows(filteredRows)
  }

  useEffect(() => {
    setIsLoading(true)
    setIsError(false)
    getRecommendationsByuserAndSciigo(account.id)
      .then((res) => {
        const newData = res.data
        setRecommendations(newData)
        setRows(newData)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
        setIsError(true)
      })
  }, [account.id])

  return (
    <>
      <WhiteBox
        title="Recommendations"
        button={
          <Button
            variant="text"
            sx={{
              fontSize: '1.125rem',
              textTransform: 'none',
            }}
            endIcon={<AddIcon />}
            onClick={() => {
              handleOpen('add', {})
            }}
          >
            Ajouter une recommendation
          </Button>
        }
        search={
          <QuickSearchToolbar
            value={searchText}
            onChange={(event) => requestSearch(event.target.value)}
            clearSearch={() => requestSearch('')}
          />
        }
        pagination={
          <Pagination
            count={5}
            page={page}
            onChange={handlePaginationChange}
            shape="rounded"
          />
        }
      >
        {isError && <FullPageErrorFallback />}
        {isLoading && <FullPageSpinner />}
        {!isError && !isLoading && (
          <>
            <Box
              mt={3}
              mb={2}
              sx={{
                px: 3,
              }}
            >
              <RecommendationFilter
                rows={rows}
                setFilter={setFilter}
                dimensions={dimensions}
                maturities={maturities}
                industries={industries}
                recommendations={recommendations}
                setRows={setRows}
              />
            </Box>
            <Box
              sx={{
                px: 3,
                mb: 2,
              }}
            >
              <Typography
                variant="body1"
                color="black.black75"
                sx={{
                  textAlign: 'right',
                }}
              >
                ({rows.length}) recommendations
              </Typography>
            </Box>

            <Grid container spacing={2}>
              {rows.length > 0 ? (
                rows.map((item) => (
                  <Grid key={item.id} item xs={12} md={12}>
                    <RecommendationCard
                      item={item}
                      handleOpen={handleOpen}
                      isLoading={isLoading}
                      isError={isError}
                    />
                  </Grid>
                ))
              ) : (
                <Box
                  mt={2}
                  sx={{
                    width: '100%',
                  }}
                >
                  <Typography
                    component="div"
                    variant="body1"
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    Pas d'éléments!{' '}
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 'bold',
                        color: 'primary.main',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleOpen('add', {})
                      }}
                    >
                      {' '}
                      insérez un
                    </Typography>
                  </Typography>
                </Box>
              )}
            </Grid>
          </>
        )}
      </WhiteBox>
      <Dialog open={dialog.open} fullWidth maxWidth="lg" disableEnforceFocus>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            component="p"
            variant="h5"
            textAlign="center"
            gutterBottom
            sx={{
              pl: 2,
            }}
          >
            {dialog.action === 'add'
              ? 'Ajouter une recommendation'
              : 'Editer la recommendation'}
          </Typography>

          <IconButton onClick={handleClose} sx={{ ml: 'auto' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <RecommendationForm
          maturities={maturities}
          dimensions={dimensions}
          industries={industries}
          action={dialog.action}
          recommendationData={dialog.data}
          handleClose={handleClose}
          setRecommendations={setRecommendations}
          setRows={setRows}
        />
      </Dialog>
    </>
  )
}
