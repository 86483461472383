import { Box } from '@mui/material'
import WhiteBox from '../../components/WhiteBox'

export default function DeleteAccount() {
  return (
    <WhiteBox title="Supprimer votre compte">
      <Box>Comming soon</Box>
    </WhiteBox>
  )
}
