import * as React from 'react'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { Box, CircularProgress, TextField, Autocomplete } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { postEvaluation, updateEvaluation } from '../api'
import { useAlert } from '../../alert/AlertProvider'
import { useAuth } from '../../auth/AuthProvider'

export default function EvaluationForm({
  industries,
  action,
  evaluationData,
  handleClose,
  setEvaluations,
  setRows,
}) {
  const [loading, setLoading] = React.useState(false)
  const { showAlert } = useAlert()
  const { account } = useAuth()

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: evaluationData?.name || '',
      industry: evaluationData?.industry || null,
    },
  })

  const addEvaluation = (sendData) => {
    postEvaluation(sendData)
      .then((res) => {
        setRows((prevRows) => [
          {
            ...sendData,
            id: res.data.id,
            published: false,
          },
          ...prevRows,
        ])
        setEvaluations((prevRows) => [
          {
            ...sendData,
            published: false,
            id: res.data.id,
          },
          ...prevRows,
        ])
        handleClose()
        showAlert("l'évaluation a été créé avec success", 'success')
      })
      .catch((error) => {
        if (error.message.includes('duplicate')) {
          showAlert(
            'Cette évaluation existe déjà, Veuillez insérer une nouvelle évaluation',
            'error',
          )
        } else {
          showAlert("Une erreur s'est produite", 'error')
        }
      })
  }

  const editEvaluation = (id, sendData) => {
    updateEvaluation(id, sendData)
      .then(() => {
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === evaluationData?.id
              ? {
                  ...sendData,
                  id: evaluationData?.id,
                }
              : row,
          ),
        )
        setEvaluations((prevItems) =>
          prevItems.map((row) =>
            row.id === evaluationData?.id
              ? {
                  ...sendData,
                  id: evaluationData?.id,
                }
              : row,
          ),
        )
        showAlert("L'évaluation a été ajouté avec success", 'success')
        handleClose()
      })
      .catch((error) => {
        if (error.message.includes('duplicate')) {
          showAlert(
            'Cette valeur existe déjà, Veuillez insérer une nouvelle valeur',
            'error',
          )
        } else {
          showAlert("Une erreur s'est produite", 'error')
        }
      })
  }

  const onSubmit = (data) => {
    setLoading(true)
    const sendData = {
      ...data,
      industry_id: data.industry.id,
      created_by: account.id,
      published: evaluationData?.published ? evaluationData.published : false,
    }
    if (action === 'add') {
      addEvaluation(sendData)
    }
    if (action === 'edit') {
      editEvaluation(evaluationData.id, sendData)
    }
    setLoading(false)
  }

  return (
    <Box
      sx={{
        boxShadow: 24,
        p: 4,
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <TextField
        {...register('name', {
          required: {
            value: true,
            message: 'Veuillez saisir une valeur',
          },
        })}
        margin="normal"
        fullWidth
        id="name"
        label="Nom d'évaluation"
        name="name"
        autoComplete="name"
        autoFocus
        helperText={errors.name?.message}
        error={!!errors.name}
      />
      {/* Industry  */}
      <Box mt={2}>
        <Controller
          control={control}
          name="industry"
          rules={{
            required: 'Veuillez choisir une réponse',
          }}
          render={({ field: { onChange } }) => (
            <Autocomplete
              defaultValue={
                evaluationData?.industry ? evaluationData.industry : null
              }
              options={industries}
              getOptionLabel={(option) => option.name}
              onChange={(event, values) => {
                onChange(values || null)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type d'industrie"
                  helperText={errors.industry?.message}
                  error={Boolean(errors.industry)}
                />
              )}
            />
          )}
        />
      </Box>
      {/* /Industry */}

      <Button
        startIcon={
          loading ? (
            <CircularProgress size={20} />
          ) : (
            <AddIcon color="white" size="small" />
          )
        }
        type="submit"
        fullWidth
        disabled={loading}
        variant="mysensContained"
        sx={{ mt: 3 }}
      >
        Enregistrer
      </Button>
    </Box>
  )
}
EvaluationForm.propTypes = {
  industries: PropTypes.array,
  action: PropTypes.string,
  evaluationData: PropTypes.object,
  handleClose: PropTypes.func,
  setEvaluations: PropTypes.func,
  setRows: PropTypes.func,
}
