import { PropTypes } from 'prop-types'
import { Grid, Box, Typography } from '@mui/material'
import StopIcon from '@mui/icons-material/Stop'
import { useAuth } from '../../modules/auth/AuthProvider'
import { StrongText } from '../../utils/customElements'

function AdminHome({ account }) {
  return (
    <Box>
      <Box>
        <Typography variant="h4">Bienvenue {account.firstName},</Typography>
        <Box mt={2}>
          <Typography variant="body1">
            Vous faites partie du cercle d&apos;experts qui peuvent fournir des
            évaluations de maturité numérique en un clin d&apos;œil et des
            stratégies impactantes pour leurs clients.
          </Typography>
        </Box>
      </Box>
      <Grid container mt={4}>
        <Grid item xs={6}>
          <Box>
            <Box>
              <Typography variant="h4">Avec mySENS.AI vous pouvez:</Typography>
            </Box>
            <Box mt={2}>
              <Box pl={2}>
                <Typography component="div" variant="body1">
                  <StopIcon
                    sx={{
                      mb: -1,
                      color: 'mysensPalette.lightBlue',
                    }}
                  />{' '}
                  <StrongText component="span">Automatiser</StrongText> votre
                  expertise de conseiller stratégique en transformation
                  numérique, Intelligence Artificielle ou Science de données.
                </Typography>
              </Box>
              <Box mt={1} pl={2}>
                <Typography component="div" variant="body1">
                  <StopIcon
                    sx={{
                      mb: -1,
                      color: 'mysensPalette.lightBlue',
                    }}
                  />{' '}
                  <StrongText component="span">Créer</StrongText> vos propres
                  diagnostiques de maturité et bâtir vos propres stratégies.
                </Typography>
              </Box>
              <Box mt={1} pl={2}>
                <Typography component="div" variant="body1">
                  <StopIcon
                    sx={{
                      mb: -1,
                      color: 'mysensPalette.lightBlue',
                    }}
                  />{' '}
                  <StrongText component="span">Bénéficier</StrongText> d&apos;un
                  cadre méthologique éprouvé issue de la R&D et d&apos;un engin
                  de recommandation propulsé par l&apos;IA pour personnaliser
                  vos services.
                </Typography>
              </Box>
              <Box mt={1} pl={2}>
                <Typography component="div" variant="body1">
                  <StopIcon
                    sx={{
                      mb: -1,
                      color: 'mysensPalette.lightBlue',
                    }}
                  />{' '}
                  <StrongText component="span">Accroître</StrongText> vos
                  services en automatisant les tâches sans valeur ajoutée des
                  audits traditionnels.
                </Typography>
              </Box>
              <Box mt={1} pl={2}>
                <Typography component="div" variant="body1">
                  <StopIcon
                    sx={{
                      mb: -1,
                      color: 'mysensPalette.lightBlue',
                    }}
                  />{' '}
                  <StrongText component="span">Propulser</StrongText> votre
                  carrière en devenant un expert reconnu en stratégie numérique.
                </Typography>
              </Box>
              <Box mt={1} pl={2}>
                <Typography variant="body1">
                  <StopIcon
                    sx={{
                      mb: -1,
                      color: 'mysensPalette.lightBlue',
                    }}
                  />{' '}
                  <StrongText component="span">Développer</StrongText> votre
                  profil de mentor, aider les équipes et vos collègues à
                  concevoir leur approche sur mesure des stratégies
                  d&apos;Intelligence Artificielle.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>
            <img
              src="/images/poweruserHomePoints.png"
              alt="mysensai Logo"
              width="100%"
              height="100%"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
AdminHome.propTypes = {
  account: PropTypes.object,
}

function ClientHome({ account }) {
  return (
    <Box>
      <Box>
        <Typography variant="h4">Bienvenue {account.firstName},</Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="body1">
          Bienvenue à mySENS.AI votre coach personnel en ligne pour vous guider
          en continu à maximiser vos chances de succès en Intelligence
          Artificielle.
        </Typography>
      </Box>

      <Box mt={4}>
        <Box>
          <Typography variant="h4">
            Commencez le pilotage de votre stratégie d&apos;innovation d&apos;IA
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 2,
            }}
          >
            Évaluez, actionnez et mesurez l&apos;impact de l&apos;IA dans votre
            entreprise en temps réel.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 1,
            }}
          >
            Votre mySENS.AI votre outil tout en un, à l&apos;échelle de votre
            entreprise. S&apos;adaptant à votre niveau de maturité.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 1,
            }}
          >
            L&apos;évaluation de votre maturité numérique vous permet
            d&apos;évaluer l&apos;état actuel des vos capacités et des
            initiatives numériques afin de comprendre vos forces, vos faiblesses
            et votre potentiel de croissance et de développement.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 1,
            }}
          >
            mySENS.AI vous permet de comprendre dans quelle mesure votre
            entreprise est bien positionnée pour tirer parti des opportunités de
            l&apos;intelligence numérique et relever les défis qui peuvent se
            présenter.
          </Typography>
        </Box>

        <Box mt={2}>
          <Box>
            <Typography variant="h5">Avec mySENS.AI vous pouvez:</Typography>
          </Box>
          <Box mt={2}>
            <Box pl={2}>
              <Typography component="div" variant="body1">
                <StopIcon
                  sx={{
                    mb: -1,
                    color: 'mysensPalette.lightBlue',
                  }}
                />{' '}
                <StrongText component="span"> Identifier </StrongText>
                les domaines dans lesquels votre entreprise est déjà forte dans
                son utilisation de l&apos;Intelligence numérique, et ceux sur
                lesquels elle pourrait devoir porter plus d&apos;attention afin
                de s&apos;améliorer.
              </Typography>
            </Box>
            <Box mt={1} pl={2}>
              <Typography component="div" variant="body1">
                <StopIcon
                  sx={{
                    mb: -1,
                    color: 'mysensPalette.lightBlue',
                  }}
                />{' '}
                <StrongText component="span">Fournir</StrongText> un point de
                référence par rapport auquel une entreprise peut mesurer ses
                progrès au fil du temps, alors qu&apos;elle continue à
                développer et à mûrir ses capacités d&apos;Intelligence
                numérique.
              </Typography>
            </Box>
            <Box mt={1} pl={2}>
              <Typography component="div" variant="body1">
                <StopIcon
                  sx={{
                    mb: -1,
                    color: 'mysensPalette.lightBlue',
                  }}
                />{' '}
                <StrongText component="span">Identifier </StrongText>
                les meilleures pratiques et les leçons apprises d&apos;autres
                entreprises qui peuvent être appliquées aux initiatives
                d&apos;Intelligence numérique d&apos;une organisation.
              </Typography>
            </Box>
            <Box mt={1} pl={2}>
              <Typography component="div" variant="body1">
                <StopIcon
                  sx={{
                    mb: -1,
                    color: 'mysensPalette.lightBlue',
                  }}
                />{' '}
                <StrongText component="span">Fournir</StrongText> une base pour
                le développement d&apos;une feuille de route ou d&apos;un plan
                d&apos;Intelligence numérique qui peut aider votre entreprise à
                prioriser ses initiatives numériques et à allouer des ressources
                en conséquence.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt={4}>
        <Box>
          <Typography variant="h4">
            Votre stratégie d&apos;Intelligence Artificielle en continu
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 1,
            }}
          >
            Évaluez, actionnez et mesurez l&apos;impact de l&apos;IA dans votre
            entreprise en temps réel.
          </Typography>
          <Box>
            <img
              src="/images/clientHomeProcess.png"
              alt="mysensai Logo"
              width="100%"
              height="100%"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
ClientHome.propTypes = {
  account: PropTypes.object,
}

export default function AuthHome() {
  const { account } = useAuth()
  if (account.role === 'admin' || account.role === 'poweruser') {
    return <AdminHome account={account} />
  }
  return <ClientHome account={account} />
}
