import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'

import {
  Box,
  CircularProgress,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import FullPageSpinner from '../../../../components/FullPageSpinner'
import FullPageErrorFallback from '../../../../components/FullPageErrorFallback'
import { useAlert } from '../../../alert/AlertProvider'
import { useAuth } from '../../../auth/AuthProvider'
import {
  getEvaluationClientsAutocomplete,
  postEvluationClient,
} from '../../api'

export default function EvaluationClientForm({
  handleClose,
  clients,
  setClients,
  setRows,
  currentEvaluation,
}) {
  const [loading, setLoading] = useState(false)
  const { showAlert } = useAlert()
  const { account } = useAuth()
  const [evluationClientsAutocomplete, setEvaluationClientsAutocomplete] =
    useState([])

  const [clientsAutocompleteLoading, setClientsAutocompleteLoading] =
    useState(false)

  const [clientsAutocompleteError, setClientsAutocompleteError] =
    useState(false)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
    setLoading(true)

    const sendData = {
      evaluation_id: currentEvaluation.id,
      user_id: data.client.id,
    }
    postEvluationClient(sendData)
      .then(() => {
        showAlert(
          "Le client a été ajouté à l'évaluation avec success ",
          'success',
        )
        handleClose()
        setClients((prev) => [...prev, data.client])
        setRows((prev) => [...prev, data.client])
      })
      .catch((error) => {
        if (error.message.includes('duplicate')) {
          showAlert(
            'Ce client a été déjà ajouté à une autre évaluation, Veuillez ajouter un autre client',
            'error',
          )
        } else {
          showAlert('Une erreur est survenue', 'error')
        }
        setLoading(false)
      })
  }

  useEffect(() => {
    if (currentEvaluation?.industry?.id) {
      setClientsAutocompleteError(false)
      setClientsAutocompleteLoading(true)
      getEvaluationClientsAutocomplete(
        account.id,
        currentEvaluation.industry.id,
      )
        .then((res) => {
          const newRes = res.filter(
            (item) => !clients.find((client) => client.id === item.id),
          )
          setEvaluationClientsAutocomplete(newRes)
          setClientsAutocompleteLoading(false)
        })
        .catch(() => {
          setClientsAutocompleteError(true)
          showAlert('Une erreur est survenue', 'error')
          setClientsAutocompleteLoading(false)
        })
    }
  }, [currentEvaluation, showAlert, account.id, clients])

  if (clientsAutocompleteError) {
    return <FullPageErrorFallback />
  }

  if (clientsAutocompleteLoading) {
    return <FullPageSpinner />
  }

  if (evluationClientsAutocomplete.length === 0) {
    return (
      <Box
        sx={{
          // width: 400,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h5" textAlign="center" component="h1">
          Ajouter un client
        </Typography>
        <Box mt={2}>
          <Typography
            variant="body1"
            color="mysensPalette.orange"
            textAlign="center"
            sx={{
              fontWeight: 'bold',
            }}
          >
            La liste du clients est vide!
          </Typography>
        </Box>
      </Box>
    )
  }
  return (
    <Box
      sx={{
        // width: 400,
        boxShadow: 24,
        p: 4,
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <>
        <Typography variant="h5" textAlign="center" component="h1" gutterBottom>
          Ajouter un client
        </Typography>

        <Box mt={2}>
          <Controller
            control={control}
            name="client"
            rules={{
              required: 'Veuillez choisir une réponse',
            }}
            render={({ field: { onChange } }) => (
              <Autocomplete
                defaultValue={null}
                options={evluationClientsAutocomplete}
                getOptionLabel={(option) => option['Nom Complet']}
                onChange={(event, values) => {
                  onChange(values || null)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Client"
                    helperText={errors.thematic?.message}
                    error={!!errors.thematic}
                  />
                )}
              />
            )}
          />
        </Box>

        <Button
          startIcon={
            loading ? (
              <CircularProgress size={20} />
            ) : (
              <AddIcon color="white" size="small" />
            )
          }
          type="submit"
          fullWidth
          disabled={loading}
          variant="mysensContained"
          sx={{ mt: 2 }}
        >
          Enregistrer
        </Button>
      </>
    </Box>
  )
}
EvaluationClientForm.propTypes = {
  handleClose: PropTypes.func,
  clients: PropTypes.array,
  setClients: PropTypes.func,
  setRows: PropTypes.func,
  currentEvaluation: PropTypes.object,
}
