import { useState, useEffect } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import FullPageSpinner from '../../../components/FullPageSpinner'
import FullPageErrorFallback from '../../../components/FullPageErrorFallback'
import ClientDetail from '../../clients/components/ClientDetail'
import WhiteBox from '../../../components/WhiteBox'
import { useAuth } from '../../auth/AuthProvider'

import ClientList from '../../clients/components/ClientList'
import { statusColor } from '../../../utils/utilfc'
import { getClientsScore } from '../api'

export default function Clients() {
  const [clients, setClients] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(null)
  const [rows, setRows] = useState(clients)
  const { account } = useAuth()

  const [showDetail, setShowDetail] = useState(false)
  const [clientDetail, setClientDetail] = useState({})

  const handleClickDetail = (detail) => {
    setShowDetail(true)
    setClientDetail(detail)
  }

  const handleBackDetail = () => {
    setShowDetail(false)
    setClientDetail({})
  }

  const constructColumns = (columns) => {
    const newColumns = []
    const filteredColumns = columns.filter(
      (item) => !item.toLowerCase().match(/.*id$|company$|Titre D'emploi$/gi),
    )
    filteredColumns.forEach((column) => {
      if (column !== 'Status') {
        newColumns.push({
          field: column,
          headerName: column,
          flex: 1,
        })
      }
    })
    newColumns.push({
      field: 'Entreprise',
      headerName: 'Entreprise',
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography>
            {params.row.Entreprise ? params.row.Entreprise : '/'}
          </Typography>
        </Box>
      ),
    })
    newColumns.push({
      field: 'Score',
      headerName: 'Score',
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        let score = -1
        if (params.row?.score) {
          score = parseFloat((params.row.score * 100).toFixed(2))
        }
        return (
          <Box>
            <Typography
              sx={{
                fontWeight: 600,
                color: `${statusColor(score)}`,
              }}
            >
              {score === -1 ? '/' : score}
            </Typography>
          </Box>
        )
      },
    })

    newColumns.push({
      field: 'Action',
      headerName: 'Action',
      sortable: false,
      width: 80,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <IconButton
            onClick={() => {
              handleClickDetail(params.row)
            }}
          >
            <VisibilityIcon />
          </IconButton>
        </Box>
      ),
    })
    return newColumns
  }

  useEffect(() => {
    // Get only clients that has acomplished there evaluation with all the dimensions
    setIsLoading(true)
    getClientsScore(account.id)
      .then((res) => {
        setClients(res)
        setRows(res)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
        setIsError(true)
      })
  }, [account.id])

  if (isError) {
    return <FullPageErrorFallback />
  }

  if (isLoading) {
    return <FullPageSpinner />
  }

  return (
    <>
      {!showDetail && (
        <WhiteBox title="Liste des scores du clients">
          <Box sx={{ width: 1 }}>
            <ClientList
              clients={clients}
              rows={rows}
              setRows={setRows}
              isLoading={isLoading}
              isError={isError}
              columns={
                clients.length > 0
                  ? constructColumns(Object.keys(clients[0]))
                  : []
              }
            />
          </Box>
        </WhiteBox>
      )}
      {showDetail && (
        <ClientDetail
          handleBackDetail={handleBackDetail}
          clientDetail={clientDetail}
        />
      )}
    </>
  )
}
