import { useAuth } from '../../modules/auth/AuthProvider'
import { useDimension } from '../../modules/dimension/DimensionProvider'
import { useAssessment } from '../../modules/assessment/AssessmentProvider'
import Score from '../../modules/score/components/Score'

export default function ScoreAI() {
  const { company } = useAuth()
  const { dimensionsScore } = useDimension()
  const { assessmentDetail } = useAssessment()

  return (
    <Score
      dimensionsScore={dimensionsScore}
      companyId={company.id}
      dimensions={assessmentDetail}
    />
  )
}
