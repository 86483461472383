import {
  useEffect,
  useState,
  memo,
  createContext,
  useMemo,
  useContext,
} from 'react'
import PropTypes from 'prop-types'
import { useAuth } from '../auth/AuthProvider'
import { getTagsByUserAndSciigo } from './api'

const TagsContext = createContext()
TagsContext.displayName = 'TagsContext'

const TagsProvider = memo(({ children }) => {
  const { account } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [tags, setTags] = useState([])
  const [rows, setRows] = useState(tags)

  // useEffect(() => {
  //   getTagsByUserAndSciigo(account.id).then((res) => {
  //     setTags(res.data)
  //   })
  // }, [account.id])
  useEffect(() => {
    setIsLoading(true)
    setIsError(false)
    getTagsByUserAndSciigo(account.id)
      .then((res) => {
        const newData = res.data
        setTags(newData)
        setRows(newData)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
        setIsError(true)
      })
  }, [account.id])

  const value = useMemo(
    () => ({ tags, setTags, isError, isLoading, rows, setRows }),
    [tags, setTags, isError, isLoading, rows, setRows],
  )

  return <TagsContext.Provider value={value}>{children}</TagsContext.Provider>
})

export default TagsProvider
export function useTags() {
  const context = useContext(TagsContext)
  if (context === undefined) {
    throw new Error('useTags must be used within a TagsProvider')
  }
  return context
}

TagsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
