export const PrivilegesMapper = {
  Evaluation: 'assessment',
  Recommendations: 'recommendations',
  "Cas d'usages": 'usecases',
}

export const dimensionNumberMapper = {
  1: 'Stratégies',
  2: 'Organisation & Écosystème',
  3: 'Human & Culture',
  4: 'Gouvernance de données',
  5: 'Opérations & Processus',
  6: 'Technologies',
  7: 'Produits & Services',
  8: 'Éthique & Impacts ESG',
  9: 'Cybersécurité',
}

export const dimensionNumberMapperIverse = {
  Stratégies: 1,
  'Organisation & Écosystème': 2,
  'Human & Culture': 3,
  'Gouvernance de données': 4,
  'Opérations & Processus': 5,
  Technologies: 6,
  'Produits & Services': 7,
  'Éthique & Impacts ESG': 8,
  Cybersécurité: 9,
}

export const dimensionMapper = {
  strategy: 'Stratégies',
  env: 'Organisation & Écosystème',
  culture: 'Human & Culture',
  datagov: 'Gouvernance de données',
  process: 'Opérations & Processus',
  techno: 'Technologies',
  services: 'Produits & Services',
  ethic: 'Éthique & Impacts ESG',
  cyberscurity: 'Cybersécurité',
  // env: 'Environment',
}

export const dimensionLink = {
  Stratégies: 'strategy',
  'Organisation & Écosystème': 'env',
  'Human & Culture': 'culture',
  'Gouvernance de données': 'datagov',
  'Opérations & Processus': 'process',
  Technologies: 'techno',
  'Produits & Services': 'services',
  'Éthique & Impacts ESG': 'ethic',
  Cybersécurité: 'cyberscurity',
  // Finances: 'finances',
  // env: 'Environment',
}

export const mapperQuestionType = {
  field: 'Champ',
  select: 'Sélection',
  multiple: 'Choix multiple',
  date: 'date',
}
export const mapperQuestionTypeInverse = {
  Champ: 'field',
  Sélection: 'select',
  'Choix multiple': 'multiple',
  data: 'data',
}

export const companyInformationMapper = {
  companyName: "Nom de l'entreprise",
  Address: 'Addresse',
  Place: 'Lieu',
  "Type d'entreprise": "Type d'entreprise",
  "Nombre d'embloyés": "Nombre d'employés",
  "Type d'industrie": "Type d'industrie",
  Spécialization: 'Spécialisation',
  'Type du client': 'Type de clients',
  'Date de création': 'Date de création',
}

export const FaisabilityMapper = {
  1: 'Haute',
  2: 'Moyenne',
  3: 'Faible',
}

export const FaisabilityMapperInverse = {
  Haute: 1,
  Moyenne: 2,
  Faible: 3,
}

// Digitization (Not Started in AI but have initiate Digit actions) - 15 ou 20
// AI Awareness - (10 - 20) % (start to understand the value of IA) 20 à 25
// AI (Ready) Beginner (minimum of data and tech-Data-Leadership engagement ) (20-30)%
// AI expérimenter (Active with POC-MVP) (30-50)%
// AI Builder ( Operational (dans au moins une ligne de fabrication-service) (50-60)%
// AI Innovator (Systemic Dans plusieurs produits) (60-70)%
// AI Achiever (Transformational of the company ADN) (70-80)%
// Shaper (leader) (80-100)% (AIOps)

// export const maturityLevel = (maturityIQ) => {
//   switch (true) {
//     case maturityIQ <= 20:
//       return 'Digitalisé'
//     case maturityIQ <= 25:
//       return 'Sensibilisé'
//     case maturityIQ <= 30:
//       return 'Débutant'
//     case maturityIQ <= 50:
//       return 'Expérimenté'
//     case maturityIQ <= 60:
//       return 'Praticien'
//     case maturityIQ <= 70:
//       return 'Opérationnel'
//     case maturityIQ <= 80:
//       return 'Innovant'
//     case maturityIQ <= 100:
//       return 'Leader'
//     default:
//       return 'Digitalisé'
//   }
// }

export const maturityLevelId = (maturities, maturityIQ) => {
  const maturityObj =
    maturities.find((maturity) => {
      const maturityNameArray = maturity.score.split('-')
      const lowRange = parseInt(maturityNameArray[0], 10)
      const highRange = parseInt(maturityNameArray[1], 10)

      if (maturityIQ > lowRange && maturityIQ <= highRange) {
        return maturity.id
      }
      return undefined
    }) || maturities[0].id
  return maturityObj.id
}

export const maturityLevelName = (maturities, maturityIQ) => {
  const maturityObj =
    maturities.find((maturity) => {
      const maturityNameArray = maturity.score.split('-')
      const lowRange = parseInt(maturityNameArray[0], 10)
      const highRange = parseInt(maturityNameArray[1], 10)

      if (maturityIQ > lowRange && maturityIQ <= highRange) {
        return maturity
      }
      return undefined
    }) || maturities[0]

  return maturityObj.name
}
