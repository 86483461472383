import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
  Grid,
  Box,
  FormControl,
  FormHelperText,
  Autocomplete,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useForm, Controller } from 'react-hook-form'
import {
  mustBeValidDate,
  mustDateBeLessThan,
} from '../../../utils/form-validators'
import AutocompleteField from '../../../components/AutocompleteField'
import {
  getCountries,
  getStateByCountry,
  getCitiesByStateAndCountry,
} from '../../../utils/extapi'
import { getCompanySize, getCompanyNumberEmployee } from '../api'

export default function CompanyProfileForm({ children, submit }) {
  const [countries, setCountries] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedRegion, setSelectedRegion] = useState(null)
  const [regionsByCountry, setRegionsByCountry] = useState([])
  const [citiesByRegion, setCitiesByRegion] = useState([])

  const {
    handleSubmit,
    register,
    setValue,
    control,
    watch,

    formState: { errors },
  } = useForm()

  const today = new Date()

  useEffect(() => {
    getCountries().then((res) => {
      if (res.data) {
        setCountries(res.data)
      }
    })
  }, [])

  useEffect(() => {
    if (selectedCountry && selectedCountry.iso2) {
      getStateByCountry(selectedCountry.iso2).then((res) => {
        if (res.data) {
          setRegionsByCountry(res.data)
        }
      })
    }
  }, [selectedCountry])

  useEffect(() => {
    if (
      selectedCountry &&
      selectedCountry.iso2 &&
      selectedRegion &&
      selectedRegion.iso2
    ) {
      getCitiesByStateAndCountry(
        selectedCountry.iso2,
        selectedRegion.iso2,
      ).then((res) => {
        if (res.data) {
          setCitiesByRegion(res.data)
        }
      })
    }
  }, [selectedRegion, selectedCountry])

  return (
    <Box
      component="form"
      onSubmit={handleSubmit((data) => {
        submit(data)
      })}
      noValidate
      sx={{
        textAlign: 'center',
      }}
    >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        {/* Name */}
        <Grid item xs={12} sm={6}>
          <TextField
            {...register('companyName', {
              required: "Veuillez insérer le nom d l'entreprise",
            })}
            margin="normal"
            fullWidth
            label="Nom d'entreprise"
            name="companyName"
            autoFocus
            // InputLabelProps={{ shrink: true }}
            autoComplete="current-companyName"
            helperText={errors.companyName?.message}
            error={!!errors.companyName}
          />
        </Grid>
        {/* /Name */}
        {/* Creation Date */}
        <Grid item xs={12} sm={6}>
          <Box sx={{ flex: 1, mt: 2 }}>
            <Controller
              control={control}
              name="creationDate"
              rules={{
                validate: {
                  lessThanToday: (value) =>
                    mustDateBeLessThan(value, 'today')(today),
                  isValidDate: (value) => mustBeValidDate(value),
                },
                required: 'Veuillez insérer la data de création',
              }}
              render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date de création"
                    value={value}
                    onChange={onChange}
                    maxDate={today}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                  <FormHelperText
                    sx={{
                      pl: 2,
                    }}
                    error={Boolean(errors.creationDate)}
                  >
                    {errors.creationDate?.message}
                  </FormHelperText>
                </LocalizationProvider>
              )}
            />
          </Box>
        </Grid>
        {/* /Creation Date */}
        {/* CompanyAdress */}
        <Grid item xs={12} sm={6}>
          <TextField
            {...register('companyAddress', {
              required: "Veuillez insérer l'adresse de l'entreprise",
            })}
            margin="normal"
            fullWidth
            label="l'adresse d'entreprise"
            name="companyAddress"
            autoComplete="current-companyAddress"
            // InputLabelProps={{ shrink: true }}
            helperText={errors.companyAddress?.message}
            error={!!errors.companyAddress}
          />
        </Grid>
        {/* /CompanyAdress */}
        {/* country */}
        <Grid item xs={12} sm={6}>
          <Box sx={{ flex: 1, mt: 2 }}>
            <Controller
              name="country"
              control={control}
              rules={{
                required: 'Veuillez sélectionner un pays',
              }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  value={value || null}
                  options={countries}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, values) => {
                    onChange(values)

                    setSelectedCountry(values)
                    setValue('region', null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Pays"
                      placeholder="Pays"
                      helperText={errors.country?.message}
                      error={!!errors.country}
                    />
                  )}
                />
              )}
            />
            <FormHelperText
              sx={{
                pl: 2,
              }}
              error={Boolean(errors.country)}
            >
              {errors.country?.message}
            </FormHelperText>
          </Box>
        </Grid>
        {/* /country */}
        {/* region */}
        {watch('country')?.name && (
          <Grid item xs={12} sm={6}>
            <Box sx={{ flex: 1, mt: 2 }}>
              <Controller
                name="region"
                control={control}
                rules={{
                  required: 'Veuillez sélectionner une region',
                }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    value={value || null}
                    options={regionsByCountry}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, values) => {
                      onChange(values)

                      setSelectedRegion(values)
                      setValue('city', null)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Région"
                        placeholder="Région"
                        helperText={errors.region?.message}
                        error={!!errors.region}
                      />
                    )}
                  />
                )}
              />
              <FormHelperText
                sx={{
                  pl: 2,
                }}
                error={Boolean(errors.region)}
              >
                {errors.region?.message}
              </FormHelperText>
            </Box>
          </Grid>
        )}
        {/* /region */}
        {/* city */}
        {watch('region')?.name && (
          <Grid item xs={12} sm={6}>
            <Box sx={{ flex: 1, mt: 2 }}>
              <Controller
                name="city"
                control={control}
                rules={{
                  required: 'Veuillez sélectionner une cité',
                }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    value={value || null}
                    options={citiesByRegion}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, values) => {
                      onChange(values)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cité"
                        placeholder="Cité"
                        helperText={errors.city?.message}
                        error={!!errors.city}
                      />
                    )}
                  />
                )}
              />
              <FormHelperText
                sx={{
                  pl: 2,
                }}
                error={Boolean(errors.city)}
              >
                {errors.city?.message}
              </FormHelperText>
            </Box>
          </Grid>
        )}{' '}
        {/* /city */}
        {/* Size */}
        <Grid item xs={12} sm={6}>
          <Box sx={{ flex: 1, mt: 2 }}>
            <Controller
              name="size"
              control={control}
              rules={{
                required: "Veuillez sélectionner la taille de l'entreprise",
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <AutocompleteField
                    value={value}
                    setValue={onChange}
                    request={getCompanySize}
                    label="Taille d'entreprise"
                  />
                </FormControl>
              )}
            />
            <FormHelperText
              sx={{
                pl: 2,
              }}
              error={Boolean(errors.size)}
            >
              {errors.size?.message}
            </FormHelperText>
          </Box>
        </Grid>
        {/* /Size */}
        {/* zipCode */}
        <Grid item xs={12} sm={6}>
          <TextField
            {...register('zipCode', {
              required: {
                value: true,
                message: 'Veuillez insérer le code postal',
              },
            })}
            margin="normal"
            fullWidth
            label="Code Postal"
            name="zipCode"
            autoComplete="current-zipCode"
            // InputLabelProps={{ shrink: true }}
            helperText={errors.zipCode?.message}
            error={!!errors.zipCode}
          />
        </Grid>
        {/* /zipCode */}
        {/* numberEmployee */}
        <Grid item xs={12} sm={6}>
          <Box sx={{ flex: 1, mt: 2 }}>
            <Controller
              name="numberEmployee"
              control={control}
              rules={{
                required: "Veuillez sélectionner le nombre d'employés",
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <AutocompleteField
                    value={value}
                    setValue={onChange}
                    request={getCompanyNumberEmployee}
                    label="Nombre d'employés"
                  />
                </FormControl>
              )}
            />
            <FormHelperText
              sx={{
                pl: 2,
              }}
              error={Boolean(errors.numberEmployee)}
            >
              {errors.numberEmployee?.message}
            </FormHelperText>
          </Box>
        </Grid>
        {/* /numberEmployee */}
      </Grid>
      {children}
    </Box>
  )
}

CompanyProfileForm.propTypes = {
  children: PropTypes.node.isRequired,
  submit: PropTypes.func.isRequired,
}
