import { Box, Typography } from '@mui/material'
// import CompanyGoalForm from '../../modules/companyGoal/components/CompanyGoalForm'
import CompanyGoalForm from '../../modules/companyGoal/components/CompanyGoalForm'
import GolasQuestions from '../../modules/companyGoal/gloalsQuestions.json'
import WhiteBox from '../../components/WhiteBox'
import { StrongText } from '../../utils/customElements'

export default function CompanyGoal() {
  return (
    <WhiteBox
      title=" Let's start building your sustainable and innovative smart digital
    future"
    >
      <Box
        sx={{
          color: 'black.black75',
        }}
      >
        <Typography
          component="div"
          variant="body1"
          sx={{
            color: 'inherit',
          }}
        >
          As a starting point, we would like you to think about your
          organization overall{' '}
          <StrongText
            component="span"
            sx={{
              color: 'mysensPalette.orange',
            }}
          >
            AI-driven
          </StrongText>{' '}
          ambition.
        </Typography>
        <Typography
          component="div"
          variant="body1"
          sx={{
            color: 'inherit',
          }}
        >
          Before to go over your readiness assessment, we would like better
          understand your{' '}
          <StrongText
            component="span"
            sx={{
              color: 'mysensPalette.orange',
            }}
          >
            mindset
          </StrongText>{' '}
          regarding some definitions
        </Typography>
      </Box>
      <Box mt={4}>
        <CompanyGoalForm steps={GolasQuestions} />
      </Box>
    </WhiteBox>
  )
}
