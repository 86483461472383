import PropTypes from 'prop-types'
import { Box, IconButton, Typography } from '@mui/material'
import StarRateIcon from '@mui/icons-material/StarRate'
import EditIcon from '@mui/icons-material/Edit'
import { mapperQuestionType } from '../../../utils/mappers'
import WhiteBox from '../../../components/WhiteBox'

export default function QuestionsCard({ item, handleOpen }) {
  return (
    <WhiteBox>
      {/* Weight && Thematics */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          {[...Array(item.weight).keys()].map((value) => (
            <StarRateIcon
              key={`${item.id}-weight-${value}`}
              sx={{
                fontSize: '1.424rem',
                color: 'black.black50',
              }}
            />
          ))}
        </Box>
        <Box
          sx={{
            py: 0.5,
            px: 2,
            border: '1px solid rgba(0,18,27,0.1)',
          }}
        >
          <Typography variant="body1" color="black.black50">
            {item?.thematic?.name}
          </Typography>
        </Box>
      </Box>
      {/* /Weight && Thematics */}

      {/* Question */}
      <Typography mt={2} variant="h5" color="black.black75">
        {item.question}
      </Typography>
      {/* /Question */}

      {/* Answers */}
      {item?.answers && (
        <Box mt={2} pl={4}>
          {JSON.parse(item.answers).map((answer) => (
            <Box
              key={`${answer.answer}-${answer.score}`}
              sx={{
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  mr: 1,
                  py: 0.125,
                  px: 1,
                  border: '1px solid rgba(0,18,27,0.1)',
                }}
              >
                <Typography variant="body1" color="black.black50">
                  {item.type === 'select' ? answer.score : 1}
                </Typography>
              </Box>

              <Box>
                <Typography variant="body1" color="black.black75">
                  {answer.answer}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      )}
      {/* /Answers */}

      {/* Question Type, answers'number && actions */}
      <Box
        mt={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Box>
            <Typography variant="body1" color="black.black50">
              {mapperQuestionType[item.type]}{' '}
              {item?.answers
                ? `/ ${JSON.parse(item.answers).length} réponses`
                : ''}
            </Typography>
          </Box>
        </Box>

        <Box>
          <IconButton
            onClick={() => {
              handleOpen('edit', item)
            }}
          >
            <EditIcon />
          </IconButton>
        </Box>
      </Box>
      {/* /Question Type, answers'number && actions */}
    </WhiteBox>
  )
}

QuestionsCard.propTypes = {
  item: PropTypes.object,
  handleOpen: PropTypes.func,
}
