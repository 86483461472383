import { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  CircularProgress,
  TextField,
  Autocomplete,
  Chip,
  FormHelperText,
  FormControl,
  Button,
  InputLabel,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useAlert } from '../../alert/AlertProvider'
import { useAuth } from '../../auth/AuthProvider'
import { postUseCase, updateUseCase } from '../api'

import { useCache } from '../../cache/CacheProvider'
import {
  FaisabilityMapper,
  FaisabilityMapperInverse,
} from '../../../utils/mappers'
import MultiSelect from '../../../components/MultiSelect'
import Editor from '../../../components/Editor'
import { useIndustries } from '../../industry/IndustryProvider'

export default function UseCasesForm({
  specializations,
  action,
  useCasesData,
  handleClose,
  setUseCases,
  setRows,
}) {
  const { industries } = useIndustries()
  const { industryType, saveIndustryType } = useCache()
  const [loading, setLoading] = useState(false)
  const { showAlert } = useAlert()

  const { account } = useAuth()

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: useCasesData?.name || '',
      description: useCasesData?.description || '',
      // prerequisite: useCasesData?.prerequisite || '',
      benefit: useCasesData?.benefit || '',
      faisability: useCasesData?.faisabilite
        ? FaisabilityMapper[useCasesData?.faisabilite]
        : null,
      tags: useCasesData?.tags ? JSON.parse(useCasesData?.tags) : [],
      industries: useCasesData?.industries
        ? JSON.parse(useCasesData?.industries)
        : industryType || [],
      specializations: useCasesData?.specializations
        ? JSON.parse(useCasesData?.specializations)
        : [],
      prerequisite_data: useCasesData?.prerequisite_data || '',
      prerequisite_technologie: useCasesData?.prerequisite_technologie || '',
      prerequisite_competance: useCasesData?.prerequisite_competance || '',
      prerequisite_general: useCasesData?.prerequisite_general || '',
    },
  })

  const addUseCase = (sendData) => {
    postUseCase({
      ...sendData,
      created_by: account.id,
    })
      .then((res) => {
        setRows((prevRows) => [
          {
            // ...sendData,
            ...res.data,
            id: res.data.id,
          },
          ...prevRows,
        ])
        setUseCases((prevQuestions) => [
          {
            // ...sendData,
            ...res.data,
            id: res.data.id,
          },
          ...prevQuestions,
        ])
        handleClose()
        showAlert("Le cas d'usage a été ajouté avec success", 'success')
      })
      .catch(() => {
        showAlert("Une erreur s'est produite", 'error')
      })
  }

  const editUseCase = (sendData) => {
    updateUseCase(useCasesData.id, sendData)
      .then(() => {
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === useCasesData?.id
              ? {
                  ...sendData,
                  id: useCasesData?.id,
                }
              : row,
          ),
        )
        setUseCases((prevQuestions) =>
          prevQuestions.map((row) =>
            row.id === useCasesData?.id
              ? {
                  ...sendData,
                  id: useCasesData?.id,
                }
              : row,
          ),
        )
        handleClose()
        showAlert("Le cas d'usage a été modifié avec success", 'success')
      })
      .catch(() => {
        showAlert("Une erreur s'est produite", 'error')
      })
  }

  const onSubmit = (data) => {
    setLoading(true)

    const sendData = {
      ...data,
      name: data.name,
      description: data.description,
      // prerequisite: data.prerequisite,
      benefit: data.benefit,
      faisabilite: FaisabilityMapperInverse[data.faisability],
      tags: JSON.stringify(data.tags),
      industries: JSON.stringify(data.industries),
      specializations: JSON.stringify(data.specializations),
      prerequisite_data: data.prerequisite_data,
      prerequisite_technologie: data.prerequisite_technologie,
      prerequisite_competance: data.prerequisite_competance,
      prerequisite_general: data.prerequisite_general,
    }

    if (action === 'add') {
      addUseCase(sendData)
    }
    if (action === 'edit') {
      // if (useCasesData.created_by_id !== account.id) {
      //   addUseCase(sendData)
      // } else {
      editUseCase({
        ...useCasesData,
        ...sendData,
      })
      // }
    }

    saveIndustryType(data.industries)
    setLoading(false)
  }

  return (
    <Box
      sx={{
        // width: 400,
        boxShadow: 24,
        p: 4,
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <>
        <Box>
          {/* Name */}
          <Box>
            <TextField
              {...register('name', {
                required: {
                  value: true,
                  message: 'Veuillez saisir une valeur',
                },
              })}
              margin="normal"
              fullWidth
              id="name"
              label="Nom"
              name="name"
              autoComplete="name"
              autoFocus
              helperText={errors.name?.message}
              error={!!errors.name}
            />
          </Box>
          {/* /Name */}
          {/* Description */}
          <Box>
            <InputLabel
              htmlFor="description"
              sx={{
                mt: 1,
                ml: 1,
                mb: 1,
              }}
            >
              Description
            </InputLabel>
            <Controller
              name="description"
              control={control}
              rules={{
                required: 'Veuillez insérer le text',
              }}
              render={({ field: { onChange } }) => (
                <FormControl fullWidth>
                  <Editor
                    initialValue={useCasesData?.description || ''}
                    onChange={onChange}
                    height={450}
                    placeholder="Insérer la description ici ... "
                  />
                </FormControl>
              )}
            />
            <Box pl={2}>
              <FormHelperText error={Boolean(errors.description)}>
                {errors.description?.message}
              </FormHelperText>
            </Box>
          </Box>
          {/* /Description */}
          {/* Benefit */}
          <Box>
            <InputLabel
              htmlFor="benefit"
              sx={{
                mt: 2,
                ml: 1,
                mb: 1,
              }}
            >
              Bénéfice
            </InputLabel>
            <Controller
              name="benefit"
              control={control}
              rules={{
                required: 'Veuillez insérer le text',
              }}
              render={({ field: { onChange } }) => (
                <FormControl fullWidth>
                  <Editor
                    initialValue={useCasesData?.benefit || ''}
                    onChange={onChange}
                    height={200}
                    placeholder="Insérer le bénéfice ici ... "
                  />
                </FormControl>
              )}
            />
            <Box pl={2}>
              <FormHelperText error={Boolean(errors.benefit)}>
                {errors.benefit?.message}
              </FormHelperText>
            </Box>
          </Box>
          {/* /Benefit */}
          {/* prerequisite */}
          {/* prerequisite data */}
          <Box>
            <InputLabel
              htmlFor="prerequisite_data"
              sx={{
                mt: 2,
                ml: 1,
                mb: 1,
              }}
            >
              Prérequis données
            </InputLabel>
            <Controller
              name="prerequisite_data"
              control={control}
              rules={{
                required: 'Veuillez insérer le text',
              }}
              render={({ field: { onChange } }) => (
                <FormControl fullWidth>
                  <Editor
                    initialValue={useCasesData?.prerequisite_data || ''}
                    onChange={onChange}
                    height={200}
                    placeholder="Insérer les prérequis données ici ... "
                  />
                </FormControl>
              )}
            />
            <Box pl={2}>
              <FormHelperText error={Boolean(errors.prerequisite_data)}>
                {errors.prerequisite_data?.message}
              </FormHelperText>
            </Box>
          </Box>
          {/* /prerequisite data */}
          {/* prerequisite_technologie */}
          <Box>
            <InputLabel
              htmlFor="prerequisite_technologie"
              sx={{
                mt: 2,
                ml: 1,
                mb: 1,
              }}
            >
              Prérequis technologiques
            </InputLabel>
            <Controller
              name="prerequisite_technologie"
              control={control}
              rules={{
                required: 'Veuillez insérer le text',
              }}
              render={({ field: { onChange } }) => (
                <FormControl fullWidth>
                  <Editor
                    initialValue={useCasesData?.prerequisite_technologie || ''}
                    onChange={onChange}
                    height={200}
                    placeholder="Insérer les prérequis technologiques ici ... "
                  />
                </FormControl>
              )}
            />
            <Box pl={2}>
              <FormHelperText error={Boolean(errors.prerequisite_technologie)}>
                {errors.prerequisite_technologie?.message}
              </FormHelperText>
            </Box>
          </Box>
          {/* /prerequisite_technologie */}
          {/* prerequisite_competance */}
          <Box>
            <InputLabel
              htmlFor="prerequisite_competance"
              sx={{
                mt: 2,
                ml: 1,
                mb: 1,
              }}
            >
              Prérequis compétances
            </InputLabel>
            <Controller
              name="prerequisite_competance"
              control={control}
              rules={{
                required: 'Veuillez insérer le text',
              }}
              render={({ field: { onChange } }) => (
                <FormControl fullWidth>
                  <Editor
                    initialValue={useCasesData?.prerequisite_competance || ''}
                    onChange={onChange}
                    height={200}
                    placeholder="Insérer les prérequis compétances ici ... "
                  />
                </FormControl>
              )}
            />
            <Box pl={2}>
              <FormHelperText error={Boolean(errors.prerequisite_competance)}>
                {errors.prerequisite_competance?.message}
              </FormHelperText>
            </Box>
          </Box>
          {/* /prerequisite_competance */}
          {/* prerequisite_general */}
          <Box>
            <InputLabel
              htmlFor="prerequisite_general"
              sx={{
                mt: 2,
                ml: 1,
                mb: 1,
              }}
            >
              Prérequis générals
            </InputLabel>
            <Controller
              name="prerequisite_general"
              control={control}
              rules={{
                required: 'Veuillez insérer le text',
              }}
              render={({ field: { onChange } }) => (
                <FormControl fullWidth>
                  <Editor
                    initialValue={useCasesData?.prerequisite_general || ''}
                    onChange={onChange}
                    height={200}
                    placeholder="Insérer les prérequis générals ici ... "
                  />
                </FormControl>
              )}
            />
            <Box pl={2}>
              <FormHelperText error={Boolean(errors.prerequisite_general)}>
                {errors.prerequisite_general?.message}
              </FormHelperText>
            </Box>
          </Box>
          {/* /prerequisite_general */}

          {/* /prerequisite */}
          {/* Faisability */}
          <Box mt={2}>
            <Controller
              control={control}
              name="faisability"
              rules={{
                required: 'Veuillez choisir une réponse',
              }}
              render={({ field: { onChange } }) => (
                <Autocomplete
                  defaultValue={
                    useCasesData?.faisabilite
                      ? FaisabilityMapper[useCasesData?.faisabilite]
                      : null
                  }
                  options={[1, 2, 3].map((item) => FaisabilityMapper[item])}
                  getOptionLabel={(option) => option}
                  onChange={(event, values) => {
                    onChange(values)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Faisabilité"
                      placeholder="Faisabilité"
                      helperText={errors.faisability?.message}
                      error={!!errors.faisability}
                    />
                  )}
                />
              )}
            />
          </Box>
          {/* /Faisability */}
          {/* tags */}
          <Box mt={2}>
            <Controller
              control={control}
              name="tags"
              rules={{
                required: 'Veuillez choisir une réponse',
              }}
              render={({ field: { onChange } }) => (
                <Autocomplete
                  defaultValue={
                    useCasesData?.tags ? JSON.parse(useCasesData?.tags) : []
                  }
                  multiple
                  id="tags-filled"
                  options={[]}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  onChange={(event, values) => {
                    onChange(values)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Métadonnées"
                      placeholder="Ecriver les métadonnées"
                      helperText={errors.tags?.message}
                      error={!!errors.tags}
                    />
                  )}
                />
              )}
            />
          </Box>
          {/* /tags */}
          {/* Industries */}
          <Box mt={2}>
            <Controller
              control={control}
              name="industries"
              rules={{
                required: 'Veuillez choisir une réponse',
              }}
              render={({ field: { onChange } }) => (
                <MultiSelect
                  defaultValue={
                    useCasesData?.industries
                      ? JSON.parse(useCasesData?.industries)
                      : industryType || []
                  }
                  // items={companyIndustryTypes}
                  items={industries}
                  label="Industries"
                  selectAllLabel="Sélectionner Tous"
                  onChange={onChange}
                />
              )}
            />
            <FormHelperText
              sx={{
                pl: 2,
              }}
              error={Boolean(errors.industries)}
            >
              {errors.industries?.message}
            </FormHelperText>
          </Box>
          {/* /Industries */}
          {/* Specializations */}
          <Box mt={2}>
            <Controller
              control={control}
              name="specializations"
              rules={{
                required: 'Veuillez choisir une réponse',
              }}
              render={({ field: { onChange } }) => (
                <MultiSelect
                  defaultValue={
                    useCasesData?.specializations
                      ? JSON.parse(useCasesData?.specializations)
                      : []
                  }
                  items={specializations}
                  label="Spécialization"
                  selectAllLabel="Sélectionner Tous"
                  onChange={onChange}
                />
              )}
            />
            <FormHelperText
              sx={{
                pl: 2,
              }}
              error={Boolean(errors.specializations)}
            >
              {errors.specializations?.message}
            </FormHelperText>
          </Box>
          {/* /Specializations */}
        </Box>

        <Button
          startIcon={
            loading ? (
              <CircularProgress size={20} />
            ) : (
              <AddIcon color="white" size="small" />
            )
          }
          type="submit"
          fullWidth
          disabled={loading}
          variant="mysensContained"
          sx={{ mt: 2 }}
        >
          Enregistrer
        </Button>
      </>
    </Box>
  )
}
UseCasesForm.propTypes = {
  specializations: PropTypes.array,
  action: PropTypes.string,
  useCasesData: PropTypes.object,
  handleClose: PropTypes.func,
  setUseCases: PropTypes.func,
  setRows: PropTypes.func,
}
