import { Routes, Route, Navigate } from 'react-router-dom'
import CreateCompany from './screens/company/CreateCompany'
import Layout from './components/Layout'
import IndustryProvider from './modules/industry/IndustryProvider'

export default function CreateCompanyApp() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <IndustryProvider>
              <CreateCompany />
            </IndustryProvider>
          </Layout>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}
