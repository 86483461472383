import PropTypes from 'prop-types'
import { Grid, Box, Avatar, Typography } from '@mui/material'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import WhiteBox from '../../components/WhiteBox'

function BasicInfo({ profileRole, account }) {
  return (
    <WhiteBox whithPadding={false}>
      <Box
        sx={{
          height: '128px',
          background:
            'linear-gradient(rgba(0, 31, 41, 0.96), rgba(0, 62, 74, 0.96))',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Avatar
          alt="profile icon"
          sx={{
            position: 'absolute',
            top: -50,
            width: '5.96rem',
            height: '5.96rem',
            color: 'white.white100',
            backgroundColor: 'mysensPalette.lightBlue',
            fontSize: '1.25rem',
            fontWeight: '600',
          }}
        >
          {`${account.firstName[0]}${account.lastName[0]}`}
        </Avatar>
      </Box>
      <Box
        mt={7}
        px={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: 4,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: 'black.black75',
          }}
        >
          {`${account.firstName} ${account.lastName}`}
        </Typography>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={4}>
            <Typography
              component="div"
              variant="body1"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <EmailOutlinedIcon
                sx={{
                  mr: 1,
                  fontSize: '1.25rem',
                  color: 'mysensPalette.orange',
                }}
              />
              {account.email}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} justifyContent="center">
            <Typography
              component="div"
              variant="body1"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LocalPhoneOutlinedIcon
                sx={{
                  mr: 1,
                  fontSize: '1.25rem',
                  color: 'mysensPalette.orange',
                }}
              />
              {account.phoneNumber}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              component="div"
              variant="body1"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <WorkOutlineOutlinedIcon
                sx={{
                  mr: 1,
                  fontSize: '1.25rem',
                  color: 'mysensPalette.orange',
                }}
              />
              {(profileRole && profileRole?.name) || account.profileRole}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </WhiteBox>
  )
}

BasicInfo.propTypes = {
  profileRole: PropTypes.object,
  account: PropTypes.object,
}

export default BasicInfo
