import { Box, Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'

export default function AuthLayout({ children }) {
  return (
    <Grid container height="100vh">
      <Grid
        item
        xs={12}
        lg={6}
        sx={{
          alignItems: 'center',
          background:
            'radial-gradient(50% 50% at 50% 50%, #003E4A 0%, #001F29 100%)',
          color: 'white.white100',
          display: {
            xs: 'none',
            md: 'flex',
          },
          justifyContent: 'center',
          '& img': {
            maxWidth: '88%',
          },
        }}
      >
        <Box sx={{ p: 3 }}>
          <Typography
            component="div"
            align="center"
            color="inherit"
            variant="h5"
            sx={{
              fontSize: '24px',
              fontWeight: 'bold',
              lineHeight: '32px',
              mb: 1,
            }}
          >
            Révélez votre potentiel numérique avec&nbsp;
            <Typography
              variant="h5"
              component="a"
              href="https://mysens.ai/"
              sx={{
                fontSize: '24px',
                fontWeight: 'bold',
                lineHeight: '32px',
                textDecoration: 'none',
                color: 'mysensPalette.primary',
              }}
              target="_blank"
            >
              MySens.AI
            </Typography>
          </Typography>
          <Typography align="center" sx={{ mb: 2 }} variant="body1">
            ici commence votre stratégie d’innovation personnalisée en IA
          </Typography>
          <img alt="mysensai logo" src="/images/MySensAI_logo.png" />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        py={2}
        px={4}
        sx={{
          direction: 'column',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          {children}
        </Box>
      </Grid>
    </Grid>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node,
}
