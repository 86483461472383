import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Grid, Box, Typography } from '@mui/material'
import Chart from 'react-apexcharts'
import { scoreCharts } from '../../../utils/utilfc'
import WhiteBox from '../../../components/WhiteBox'
import { dimensionNumberMapper } from '../../../utils/mappers'
// import { useDimension } from '../../dimension/DimensionProvider'

export default function DimensionsScore({ dimensionsScore }) {
  const [chart, setChart] = useState({
    donut: {
      options: {},
      series: [],
    },
    radar: {
      options: {},
      series: [],
    },
    bar: {
      options: {},
      series: [],
    },
  })
  // const { dimensionsScore } = useDimension()

  useEffect(() => {
    if (dimensionsScore) {
      setChart(scoreCharts(dimensionsScore))
    }
  }, [dimensionsScore])

  if (
    dimensionsScore === undefined ||
    (dimensionsScore && !dimensionsScore.length)
  ) {
    return null
  }
  return (
    <WhiteBox title="Scores par dimensions">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Chart
            options={chart.bar.options}
            series={chart.bar.series}
            type="bar"
            height="304px"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Chart
            options={chart.radar.options}
            series={chart.radar.series}
            type="radar"
            width="100%"
            height="304px"
          />
          <Box
            mt={chart.radar.labels <= 3 ? -11.5 : -7.5}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {chart.radar.labels &&
              chart.radar.labels.map((label) => (
                <Box
                  key={label}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',

                    mr: 1,
                  }}
                >
                  <Box
                    sx={{
                      py: '0rem',
                      px: '0.35rem',
                      borderRadius: '100%',
                      border: '1px solid rgba(0,18,27,0.1)',
                      mr: 0.4,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="caption">{label}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption" color="black.black75">
                      {dimensionNumberMapper[label].split('&')[0]}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>
        </Grid>
      </Grid>
    </WhiteBox>
  )
}

DimensionsScore.propTypes = {
  dimensionsScore: PropTypes.array,
}
