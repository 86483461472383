import { useState } from 'react'
import PropTypes from 'prop-types'
import { styled, Box, Toolbar, IconButton, Avatar } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'

import MenuIcon from '@mui/icons-material/Menu'
import MenuComponent from './MenuComponent'
import { useAuth } from '../modules/auth/AuthProvider'

const drawerWidth = 287.07

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export default function Appbar({ open, toggleDrawer }) {
  const { account } = useAuth()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <AppBar position="absolute" open={open}>
      <Box
        sx={{
          boxShadow:
            'rgb(0 0 0 / 6%) 0px 2px 4px -1px, rgb(0 0 0 / 4%) 0px 4px 5px 0px, rgb(0 0 0 / 4%) 0px 1px 10px 0px',
          background:
            'radial-gradient(100% 100% at 100% 50%,  #003E4A 10%,  #001F29 100%)',
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
          }}
        >
          <Box
            sx={{
              flex: 1,
            }}
          >
            <IconButton
              edge="start"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                mr: 2,
              }}
            >
              <MenuIcon
                sx={{
                  color: 'white.white75',
                  '&:hover': {
                    color: 'white.white100',
                  },
                }}
              />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box>
              <Avatar
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                alt="profile icon"
                sx={{
                  color: 'black.black75',
                  backgroundColor: 'white.white75',
                  cursor: 'pointer',
                  fontSize: '1rem',
                  fontWeight: '600',
                  '&:hover': {
                    backgroundColor: 'white.white100',
                  },
                }}
              >
                {`${account.firstName[0]}${account.lastName[0]}`}
              </Avatar>
              <MenuComponent anchorEl={anchorEl} handleClose={handleClose} />
            </Box>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  )
}

Appbar.propTypes = {
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func,
}
