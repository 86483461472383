import { useState, useEffect, useRef } from 'react'
import {
  Box,
  Tab,
  Button,
  Dialog,
  Pagination,
  DialogTitle,
  Typography,
  IconButton,
  Grid,
} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import FullPageSpinner from '../../components/FullPageSpinner'
import FullPageErrorFallback from '../../components/FullPageErrorFallback'
import { useDimension } from '../../modules/dimension/DimensionProvider'
import { escapeRegExp } from '../../utils/utilfc'
import QuickSearchToolbar from '../../components/QuickSearchToolbar'
import { useAuth } from '../../modules/auth/AuthProvider'
import { getQuestionsBySiigoByUserByDimension } from '../../modules/adminQuestion/api'
import QuestionForm from '../../modules/adminQuestion/components/QuestionForm'
import QuestionsCard from '../../modules/adminQuestion/components/QuestionsCard'
import WhiteBox from '../../components/WhiteBox'
import QuestionFilter from '../../modules/adminQuestion/components/QuestionFilter'
import { getThematicsByUserAndSciigoByDimension } from '../../modules/thematics/api'

export default function LabTabs() {
  const { account } = useAuth()
  const [thematics, setThematics] = useState([])
  const { dimensions, dimensionsLoding, dimensionsError } = useDimension()
  const [page, setPage] = useState(1)
  const [value, setValue] = useState('Stratégies')
  const [dimensionId, setDimensionId] = useState()
  const [questions, setQuestions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(null)
  const [rows, setRows] = useState(questions)
  const [searchText, setSearchText] = useState('')

  const [dialog, setDialog] = useState({
    open: false,
    action: 'add',
    data: {},
  })

  const filter = useRef({
    weight: 0,
    thematic: null,
    industry: null,
  })

  const setFilter = (filterVal) => {
    filter.current = filterVal
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleOpen = (action, data) => {
    setDialog({
      action,
      data,
      open: true,
    })
  }
  const handleClose = () => {
    setDialog({
      ...dialog,
      open: false,
    })
  }

  // const requestSearch = (searchValue) => {
  //   setSearchText(searchValue)
  //   const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
  //   const filteredRows = questions.filter((row) =>
  //     Object.keys(row).some((field) => searchRegex.test(row[field].toString())),
  //   )
  //   setRows(filteredRows)
  // }
  const requestSearch = (searchValue) => {
    setSearchText(searchValue)
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
    const filteredRows = questions.filter((row) =>
      searchRegex.test(row.question),
    )
    setRows(filteredRows)
  }

  const handlePaginationChange = (event, newValue) => {
    setPage(newValue)
  }

  useEffect(() => {
    if (dimensionId) {
      getThematicsByUserAndSciigoByDimension(account.id, dimensionId).then(
        (res) => {
          setThematics(res.data)
        },
      )
    }
  }, [account.id, dimensionId])

  useEffect(() => {
    if (dimensionId) {
      setIsLoading(true)
      getQuestionsBySiigoByUserByDimension(account.id, dimensionId)
        .then((res) => {
          setQuestions(res.data)
          setRows(res.data)
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
          setIsError(true)
        })
    }
  }, [account.id, dimensionId])

  useEffect(() => {
    if (dimensions && dimensions.length > 0) {
      const currentDimension = dimensions.find(
        (dimension) => dimension.name === value,
      )
      setDimensionId(currentDimension.id)
    }
  }, [dimensions, value])

  if (dimensionsError) {
    return <FullPageErrorFallback />
  }

  if (dimensionsLoding) {
    return <FullPageSpinner />
  }

  return (
    <>
      <WhiteBox
        title="Questions"
        button={
          <Button
            variant="text"
            sx={{
              fontSize: '1.125rem',
              textTransform: 'none',
            }}
            endIcon={<AddIcon />}
            onClick={() => {
              handleOpen('add', {})
            }}
          >
            Ajouter une question
          </Button>
        }
        search={
          <QuickSearchToolbar
            value={searchText}
            onChange={(event) => requestSearch(event.target.value)}
            clearSearch={() => requestSearch('')}
          />
        }
        pagination={
          <Pagination
            count={5}
            page={page}
            onChange={handlePaginationChange}
            shape="rounded"
          />
        }
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} variant="scrollable">
              {dimensions &&
                dimensions.map((dimension) => (
                  <Tab
                    key={dimension.id}
                    value={dimension.name}
                    label={dimension.name}
                    sx={{
                      textTransform: 'none',
                    }}
                  />
                ))}
            </TabList>
          </Box>
          {dimensions &&
            dimensions.map((dimension) => (
              <Box key={dimension.id}>
                {isError && <FullPageErrorFallback />}
                {isLoading && <FullPageSpinner />}
                {!isError && !isLoading && (
                  <TabPanel value={dimension.name}>
                    <Box
                      mt={3}
                      mb={2}
                      sx={{
                        px: 3,
                      }}
                    >
                      <QuestionFilter
                        rows={rows}
                        setFilter={setFilter}
                        thematics={thematics}
                        questions={questions}
                        setRows={setRows}
                      />
                    </Box>
                    <Box
                      sx={{
                        px: 3,
                        mb: 2,
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="black.black75"
                        sx={{
                          textAlign: 'right',
                        }}
                      >
                        ({rows.length}) questions
                      </Typography>
                    </Box>
                    <Grid container spacing={2}>
                      {rows.length > 0 ? (
                        rows.map((item) => (
                          <Grid key={item.id} item xs={12}>
                            <QuestionsCard
                              item={item}
                              handleOpen={handleOpen}
                              isLoading={isLoading}
                              isError={isError}
                            />
                          </Grid>
                        ))
                      ) : (
                        <Box
                          mt={2}
                          sx={{
                            width: '100%',
                          }}
                        >
                          <Typography
                            component="div"
                            variant="body1"
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            Pas d'éléments!{' '}
                            <Typography
                              component="span"
                              sx={{
                                fontWeight: 'bold',
                                color: 'primary.main',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                handleOpen('add', {})
                              }}
                            >
                              {' '}
                              insérez un
                            </Typography>
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </TabPanel>
                )}
              </Box>
            ))}
        </TabContext>
      </WhiteBox>
      <Dialog
        // onClose={handleClose}
        open={dialog.open}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            component="p"
            variant="h5"
            textAlign="center"
            gutterBottom
            sx={{
              pl: 2,
            }}
          >
            {dialog.action === 'add'
              ? 'Ajouter une question'
              : 'Editer la question'}
          </Typography>

          <IconButton onClick={handleClose} sx={{ ml: 'auto' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <QuestionForm
          filter={filter.current}
          thematics={thematics}
          dimensionId={dimensionId}
          action={dialog.action}
          questionData={dialog.data}
          handleClose={handleClose}
          setQuestions={setQuestions}
          setRows={setRows}
        />
      </Dialog>
    </>
  )
}
