import api from '../../utils/api'

export async function getClientsStats(poweruserId) {
  const url = `poweruserDashboard/clients/${poweruserId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result.data
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getEvaluationsStats(poweruserId) {
  const url = `poweruserDashboard/evaluations/${poweruserId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result.data
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getClientsScore(poweruserId) {
  const url = `poweruserDashboard/clientsScore/${poweruserId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result.data
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getMaturities(poweruserId) {
  const url = `poweruserDashboard/maturities/${poweruserId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result.data
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}
