import { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import LastPageIcon from '@mui/icons-material/LastPage'
import Chart from 'react-apexcharts'
import FullPageSpinner from '../../../components/FullPageSpinner'
import { statusColor } from '../../../utils/utilfc'
import { useAuth } from '../../auth/AuthProvider'
import { getClientsStats } from '../api'

export default function StatsClients() {
  const { account } = useAuth()
  const [loading, setLoading] = useState(false)
  const [clientsStats, setClientsStats] = useState({
    totalClients: 0,
    verifiedClients: 0,
    notVerifiedClients: 0,
  })

  useEffect(() => {
    setLoading(true)
    getClientsStats(account.id)
      .then((res) => {
        setClientsStats({
          totalClients: res.total_clients,
          verifiedClients: res.verified_clients,
          notVerifiedClients: res.not_verified_clients,
        })
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [account.id])

  if (loading) {
    return <FullPageSpinner />
  }

  const ScoreSemiCircular = {
    options: {
      chart: {
        height: '120px',
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          hollow: {
            size: '64%',
            backgroundColor: 'transparent',
          },
          track: {
            background: '#f1f1f1',
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },

      fill: {
        colors: [statusColor(clientsStats?.verifiedClients || 0)],
      },
    },
    series: [clientsStats?.verifiedClients || 0],
  }

  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Chart
          options={ScoreSemiCircular.options}
          series={ScoreSemiCircular.series}
          type="radialBar"
        />
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontWeight: '600',
              color: statusColor(25),
            }}
          >
            {clientsStats.totalClients}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          mt: {
            md: '-3.5rem',
            xs: '-1.5rem',
          },
        }}
      >
        <Typography
          component="div"
          variant="body1"
          sx={{
            textAlign: 'center',
            color: 'black.black50',
          }}
        >
          Total clients
        </Typography>
        {/* <Typography
          component="div"
          variant="body2"
          sx={{
            textAlign: 'center',
            color: 'black.black50',
          }}
        >
          Augmentation
        </Typography> */}
      </Box>

      <Box mt={4}>
        <Typography
          component="div"
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'black.black50',
          }}
        >
          <LastPageIcon
            sx={{
              color: 'mysensPalette.orange',
            }}
          />
          Nombre total des clients créés:&#8201;
          <Typography
            component="span"
            sx={{
              fontWeight: '600',
              color: 'mysensPalette.orange',
            }}
          >
            {clientsStats.verifiedClients}%
          </Typography>
        </Typography>
        <Typography
          component="div"
          sx={{
            mt: 1,
            display: 'flex',
            alignItems: 'center',
            color: 'black.black50',
          }}
        >
          <LastPageIcon
            sx={{
              color: 'mysensPalette.orange',
            }}
          />
          Nombre total des clients en attentes:&#8201;
          <Typography
            component="span"
            sx={{
              fontWeight: '600',
              color: 'mysensPalette.orange',
            }}
          >
            {clientsStats.notVerifiedClients}%
          </Typography>
        </Typography>
      </Box>
    </Box>
  )
}
