import PropTypes from 'prop-types'
import { Box, Divider, Typography } from '@mui/material'
import WhiteBox from '../../../components/WhiteBox'
import { companyInformationMapper } from '../../../utils/mappers'

function ItemBox({ title, value, index }) {
  return (
    <>
      <Box
        sx={{
          mt: index === 0 ? 0 : 2,
          display: 'flex',
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Typography variant="body1">{title}</Typography>
        </Box>
        <Box>
          <Typography variant="body1">{value}</Typography>
        </Box>
      </Box>
      <Divider
        sx={{
          mt: 2,
        }}
      />
    </>
  )
}
ItemBox.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  value: PropTypes.string,
}

export default function CompanyInfo({ account }) {
  return (
    <WhiteBox title="Information  sur l'entreprise">
      {account.company &&
        account.company.map((item, index) => (
          <Box key={item.key}>
            <ItemBox
              index={index}
              title={companyInformationMapper[item.key]}
              // title={item.key}
              value={item.value}
            />
          </Box>
        ))}
      {!account.company && (
        <Box>
          <Typography
            variant="body1"
            color="black.black75"
            sx={{
              textAlign: 'center',
            }}
          >
            Le client n'a pas encore remplie les informations de son entreprise
          </Typography>
        </Box>
      )}
    </WhiteBox>
  )
}

CompanyInfo.propTypes = {
  account: PropTypes.object,
}
