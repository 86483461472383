import { useState } from 'react'
import {
  Button,
  Dialog,
  Pagination,
  Grid,
  Box,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import FullPageSpinner from '../../components/FullPageSpinner'
import FullPageErrorFallback from '../../components/FullPageErrorFallback'
import TagsCard from '../../modules/thematics/components/ThematicTagsCard'
import TagsForm from '../../modules/tags/components/TagsForm'
import WhiteBox from '../../components/WhiteBox'
// import { useAuth } from '../../modules/auth/AuthProvider'
// import { getTagsByUser } from '../../modules/tags/api'
import { escapeRegExp } from '../../utils/utilfc'
import QuickSearchToolbar from '../../components/QuickSearchToolbar'
import { useTags } from '../../modules/tags/TagsProvider'

export default function Tags() {
  // const { account } = useAuth()
  const { tags, setTags, isError, isLoading, rows, setRows } = useTags()
  // const [tags, setTags] = useState([])
  // const [isLoading, setIsLoading] = useState(false)
  // const [isError, setIsError] = useState(null)
  // const [rows, setRows] = useState(tags)
  const [searchText, setSearchText] = useState('')
  const [dialog, setDialog] = useState({
    open: false,
    action: 'add',
    data: {},
  })
  const [page, setPage] = useState(1)
  const handlePaginationChange = (event, newValue) => {
    setPage(newValue)
  }

  const handleOpen = (action, data) => {
    setDialog({
      action,
      data,
      open: true,
    })
  }
  const handleClose = () => {
    setDialog({
      ...dialog,
      open: false,
    })
  }

  const requestSearch = (searchValue) => {
    setSearchText(searchValue)
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
    const filteredRows = tags.filter((row) => searchRegex.test(row.name))
    setRows(filteredRows)
  }

  if (isError) {
    return <FullPageErrorFallback />
  }

  if (isLoading) {
    return <FullPageSpinner />
  }
  return (
    <>
      <WhiteBox
        title="Métadonnées"
        button={
          <Button
            variant="text"
            sx={{
              fontSize: '1.125rem',
              textTransform: 'none',
            }}
            endIcon={<AddIcon />}
            onClick={() => {
              handleOpen('add', {})
            }}
          >
            Ajouter une métadonnée
          </Button>
        }
        search={
          <QuickSearchToolbar
            value={searchText}
            onChange={(event) => requestSearch(event.target.value)}
            clearSearch={() => requestSearch('')}
          />
        }
        pagination={
          <Pagination
            count={5}
            page={page}
            onChange={handlePaginationChange}
            shape="rounded"
          />
        }
      >
        <Grid container spacing={2}>
          {rows.length > 0 ? (
            rows.map((item) => (
              <Grid key={item.id} item xs={12} sm={4} md={3}>
                <TagsCard item={item} handleOpen={handleOpen} />
              </Grid>
            ))
          ) : (
            <Box
              mt={2}
              sx={{
                width: '100%',
              }}
            >
              <Typography
                component="div"
                variant="body1"
                sx={{
                  textAlign: 'center',
                }}
              >
                Pas d'éléments!{' '}
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 'bold',
                    color: 'primary.main',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleOpen('add', {})
                  }}
                >
                  {' '}
                  insérez un
                </Typography>
              </Typography>
            </Box>
          )}
        </Grid>
      </WhiteBox>
      <Dialog onClose={handleClose} open={dialog.open} fullWidth>
        <TagsForm
          action={dialog.action}
          tagData={dialog.data}
          handleClose={handleClose}
          setTags={setTags}
          setRows={setRows}
        />
      </Dialog>
    </>
  )
}
