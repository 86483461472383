// import { useState, useEffect } from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { BrowserRouter } from 'react-router-dom'
import CreateCompanyApp from './CreateCompanyApp'
import UnAuthenticatedApp from './UnAuthenticatedApp'
import AuthenticatedApp from './AuthenticatedApp'
import theme from './utils/theme'
import AlertProvider from './modules/alert/AlertProvider'
import AuthProvider, { useAuth } from './modules/auth/AuthProvider'
// import FullPageSpinner from './components/FullPageSpinner'
import CacheProvider from './modules/cache/CacheProvider'

function AppSwitcher() {
  const { account, company } = useAuth()

  if (account?.is_verified && !company) {
    return <CreateCompanyApp />
  }

  if (account?.is_verified && company) {
    return <AuthenticatedApp />
  }

  return <UnAuthenticatedApp />
}
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <BrowserRouter>
          <CacheProvider>
            <AlertProvider>
              <AuthProvider>
                <AppSwitcher />
              </AuthProvider>
            </AlertProvider>
          </CacheProvider>
        </BrowserRouter>
      </CssBaseline>
    </ThemeProvider>
  )
}
export default App
