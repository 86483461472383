import { useState, useEffect } from 'react'
// import { Box } from '@mui/material'
import { useAuth } from '../../modules/auth/AuthProvider'
import { getCompanyRole } from '../../modules/company/api'
// import BasicInfo from './BasicInfo'
import BasicInfoEdit from './BasicInfoEdit'
import FullPageSpinner from '../../components/FullPageSpinner'

export default function PersonalInfo() {
  const { account } = useAuth()
  const [roles, setRoles] = useState([])
  const [profileRole, setProfileRole] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getCompanyRole()
      .then((compnayRoles) => {
        setRoles(compnayRoles.data)
        const userRole = compnayRoles.data.find(
          (role) => role.id === account?.jobTitle_id,
        )
        setProfileRole(userRole)
        setLoading(false)
        // setValue('jobTitle', userRole)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [account, account?.jobTitle_id])

  if (loading) {
    return <FullPageSpinner />
  }
  return <BasicInfoEdit roles={roles} profileRole={profileRole} />
}
