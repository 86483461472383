/* eslint-disable arrow-body-style */
import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { useAsync } from '@react-extra/hooks'
import PropTypes from 'prop-types'
import throttle from 'lodash/throttle'
import { Checkbox } from '@mui/material'
import { CheckBoxOutlineBlank } from '@mui/icons-material'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

//  Please refer to this stackoverflow thread and its related sandboxes in order to understand the MUI autocompletes logic (with default values!!) with react hook form : https://stackoverflow.com/questions/63149959/muis-autocomplete-as-multiple-input-react-hook-form-controlling-default-val
// TODO : Throttle

export default function AutocompleteField({
  request,
  label = '',
  value,
  setValue,
  // name,
  type = 'text',
  noFetch,
  autoCompleteOptions,
}) {
  const [open, setOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')
  const {
    run,
    isLoading: loading,
    setData,
    data: options,
  } = useAsync({ data: { data: value ? [{ ...value }] : [] } })

  const fetch = React.useMemo(
    () =>
      throttle(
        (inputV) => run(request({ name: inputV, keyword: inputV })),
        300,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [run, request],
  )
  React.useEffect(() => {
    if (!noFetch) {
      fetch(inputValue)
    }
  }, [fetch, inputValue, noFetch])

  React.useEffect(() => {
    if (autoCompleteOptions) {
      setData(autoCompleteOptions)
    }
  }, [autoCompleteOptions, setData])
  return (
    <Autocomplete
      id="asynchronous-demo"
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      isOptionEqualToValue={(data, val) => {
        if (data && val) {
          return data.id === val.id
        }
        return false
      }}
      getOptionLabel={(data) => data.name || data.answer || ''}
      options={options.data || options}
      filterOptions={(x) => x}
      autoComplete
      includeInputInList
      filterSelectedOptions
      multiple={type === 'checkbox'}
      value={type === 'checkbox' ? [...value] : value}
      onChange={(event, newValue) => {
        setValue(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      loading={loading}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          {type === 'checkbox' && (
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
          )}
          {option.name || option.answer}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

AutocompleteField.propTypes = {
  request: PropTypes.func.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.func,
  name: PropTypes.string,
  type: PropTypes.string,
  noFetch: PropTypes.bool,
  autoCompleteOptions: PropTypes.object,
}
