import api from '../../utils/api'

export async function getThematics() {
  const url = `recommendations/thematic`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getThematicsByUser(userId) {
  const url = `recommendations/thematicsByUser/${userId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getThematicsByUserByDimension(userId, dimensionId) {
  const url = `recommendations/thematicsByUserByDimension/${userId}/${dimensionId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}
export async function getThematicsByUserAndSciigoByDimension(
  userId,
  dimensionId,
) {
  const url = `recommendations/thematicsByUserAndSciigoByDimension/${userId}/${dimensionId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function postThematic(data) {
  const url = `recommendations/thematic`
  try {
    const result = await api(url, { data }).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function updateThematic(id, data) {
  try {
    const result = await api(`recommendations/thematic/${id}`, {
      data,
      method: 'PUT',
    })

    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}
