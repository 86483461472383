import { useState } from 'react'
import PropTypes from 'prop-types'
import { DataGrid, frFR, GridToolbar } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import QuickSearchToolbar from '../../../components/QuickSearchToolbar'
import { escapeRegExp } from '../../../utils/utilfc'

export default function ClientList({
  clients,
  rows,
  setRows,
  isLoading,
  columns,
}) {
  const [searchText, setSearchText] = useState('')
  const [pageSize, setPageSize] = useState(5)
  const requestSearch = (searchValue) => {
    setSearchText(searchValue)
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
    const filteredRows = clients.filter((row) =>
      Object.keys(row).some((field) => searchRegex.test(row[field].toString())),
    )
    setRows(filteredRows)
  }

  return (
    <Box sx={{ width: 1 }}>
      <DataGrid
        autoHeight
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        components={{ Toolbar: QuickSearchToolbar }}
        rows={rows || clients || []}
        columns={columns}
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(''),
            gridToolbar: <GridToolbar />,
          },
        }}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        disableSelectionOnClick
        loading={isLoading}
      />
    </Box>
  )
}
ClientList.propTypes = {
  clients: PropTypes.array,
  rows: PropTypes.array,
  setRows: PropTypes.func,
  isLoading: PropTypes.bool,
  columns: PropTypes.array,
}
