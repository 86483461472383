import { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import Chart from 'react-apexcharts'
import WhiteBox from '../../../components/WhiteBox'
import { optimalColumnWidthPercent } from '../../../utils/utilfc'
import FullPageSpinner from '../../../components/FullPageSpinner'
import { useAuth } from '../../auth/AuthProvider'
import { getMaturities } from '../api'

export default function Maturity() {
  const { account } = useAuth()
  const [loading, setLoading] = useState(false)
  const [donut, setDonut] = useState({
    options: {},
    series: [],
  })
  const [bar, setBar] = useState({
    options: {},
    series: [],
  })

  const constructDonut = (maturityMetas) => {
    const donutSeries = []
    const donutLables = []

    Object.keys(maturityMetas).forEach((index) => {
      donutLables.push(index)
      donutSeries.push(maturityMetas[index])
    })
    // for (let index in maturityMetas) {
    //   donutLables.push(index)
    //   donutSeries.push(maturityMetas[index])
    // }
    const donutChart = {
      options: {
        labels: donutLables,
        legend: {
          position: 'bottom',
        },
        colors: [
          'rgb(255, 61, 87)',
          'rgb(94, 92, 230)',
          'rgb(0, 129, 255)',
          'rgb(9, 182, 109)',
        ],
      },
      series: donutSeries,
    }
    setDonut({
      options: donutChart.options,
      series: donutChart.series,
    })
  }
  const constructBar = (maturityIndexs) => {
    const barSeries = []
    const barLabels = []

    Object.keys(maturityIndexs).forEach((index) => {
      barLabels.push(index)
      barSeries.push(maturityIndexs[index])
    })
    // for (let index in maturityIndexs) {
    //   barLabels.push(index)
    //   barSeries.push(maturityIndexs[index])
    // }

    const barChart = {
      series: [
        {
          name: 'Score',
          data: barSeries,
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: false,
            borderRadius: 8,
            columnWidth: `${optimalColumnWidthPercent(barSeries)}%`,
          },
        },
        legend: {
          show: false,
          position: 'bottom',
        },
        dataLabels: {
          enabled: true,
        },
        yaxis: {
          labels: {
            show: false,
            style: {
              fontSize: '12.80px',
              colors: ['rgba(0,18,27,0.50)'],
            },
          },
        },
        xaxis: {
          categories: barLabels,
          labels: {
            style: {
              fontSize: '12.80px',
              colors: barLabels.map(() => 'rgba(0,18,27,0.50)'),
            },
          },
          axisTicks: {
            show: false,
          },
        },
        fill: {
          // colors: ['#00ACE0'],
          colors: [
            'rgb(255, 61, 87)',
            'rgb(255, 61, 87)',
            'rgb(94, 92, 230)',
            'rgb(94, 92, 230)',
            'rgb(0, 129, 255)',
            'rgb(0, 129, 255)',
            'rgb(9, 182, 109)',
            'rgb(9, 182, 109)',
          ],
        },
      },
    }
    setBar({
      options: barChart.options,
      series: barChart.series,
    })
  }

  useEffect(() => {
    setLoading(true)
    getMaturities(account.id)
      .then((res) => {
        constructBar(res.maturity_indexes)
        constructDonut(res.maturity_metas)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [account.id])

  if (loading) {
    return <FullPageSpinner />
  }

  return (
    <WhiteBox title="Répartition des clients sur le méta indexe de maturité & indexe de maturité détaillé">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Chart
            options={bar.options}
            series={bar.series}
            type="bar"
            height="304px"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Chart
            options={donut.options}
            series={donut.series}
            type="donut"
            width="100%"
            height="304px"
          />
        </Grid>
      </Grid>
    </WhiteBox>
  )
}
