import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  TextField,
  CircularProgress,
  Grid,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Avatar,
  Typography,
  Autocomplete,
} from '@mui/material'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useForm, Controller } from 'react-hook-form'
import { useAuth } from '../../modules/auth/AuthProvider'
import { useAlert } from '../../modules/alert/AlertProvider'
import WhiteBox from '../../components/WhiteBox'

function BasicInfoEdit({ roles, profileRole }) {
  const { showAlert } = useAlert()
  const { account, patchMe, setAccount } = useAuth()
  const [loading] = useState(false)
  const [fullNameFistLetter, setFullNameFirstLetter] = useState(
    `${account.firstName[0]}${account.lastName[0]}`,
  )
  const [fullName, setFullName] = useState(
    `${account.firstName} ${account.lastName}`,
  )
  const [phoneNumber, setPhoneNumber] = useState(account.phoneNumber)

  const [role, setRole] = useState(
    (profileRole && profileRole?.name) ||
      (account.profileRole && account.profileRole.name) ||
      '',
  )

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: account?.firstName,
      lastName: account?.lastName,
      phone: account?.phoneNumber,
      jobTitle: profileRole || null,
    },
  })

  useEffect(() => {
    setValue('jobTitle', profileRole)
  }, [profileRole, setValue])

  const onSubmit = (data) => {
    const updatedData = {
      ...account,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phone,
      jobTitle_id: data.jobTitle?.id,
    }

    patchMe(updatedData)
      .then(() => {
        showAlert(
          'Votre informations personnelles sont mises à jours avec succès',
          'success',
        )
        setFullNameFirstLetter(`${data.lastName[0]} ${data.firstName[0]}`)
        setFullName(`${data.lastName} ${data.firstName}`)
        setPhoneNumber(data.phone)
        setRole(data.jobTitle?.name || '')
        setAccount(updatedData)
      })
      .catch(() => {
        showAlert('une Erreur est survenue', 'error')
      })
  }

  return (
    <>
      <WhiteBox whithPadding={false}>
        <Box
          sx={{
            height: '128px',
            background:
              'linear-gradient(rgba(0, 31, 41, 0.96), rgba(0, 62, 74, 0.96))',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          <Avatar
            alt="profile icon"
            sx={{
              position: 'absolute',
              top: -50,
              width: '5.96rem',
              height: '5.96rem',
              color: 'white.white100',
              backgroundColor: 'mysensPalette.lightBlue',
              fontSize: '1.25rem',
              fontWeight: '600',
            }}
          >
            {fullNameFistLetter}
          </Avatar>
        </Box>
        <Box
          mt={7}
          px={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: 4,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: 'black.black75',
            }}
          >
            {fullName}
          </Typography>

          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={4}>
              <Typography
                component="div"
                variant="body1"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <EmailOutlinedIcon
                  sx={{
                    mr: 1,
                    fontSize: '1.25rem',
                    color: 'mysensPalette.orange',
                  }}
                />
                {account.email}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} justifyContent="center">
              <Typography
                component="div"
                variant="body1"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <LocalPhoneOutlinedIcon
                  sx={{
                    mr: 1,
                    fontSize: '1.25rem',
                    color: 'mysensPalette.orange',
                  }}
                />
                {phoneNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                component="div"
                variant="body1"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <WorkOutlineOutlinedIcon
                  sx={{
                    mr: 1,
                    fontSize: '1.25rem',
                    color: 'mysensPalette.orange',
                  }}
                />
                {role}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </WhiteBox>
      <Box mt={2}>
        <WhiteBox title="Informations Basiques">
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('firstName', {
                    required: 'Veuillez insérer votre prénom',
                  })}
                  fullWidth
                  label="Nom"
                  name="firstName"
                  autoComplete="current-firstName"
                  helperText={errors.firstName?.message}
                  error={!!errors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('lastName', {
                    required: 'Veuillez insérer votre prénom',
                  })}
                  fullWidth
                  label="Prénom"
                  name="lastName"
                  autoComplete="current-lastName"
                  helperText={errors.lastName?.message}
                  error={!!errors.lastName}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box sx={{ flex: 1, mt: 2 }}>
                  <Controller
                    control={control}
                    name="jobTitle"
                    rules={{
                      required: 'Veuillez choisir une réponse',
                    }}
                    render={({ field: { onChange } }) => (
                      <Autocomplete
                        defaultValue={profileRole || null}
                        options={roles}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, values) => {
                          onChange(values || null)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Titre d'emploi"
                            helperText={errors.jobTitle?.message}
                            error={!!errors.jobTitle}
                          />
                        )}
                      />
                    )}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box sx={{ flex: 1, mt: 2 }}>
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: 'Veuillez entrer votre téléphone',
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl fullWidth>
                        <PhoneInput
                          country="ca"
                          containerStyle={{
                            border: 'none',
                          }}
                          inputStyle={{
                            width: '100%',
                            height: '56px',
                          }}
                          id="phone"
                          value={value}
                          onChange={onChange}
                          defaultCountry="CA"
                          label="Phone"
                        />
                      </FormControl>
                    )}
                  />
                  <FormHelperText
                    sx={{
                      pl: 2,
                    }}
                    error={Boolean(errors.phone)}
                  >
                    {errors.phone?.message}
                  </FormHelperText>
                </Box>
              </Grid>
            </Grid>

            <Button type="submit" variant="mysensContained" sx={{ mt: 3 }}>
              {loading && (
                <CircularProgress
                  color="secondary"
                  size={20}
                  sx={{ marginRight: '1rem' }}
                />
              )}
              Enregistrer
            </Button>
          </Box>
        </WhiteBox>
      </Box>
    </>
  )
}

BasicInfoEdit.propTypes = {
  roles: PropTypes.array,
  profileRole: PropTypes.object,
}

export default BasicInfoEdit
