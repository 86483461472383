import PropTypes from 'prop-types'
import { Autocomplete, TextField, Grid, IconButton } from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'
import { useForm, Controller } from 'react-hook-form'
import {
  FaisabilityMapper,
  FaisabilityMapperInverse,
} from '../../../utils/mappers'

export default function UseCasesFilter({
  industries,
  specializations,
  useCases,
  rows,
  setRows,
  setFilter,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  })

  const onSubmit = (data) => {
    setFilter({
      specialization: data.specialization,
      industry: data.industry,
      faisability: data.faisability,
    })

    if (data.specialization || data.faisability || data.industry) {
      const filteredRows = useCases
        .filter((row) => {
          if (data.industry) {
            return JSON.parse(row.industries).find(
              (indust) => indust.id === data.industry.id,
            )
          }
          return row
        })
        .filter((row) => {
          if (data.specialization) {
            return JSON.parse(row.specializations).find(
              (spec) => spec.id === data.specialization.id,
            )
          }
          return row
        })
        .filter((row) => {
          if (data.faisability) {
            return (
              row.faisabilite === FaisabilityMapperInverse[data.faisability]
            )
          }
          return row
        })

      setRows(filteredRows)
    } else if (rows.length < useCases.length) {
      setRows(useCases)
    }
  }
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Grid item xs={12} sm={11}>
        <Grid container spacing={2} alignItems="center">
          {/* Industry  */}
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              control={control}
              name="industry"
              render={({ field: { onChange } }) => (
                <Autocomplete
                  options={industries}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, values) => {
                    onChange(values || null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Type d'industrie"
                      helperText={errors.industry?.message}
                      error={Boolean(errors.industry)}
                    />
                  )}
                />
              )}
            />
          </Grid>
          {/* /Industry */}
          {/* Specialization  */}
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              control={control}
              name="specialization"
              render={({ field: { onChange } }) => (
                <Autocomplete
                  options={specializations}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, values) => {
                    onChange(values || null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Spésialisation"
                      helperText={errors.specialization?.message}
                      error={Boolean(errors.specialization)}
                    />
                  )}
                />
              )}
            />
          </Grid>
          {/* /Specialization */}
          {/* Faisability */}
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              control={control}
              name="faisability"
              render={({ field: { onChange } }) => (
                <Autocomplete
                  options={[1, 2, 3].map((item) => FaisabilityMapper[item])}
                  getOptionLabel={(option) => option}
                  onChange={(event, values) => {
                    onChange(values)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Faisabilité"
                      placeholder="Faisabilité"
                      helperText={errors.faisability?.message}
                      error={!!errors.faisability}
                    />
                  )}
                />
              )}
            />
          </Grid>
          {/* /Faisability */}
        </Grid>
      </Grid>

      {/* FilterButton */}
      <Grid item xs={12} sm={1}>
        <IconButton type="submit">
          <TuneIcon />
        </IconButton>
      </Grid>

      {/* /FilterButton */}
    </Grid>
  )
}

UseCasesFilter.propTypes = {
  rows: PropTypes.array,
  useCases: PropTypes.array,
  setRows: PropTypes.func,
  industries: PropTypes.array,
  specializations: PropTypes.array,
  setFilter: PropTypes.func,
}
