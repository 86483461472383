import { useState, useEffect, useRef } from 'react'
import {
  IconButton,
  Dialog,
  DialogTitle,
  Typography,
  Grid,
} from '@mui/material'
import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import FullPageSpinner from '../../components/FullPageSpinner'
import FullPageErrorFallback from '../../components/FullPageErrorFallback'
import QuestionForm from '../../modules/adminQuestion/components/QuestionForm'
import EvaluationLayout from '../../modules/evaluation/components/evaluationDetail/EvaluationLayout'
import EvaluationQuestions from '../../modules/evaluation/components/evaluationDetail/EvaluationQuestions'
import { useDimension } from '../../modules/dimension/DimensionProvider'
import { useAuth } from '../../modules/auth/AuthProvider'
import { getQuestionsBySiigoByUserByDimension } from '../../modules/adminQuestion/api'
import { getThematicsByUserAndSciigoByDimension } from '../../modules/thematics/api'
import { getEvaluationDimensions } from '../../modules/evaluation/api'

export default function EvaluationDetail({
  handleCloseDetail,
  currentEvaluation,
  setEvaluations,
  setEvaluationsRows,
  setCurrentEvaluation,
}) {
  const { account } = useAuth()
  const [value, setValue] = useState('Stratégies')
  const [thematics, setThematics] = useState([])
  const [evaluationDimensions, setEvaluationDimensions] = useState({})
  const [evaluationDimensionsLoading, setEvaluationDimensionsLoading] =
    useState(true)
  const { dimensions, dimensionsLoding, dimensionsError } = useDimension()
  const [questions, setQuestions] = useState([])
  const [dimensionId, setDimensionId] = useState()
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(null)

  const [selectedQuestions, setSelectedQuestions] = useState(0)

  const handleSelectedQuestions = (checked) => {
    if (checked) {
      setSelectedQuestions((prev) => prev + 1)
    } else {
      setSelectedQuestions((prev) => prev - 1)
    }
  }

  const filter = useRef({
    weight: 0,
    thematic: null,
    industry: null,
  })

  const setFilter = (filterVal) => {
    filter.current = filterVal
  }

  const [dialog, setDialog] = useState({
    open: false,
    action: 'add',
    data: {},
  })

  const handleOpen = (action, data) => {
    setDialog({
      action,
      data,
      open: true,
    })
  }
  const handleClose = () => {
    setDialog({
      ...dialog,
      open: false,
    })
  }

  const handlePaginationChange = (event, newValue) => {
    setPage(newValue)
  }

  useEffect(() => {
    if (currentEvaluation.id) {
      getEvaluationDimensions(currentEvaluation.id)
        .then((res) => {
          if (res) {
            const dimensionObj = res.reduce(
              (acc, currDimension) => ({
                ...acc,
                [currDimension.dimension_id]: {
                  ...currDimension,
                  questions: JSON.parse(currDimension.questions),
                },
              }),
              {},
            )
            setEvaluationDimensions(dimensionObj)
          }
          setEvaluationDimensionsLoading(false)
        })
        .catch(() => {
          setEvaluationDimensionsLoading(false)
        })
    }
  }, [currentEvaluation.id])

  useEffect(() => {
    if (dimensionId) {
      setIsLoading(true)
      getQuestionsBySiigoByUserByDimension(account.id, dimensionId)
        .then((res) => {
          setQuestions(res.data)
          setRows(res.data)
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
          setIsError(true)
        })
    }
  }, [account.id, dimensionId])

  useEffect(() => {
    if (dimensionId && evaluationDimensions) {
      setSelectedQuestions(
        evaluationDimensions[dimensionId]?.questions?.length || 0,
      )
    }
  }, [dimensionId, evaluationDimensions])

  useEffect(() => {
    if (dimensions && dimensions.length > 0) {
      const currentDimension = dimensions.find(
        (dimension) => dimension.name === value,
      )
      setDimensionId(currentDimension.id)
    }
  }, [dimensions, value])

  useEffect(() => {
    if (dimensionId) {
      getThematicsByUserAndSciigoByDimension(account.id, dimensionId).then(
        (res) => {
          setThematics(res.data)
        },
      )
    }
  }, [account.id, dimensionId])

  if (dimensionsError) {
    return <FullPageErrorFallback />
  }

  if (dimensionsLoding) {
    return <FullPageSpinner />
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <EvaluationLayout
            value={value}
            setValue={setValue}
            currentEvaluation={currentEvaluation}
            evaluationDimensions={evaluationDimensions}
            setEvaluations={setEvaluations}
            setEvaluationsRows={setEvaluationsRows}
            setCurrentEvaluation={setCurrentEvaluation}
            setEvaluationDimensions={setEvaluationDimensions}
            loading={evaluationDimensionsLoading}
          />
        </Grid>
        <Grid item xs={9}>
          <EvaluationQuestions
            thematics={thematics}
            questions={questions}
            rows={rows}
            setRows={setRows}
            dimensionId={dimensionId}
            currentEvaluation={currentEvaluation}
            page={page}
            handlePaginationChange={handlePaginationChange}
            handleOpen={handleOpen}
            isError={isError}
            isLoading={isLoading}
            setFilter={setFilter}
            evaluationDimensions={evaluationDimensions}
            setEvaluationDimensions={setEvaluationDimensions}
            selectedQuestions={selectedQuestions}
            handleSelectedQuestions={handleSelectedQuestions}
            handleCloseDetail={handleCloseDetail}
          />
        </Grid>
      </Grid>

      <Dialog open={dialog.open} fullWidth maxWidth="md">
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            component="p"
            variant="h5"
            textAlign="center"
            gutterBottom
            sx={{
              pl: 2,
            }}
          >
            {dialog.action === 'add'
              ? 'Ajouter une question'
              : 'Editer la question'}
          </Typography>

          <IconButton onClick={handleClose} sx={{ ml: 'auto' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <QuestionForm
          filter={filter.current}
          thematics={thematics}
          dimensionId={dimensionId}
          action={dialog.action}
          questionData={dialog.data}
          handleClose={handleClose}
          setQuestions={setQuestions}
          setRows={setRows}
        />
      </Dialog>
    </>
  )
}

EvaluationDetail.propTypes = {
  handleCloseDetail: PropTypes.func,
  currentEvaluation: PropTypes.object,
  setEvaluations: PropTypes.func,
  setEvaluationsRows: PropTypes.func,
  setCurrentEvaluation: PropTypes.func,
}
