import api from '../../utils/api'

export async function postDimensionScoreHistory(data) {
  const url = `dimensions/DimensionScoreHistory`
  try {
    const result = await api(url, { data }).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result.data
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}
