import { Typography, styled, experimental_sx as sx } from '@mui/material'

export const StrongText = styled(Typography)(({ theme, color }) => ({
  color: color || theme.palette.mysensPalette.lightBlue,
  fontWeight: '600',
}))

export const IndentTypography = styled(Typography)(
  sx({
    textIndent: 24,
  }),
)
