import { useState, useEffect } from 'react'
import { Box, Button, Dialog, IconButton, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AddIcon from '@mui/icons-material/Add'
import FullPageSpinner from '../../components/FullPageSpinner'
import FullPageErrorFallback from '../../components/FullPageErrorFallback'
import CreateClient from '../../modules/clients/components/CreateClient'
import ClientDetail from '../../modules/clients/components/ClientDetail'
import WhiteBox from '../../components/WhiteBox'
import { useAuth } from '../../modules/auth/AuthProvider'
import { getClientsByPowerUser } from '../../modules/clients/api'
import ClientList from '../../modules/clients/components/ClientList'
import ClientPrivileges from '../../modules/clients/components/ClientPrivileges'

export default function Clients() {
  const [clients, setClients] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(null)
  const [rows, setRows] = useState(clients)
  const { account } = useAuth()

  const [dialog, setDialog] = useState(false)
  const [privilegesDialog, setPrivilegesDialog] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const [clientDetail, setClientDetail] = useState({})

  const handleOpen = () => {
    setDialog(true)
  }
  const handleClose = () => {
    setDialog(false)
  }

  const handleOpenPrivilegesDialog = () => {
    setPrivilegesDialog(true)
  }
  const handleClosePrivilegesDialog = () => {
    setPrivilegesDialog(false)
  }

  const handleClickDetail = (detail) => {
    setShowDetail(true)
    setClientDetail(detail)
  }

  const handleBackDetail = () => {
    setShowDetail(false)
    setClientDetail({})
  }

  const handleEditClick = (detail) => {
    setClientDetail(detail)
    handleOpenPrivilegesDialog()
  }
  const constructColumns = (columns) => {
    const newColumns = []
    const filteredColumns = columns.filter(
      (item) => !item.toLowerCase().match(/.*id$|company$/gi),
    )
    filteredColumns.forEach((column) => {
      if (column !== 'Status') {
        newColumns.push({
          field: column,
          headerName: column,
          flex: 1,
        })
      }
    })
    newColumns.push({
      field: 'Entreprise',
      headerName: 'Entreprise',
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontWeight: 450,
            }}
          >
            {params.row.Entreprise ? params.row.Entreprise : '/'}
          </Typography>
        </Box>
      ),
    })
    newColumns.push({
      field: 'Stuatus',
      headerName: 'Status',
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontWeight: 450,
              color:
                params.row.Status === 'En attente'
                  ? 'rgba(254, 176, 26, 1)'
                  : 'rgba(9, 182, 109, 1)',
            }}
          >
            {params.row.Status}
          </Typography>
        </Box>
      ),
    })

    newColumns.push({
      field: 'Action',
      headerName: 'Action',
      sortable: false,
      // flex: 1,
      width: 100,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <IconButton
            onClick={() => {
              handleClickDetail(params.row)
            }}
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              handleEditClick(params.row)
            }}
          >
            <EditIcon />
          </IconButton>
        </Box>
      ),
    })
    return newColumns
  }

  useEffect(() => {
    setIsLoading(true)
    getClientsByPowerUser(account.id)
      .then((res) => {
        setClients(res)
        setRows(res)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
        setIsError(true)
      })
  }, [account.id])

  if (isError) {
    return <FullPageErrorFallback />
  }

  if (isLoading) {
    return <FullPageSpinner />
  }

  return (
    <>
      {!showDetail && (
        <>
          <WhiteBox
            title="Liste du clients"
            button={
              <Button
                variant="text"
                sx={{
                  fontSize: '1.125rem',
                  textTransform: 'none',
                }}
                endIcon={<AddIcon />}
                onClick={handleOpen}
              >
                Ajouter un client
              </Button>
            }
          >
            <Box sx={{ width: 1 }}>
              <ClientList
                clients={clients}
                rows={rows}
                setRows={setRows}
                isLoading={isLoading}
                isError={isError}
                columns={
                  clients.length > 0
                    ? constructColumns(Object.keys(clients[0]))
                    : []
                }
              />
            </Box>
          </WhiteBox>
          <Dialog open={dialog} fullWidth onClose={handleClose}>
            <CreateClient
              handleClose={handleClose}
              setClients={setClients}
              setRows={setRows}
            />
          </Dialog>
        </>
      )}
      {showDetail && (
        <ClientDetail
          handleBackDetail={handleBackDetail}
          clientDetail={clientDetail}
        />
      )}

      <Dialog
        open={privilegesDialog}
        fullWidth
        onClose={handleClosePrivilegesDialog}
      >
        <ClientPrivileges
          handleClose={handleClosePrivilegesDialog}
          setClients={setClients}
          setRows={setRows}
          clientDetail={clientDetail}
        />
      </Dialog>
    </>
  )
}
