import PropTypes from 'prop-types'
import { Box } from '@mui/material'

import BasicInfo from '../../../screens/user/BasicInfo'
import CompanyInfo from './CompanyInfo'

export default function ProfileClient({ account }) {
  return (
    <>
      <BasicInfo account={account} />
      <Box mt={2}>
        <CompanyInfo account={account} />
      </Box>
    </>
  )
}

ProfileClient.propTypes = {
  account: PropTypes.object,
}
