import PropTypes from 'prop-types'
import { Typography, TextField, Box } from '@mui/material'
import { useForm } from 'react-hook-form'

export default function ForgotPassword({ email, children, submit }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()

  return (
    <Box>
      <Typography
        component="h1"
        variant="h5"
        sx={{ mb: 3, color: 'black.black75' }}
      >
        Mot de passe oublié ? Entrez votre Email
      </Typography>

      <Box
        component="form"
        // onSubmit={handleSubmit(onSubmit)}
        onSubmit={handleSubmit((data) => submit(data))}
        noValidate
        sx={{
          textAlign: 'center',
        }}
      >
        <TextField
          defaultValue={email}
          {...register('username', {
            required: {
              value: true,
              message: 'Veuillez entrer votre adresse email',
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Addresse email invalide',
            },
          })}
          fullWidth
          id="username"
          label="Adresse email"
          name="username"
          autoComplete="username"
          autoFocus
          helperText={errors.username?.message}
          error={!!errors.username}
        />

        <Box>{children}</Box>
      </Box>
    </Box>
  )
}

ForgotPassword.propTypes = {
  email: PropTypes.string,
  children: PropTypes.node,
  submit: PropTypes.func,
}
