import {
  memo,
  useMemo,
  useContext,
  createContext,
  useState,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'

const CacheContext = createContext()
CacheContext.displayName = 'CacheContext'

const CacheProvider = memo(({ children }) => {
  const [industryType, setIndustryType] = useState([])

  const saveIndustryType = useCallback((value) => {
    setIndustryType(value)
  }, [])

  const value = useMemo(
    () => ({
      industryType,
      saveIndustryType,
    }),
    [industryType, saveIndustryType],
  )

  return <CacheContext.Provider value={value}>{children}</CacheContext.Provider>
})
export default CacheProvider
export function useCache() {
  const context = useContext(CacheContext)
  if (context === undefined) {
    throw new Error('useCache must be used within a CacheProvider')
  }
  return context
}

CacheProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
