import RegisterCompanyStepper from '../../modules/company/componenets/RegisterCompanyStepper'
import CompanyProvider from '../../modules/company/companyProvider'

export default function CreateCompany() {
  return (
    <CompanyProvider>
      <RegisterCompanyStepper />
    </CompanyProvider>
  )
}
