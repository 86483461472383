import * as React from 'react'
import qs from 'querystring'
import PropTypes from 'prop-types'
// import { useLocalStorageState } from '@react-extra/hooks'
import { useNavigate } from 'react-router-dom'
import api, { TOKENS_STORAGE_KEY, setLocalStorageItem } from '../../utils/api'
import { useLocalStorageState } from '../../utils/useHooks'

import { useAlert } from '../alert/AlertProvider'
import { getCompanyByUser } from '../company/api'

const AuthContext = React.createContext()
AuthContext.displayName = 'AuthContext'

export const roles = {
  Admin: { value: 'admin', label: 'Super admin' },
  PowerUser: { value: 'poweruser', label: 'Power User' },
  Client: { value: 'client', label: 'client' },
}

const AuthProvider = React.memo(({ children }) => {
  const [account, setAccount] = useLocalStorageState(null)
  const [company, setCompany] = useLocalStorageState(null)
  const navigate = useNavigate()
  const { showAlert } = useAlert()
  const [tokens, setToken] = useLocalStorageState(TOKENS_STORAGE_KEY, null)

  const setCompanyData = React.useCallback(
    (companyRes) => {
      setCompany(companyRes)
    },
    [setCompany],
  )

  const getAccountData = React.useCallback(
    async (token) => {
      if (!token) {
        return
      }
      try {
        const data = await api('users/me')
        // eslint-disable-next-line consistent-return
        return data
      } catch (e) {
        showAlert('Une erreur est survenue', 'error')
      }
    },
    [showAlert],
  )

  const login = React.useCallback(
    async ({ username, password }) => {
      const data = {
        grant_type: '',
        username,
        password,
        scope: '',
        client_id: '',
        client_secret: '',
      }

      setToken(null)
      setLocalStorageItem(TOKENS_STORAGE_KEY, null)

      const token = await api('auth/jwt/login', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: qs.stringify(data),
        isPrivate: false,
      })
      setToken(token)
      setLocalStorageItem(TOKENS_STORAGE_KEY, token)

      const accountData = await getAccountData(token)
      setAccount(accountData)

      if (accountData.is_verified) {
        getCompanyByUser(accountData.id)
          .then((companyRes) => {
            setCompany(companyRes.data)
          })
          .catch((error) => {
            if (error.message === 'company not found') {
              setCompany(null)
            } else {
              showAlert('Une erreur est survenue', 'error')
            }
          })
      }

      return accountData
    },
    [getAccountData, setAccount, setCompany, setToken, showAlert],
  )

  const logout = React.useCallback(() => {
    setToken(null)
    window.localStorage.clear()
    window.sessionStorage.clear()
    setAccount(null)
    setCompany(null)
    navigate('/')
  }, [navigate, setAccount, setCompany, setToken])

  const resentCode = React.useCallback(async (email) => {
    await api(`auth/confirmUser/${email}`, {
      method: 'POST',
      isPrivate: false,
    })
  }, [])

  const verifyUser = React.useCallback(async ({ email, code }) => {
    const data = await api(
      `auth/confirmEmailCode?email=${email}&code=${code}`,
      {
        method: 'POST',
        isPrivate: false,
      },
    )
    return data
  }, [])

  const registerUser = React.useCallback(
    async ({ firstName, lastName, email, password, phone, jobTitle, role }) => {
      const data = {
        email,
        password,
        firstName,
        lastName,
        phoneNumber: phone,
        jobTitle_id: jobTitle.id,
        role: role === 'Entreprise' ? 'client' : 'poweruser',
      }
      await api('auth/register', {
        method: 'POST',
        data,
        isPrivate: false,
      })

      showAlert('Votre compte a été créé avec succès', 'success')
    },
    [showAlert],
  )

  const forgotPasswrod = React.useCallback(
    async (email) => {
      try {
        await api('auth/forgot-password', {
          data: { email },
        })
        showAlert(
          "L'email de réinitialisation du mot de passe vous sera envoyé",
          'success',
        )
      } catch (e) {
        showAlert('Une erreur est survenue', 'error')
      }
    },
    [showAlert],
  )

  const resetPassword = React.useCallback(
    async ({ token, password }) => {
      try {
        await api('auth/reset-password', {
          data: { token, password },
        })
        showAlert(
          'Le mail de confirmation de réinitialisation du mot de passe vous sera envoyé',
          'success',
        )
      } catch (e) {
        showAlert('Une erreur est survenue', 'error')
      }
    },
    [showAlert],
  )

  const patchMe = React.useCallback(
    async (data, isChagingPassword = false) => {
      try {
        await api('users/me', {
          method: 'PATCH',
          data,
        })
        if (!isChagingPassword) {
          showAlert('Le profil a été mis à jour avec succès', 'success')
        }
      } catch (e) {
        showAlert(
          'Une erreur est survenu veuiller réassayer plus tard',
          'error',
        )
      }
    },
    [showAlert],
  )

  const value = React.useMemo(
    () => ({
      login,
      logout,
      tokens,
      forgotPasswrod,
      getAccountData,
      resetPassword,
      registerUser,
      resentCode,
      verifyUser,
      account,
      setAccount,
      patchMe,
      company,
      setCompanyData,
    }),
    [
      login,
      logout,
      tokens,
      forgotPasswrod,
      getAccountData,
      resetPassword,
      registerUser,
      resentCode,
      verifyUser,
      account,
      setAccount,
      patchMe,
      company,
      setCompanyData,
    ],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
})
export default AuthProvider
export function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
