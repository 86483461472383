import { Grid, Box, Card, CardContent, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  statusAugmentationColor,
  statusAugmentationColorOpacity,
} from '../../../utils/utilfc'

export default function DimensionPercentBoxes({ dimensionsScore }) {
  const newScorePercent = (beforeLastScore, lastScore) => {
    if (beforeLastScore === 0) {
      return lastScore
    }
    return parseFloat(
      (((lastScore - beforeLastScore) * 100) / beforeLastScore).toFixed(2),
    )
  }

  return (
    <Grid container spacing={2}>
      {dimensionsScore.map((dimension) => (
        <Grid key={dimension.id} item xs={12} sm={6} md={4} lg={3}>
          <Card
            sx={{
              transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              boxShadow:
                'rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px',
              borderRadius: '8px',
            }}
          >
            <CardContent
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  color: 'black.black50',
                }}
              >
                {dimension.dimension.name}
              </Typography>

              <Typography
                mt={1}
                variant="h1"
                sx={{
                  color: 'black.black75',
                }}
              >
                {parseFloat((dimension.last_score * 100).toFixed(2))}%
              </Typography>

              <Box
                sx={{
                  mt: 3,
                  py: '0.308rem',
                  px: '0.616rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 32,
                  width: 100,
                  backgroundColor: statusAugmentationColorOpacity(
                    parseFloat(
                      newScorePercent(
                        dimension.before_last_score,
                        dimension.last_score,
                      ).toFixed(1),
                    ),
                  ),
                  color: statusAugmentationColor(
                    parseFloat(
                      newScorePercent(
                        dimension.before_last_score,
                        dimension.last_score,
                      ).toFixed(1),
                    ),
                  ),
                  '&:hover': {
                    backgroundColor: statusAugmentationColor(
                      parseFloat(
                        newScorePercent(
                          dimension.before_last_score,
                          dimension.last_score,
                        ).toFixed(1),
                      ),
                    ),
                    color: 'white.white100',
                  },
                }}
              >
                {dimension.last_score - dimension.before_last_score < 0 ? (
                  <ExpandMoreIcon
                    sx={{
                      mr: '0.889',
                      width: '1.266rem',
                      fontWeight: '500',
                      color: 'inherit',
                    }}
                  />
                ) : (
                  <ExpandLessIcon
                    sx={{
                      mr: '0.889',
                      width: '1.266rem',
                      fontWeight: '500',
                      color: 'inherit',
                    }}
                  />
                )}
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  sx={{
                    fontWeight: '500',
                    color: 'inherit',
                  }}
                >
                  {parseFloat(
                    newScorePercent(
                      dimension.before_last_score,
                      dimension.last_score,
                    ).toFixed(1),
                  )}
                  %
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

DimensionPercentBoxes.propTypes = {
  dimensionsScore: PropTypes.array.isRequired,
}
