import PropTypes from 'prop-types'
import { Box, IconButton, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import FullPageSpinner from '../../../components/FullPageSpinner'
import FullPageErrorFallback from '../../../components/FullPageErrorFallback'
import WhiteBox from '../../../components/WhiteBox'

export default function ThematicTagsCard({
  item,
  handleOpen,
  isError,
  isLoading,
}) {
  if (isError) {
    return <FullPageErrorFallback />
  }
  if (isLoading) {
    return <FullPageSpinner />
  }
  return (
    <WhiteBox>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 100,
        }}
      >
        <IconButton
          onClick={() => {
            handleOpen('edit', item)
          }}
        >
          <EditIcon />
        </IconButton>
        <Typography
          variant="body1"
          color="black.black75"
          gutterBottom
          sx={{
            textAlign: 'center',
          }}
        >
          {item.name}
        </Typography>
      </Box>
    </WhiteBox>
  )
}

ThematicTagsCard.propTypes = {
  item: PropTypes.object,
  handleOpen: PropTypes.func,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
}
