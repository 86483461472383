import { useNavigate } from 'react-router-dom'
import { Box, ButtonBase, Typography, Grid, Alert, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useAssessment } from '../../modules/assessment/AssessmentProvider'
import { useDimension } from '../../modules/dimension/DimensionProvider'
import { isAlreadyAnswered } from '../../utils/utilfc'
import { dimensionLink } from '../../utils/mappers'
import { dimensionsIcons } from '../../utils/icons'
import FullPageSpinner from '../../components/FullPageSpinner'
import FullPageErrorFallback from '../../components/FullPageErrorFallback'

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.95,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}))

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}))

const ImageBackdrop = styled('span')(({ theme, alreadyAnswered }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: alreadyAnswered
    ? theme.palette.mysensPalette.lightBlue
    : theme.palette.black.black75,

  transition: theme.transitions.create('opacity'),
}))

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity'),
}))

export default function Dimensions() {
  const navigate = useNavigate()
  const {
    dimensionsScore,

    dimensionScoreLoading,
    dimensionScoreError,
  } = useDimension()

  const {
    assessmentDetail,
    assessmentDetailLoading,
    assessmentDetailError,
    setQuestions,
  } = useAssessment()

  if (dimensionScoreError || assessmentDetailError) {
    return <FullPageErrorFallback />
  }

  if (assessmentDetailLoading || dimensionScoreLoading) {
    return <FullPageSpinner />
  }

  return (
    <Box>
      <Box>
        <Typography
          componeent="h1"
          variant="h5"
          sx={{ textAlign: 'center', color: 'black.black75' }}
        >
          Cadre de maturité d&apos;intelligence numérique
        </Typography>
      </Box>
      <Grid container spacing={2} mt={2} justifyContent="center">
        {assessmentDetail.length ? (
          assessmentDetail.map((dimension) => (
            <Grid item xs={6} md={4} key={dimension.dimensionId}>
              <ImageButton
                focusRipple
                style={{
                  width: '100%',
                }}
                onClick={() => {
                  // handleShowQuestions(dimension.questions)
                  setQuestions(dimension.questions)
                  navigate(
                    `/dimensions/${dimensionLink[dimension.dimensionName]}`,
                  )
                }}
              >
                <ImageBackdrop
                  className="MuiImageBackdrop-root"
                  alreadyAnswered={isAlreadyAnswered(
                    {
                      dimensionId: dimension.dimensionId,
                      dimensionName: dimension.dimensionName,
                    },
                    dimensionsScore,
                  )}
                />
                <Image>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '2rem',
                    }}
                  >
                    {dimensionsIcons[dimension.dimensionName]}
                    <Typography
                      mt={1}
                      component="div"
                      // component="span"
                      variant="body1"
                      color="inherit"
                      sx={{
                        position: 'relative',
                        p: 4,
                        pt: 2,
                        pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                      }}
                    >
                      {dimension.dimensionName}
                      <ImageMarked className="MuiImageMarked-root" />
                    </Typography>
                  </Box>
                </Image>
              </ImageButton>
            </Grid>
          ))
        ) : (
          <Stack>
            <Alert severity="info" variant="filled">
              L&apos;évaluation arrive bientôt
            </Alert>
          </Stack>
        )}
      </Grid>
    </Box>
  )
}
