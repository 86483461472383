import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Typography, Button } from '@mui/material'
import QuestionForm from '../../../modules/QuestionAnswer/components/QuestionForm'
import { StrongText } from '../../../utils/customElements'
import { useAssessment } from '../../../modules/assessment/AssessmentProvider'

function Description({ handleOpenForm }) {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        px: {
          xs: 2,
          md: 4,
        },
      }}
    >
      <Typography
        component="h1"
        variant="h4"
        sx={{
          textAlign: 'center',
          color: 'black.black75',
        }}
      >
        Dimension Organisation & Ecosystème
      </Typography>

      <Box
        mt={3}
        sx={{
          color: 'black.black75',
        }}
      >
        <Box>
          <Typography component="div" variant="body1">
            La dimension
            <StrongText component="span">
              {' '}
              Organisation & Ecosystème{' '}
            </StrongText>
            concerne le niveau de collaboration, de communication et d'impact
            qu'une organisation atteint avec ses parties prenantes internes et
            externes, ses partenaires et ses collaborateurs. Elle fait référence
            à la mesure dans laquelle une organisation a mis en place la
            structure, les processus et la culture nécessaires pour soutenir
            l'utilisation efficace de l'IA. Cela comprend non seulement le
            leadership numérique et les ressources nécessaires pour mettre en
            œuvre et gérer les projets d'IA, mais aussi l'environnement
            organisationnel plus large dans lequel l'IA est adoptée.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 3,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          onClick={() => {
            navigate(`/dimensions`)
          }}
          variant="outlined"
          sx={{
            px: 4,
            borderRadius: 8,
          }}
        >
          Précédent
        </Button>
        <Button
          variant="mysensContained"
          onClick={handleOpenForm}
          sx={{
            borderRadius: 8,
          }}
        >
          Suivant
        </Button>
      </Box>
    </Box>
  )
}
Description.propTypes = {
  handleOpenForm: PropTypes.func.isRequired,
}

export default function EnvDimension() {
  const [openForm, setOpenForm] = useState(false)
  const handleOpenForm = () => setOpenForm(true)
  const { questions } = useAssessment()
  return openForm ? (
    <QuestionForm steps={questions} />
  ) : (
    <Description handleOpenForm={handleOpenForm} />
  )
}
