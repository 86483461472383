import { useRef } from 'react'
import PropTypes from 'prop-types'
import { Editor } from '@tinymce/tinymce-react'

export default function MyEditor({
  initialValue,
  onChange,
  onFocus,
  ...props
}) {
  const editorRef = useRef(null)
  return (
    <Editor
      initialValue={initialValue}
      apiKey={process.env.REACT_APP_TINY_API_KEY}
      onInit={(evt, editor) => {
        editorRef.current = editor
      }}
      init={{
        placeholder: 'Insérer le contenu ici...',
        language: 'fr_FR',
        branding: false,
        // height: 500,
        menubar: true,
        plugins: [
          'template',
          'searchreplace',
          'quickbars',
          'image',
          'link',
          'codesample',
          'emoticons',
          'insertdatetime',
          'nonbreaking',
          'pagebreak',
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'code',
          'insertdatetime',
          'media',
          'table',
          'help',
          // 'wordcount',
        ],
        toolbar:
          'undo redo | blocks  | bold italic forecolor | link image| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat searchreplace help',

        content_style:
          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } img { max-width: 100%; height: 370px; }',
        ...props,
      }}
      onEditorChange={onChange}
      onNodeChange={(e) => {
        if (e && e.element.nodeName.toLowerCase() === 'img') {
          editorRef.current.dom.setAttribs(e.element, {
            width: '100%',
            height: '370px',
          })
        }
      }}
      onFocus={onFocus}
    />
  )
}

MyEditor.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
}
