import {
  memo,
  useMemo,
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'

import { useAuth } from '../auth/AuthProvider'
import { getScoreDimension, getDimensions } from './api'
// import { getAssessmentsByPoweruser } from '../assessment/api'

const DimensionContext = createContext()
DimensionContext.displayName = 'DimensionContext'

const DimensionProvider = memo(({ children }) => {
  const { company } = useAuth()
  const [dimensions, setDimensions] = useState([])
  const [dimensionsScore, setDimensionsScore] = useState([])
  const [dimensionScoreLoading, setDimensionScoreLoading] = useState(false)
  const [dimensionScoreError, setDimensionScoreError] = useState(false)

  const [dimensionsLoading, setDimensionsLoading] = useState(false)
  const [dimensionsError, setDimensionsError] = useState(false)

  const handleNewScore = useCallback((newScore) => {
    setDimensionsScore((prevDimensionScore) => {
      if (
        prevDimensionScore.findIndex(
          (dimensionScore) =>
            dimensionScore.dimension_id === newScore.dimension.id,
        ) === -1
      ) {
        return [
          ...prevDimensionScore,
          {
            ...newScore,
            last_score: parseFloat(newScore.score.toFixed(2)),
            before_last_score: parseFloat(newScore.score.toFixed(2)),
          },
        ]
      }
      return prevDimensionScore.map((dimension) =>
        dimension.dimension_id === newScore.dimension_id
          ? {
              ...newScore,
              dimension: dimension.dimension,
              last_score: parseFloat(newScore.score.toFixed(2)),
              before_last_score: parseFloat(dimension.last_score.toFixed(2)),
            }
          : dimension,
      )
    })
  }, [])
  const value = useMemo(
    () => ({
      dimensionsScore,
      // questions,
      // setQuestions,
      handleNewScore,
      dimensions,
      // assessment,
      // assessmentLoding,
      dimensionsLoading,
      dimensionsError,
      dimensionScoreLoading,
      // assessmentError,
      dimensionScoreError,
    }),
    [
      dimensionsScore,
      // questions,
      // setQuestions,
      handleNewScore,
      dimensions,
      // assessment,
      // assessmentLoding,
      dimensionsLoading,
      dimensionsError,
      dimensionScoreLoading,
      // assessmentError,
      dimensionScoreError,
    ],
  )

  useEffect(() => {
    setDimensionsError(false)
    setDimensionsLoading(true)
    getDimensions()
      .then((res) => {
        setDimensions(res.data)
        setDimensionsLoading(false)
      })
      .catch(() => {
        setDimensionsError(true)
        setDimensionsLoading(false)
      })
  }, [])

  useEffect(() => {
    setDimensionScoreError(false)
    if (company) {
      setDimensionScoreLoading(true)
      getScoreDimension(company.id)
        .then((res) => {
          setDimensionsScore(res.data)
          setDimensionScoreLoading(false)
        })
        .catch(() => {
          setDimensionScoreError(true)
          setDimensionScoreLoading(false)
        })
    }
  }, [company])

  // useEffect(() => {
  //   setAssessmentError(false)
  //   setAssessmentLoding(true)
  //   getAssessmentsByPoweruser(process.env.REACT_APP_ADMIN_ID)
  //     .then((res) => {
  //       if (res) {
  //         setAssessment(res)
  //       }
  //       setAssessmentLoding(false)
  //     })
  //     .catch(() => {
  //       setAssessmentError(true)
  //       setAssessmentLoding(false)
  //     })
  // }, [])

  return (
    <DimensionContext.Provider value={value}>
      {children}
    </DimensionContext.Provider>
  )
})
export default DimensionProvider
export function useDimension() {
  const context = useContext(DimensionContext)
  if (context === undefined) {
    throw new Error('useDimension must be used within a DimensionProvider')
  }
  return context
}

DimensionProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
