import { createTheme } from '@mui/material'

export default createTheme({
  palette: {
    mode: 'light',
    primary: {
      // main: '#4CA8B5',
      main: '#00ACE0',
    },
    secondary: {
      main: '#4B9BCC',
    },
    mysensPalette: {
      primary: '#4CA8B5',
      secondary: '#4B9BCC',
      colora: '#4098BD',
      colorb: '#5685BC',
      yellow: '#EDB53E',
      blue: '#285F7D',
      lightBlue: '#00ACE0',
      orange: '#BC7328',
      dark: '#0D283A',
    },
    black: {
      black100: '#00121B',
      black75: 'rgba(0,18,27,0.75)',
      black50: 'rgba(0,18,27,0.50)',
      black25: 'rgba(0,18,27,0.25)',
      black10: 'rgba(0,18,27,0.10)',
      black5: 'rgba(0,18,27,0.05)',
    },
    white: {
      white100: '#fff',
      white75: 'rgba(255, 255, 255, 0.75)',
      white50: 'rgba(255, 255, 255, 0.50)',
      white25: 'rgba(255, 255, 255, 0.25)',
      white10: 'rgba(255, 255, 255, 0.10)',
      white5: 'rgba(255, 255, 255, 0.05)',
    },
    background: {
      lightWhite: '#fafafa',
    },
    succss: {
      main: '#73a839',
    },
    default: {
      main: '#033c73',
    },
    orange: {
      main: '#dd5600',
    },
    danger: {
      main: '#c71c22',
    },
  },
  typography: {
    fontFamily: ['Roboto'].join(','),
    fontSize: 16,
    htmlFontSize: 16,
    h1: {
      // fontSize: '4.2rem',
      fontSize: '1.802rem',
      fontWeight: 'bold',
    },
    h2: {
      // fontSize: '3.2rem',
      fontSize: '1.602rem',
      fontWeight: 'bold',
    },
    h3: {
      // fontSize: '2.4rem',
      fontSize: '1.424rem',
    },
    h4: {
      // fontSize: '1.8rem',
      fontSize: '1.266rem',
    },
    h5: {
      // fontSize: '1.3rem',
      fontSize: '1.125rem',
    },
    h6: {
      fontSize: '1rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      // fontSize: '0.75rem',
      fontSize: '0.889rem',
    },
    subtitle1: {
      // fontSize: '0.56rem',
      fontSize: '0.79rem',
    },
    button: {
      fontSize: '1rem',
    },
    caption: {
      // fontSize: '0.42rem',
      fontSize: '0.702rem',
    },
    // overline
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'mysensContained' },
          style: {
            paddingRight: '32px',
            paddingLeft: '32px',
            color: 'white',
            // backgroundColor: '#4CA8B5',
            backgroundColor: '#00ACE0',
            textTransform: 'none',

            '&:hover': {
              backgroundColor: '#4098BD',
            },
          },
        },
        {
          props: { variant: 'mysensOutlined' },
          style: {
            padding: '0rem 2rem',
            border: '2px solid #00ACE0',
            color: '#00ACE0',
            textTransform: 'none',

            '&:hover': {
              color: '#4098BD',
              border: '2px solid #4098BD',
              backgroundColor: '#FFFFFF',
              // backgroundColor: '#4098BD',
            },
          },
        },
      ],
    },
  },
})
