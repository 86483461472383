import api from '../../utils/api'

export async function postRecommendation(data) {
  const url = `recommendations/recommendations`
  try {
    const result = await api(url, { data }).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getRecommendations() {
  const url = `recommendations/recommendations`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getRecommendationsByuser(userId) {
  const url = `recommendations/recommendationsByUser/${userId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getRecommendationsByuserAndSciigo(userId) {
  const url = `recommendations/recommendationsByUserAndSccigo/${userId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}
export async function getRrecommendationsBySciigoAndUserByDimensionByMaturity(
  userId,
  dimensionId,
  maturityId,
) {
  const url = `recommendations/recommendationsBySciigoAndUserByDimensionByMaturity/${userId}/${dimensionId}/${maturityId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getRrecommendationsBySciigoAndUserByDimensionByMaturityByIndustry(
  userId,
  dimensionId,
  maturityId,
  industryid,
) {
  const url = `recommendations/recommendationsBySciigoAndUserByDimensionByMaturityByIndustry/${userId}/${dimensionId}/${maturityId}/${industryid}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getGlobalRrecommendationsBySciigoAndUserByMaturity(
  userId,
  maturityId,
) {
  const url = `recommendations/globalRecommendationsBySciigoAndUserByMaturity/${userId}/${maturityId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getGlobalRrecommendationsBySciigoAndUserByMaturityByIndustry(
  userId,
  maturityId,
  industryId,
) {
  const url = `recommendations/globalRecommendationsBySciigoAndUserByMaturityByIndustry/${userId}/${maturityId}/${industryId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getGlobalRecommendationsByuser(userId) {
  const url = `recommendations/globalRecommendationsByUser/${userId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function updateRecommendation(id, data) {
  try {
    const result = await api(`recommendations/recommendations/${id}`, {
      data,
      method: 'PUT',
    })

    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}
