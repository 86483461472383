import { Box, Card, CardContent, Typography } from '@mui/material'
import PropTypes from 'prop-types'

export default function WhiteBox({
  children,
  title,
  startButton,
  endButton,
  button,
  search,
  pagination,
  whithPadding = true,
  paddingY = 4,
  paddingX = 4,
  titlePaddingX = 4,
  titlePaddingY = 2,
}) {
  return (
    <Box>
      <Card
        sx={{
          transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          boxShadow:
            'rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px',
          borderRadius: '8px',
        }}
      >
        {search && button && (
          <Box
            py={titlePaddingY}
            px={titlePaddingX}
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid rgba(52, 49, 76, 0.2)',
              color: 'black.black75',
            }}
          >
            <Box
              sx={{
                flex: 1,
              }}
            >
              {search}
            </Box>

            {button}
          </Box>
        )}

        {title && !search && button && (
          <Box
            py={titlePaddingY}
            px={titlePaddingX}
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid rgba(52, 49, 76, 0.2)',
              color: 'black.black75',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flex: 1,
              }}
            >
              {startButton && startButton}
              <Typography
                variant="h5"
                sx={{
                  color: 'black.black75',
                  // textTransform: 'capitalize',
                }}
              >
                {title}
              </Typography>
              {endButton && endButton}
            </Box>
            <Box>{button}</Box>
          </Box>
        )}

        {title && !button && search && (
          <Box
            py={titlePaddingY}
            px={titlePaddingX}
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid rgba(52, 49, 76, 0.2)',
              color: 'black.black75',
            }}
          >
            <Box
              sx={{
                flex: 1,
              }}
            >
              {startButton && startButton}
              <Typography
                variant="h5"
                sx={{
                  color: 'black.black75',
                  // textTransform: 'capitalize',
                }}
              >
                {title}
              </Typography>
              {endButton && endButton}
            </Box>
            <Box>{search}</Box>
          </Box>
        )}

        {title && !button && !search && (
          <Box
            py={titlePaddingY}
            px={titlePaddingX}
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid rgba(52, 49, 76, 0.2)',
              color: 'black.black75',
            }}
          >
            <Box
              sx={{
                flex: 1,
              }}
            >
              {startButton && startButton}
              <Typography
                variant="h5"
                sx={{
                  color: 'black.black75',
                  // textTransform: 'capitalize',
                }}
              >
                {title}
              </Typography>
              {endButton && endButton}
            </Box>
          </Box>
        )}

        <CardContent
          sx={{
            '&:last-child': {
              padding: 0,
            },
          }}
        >
          <Box
            sx={{
              py: whithPadding ? paddingY : 0,
              px: whithPadding ? paddingX : 0,
            }}
          >
            {children}
          </Box>
        </CardContent>

        {pagination && (
          <Box
            py={2}
            px={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderTop: '1px solid rgba(52, 49, 76, 0.2)',
              color: 'black.black75',
            }}
          >
            <Box
              sx={{
                flex: 1,
              }}
            />
            {pagination}
          </Box>
        )}
      </Card>
    </Box>
  )
}

WhiteBox.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  whithPadding: PropTypes.bool,
  button: PropTypes.node,
  search: PropTypes.node,
  startButton: PropTypes.node,
  endButton: PropTypes.node,
  pagination: PropTypes.node,
  paddingX: PropTypes.number,
  paddingY: PropTypes.number,
  titlePaddingX: PropTypes.number,
  titlePaddingY: PropTypes.number,
}
