import { Box, Typography, Icon, Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ExtensionIcon from '@mui/icons-material/Extension'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'

export default function KnowledgeLibrary() {
  return (
    <Box>
      <Box>
        <Typography variant="h5">What’s Artificial Intelligence</Typography>
      </Box>
      <Box mt={3} px={2}>
        <Box>
          <Typography variant="body1">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Qu'est ce que l'intelligence artificial?
          </Typography>
        </Box>
        <Box mt={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box>
              <Icon
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  backgroundColor: 'primary.main',
                  color: 'white',
                  p: 2,
                }}
              >
                <CloseIcon />
              </Icon>
            </Box>
            <Box ml={2}>
              <Typography variant="body1">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Il n'ya pas de définition simple de l'intelligence artificielle
                ou plutôt il y en a des centaines.
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box>
              <Icon
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  backgroundColor: 'primary.main',
                  color: 'white',
                  p: 2,
                }}
              >
                <DoneIcon />
              </Icon>
            </Box>
            <Box ml={2}>
              <Box>
                <Typography variant="body1">
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Aussi, on parle d'intelligence artificielle pour caractériser
                  une association de thecnologiesde technologies qui vise à
                  reproduire des systèmes congnitifs simples. Les principales
                  actions reproduites sont:
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box mt={5}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    borderRadius: '10px',
                    backgroundColor: 'bgColor.main',
                    p: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box mr={2}>
                      <Icon>
                        <VisibilityIcon
                          sx={{
                            color: 'primary.main',
                          }}
                        />
                      </Icon>
                    </Box>
                    <Box>
                      <Typography
                        color="primary.main"
                        sx={{
                          fontSize: 18,
                        }}
                      >
                        Percevoire
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <Typography>
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      Ex: L'IA est capable de déterminer s'il s'agit d'une pomme
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      out d'une poire sure une photographie
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    borderRadius: '10px',
                    backgroundColor: 'bgColor.main',
                    p: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box mr={2}>
                      <Icon>
                        <ExtensionIcon
                          sx={{
                            color: 'primary.main',
                          }}
                        />
                      </Icon>
                    </Box>
                    <Box color="primary.main">Comprendre</Box>
                  </Box>

                  <Box mt={2}>
                    <Typography>
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      Ex: Par la position du nom dans une lettre, l'IA comprend
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      qu'il s'agit de l'expéditeur ou du destinataire
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid mt={2} container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    borderRadius: '10px',
                    backgroundColor: 'bgColor.main',
                    p: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box mr={2}>
                      <Icon>
                        <PrecisionManufacturingIcon
                          sx={{
                            color: 'primary.main',
                          }}
                        />
                      </Icon>
                    </Box>
                    <Box color="primary.main">Agir {'    '}</Box>
                  </Box>
                  <Box mt={2}>
                    <Typography>
                      Ex: Dans la robotique, des robots industiels manipulent
                      des objets
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    borderRadius: '10px',
                    backgroundColor: 'bgColor.main',
                    p: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box mr={2}>
                      <Icon>
                        <LocalLibraryIcon
                          sx={{
                            color: 'primary.main',
                          }}
                        />
                      </Icon>
                    </Box>
                    <Box color="primary.main">Apprendre</Box>
                  </Box>
                  <Box mt={2}>
                    <Typography>
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      Ex: L'IA optimise ses propositions de films en prenat en
                      compte les notes attribuées par les autre utilisateurs
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
