import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Typography,
  TextField,
  CircularProgress,
  Grid,
  Box,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { useForm } from 'react-hook-form'

import {
  mustBePassword,
  mustBeConfirmPassword,
} from '../../utils/form-validators'
import { useAuth } from '../../modules/auth/AuthProvider'

export default function ResetPassword() {
  const [showPassword, setshowPassword] = useState(false)
  const [showConfirmPassword, setshowConfirmPassword] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const { resetPassword } = useAuth()
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
    setLoading(true)
    const params = new URLSearchParams(location.search)
    const token = params.get('token')
    resetPassword({
      token,
      password: data.password,
    })
      .then(() => {
        setLoading(false)
        navigate('/')
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Box>
      <Typography
        component="h1"
        variant="h5"
        sx={{ mb: 3, color: 'black.black75' }}
      >
        Réinitialiser Mot De Passe
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{
          textAlign: 'center',
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} sm={12}>
            <TextField
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setshowPassword(!showPassword)}
                      color="primary"
                      edge="end"
                    >
                      {!showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register('password', {
                required: 'Veuillez insirer votre mot de passe',
                validate: {
                  password: (value) => mustBePassword(value),
                },
              })}
              fullWidth
              name="password"
              label="Mot de passe"
              helperText={errors.password?.message}
              error={!!errors.password}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setshowConfirmPassword(!showConfirmPassword)
                      }
                      color="primary"
                      edge="end"
                    >
                      {!showConfirmPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register('repassword', {
                required: 'Veuillez confirmer votre mot de passe',
                validate: {
                  repassword: (value) =>
                    mustBeConfirmPassword(getValues().password)(value),
                },
              })}
              margin="normal"
              fullWidth
              name="repassword"
              label="Confirmer Mot de passe"
              helperText={errors.repassword?.message}
              error={!!errors.repassword}
            />
          </Grid>
        </Grid>

        <Button
          type="submit"
          variant="mysensContained"
          fullWidth
          sx={{ mt: '16px', mb: '24px' }}
        >
          {loading && (
            <CircularProgress
              size={20}
              sx={{ color: 'white', marginRight: '1rem' }}
            />
          )}
          Confirmer
        </Button>
      </Box>

      <Typography
        variant="body1"
        sx={{
          fontWeight: 600,
          cursor: 'pointer',
          paddingLeft: '8px',
          color: 'mysensPalette.lightBlue',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
        onClick={() => {
          navigate('/')
        }}
      >
        Connecter
      </Typography>
    </Box>
  )
}
