import { Routes, Route, Navigate } from 'react-router-dom'
import Auth from './screens/auth/Auth'
import ResetPassword from './screens/user/ResetPassword'
import AuthLayout from './modules/auth/components/AuthLayout'

export default function UnAuthenticatedApp() {
  return (
    <Routes>
      <Route
        path="/reset-password"
        element={
          <AuthLayout>
            <ResetPassword />
          </AuthLayout>
        }
      />
      <Route path="/" element={<Auth />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}
