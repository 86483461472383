import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Typography, Button } from '@mui/material'
// import StopIcon from '@mui/icons-material/Stop'

import QuestionForm from '../../../modules/QuestionAnswer/components/QuestionForm'
// import questions from '../../../modules/QuestionAnswer/questions/CultureDimension.json'
import { StrongText } from '../../../utils/customElements'
import { useAssessment } from '../../../modules/assessment/AssessmentProvider'

function Description({ handleOpenForm }) {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        px: {
          xs: 2,
          md: 4,
        },
      }}
    >
      <Typography
        component="h1"
        variant="h4"
        sx={{
          textAlign: 'center',
          color: 'black.black75',
        }}
      >
        Dimension Humain et Culture
      </Typography>

      <Box
        mt={3}
        sx={{
          color: 'black.black75',
        }}
      >
        <Box>
          <Typography component="div" variant="body1">
            La dimension{' '}
            <StrongText component="span">Humain et Culture </StrongText>
            concerne la définition et le développement d’une culture
            organisationnelle avec des processus/politiques de gouvernance et de
            talent pour soutenir les progrès dans l'adoption et la mise en œuvre
            de l'IA pour la main-d'œuvre, les fournisseurs internes et tiers,
            afin d'atteindre les objectifs de croissance et d'innovation de
            l'organisation.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 3,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          onClick={() => {
            navigate(`/dimensions`)
          }}
          variant="outlined"
          sx={{
            px: 4,
            borderRadius: 8,
          }}
        >
          Précédent
        </Button>
        <Button
          variant="mysensContained"
          onClick={handleOpenForm}
          sx={{
            borderRadius: 8,
          }}
        >
          Suivant
        </Button>
      </Box>
    </Box>
  )
}
Description.propTypes = {
  handleOpenForm: PropTypes.func.isRequired,
}

export default function Culture() {
  const [openForm, setOpenForm] = useState(false)
  const handleOpenForm = () => setOpenForm(true)
  const { questions } = useAssessment()
  return openForm ? (
    <QuestionForm steps={questions} />
  ) : (
    <Description handleOpenForm={handleOpenForm} />
  )
}
