import * as React from 'react'
import {
  Box,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
} from '@mui/material'

import CompanyProfileForm from './CompanyProfileForm'
import IndustryForm from './IndustryForm'
import { useAlert } from '../../alert/AlertProvider'
import { useCompany } from '../companyProvider'
import { useAuth } from '../../auth/AuthProvider'

const steps = ["Profil d'entreprise", 'Industrie']

export default function RegisterCompanyStepper() {
  const { account, setCompanyData } = useAuth()
  const { showAlert } = useAlert()
  const { createCompany } = useCompany()
  const [companyProfileData, setCompanyProfileData] = React.useState(null)
  const [activeStep, setActiveStep] = React.useState(0)
  const [loading, setLoading] = React.useState(false)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const onSubmit = (data) => {
    setLoading(true)

    const creationDate = new Date(data.creationDate).toISOString()
    const sendData = {
      ...data,
      country: JSON.stringify(data.country),
      region: JSON.stringify(data.region),
      city: JSON.stringify(data.city),
      creationDate,
      userId: account?.id,
    }
    createCompany(sendData)
      .then((res) => {
        showAlert(
          `L'entreprise ${sendData.companyName} a été créé avec success`,
          'success',
        )
        setCompanyData({ ...data, id: res.id, userId: account?.id })
        // setCompanyData(res.data)
        setLoading(false)
      })
      .catch((e) => {
        setCompanyData(null)

        if (e.message.includes('Ce nom de société existe déjà')) {
          showAlert(
            'Cette entreprise existe déjà, veuillez introduire un nouvelle entreprise',
            'error',
          )
        } else {
          showAlert('Une erreur est survenue, Veuillez réessayer', 'error')
        }
      })
  }

  const submitCompanyProfile = (data) => {
    setCompanyProfileData(data)
    handleNext()
  }
  const submitIndustry = (data) => {
    onSubmit({ ...companyProfileData, ...data })
  }
  //   const handleBack = () => {
  //     setActiveStep((prevActiveStep) => prevActiveStep - 1)
  //   }

  const stepContent = () =>
    activeStep === 0 ? (
      <CompanyProfileForm submit={submitCompanyProfile}>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button type="submit">Suivant</Button>
        </Box>
      </CompanyProfileForm>
    ) : (
      <IndustryForm submit={submitIndustry}>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button type="submit">
            {loading && (
              <CircularProgress
                color="secondary"
                size={20}
                sx={{ marginRight: '1rem' }}
              />
            )}
            Terminer
          </Button>
        </Box>
      </IndustryForm>
    )

  return (
    <Box>
      {activeStep === 0 && (
        <Box mb={4}>
          <Typography variant="h5">
            {account.firstName}, personnalisons votre profil.
          </Typography>
          <Typography variant="body">
            Dites-nous en plus sur votre entreprise
          </Typography>
        </Box>
      )}
      {activeStep === 1 && (
        <Box mb={4}>
          <Typography variant="h5">
            {account.firstName}, votre profil est presque complet
          </Typography>
          <Typography variant="body">Parlez-nous de votre industrie</Typography>
        </Box>
      )}
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ? (
        <Typography sx={{ mt: 2, mb: 1 }}>
          Toutes les étapes sont terminées, vous avez terminé!
        </Typography>
      ) : (
        <Box mt={3}>{stepContent()}</Box>
      )}
    </Box>
  )
}
