import PropTypes from 'prop-types'
import { Box } from '@mui/material'
// import LevelBoxes from './LevelBoxes'
import ProfileBox from './ProfileBox'
import DimensionsScore from './DimensionsScore'
import ScoreEvolution from './ScoreEvolution'
import DimensionHistoryChart from './DimensionHistoryChart'

export default function Score({ dimensionsScore, companyId, dimensions }) {
  return (
    <Box>
      {/* <Box>
        <LevelBoxes />
      </Box> */}
      <Box mt={3}>
        <ProfileBox dimensionsScore={dimensionsScore} dimensions={dimensions} />
      </Box>
      <Box mt={3}>
        <DimensionsScore dimensionsScore={dimensionsScore} />
      </Box>
      <Box mt={3}>
        <ScoreEvolution dimensionsScore={dimensionsScore} />
      </Box>
      <Box mt={3}>
        <DimensionHistoryChart companyId={companyId} />
      </Box>
    </Box>
  )
}

Score.propTypes = {
  dimensionsScore: PropTypes.array,
  companyId: PropTypes.number,
  dimensions: PropTypes.array,
}
