import * as React from 'react'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import {
  Box,
  CircularProgress,
  TextField,
  Typography,
  // Autocomplete,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useAlert } from '../../alert/AlertProvider'
import { useAuth } from '../../auth/AuthProvider'
// import { useDimension } from '../../dimension/DimensionProvider'
import { postThematic, updateThematic } from '../api'

export default function ThematicForm({
  dimensionId,
  action,
  thematicData,
  handleClose,
  setThematics,
  setRows,
}) {
  const [loading, setLoading] = React.useState(false)
  const { showAlert } = useAlert()

  // const { dimensions } = useDimension()
  const { account } = useAuth()

  const {
    handleSubmit,
    // control,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: thematicData?.name || '',
      // weight: thematicData?.weight || null,
      // dimension: thematicData?.dimension,
    },
  })

  const onSubmit = (data) => {
    setLoading(true)

    const sendData = {
      ...data,
      // dimension_id: data.dimension.id,
      dimension_id: dimensionId,
      created_by: account.id,
    }

    if (action === 'add') {
      postThematic(sendData)
        .then((res) => {
          setRows((prevRows) => [
            {
              ...sendData,
              id: res.data.id,
            },
            ...prevRows,
          ])
          setThematics((prevRows) => [
            {
              ...sendData,
              id: res.data.id,
            },
            ...prevRows,
          ])
          handleClose()
        })
        .catch((error) => {
          if (error.message.includes('duplicate')) {
            showAlert(
              'Cette valeur existe déjà, Veuillez insérer une nouvelle valeur',
              'error',
            )
          } else {
            showAlert("Une erreur s'est produite", 'error')
          }
        })
    }
    if (action === 'edit') {
      updateThematic(thematicData.id, sendData)
        .then(() => {
          setRows((prevRows) =>
            prevRows.map((row) =>
              row.id === thematicData?.id
                ? {
                    ...sendData,
                    id: thematicData?.id,
                  }
                : row,
            ),
          )
          setThematics((prevQuestions) =>
            prevQuestions.map((row) =>
              row.id === thematicData?.id
                ? {
                    ...sendData,
                    id: thematicData?.id,
                  }
                : row,
            ),
          )
          handleClose()
        })
        .catch((error) => {
          if (error.message.includes('duplicate')) {
            showAlert(
              'Cette valeur existe déjà, Veuillez insérer une nouvelle valeur',
              'error',
            )
          } else {
            showAlert("Une erreur s'est produite", 'error')
          }
        })
    }
    setLoading(false)
  }

  return (
    <Box
      sx={{
        // width: 400,
        boxShadow: 24,
        p: 4,
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <>
        <Typography variant="h5" textAlign="center" component="h1" gutterBottom>
          {action === 'add' ? 'Ajouter une thématique' : 'Editer la thématique'}
        </Typography>

        <Box mt={2}>
          <Box>
            <TextField
              {...register('name', {
                required: {
                  value: true,
                  message: 'Veuillez saisir une valeur',
                },
              })}
              margin="normal"
              fullWidth
              id="name"
              label="Nom de la thématique"
              name="name"
              autoComplete="name"
              autoFocus
              helperText={errors.name?.message}
              error={!!errors.name}
            />
          </Box>

          {/* <Box mt={1}>
            <Controller
              control={control}
              name="dimension"
              rules={{
                required: 'Veuillez choisir une réponse',
              }}
              render={({ field: { onChange } }) => (
                <Autocomplete
                  defaultValue={thematicData?.dimension}
                  options={dimensions}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, values) => {
                    onChange(values)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Dimensions"
                      placeholder="Dimensions"
                      helperText={errors.dimension?.message}
                      error={!!errors.dimension}
                    />
                  )}
                />
              )}
            />
          </Box> */}

          {/* <Box>
            <TextField
              {...register('weight', {
                required: {
                  value: true,
                  message: 'Veuillez saisir une valeur',
                },
              })}
              type="number"
              margin="normal"
              fullWidth
              id="name"
              label="Poids"
              name="weight"
              autoComplete="weight"
              helperText={errors.weight?.message}
              error={!!errors.weight}
            />
          </Box> */}
        </Box>

        <Button
          startIcon={
            loading ? (
              <CircularProgress size={20} />
            ) : (
              <AddIcon color="white" size="small" />
            )
          }
          type="submit"
          fullWidth
          disabled={loading}
          variant="mysensContained"
          sx={{ mt: 2 }}
        >
          Enregistrer
        </Button>
      </>
    </Box>
  )
}
ThematicForm.propTypes = {
  dimensionId: PropTypes.number,
  action: PropTypes.string,
  thematicData: PropTypes.object,
  handleClose: PropTypes.func,
  setThematics: PropTypes.func,
  setRows: PropTypes.func,
}
