import PropTypes from 'prop-types'
import { Box, IconButton, Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import PeopleIcon from '@mui/icons-material/People'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import UnpublishedIcon from '@mui/icons-material/Unpublished'
import WhiteBox from '../../../components/WhiteBox'

export default function EvaluationCard({
  item,
  handleOpen,
  handleShowDetailClick,
  handleClientsClick,
}) {
  return (
    <WhiteBox paddingY={2} paddingX={2.5}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: 100,
        }}
      >
        {/* Published */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box mt={1}>
            {item.published && (
              <CheckCircleIcon
                sx={{
                  color: 'mysensPalette.lightBlue',
                }}
              />
            )}
            {!item.published && (
              <UnpublishedIcon
                sx={{
                  color: 'mysensPalette.orange',
                }}
              />
            )}
          </Box>
        </Box>
        {/* /Published */}

        {/* Content */}
        <Box
          mt={1}
          mb={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" color="black.black50">
            {item.industry.name}
          </Typography>
          <Typography
            variant="h5"
            color="black.black75"
            sx={{
              mt: 0.5,
            }}
          >
            {item.name}
          </Typography>
        </Box>
        {/* /Content */}

        {/* Action */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              flex: 1,
              color: 'black.black50',
            }}
          >
            <IconButton
              sx={{
                color: 'inherit',
              }}
              disabled={!item.published}
              onClick={() => {
                handleClientsClick(item)
              }}
            >
              <PeopleIcon />
            </IconButton>
            <IconButton
              sx={{
                color: 'inherit',
              }}
              onClick={() => {
                handleShowDetailClick(item)
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              color: 'black.black50',
            }}
          >
            <IconButton
              sx={{
                color: 'inherit',
              }}
              onClick={() => {
                handleOpen('edit', item)
              }}
              disabled={item.published}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              sx={{
                color: 'inherit',
              }}
              disabled
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
        {/* /Action */}
      </Box>
    </WhiteBox>
  )
}

EvaluationCard.propTypes = {
  item: PropTypes.object,
  handleOpen: PropTypes.func,
  handleShowDetailClick: PropTypes.func,
  handleClientsClick: PropTypes.func,
}
