import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Box,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ListItemButton from '@mui/material/ListItemButton'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import AcUnitIcon from '@mui/icons-material/AcUnit'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import BookmarksIcon from '@mui/icons-material/Bookmarks'
import LabelIcon from '@mui/icons-material/Label'
// import ContactSupportIcon from '@mui/icons-material/ContactSupport'
// import ClassIcon from '@mui/icons-material/Class'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import ReviewsIcon from '@mui/icons-material/Reviews'
import ArticleIcon from '@mui/icons-material/Article'
import PeopleIcon from '@mui/icons-material/People'
import AppsIcon from '@mui/icons-material/Apps'
import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import { roles, useAuth } from '../modules/auth/AuthProvider'

const drawerWidth = 287.07

function AdminItems({ open }) {
  const { pathname } = useLocation()
  const items = [
    {
      href: '/padashboard',
      title: 'Tableau de bord',
      icon: (
        <DashboardIcon
          sx={{
            width: '2rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
    {
      href: '/thematics',
      title: 'Thématiques',
      icon: (
        <BookmarksIcon
          sx={{
            width: '2rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
    {
      href: '/tags',
      title: 'Métadonnées',
      icon: (
        <LabelIcon
          sx={{
            width: '2rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
    {
      href: '/adminQuestions',
      title: 'Questions',
      icon: (
        <QuestionAnswerIcon
          sx={{
            width: '2rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
    {
      href: '/recommendation/recommendations',
      title: 'Recommendations',
      icon: (
        <ReviewsIcon
          sx={{
            width: '2rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
    {
      href: '/evaluations',
      title: 'Évaluations',
      icon: (
        <ArticleIcon
          sx={{
            width: '2rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
    {
      href: '/useCases',
      title: "Cas d'usages",
      icon: (
        <AppsIcon
          sx={{
            width: '2rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
    {
      href: '/clients',
      title: 'Clients',
      icon: (
        <PeopleIcon
          sx={{
            width: '2rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
  ]

  return (
    <List
      sx={{
        px: open ? 2 : 0,
      }}
    >
      {items.map((item) => (
        <Box key={item.title} mb={1}>
          <ListItemButton
            component={Link}
            to={`${item.href}`}
            selected={pathname === item.href}
            sx={{
              color: 'white.white75',
            }}
          >
            {item.icon}

            <ListItemText>
              <Typography
                variant="body1"
                sx={{
                  color: 'white.white75',
                }}
              >
                {item.title}
              </Typography>
            </ListItemText>
          </ListItemButton>
        </Box>
      ))}
    </List>
  )
}
AdminItems.propTypes = {
  open: PropTypes.bool,
}

function PowerUserItems({ open }) {
  const { pathname } = useLocation()
  const items = [
    {
      href: '/padashboard',
      title: 'Tableau de bord',
      icon: (
        <DashboardIcon
          sx={{
            width: '2rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
    {
      href: '/adminQuestions',
      title: 'Questions',
      icon: (
        <QuestionAnswerIcon
          sx={{
            width: '2rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
    {
      href: '/recommendation/recommendations',
      title: 'Recommendations',
      icon: (
        <ReviewsIcon
          sx={{
            width: '2rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
    {
      href: '/recommendation/assessment',
      title: 'Évaluations',
      icon: (
        <ArticleIcon
          sx={{
            width: '2rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
    {
      href: '/useCases',
      title: "Cas d'usages",
      icon: (
        <AppsIcon
          sx={{
            width: '2rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
    {
      href: '/clients',
      title: 'Clients',
      icon: (
        <PeopleIcon
          sx={{
            width: '2rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
  ]

  return (
    <List
      sx={{
        px: open ? 2 : 0,
      }}
    >
      {items.map((item) => (
        <Box key={item.title} mb={1}>
          <ListItemButton
            component={Link}
            to={`${item.href}`}
            selected={pathname === item.href}
            sx={{
              color: 'white.white75',
            }}
          >
            {item.icon}

            <ListItemText>
              <Typography
                variant="body1"
                sx={{
                  color: 'white.white75',
                }}
              >
                {item.title}
              </Typography>
            </ListItemText>
          </ListItemButton>
        </Box>
      ))}
    </List>
  )
}
PowerUserItems.propTypes = {
  open: PropTypes.bool,
}

function ClientsItems({ open }) {
  const { account } = useAuth()
  const { pathname } = useLocation()
  const [openReadiness, setOpenReadiness] = useState(true)
  const [openOpportunities, setOpenOpportunities] = useState(true)
  const [openInsights, setOpenInsights] = useState(true)

  const clientItems = []

  if (account.privileges && account.privileges.includes('assessment')) {
    clientItems.push({
      name: 'Tableau de bord',
      icon: (
        <DashboardIcon
          sx={{
            width: '2rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
      href: '/myscoreai',
      soloItem: true,
    })

    clientItems.push({
      name: 'État d’avancement',
      icon: (
        <InsertDriveFileIcon
          sx={{
            width: '2rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
      soloItem: false,
      open: openReadiness,
      onClick: () => {
        setOpenReadiness(!openReadiness)
      },
      items: [
        {
          href: '/dimensions',
          title: 'Évaluation',
        },
        {
          href: '/myscoreai',
          title: 'Score AiQ',
        },
      ],
    })
  }

  if (account.privileges && account.privileges.includes('recommendations')) {
    clientItems.push({
      name: 'Perspectives',
      icon: (
        <LightbulbIcon
          sx={{
            width: '2rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
      soloItem: false,
      open: openInsights,
      onClick: () => {
        setOpenInsights(!openInsights)
      },
      items: [
        {
          href: '/toprecommendations',
          title: 'Recommendations',
        },
      ],
    })
  }

  if (account.privileges && account.privileges.includes('usecases')) {
    clientItems.push({
      name: 'Opportunités',
      icon: (
        <AcUnitIcon
          sx={{
            width: '2rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
      soloItem: false,
      open: openOpportunities,
      onClick: () => {
        setOpenOpportunities(!openOpportunities)
      },
      items: [
        // {
        //   href: '#',
        //   title: 'Business Oppportunities',
        // },
        {
          href: '/topUseCases',
          title: 'Cas d’usages',
        },
        // {
        //   href: '#',
        //   title: 'AI path',
        // },
      ],
    })
  }

  return (
    <List
      sx={{
        px: open ? 2 : 0,
      }}
    >
      {clientItems.map((item) =>
        item.soloItem ? (
          <Box key={item.name} mb={2}>
            <ListItemButton
              component={Link}
              to={`${item.href}`}
              selected={pathname === item.href}
              sx={{
                color: 'white.white75',
              }}
            >
              {item.icon}

              <ListItemText>
                <Typography
                  variant="body1"
                  sx={{
                    color: 'white.white75',
                  }}
                >
                  {item.name}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </Box>
        ) : (
          <Box key={item.name} mb={2}>
            <ListItemButton
              sx={{
                color: 'white.white75',
              }}
              onClick={item.onClick}
            >
              {item.icon}

              <ListItemText>
                <Typography
                  variant="body1"
                  sx={{
                    color: 'white.white75',
                  }}
                >
                  {item.name}
                </Typography>
              </ListItemText>
              {item.open ? (
                <ExpandLess
                  sx={{
                    color: 'inherit',
                  }}
                />
              ) : (
                <ChevronRightIcon
                  sx={{
                    color: 'inherit',
                  }}
                />
              )}
            </ListItemButton>
            <Collapse in={item.open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding dense>
                {item.items.map((subitem) => (
                  <ListItem
                    key={subitem.title}
                    component={Link}
                    to={`${subitem.href}`}
                    button
                    selected={pathname === `${subitem.href}`}
                    sx={{
                      mb: 1,
                    }}
                  >
                    <ListItemText inset>
                      <Typography
                        variant="body1"
                        sx={{ color: 'white.white75' }}
                      >
                        {subitem.title}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </Box>
        ),
      )}
    </List>
  )
}

ClientsItems.propTypes = {
  open: PropTypes.bool,
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(8),
    }),
  },
}))

export default function Sidebar({ open }) {
  const { account } = useAuth()
  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          position: 'fixed',
          zIndex: '211',
          backgroundColor: 'linear-gradient(#001F29, #003E4A)',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '48px',
            width: '48px',
          }}
        >
          <img
            src="/images/MySensAI_logo_NB.png"
            alt="mysensai logo"
            style={{
              height: '48px',
              width: '48px',
            }}
          />
        </Box>
      </Toolbar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundSize: 'cover',
          height: '100vh',
          width: '100%',
          top: 0,
          left: 0,
          zIndex: '111',
          backgroundRepeat: 'no-repeat',
          transition: 'all 250ms ease-in-out 0s',
          backgroundImage:
            'linear-gradient(rgba(0, 31, 41, 0.96), rgba(0, 62, 74, 0.96)), url(/images/thunder.png)',
          // backgroundImage:
          //   'linear-gradient(rgba(34, 42, 69, 0.96), rgba(34, 42, 69, 0.96)), url(/images/thunder.png)',

          boxShadow:
            'rgb(0 0 0 / 6%) 0px 5px 5px -3px, rgb(0 0 0 / 4%) 0px 8px 10px 1px, rgb(0 0 0 / 4%) 0px 3px 14px 2px',
          backgroundPosition: 'center top',
          // overflowY: 'hidden',
          overflowY: 'scroll',
          '&:hover::-webkit-scrollbar': {
            backgroundColor: 'rgba(0, 31, 41, 1)',
            display: 'block',
          },
          '&::-webkit-scrollbar': {
            display: 'none',
            marginTop: '8rem',
            width: '0.512rem',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#8d8e90',
            height: '8px',
            borderRadius: '8px',
          },
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Box>
            <Toolbar />
            {(account.role.includes('client') ||
              account.role.includes(roles.Client.value)) && (
              <ClientsItems open={open} />
            )}
            {(account.role.includes('poweruser') ||
              account.role.includes(roles.PowerUser.value)) && (
              <PowerUserItems open={open} />
            )}
            {(account.role.includes('admin') ||
              account.role.includes(roles.Admin.value)) && (
              <AdminItems open={open} />
            )}
            {/* <BottomItems open={open} /> */}
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}

Sidebar.propTypes = {
  open: PropTypes.bool,
}
