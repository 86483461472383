import { useState } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import {
  Box,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import CloseIcon from '@mui/icons-material/Close'
import BusinessIcon from '@mui/icons-material/Business'
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined'
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined'
import { FaisabilityMapper } from '../../../utils/mappers'
import WhiteBox from '../../../components/WhiteBox'
import { StrongText } from '../../../utils/customElements'

export default function UseCasesCard({ item, handleOpen }) {
  const [dialog, setDialog] = useState(false)
  const parsedContent = item.description && parse(item.description)
  const div = document.createElement('div')
  div.innerHTML = item.description
  const shortDescription = div.innerText
    .replace(/<\/?[^>]+(>|$)/g, '')
    .substring(0, 233)

  const handleOpenDialogContent = () => {
    setDialog(true)
  }
  const handleCloseDialogContent = () => {
    setDialog(false)
  }
  return (
    <>
      <WhiteBox>
        {/* Header */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              flex: 1,
            }}
          />
          <Box
            sx={{
              py: 0.5,
              px: 2,
              border: '1px solid rgba(0,18,27,0.1)',
            }}
          >
            <Typography variant="body1" color="black.black50">
              {FaisabilityMapper[item.faisabilite]}
            </Typography>
          </Box>
        </Box>
        {/* /Header */}

        {/* Name */}
        <Typography mt={2} variant="h5" color="black.black75">
          {item.name}
        </Typography>
        {/* /Name */}

        <Box>
          {/* Content benifit prerequis */}
          <Typography mt={2} pl={4} variant="body1" color="black.black50">
            {`${shortDescription} ..`}
          </Typography>
          {/* /Content */}
        </Box>

        {/* Other Info */}
        <Box mt={3}>
          {/* Industries */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <BusinessIcon
              sx={{
                mr: 1,
                fontSize: '1.424rem',
                color: 'black.black50',
              }}
            />
            <Typography variant="body1" color="black.black75">
              {JSON.parse(item.industries).map((itemv) => `${itemv.name}, `)}
            </Typography>
          </Box>
          {/* /Industries */}

          {/* specialization */}
          {JSON.parse(item.specializations).length > 0 && (
            <Box
              mt={1}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <PolylineOutlinedIcon
                sx={{
                  mr: 1,
                  fontSize: '1.424rem',
                  color: 'black.black50',
                }}
              />
              <Typography variant="body1" color="black.black75">
                {JSON.parse(item.specializations).map(
                  (itemv) => `${itemv.name}, `,
                )}
              </Typography>
            </Box>
          )}
          {/* /specialization */}

          {/* tags */}
          {JSON.parse(item.tags).length > 0 && (
            <Box
              mt={1}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <LabelOutlinedIcon
                sx={{
                  mr: 1,
                  fontSize: '1.424rem',
                  color: 'black.black50',
                }}
              />
              <Typography variant="body1" color="black.black75">
                {JSON.parse(item.tags).map((itemv) => `${itemv}, `)}
              </Typography>
            </Box>
          )}
          {/* /tags */}
        </Box>
        {/* /Other Info */}
        {/* Actions */}
        <Box
          mt={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              flex: 1,
            }}
          >
            <Box>
              <Typography mt={2} variant="body1" color="black.black50">
                {`${JSON.parse(item.industries).length} industries / `}
                {`${
                  JSON.parse(item.specializations).length
                } spécialisations / `}
                {`${JSON.parse(item.tags).length} métadonnées`}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
            }}
          >
            <IconButton onClick={handleOpenDialogContent}>
              <VisibilityIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                handleOpen('edit', item)
              }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Box>
        {/* /Actions */}
      </WhiteBox>
      <Dialog
        // onClose={handleClose}
        open={dialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h3"
            textAlign="center"
            component="p"
            color="black.black75"
          >
            {item.name}
          </Typography>

          <IconButton onClick={handleCloseDialogContent} sx={{ ml: 'auto' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            {/* Header */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  flex: 1,
                }}
              />
              <Box
                sx={{
                  py: 0.5,
                  px: 2,
                  border: '1px solid rgba(0,18,27,0.1)',
                }}
              >
                <Typography variant="body1" color="black.black50">
                  {FaisabilityMapper[item.faisabilite]}
                </Typography>
              </Box>
            </Box>
            {/* /Header */}
            {/* Content */}
            <Typography mt={2} pl={4} variant="body1" color="black.black50">
              {parsedContent}
            </Typography>
            {/* /Content */}

            {/* Benifit */}
            <Typography
              component="div"
              mt={3}
              pl={4}
              variant="body1"
              color="black.black50"
            >
              <StrongText color="mysensPalette.orange" component="span">
                Bénéfice:
              </StrongText>{' '}
              {item.benefit && parse(item.benefit)}
            </Typography>

            {/* /Benifit */}

            {/* prerquisite */}
            <Box>
              <Typography
                component="div"
                mt={1}
                pl={4}
                variant="body1"
                color="black.black50"
              >
                <StrongText color="mysensPalette.orange" component="span">
                  Prérequis données:
                </StrongText>{' '}
                {item.prerequisite_data && parse(item.prerequisite_data)}
              </Typography>
              <Typography
                component="div"
                mt={1}
                pl={4}
                variant="body1"
                color="black.black50"
              >
                <StrongText color="mysensPalette.orange" component="span">
                  Prérequis technologiques:
                </StrongText>{' '}
                {item.prerequisite_technologie &&
                  parse(item.prerequisite_technologie)}
              </Typography>
              <Typography
                component="div"
                mt={1}
                pl={4}
                variant="body1"
                color="black.black50"
              >
                <StrongText color="mysensPalette.orange" component="span">
                  Prérequis compétances:
                </StrongText>{' '}
                {item.prerequisite_competance &&
                  parse(item.prerequisite_competance)}
              </Typography>
              <Typography
                component="div"
                mt={1}
                pl={4}
                variant="body1"
                color="black.black50"
              >
                <StrongText color="mysensPalette.orange" component="span">
                  Prérequis générals:
                </StrongText>{' '}
                {item.prerequisite_general && parse(item.prerequisite_general)}
              </Typography>
            </Box>
            {/* /prerquisite */}
            {/* Other Info */}
            <Box mt={3}>
              {/* Industries */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <BusinessIcon
                  sx={{
                    mr: 1,
                    fontSize: '1.424rem',
                    color: 'black.black50',
                  }}
                />
                <Typography variant="body1" color="black.black75">
                  {JSON.parse(item.industries).map(
                    (itemv) => `${itemv.name}, `,
                  )}
                </Typography>
              </Box>
              {/* /Industries */}

              {/* specialization */}
              {JSON.parse(item.specializations).length > 0 && (
                <Box
                  mt={1}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <PolylineOutlinedIcon
                    sx={{
                      mr: 1,
                      fontSize: '1.424rem',
                      color: 'black.black50',
                    }}
                  />
                  <Typography variant="body1" color="black.black75">
                    {JSON.parse(item.specializations).map(
                      (itemv) => `${itemv.name}, `,
                    )}
                  </Typography>
                </Box>
              )}
              {/* /specialization */}

              {/* tags */}
              {JSON.parse(item.tags).length > 0 && (
                <Box
                  mt={1}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <LabelOutlinedIcon
                    sx={{
                      mr: 1,
                      fontSize: '1.424rem',
                      color: 'black.black50',
                    }}
                  />
                  <Typography variant="body1" color="black.black75">
                    {JSON.parse(item.tags).map((itemv) => `${itemv}, `)}
                  </Typography>
                </Box>
              )}
              {/* /tags */}
            </Box>
            {/* /Other Info */}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
UseCasesCard.propTypes = {
  item: PropTypes.object,
  handleOpen: PropTypes.func,
}
