import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import WhiteBox from '../../../components/WhiteBox'
import ScoreForPoweruser from '../../score/components/ScoreForPoweruser'

export default function DashboardClient({ companyId }) {
  return (
    <WhiteBox title="Statistique sur l'entreprise du client">
      {companyId ? (
        <ScoreForPoweruser companyId={companyId} />
      ) : (
        <Box>
          <Typography
            variant="body1"
            color="black.black75"
            sx={{
              textAlign: 'center',
            }}
          >
            Le client n'a pas encore remplie les informations de son entreprise
          </Typography>
        </Box>
      )}
    </WhiteBox>
  )
}
DashboardClient.propTypes = {
  companyId: PropTypes.number,
}
