import api from '../../utils/api'

export async function getIndustries() {
  const url = 'company/CompanyIndustryType'
  try {
    const data = await api(url).then((response) => ({
      count: response.data.length,
      data: response.data,
    }))
    return data
  } catch (error) {
    throw new Error(error)
  }
}
