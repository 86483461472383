import api from '../../utils/api'

export async function getTags() {
  const url = `recommendations/tags`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getTagsByUser(userId) {
  const url = `recommendations/tagsByUser/${userId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getTagsByUserAndSciigo(userId) {
  const url = `recommendations/tagsByUserAndSciigo/${userId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function postTag(data) {
  const url = `recommendations/tags`
  try {
    const result = await api(url, { data }).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function updateTag(id, data) {
  try {
    const result = await api(`recommendations/tags/${id}`, {
      data,
      method: 'PUT',
    })

    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}
