import { useState } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material'
// import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import BusinessIcon from '@mui/icons-material/Business'
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined'
import AutoAwesomeMotionOutlinedIcon from '@mui/icons-material/AutoAwesomeMotionOutlined'
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
// import ExpandLessIcon from '@mui/icons-material/ExpandLess'
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloseIcon from '@mui/icons-material/Close'
import WhiteBox from '../../../components/WhiteBox'
import { StrongText } from '../../../utils/customElements'

export default function RecommendationCard({ item, handleOpen }) {
  const [dialog, setDialog] = useState(false)
  const parsedContent = item.content && parse(item.content)
  const div = document.createElement('div')
  div.innerHTML = item.content
  const shortDescription = div.innerText
    .replace(/<\/?[^>]+(>|$)/g, '')
    .substring(0, 233)
  // const [readMore, setReadMore] = useState(false)
  // const handReadMoreClick = () => {
  //   setReadMore(!readMore)
  // }
  const handleOpenDialogContent = () => {
    setDialog(true)
  }
  const handleCloseDialogContent = () => {
    setDialog(false)
  }
  return (
    <>
      <WhiteBox
      // title={item.name}
      >
        {/* Header */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              flex: 1,
            }}
          >
            {item.maturities && JSON.parse(item.maturities).length > 0 && (
              <Box
                mt={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <InsightsOutlinedIcon
                  sx={{
                    mr: 1,
                    fontSize: '1.424rem',
                    color: 'black.black50',
                  }}
                />
                <Typography variant="body1" color="black.black50">
                  {JSON.parse(item.maturities).map(
                    (value) => `${value.name}, `,
                  )}
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              py: 0.5,
              px: 2,
              border: '1px solid rgba(0,18,27,0.1)',
            }}
          >
            <Typography variant="body1" color="black.black50">
              {JSON.parse(item.dimensions).length > 0
                ? 'Spécifique'
                : 'Globale'}
            </Typography>
          </Box>
        </Box>
        {/* /Header */}

        {/* Name */}
        <Typography mt={2} variant="h5" color="black.black75">
          {item.name}
        </Typography>
        {/* /Name */}

        {/* Content */}
        <Box>
          <Typography
            component="div"
            mt={2}
            pl={4}
            variant="body1"
            color="black.black50"
          >
            {`${shortDescription} ...`}
          </Typography>
        </Box>
        {/* /Content */}

        {/* Other Info */}
        <Box mt={2}>
          {JSON.parse(item.dimensions).length > 0 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <AutoAwesomeMotionOutlinedIcon
                sx={{
                  mr: 1,
                  fontSize: '1.424rem',
                  color: 'black.black50',
                }}
              />
              <Typography variant="body1" color="black.black75">
                {JSON.parse(item.dimensions).map((itemv) => `${itemv.name}, `)}
              </Typography>
            </Box>
          )}
          <Box
            mt={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <BusinessIcon
              sx={{
                mr: 1,
                fontSize: '1.424rem',
                color: 'black.black50',
              }}
            />
            <Typography variant="body1" color="black.black75">
              {JSON.parse(item.industries).map((itemv) => `${itemv.name}, `)}
            </Typography>
          </Box>

          {JSON.parse(item.tags).length > 0 && (
            <Box
              mt={1}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <LabelOutlinedIcon
                sx={{
                  mr: 1,
                  fontSize: '1.424rem',
                  color: 'black.black50',
                }}
              />
              <Typography variant="body1" color="black.black75">
                {JSON.parse(item.tags).map((itemv) => `${itemv.name}, `)}
              </Typography>
            </Box>
          )}
        </Box>
        {/* /Other Info */}

        {/* Actions */}
        <Box
          mt={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              flex: 1,
            }}
          >
            <Box>
              <Typography mt={2} variant="body1" color="black.black50">
                {JSON.parse(item.dimensions).length > 0 &&
                  `${JSON.parse(item.dimensions).length} dimensions / `}
                {`${JSON.parse(item.industries).length} industries / `}
                {`${JSON.parse(item.tags).length} métadonnées`}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
            }}
          >
            <IconButton onClick={handleOpenDialogContent}>
              <VisibilityIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                handleOpen('edit', item)
              }}
            >
              <EditIcon />
            </IconButton>
          </Box>
        </Box>
        {/* /Actions */}
      </WhiteBox>
      <Dialog
        // onClose={handleClose}
        open={dialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h3"
            textAlign="center"
            component="p"
            color="black.black75"
          >
            {item.name}
          </Typography>

          <IconButton onClick={handleCloseDialogContent} sx={{ ml: 'auto' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            {/* Header */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  flex: 1,
                }}
              >
                {item.maturities && JSON.parse(item.maturities).length > 0 && (
                  <Box
                    mt={2}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <InsightsOutlinedIcon
                      sx={{
                        mr: 1,
                        fontSize: '1.424rem',
                        color: 'black.black50',
                      }}
                    />
                    <Typography variant="body1" color="black.black50">
                      {JSON.parse(item.maturities).map(
                        (value) => `${value.name}, `,
                      )}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  py: 0.5,
                  px: 2,
                  border: '1px solid rgba(0,18,27,0.1)',
                }}
              >
                <Typography variant="body1" color="black.black50">
                  {JSON.parse(item.dimensions).length > 0
                    ? 'Spécifique'
                    : 'Globale'}
                </Typography>
              </Box>
            </Box>
            {/* /Header */}

            {/* Name */}
            <Typography mt={2} variant="h5" color="black.black75">
              {item.name}
            </Typography>
            {/* /Name */}

            {/* Content */}
            <Box>
              <Typography
                component="div"
                mt={2}
                pl={4}
                variant="body1"
                color="black.black50"
              >
                {parsedContent}
              </Typography>
              <Box
                mt={3}
                pl={4}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  component="div"
                  variant="body1"
                  color="black.black75"
                >
                  <StrongText component="span" color="mysensPalette.orange">
                    Prérequis:{' '}
                  </StrongText>
                  {item.prerequisite && parse(item.prerequisite)}
                </Typography>
              </Box>
            </Box>
            {/* /Content */}

            {/* Other Info */}
            <Box>
              <Box
                mt={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <BusinessIcon
                  sx={{
                    mr: 1,
                    fontSize: '1.424rem',
                    color: 'black.black50',
                  }}
                />
                <Typography variant="body1" color="black.black75">
                  {JSON.parse(item.industries).map(
                    (itemv) => `${itemv.name}, `,
                  )}
                </Typography>
              </Box>

              {JSON.parse(item.dimensions).length > 0 && (
                <Box
                  mt={1}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <AutoAwesomeMotionOutlinedIcon
                    sx={{
                      mr: 1,
                      fontSize: '1.424rem',
                      color: 'black.black50',
                    }}
                  />
                  <Typography variant="body1" color="black.black75">
                    {JSON.parse(item.dimensions).map(
                      (itemv) => `${itemv.name}, `,
                    )}
                  </Typography>
                </Box>
              )}

              {JSON.parse(item.tags).length > 0 && (
                <Box
                  mt={1}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <LabelOutlinedIcon
                    sx={{
                      mr: 1,
                      fontSize: '1.424rem',
                      color: 'black.black50',
                    }}
                  />
                  <Typography variant="body1" color="black.black75">
                    {JSON.parse(item.tags).map((itemv) => `${itemv.name}, `)}
                  </Typography>
                </Box>
              )}
            </Box>
            {/* /Other Info */}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

RecommendationCard.propTypes = {
  item: PropTypes.object,
  handleOpen: PropTypes.func,
}
