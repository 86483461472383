import { PropTypes } from 'prop-types'
import { useState } from 'react'
import { TextField, Autocomplete, Checkbox } from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'

export default function MultiSelect({
  items,
  defaultValue,
  label,
  selectAllLabel,
  onChange,
}) {
  const [selectedOptions, setSelectedOptions] = useState(defaultValue)
  const allSelected = items.length === selectedOptions.length

  const handleToggleOption = (selectedOps) => setSelectedOptions(selectedOps)
  const handleClearOptions = () => setSelectedOptions([])

  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedOptions(items)
    } else {
      handleClearOptions()
    }
  }

  const handleToggleSelectAll = () => {
    // handleSelectAll && handleSelectAll(!allSelected)
    handleSelectAll(!allSelected)
  }
  // eslint-disable-consistent-return
  const handleChange = (event, selectedOps, reason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (selectedOps.find((option) => option.name === 'Sélectionner Tous')) {
        handleToggleSelectAll()
        const result = items.filter((el) => el.name !== 'Sélectionner Tous')
        return onChange(result)
      }
      handleToggleOption(selectedOps)
      return onChange(selectedOps)
    }
    if (reason === 'clear') {
      return handleClearOptions()
    }
    return onChange(selectedOps)
  }

  const filter = createFilterOptions()

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      options={items}
      value={selectedOptions}
      getOptionLabel={(option) => option.name}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)
        return [{ id: 0, name: selectAllLabel }, ...filtered]
      }}
      renderOption={(props, option, { selected }) => {
        // To control the state of 'select-all' checkbox
        const selectAllProps =
          option.name === 'Sélectionner Tous' ? { checked: allSelected } : {}
        return (
          <li {...props}>
            <Checkbox checked={selected} {...selectAllProps} />
            {option.name}
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={label} />
      )}
      onChange={handleChange}
    />
  )
}

MultiSelect.propTypes = {
  items: PropTypes.array,
  defaultValue: PropTypes.array,
  label: PropTypes.string,
  selectAllLabel: PropTypes.string,
  onChange: PropTypes.func,
}
