import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Grid, Typography } from '@mui/material'
import LastPageIcon from '@mui/icons-material/LastPage'
import Chart from 'react-apexcharts'
import WhiteBox from '../../../components/WhiteBox'
import { maturityLevelName } from '../../../utils/mappers'
import { useMaturities } from '../../maturity/MaturityProvider'
import { useAuth } from '../../auth/AuthProvider'
import { maturityIndexColor } from '../../../utils/utilfc'

// const scoreLine = {
//   options: {
//     xaxis: {
//       categories: [
//         'Jan',
//         'Feb',
//         'Mar',
//         'Apr',
//         'May',
//         'Jun',
//         'Jul',
//         'Aug',
//         'Sep',
//       ],
//     },
//     chart: {
//       height: '296px',
//       type: 'line',
//       zoom: {
//         enabled: false,
//       },
//       toolbar: {
//         show: false,
//       },
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     stroke: {
//       curve: 'smooth',
//     },
//     markers: {
//       size: 5,
//     },
//     grid: {
//       row: {
//         colors: ['transparent'], // takes an array which will be repeated on columns
//         opacity: 0.5,
//       },
//     },
//     colors: ['#00ACE0'],
//   },
//   series: [
//     {
//       name: 'Score Moyen',
//       data: [10, 41, 35, 51, 49, 62, 69, 91, 95],
//     },
//   ],
// }

function Digitalisé() {
  return (
    <Box>
      &apos;
      <Typography>
        Le niveau de digitalisation de l&apos;entreprise désigne le processus de
        transformation des informations, des processus et des systèmes sous
        forme numérique. Elle implique l'utilisation de technologies numériques
        pour rationaliser et automatiser divers processus commerciaux et
        l'adoption de technologies numériques telles que l'informatique en
        nuage, l'analyse des données et les outils d'automatisation.
      </Typography>
      <Typography mt={2}>
        Au stade de la digitalisation, votre entreprise est susceptible de se
        concentrer sur l&apos;établissement de l&apos;infrastructure et des
        processus nécessaires pour soutenir la mise en œuvre des technologies
        numériques. Il peut s&apos;agir d&apos;investir dans des systèmes de
        stockage et de gestion des données, ainsi que de développer son
        infrastructure et ses capacités informatiques.
      </Typography>
      <Typography mt={2}>
        L&apos;étape de la digitalisation est une base importante pour des
        niveaux plus avancés d&apos;adoption de l&apos;IA. Les entreprises qui
        ont une base solide en matière de numérisation sont mieux placées pour
        exploiter efficacement l&apos;IA et d'autres technologies numériques
        afin d&apos;améliorer leurs opérations et de générer de la valeur
        commerciale.
      </Typography>
    </Box>
  )
}

function Sensibilisé() {
  return (
    <Box>
      <Typography>
        Le niveau de la sensibilisation signifie que l &apos;entreprise commence
        à prendre conscience du potentiel de l'IA et qu&apos;elle explore les
        moyens de l'utiliser pour améliorer ses opérations, mais n'a pas encore
        réalisé d'investissements ou d'engagements significatifs dans ce
        domaine.
      </Typography>
      <Typography mt={2}>
        À ce stade, l'entreprise peut avoir une compréhension générale de ce
        qu'est l'IA et de la manière dont elle pourrait être utilisée pour
        générer de la valeur, mais elle peut ne pas avoir une vision claire de
        la manière dont elle utilisera l'IA dans la pratique. L'entreprise peut
        être en train d'évaluer les avantages et les risques potentiels de l'IA,
        et réfléchir aux mesures à prendre pour lancer des initiatives d'IA.
      </Typography>
      <Typography mt={2}>
        Les entreprises à ce stade peuvent chercher à développer une expertise
        et des capacités internes ou à rechercher des conseils externes pour
        progresser dans l'échelle de maturité.
      </Typography>
      <Typography mt={2}>
        Le stade de la sensibilisation à l'IA est un point de départ important
        pour les entreprises qui cherchent à adopter l'IA. C'est le moment pour
        les entreprises de se renseigner sur l'IA et d'évaluer leur état de
        préparation à la mise en œuvre. Il est important pour les entreprises à
        ce stade d'examiner attentivement leurs buts et objectifs en matière
        d'IA et d'élaborer un plan clair pour aller de l'avant.
      </Typography>
    </Box>
  )
}

function Débutant() {
  return (
    <Box>
      <Typography>
        Au niveau débutant, l'entreprise commence tout juste à explorer le
        potentiel de l'IA, et n'a pas encore développé une stratégie ou un plan
        complet sur la façon dont elle utilisera l'IA pour générer de la valeur.
      </Typography>
      <Typography mt={2}>
        À ce stade, l'entreprise a pris la décision d'adopter l'IA et a commencé
        à prendre les mesures nécessaires pour mettre en œuvre des solutions
        d'IA. Elle expérimente quelques projets pilotes d'IA à petite échelle,
        mais ceux-ci ne sont pas encore totalement intégrés à ses opérations.
        L'entreprise peut être en train de se familiariser avec les différents
        types de technologies et d'approches d'IA disponibles, et ne pas encore
        avoir une idée claire de la manière dont elle va hiérarchiser et allouer
        les ressources à ses initiatives d'IA. Au fur et à mesure que
        l'entreprise acquiert de l'expérience et de l'expertise en matière d'IA,
        elle progressera probablement dans l'échelle de maturité et deviendra
        plus stratégique dans son utilisation de l'IA.
      </Typography>
      <Typography mt={2}>
        L’entreprise se concentre sur le développement de l'expertise et des
        capacités internes en matière d'IA, ainsi que sur le développement de
        processus et d'infrastructures pour soutenir la mise en œuvre de
        solutions d'IA. Elle peut également expérimenter différentes
        technologies et applications d'IA pour comprendre comment elles peuvent
        être utilisées pour améliorer ses opérations.
      </Typography>
      <Typography mt={2}>
        Le stade de débutant en IA est une étape importante dans le cheminement
        vers des niveaux plus avancés d'adoption de l'IA. C'est le moment pour
        les entreprises de commencer à construire leur base d'IA et de
        développer l'expertise et l'infrastructure nécessaires pour soutenir des
        initiatives d'IA plus avancées. Il est important pour les entreprises à
        ce stade de planifier soigneusement leurs initiatives d'IA et de fixer
        des buts et des objectifs clairs afin de maximiser leurs chances de
        réussite.
      </Typography>
    </Box>
  )
}

function Expérimenté() {
  return (
    <Box>
      <Typography>
        Au niveau expérimentateur, l’entreprise explore et teste activement
        différentes technologies et applications d'IA pour voir comment elles
        peuvent être utilisées pour améliorer ses opérations, mais ne les a pas
        encore pleinement intégrées dans toutes ses opérations et elle ne s'est
        pas encore pleinement engagée dans une stratégie d'IA globale..
      </Typography>
      <Typography mt={2}>
        L’entreprise commence tout juste à s'immerger dans le monde de l'IA et
        possède quelques capacités de base en matière d'IA, mais qui n'a pas
        encore établi de base solide pour des initiatives d'IA plus avancées.
      </Typography>
      <Typography mt={2}>
        Ce stade est généralement caractérisé par le déploiement de projets
        pilotes ou d'implémentations limitées de technologies d'IA, ainsi que
        par la mise en place d'un comité de pilotage de l'IA ou d'une autre
        structure de gouvernance pour superviser les initiatives d'IA.
      </Typography>
      <Typography mt={2}>
        Les expérimentateurs de l'IA sont susceptibles d'être à un stade précoce
        de leur parcours de maturité de l'IA, et peuvent avoir besoin d'investir
        dans des ressources et une expertise supplémentaires afin d'atteindre
        des niveaux plus avancés d'adoption de l'IA. Il est important pour ces
        entreprises d'évaluer soigneusement leur état de préparation à l'IA,
        leurs initiatives d'IA, de fixer des buts et des objectifs clairs afin
        de maximiser leurs chances de réussite et de planifier soigneusement
        leurs initiatives d'IA afin de maximiser leurs chances de succès.
      </Typography>
    </Box>
  )
}

function Praticien() {
  return (
    <Box>
      <Typography>
        Le niveau Praticien est utilisé pour décrire une entreprise qui a fait
        des progrès significatifs dans l'intégration de l'IA dans ses opérations
        et ses processus décisionnels. Ces entreprises ont établi une base
        solide pour l'IA et ont développé l'infrastructure, les processus et
        l'expertise nécessaires pour utiliser efficacement l'IA afin d'améliorer
        leurs résultats commerciaux.
      </Typography>
      <Typography mt={2}>
        Les praticiens de l'IA sont susceptibles d'avoir des niveaux avancés de
        numérisation et d'infrastructure de données, ainsi qu'un fort soutien du
        leadership aux initiatives d'IA. Ils peuvent avoir mis en œuvre une
        gamme de solutions d'IA dans différentes fonctions commerciales et sont
        en mesure de démontrer la valeur et l'impact de ces solutions sur leurs
        opérations.
      </Typography>
      <Typography mt={2}>
        Les praticiens de l'IA sont bien placés pour continuer à faire
        progresser leurs capacités d'IA et pour tirer parti des nouvelles
        technologies et applications d'IA à mesure qu'elles apparaissent. Il est
        important pour ces entreprises de continuer à investir dans leur
        infrastructure et leur expertise en matière d'IA afin de conserver leur
        avantage concurrentiel et de rester en tête de la courbe.
      </Typography>
    </Box>
  )
}

function Opérationnel() {
  return (
    <Box>
      <Typography>
        Le niveau opérationnel est un indicateur de la progression d'une
        entreprise dans l'adoption et l'exploitation de l'IA pour améliorer ses
        opérations. Les entreprises créent et déploient des solutions d'IA
        personnalisées.
      </Typography>
      <Typography mt={2}>
        A ce stade de maturité l’entreprise a pleinement intégré l'IA dans ses
        opérations et l'utilise pour générer de la valeur commerciale au
        quotidien. Ces entreprises sont en mesure de démontrer l'impact et la
        valeur de leurs initiatives d'IA dans différentes fonctions
        commerciales.
      </Typography>
      <Typography mt={2}>
        Dans le contexte de l'évaluation de la maturité de l'IA, les entreprises
        opérationnelles ont établi une base solide pour l'IA et ont développé
        les processus, l'infrastructure et l'expertise nécessaires pour utiliser
        efficacement l'IA afin d'améliorer leurs résultats commerciaux.
      </Typography>
      <Typography mt={2}>
        Les entreprises opérationnelles en matière d'IA sont bien placées pour
        continuer à exploiter l'IA afin de générer de la valeur commerciale et
        pour tirer parti des nouvelles technologies et applications d'IA à
        mesure qu'elles apparaissent. Il est important pour ces entreprises de
        continuer à investir dans leur infrastructure et leur expertise en
        matière d'IA afin de conserver leur avantage concurrentiel et de rester
        compétitives.
      </Typography>
    </Box>
  )
}

function Innovant() {
  return (
    <Box>
      <Typography>
        L'innovateur en IA est un terme utilisé pour décrire les entreprises qui
        sont à l'avant-garde de l'adoption de l'IA et qui montrent la voie en
        termes d'utilisations innovantes et avancées de l'IA. Ces entreprises
        ont pleinement intégré l'IA dans leurs opérations et leurs processus
        décisionnels et se sont imposées comme des leaders d'opinion dans ce
        domaine.
      </Typography>
      <Typography mt={2}>
        Les innovateurs en IA sont susceptibles d'avoir des niveaux avancés de
        numérisation, d'infrastructure de données et de soutien du leadership
        pour les initiatives d'IA. Ils sont susceptibles d'utiliser l'IA pour
        conduire la transformation de l'entreprise et créer de nouveaux produits
        et services qui les différencient de leurs concurrents.
      </Typography>
      <Typography mt={2}>
        Les innovateurs en IA sont constamment à la recherche de moyens nouveaux
        et innovants pour exploiter l'IA afin de créer de la valeur pour
        l'entreprise et de garder une longueur d'avance. Il est probable qu'ils
        investissent massivement dans la recherche et le développement de l'IA,
        et qu'ils aient instauré une culture d'apprentissage et
        d'expérimentation continue. Il est important pour ces entreprises de
        continuer à repousser les limites de l'IA et de rester à la pointe de
        l'innovation afin de conserver leur avantage concurrentiel.
      </Typography>
    </Box>
  )
}

function Leader() {
  return (
    <Box>
      <Typography>
        A ce niveau de maturité, les entreprises leaders montrent la voie dans
        l'adoption et l'utilisation de l'IA et façonnent la direction de
        l'industrie de l'IA dans son ensemble. Ces entreprises ont atteint un
        niveau élevé de maturité en matière d'IA et sont en mesure de démontrer
        l'impact et la valeur de leurs initiatives d'IA dans différentes
        fonctions commerciales.
      </Typography>
      <Typography mt={2}>
        Les leaders sont susceptibles d'avoir des niveaux avancés de
        numérisation, d'infrastructure de données et de soutien du leadership
        pour les initiatives d'IA. Ils ont établi une base solide pour l'IA et
        ont développé les processus, l'infrastructure et l'expertise nécessaires
        pour utiliser efficacement l'IA afin d'améliorer leurs résultats
        commerciaux.
      </Typography>
      <Typography mt={2}>
        Les leaders en IA sont à l'avant-garde de l'industrie de l'IA et
        repoussent constamment les limites de ce qui est possible avec l'IA. Ils
        sont susceptibles d'investir massivement dans la recherche et le
        développement de l'IA et ont établi une culture d'apprentissage et
        d'expérimentation continue. Il est important que ces entreprises
        continuent à ouvrir la voie de l'innovation en matière d'IA afin de
        conserver leur avantage concurrentiel et de façonner l'orientation de
        l'industrie dans son ensemble.
      </Typography>
    </Box>
  )
}

const maturityText = (maturity) => {
  switch (true) {
    case maturity === 'Digitalisé':
      return <Digitalisé />
    case maturity === 'Sensibilisé':
      return <Sensibilisé />
    case maturity === 'Débutant':
      return <Débutant />
    case maturity === 'Expérimenté':
      return <Expérimenté />
    case maturity === 'Praticien':
      return <Praticien />
    case maturity === 'Opérationnel':
      return <Opérationnel />
    case maturity === 'Innovant':
      return <Innovant />
    case maturity === 'Leader':
      return <Leader />
    default:
      return <Digitalisé />
  }
}

export default function ProfileBox({ dimensionsScore, dimensions }) {
  const { maturities } = useMaturities()
  const navigate = useNavigate()
  const { account } = useAuth()
  const handleNavigate = () => {
    navigate('/dimensions')
  }
  if (
    dimensionsScore.length === 0 ||
    dimensionsScore.length < dimensions.length
  ) {
    return (
      <WhiteBox title="Maturité numérique">
        {account.role === 'client' ? (
          <Typography
            color="black.black75"
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
              '&:hover': {
                color: 'mysensPalette.blue',
              },
            }}
            onClick={handleNavigate}
          >
            Répondre à toutes les dimension pour voire les résultats
          </Typography>
        ) : (
          'Répondre à toutes les dimension pour voire les résultats'
        )}
      </WhiteBox>
    )
  }

  const ScoreSemiCircular = {
    options: {
      chart: {
        height: '120px',
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          hollow: {
            size: '64%',
            backgroundColor: 'transparent',
          },
          track: {
            background: '#f1f1f1',
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: '1.802rem',
              color: maturityIndexColor(
                parseFloat(
                  (
                    (dimensionsScore.reduce(
                      (acc, current) => acc + current.last_score,
                      0,
                    ) *
                      100) /
                    dimensionsScore.length
                  ).toFixed(2),
                ) || 0,
              ),
              // color: '#00ACE0',
              fontWeight: '600',
            },
          },
        },
      },

      fill: {
        // colors: ['#00ACE0'],
        colors: [
          maturityIndexColor(
            parseFloat(
              (
                (dimensionsScore.reduce(
                  (acc, current) => acc + current.last_score,
                  0,
                ) *
                  100) /
                dimensionsScore.length
              ).toFixed(2),
            ) || 0,
          ),
        ],
      },
    },
    series: [
      parseFloat(
        (
          (dimensionsScore.reduce(
            (acc, current) => acc + current.last_score,
            0,
          ) *
            100) /
          dimensionsScore.length
        ).toFixed(2),
      ) || 0,
    ],
  }
  return (
    <WhiteBox title="Maturité numérique">
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box>
            <Chart
              options={ScoreSemiCircular.options}
              series={ScoreSemiCircular.series}
              type="radialBar"
            />
            <Typography
              component="div"
              variant="body2"
              sx={{
                mt: '-1.5rem',
                textAlign: 'center',
                color: 'black.black75',
              }}
            >
              Votre Profile est :&#8201;
              <Typography
                component="span"
                sx={{
                  fontWeight: '600',
                  color: maturityIndexColor(
                    parseFloat(
                      (
                        (dimensionsScore.reduce(
                          (acc, current) => acc + current.last_score,
                          0,
                        ) *
                          100) /
                        dimensionsScore.length
                      ).toFixed(2),
                    ) || 0,
                  ),
                }}
              >
                {maturities &&
                  maturities.length > 0 &&
                  maturityLevelName(
                    maturities,
                    (dimensionsScore.reduce(
                      (acc, current) => acc + current.last_score,
                      0,
                    ) *
                      100) /
                      dimensionsScore.length,
                  )}
              </Typography>
            </Typography>
            <Box mt={4}>
              <Typography
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'black.black50',
                }}
              >
                <LastPageIcon
                  sx={{
                    color: 'mysensPalette.orange',
                  }}
                />
                Score moyen des concurrents:&#8201;
                <Typography
                  component="span"
                  sx={{
                    fontWeight: '600',
                    color: 'mysensPalette.orange',
                  }}
                >
                  24
                </Typography>
              </Typography>
              <Typography
                component="div"
                sx={{
                  mt: 1,
                  display: 'flex',
                  alignItems: 'center',
                  color: 'black.black50',
                }}
              >
                <LastPageIcon
                  sx={{
                    color: 'mysensPalette.orange',
                  }}
                />
                Entreprises du même niveau:&#8201;
                <Typography
                  component="span"
                  sx={{
                    fontWeight: '600',
                    color: 'mysensPalette.orange',
                  }}
                >
                  22%
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {maturities &&
            maturities.length &&
            maturityText(
              maturityLevelName(
                maturities,
                (dimensionsScore.reduce(
                  (acc, current) => acc + current.last_score,
                  0,
                ) *
                  100) /
                  dimensionsScore.length,
              ),
            )}
        </Grid>
      </Grid>
    </WhiteBox>
  )
}

ProfileBox.propTypes = {
  dimensionsScore: PropTypes.array,
  dimensions: PropTypes.array,
}
