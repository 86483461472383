import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Typography, TextField, Grid, Box } from '@mui/material'

import { useForm } from 'react-hook-form'
import { mustBeCode } from '../../../utils/form-validators'

export default function ValidateAccount({ email, children, submit }) {
  const location = useLocation()
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (new URLSearchParams(location.search).get('code') && email) {
      const code = new URLSearchParams(location.search).get('code')
      submit({
        email,
        code,
      })
    }
  }, [email, location.search, submit])

  return (
    <Box
      sx={{
        m: 1,
        py: 1,
        px: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        component="h1"
        variant="h5"
        sx={{ mb: 3, color: 'black.black75' }}
      >
        Insérez le code reçu par courriel pour vérifier votre compte
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit((data) => submit(data))}
        noValidate
        id="verify-form"
        sx={{
          textAlign: 'center',
          width: '100%',
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12}>
            <TextField
              defaultValue={
                new URLSearchParams(location.search).get('code') || ''
              }
              {...register('code', {
                required: 'Veuillez insérer votre code',
                validate: {
                  code: (value) => mustBeCode(value),
                },
              })}
              fullWidth
              id="code"
              label="Code"
              name="code"
              type="number"
              autoComplete="current-code"
              helperText={errors.code?.message}
              error={!!errors.code}
            />
          </Grid>
          <Grid item xs={12}>
            <Box>{children[0]}</Box>
          </Grid>
        </Grid>

        {children[1]}
      </Box>
    </Box>
  )
}

ValidateAccount.propTypes = {
  email: PropTypes.string,
  children: PropTypes.node,
  submit: PropTypes.func,
}
