import api from '../../utils/api'

export async function postCompanyGoal(companyId, data) {
  const url = `company/CompanyGoal/${companyId}`
  try {
    const result = await api(url, { data }).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result.data
  } catch (error) {
    throw new Error(error)
  }
}

export async function getCompanyGoal(companyId) {
  const url = `company/CompanyGoal/${companyId}`
  try {
    const result = await api(url)
    return result.data
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}
