import { useCallback, useRef, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import propTypes from 'prop-types'
import 'survey-core/defaultV2.min.css'
import { StylesManager, Model, surveyLocalization } from 'survey-core'
import { Survey } from 'survey-react-ui'
import 'survey-core/survey.i18n'
import './index.css'
import { useAuth } from '../../auth/AuthProvider'
import { useAlert } from '../../alert/AlertProvider'
import { useDimension } from '../../dimension/DimensionProvider'
import { postDimensionScoreHistory } from '../api'
import { dimensionMapper } from '../../../utils/mappers'
import DimensionLayout from './DimensionsLayout'

StylesManager.applyTheme('defaultV2')
surveyLocalization.defaultLocale = 'fr'

const elementTitle = (type) => {
  switch (type) {
    case 'select':
      return 'Veuillez choisir une réponse'
    case 'multiple':
      return 'Veuillez choisir depuis la list'
    case 'date':
      return 'Veuillez introduire une date'
    default:
      return 'Veuillez introduire une réponse'
  }
}
export default function QuestionForm({ steps }) {
  const { showAlert } = useAlert()
  const navigate = useNavigate()
  const location = useLocation()
  const { company } = useAuth()
  const { dimensions, handleNewScore, dimensionsScore } = useDimension()
  // const [previousAnswers, setPreviousAnswers] =

  const scoreDimension = useRef({
    // compagny_id: account.id,
    dimensionScore: 0,
    // answers: [],
    sum_questions_weights: steps.reduce(
      (acc, question) => acc + question.weight,
      0,
    ),
    thematics: steps.reduce(
      (acc, currQuestion) => ({
        ...acc,
        [currQuestion.thematic_id]: {
          weight: 0,
          score: 0,
        },
      }),
      {},
    ),
  })

  const currentDimensionScore = useRef(
    dimensionsScore.find(
      (dimension) =>
        dimension.dimension.name ===
        dimensionMapper[location.pathname.split('/')[2]],
    ),
  )
  const previousAnswers = useRef(
    currentDimensionScore.current
      ? JSON.parse(currentDimensionScore.current.answers)
      : undefined,
  )

  const surveyJson = useRef({
    // title: 'Questions',
    showProgressBar: 'bottom',
    showPreviewBeforeComplete: 'showAnsweredQuestions',
    pages: steps.map((step) => {
      const page = {
        title: step.question,
        elements: [
          {
            name: `${step.id}`,
            title: elementTitle(step.type),
            isRequired: true,
            // default
          },
        ],
      }
      switch (step.type) {
        case 'select':
          page.elements[0].type = 'radiogroup'
          page.elements[0].colCount = 1
          page.elements[0].choices = JSON.parse(step.answers).map(
            (answer) => answer.answer,
          )
          // "colCount": 4,
          // "separateSpecialChoices": true,
          //     "showClearButton": true
          break
        case 'multiple':
          page.elements[0].type = 'checkbox'
          page.elements[0].choices = JSON.parse(step.answers).map(
            (answer) => answer.answer,
          )
          page.elements[0].colCount = 2
          page.elements[0].showSelectAllItem = true
          page.elements[0].showNoneItem = true
          // "description": "If you own cars from multiple brands, please select all of them.",
          // "showNoneItem": true,
          // "showOtherItem": true,
          // "showSelectAllItem": true,
          // "separateSpecialChoices": true
          break
        case 'date':
          page.elements[0].type = 'text'
          page.elements[0].inputType = 'date'
          // "defaultValueExpression": "today()",
          // "minValueExpression": "today()",
          break
        default:
          page.elements[0].type = 'text'
          break
      }
      if (previousAnswers.current) {
        page.elements[0].defaultValue = previousAnswers.current[step.id]
      }
      return page
    }),
  })

  // useRef enables the Model object to persist between state changes
  const survey = useRef(new Model(surveyJson.current)).current
  survey.locale = 'fr'

  const onSubmit = useCallback(
    (sender) => {
      // calcualte the wight of each thematic
      steps.forEach((step) => {
        scoreDimension.current.thematics[step.thematic_id].weight += step.weight
      })

      // calculate the score of each group
      steps.forEach((step) => {
        // calculate the score of each question
        // if the question type is select
        let questionScore = 0
        if (step.type === 'select') {
          // get the score of the answer
          const answer = JSON.parse(step.answers).find(
            (answerElement) => answerElement.answer === sender.data[step.id],
          )
          questionScore = (answer.score * 4 * 5) / 100
        } else if (step.type === 'multiple') {
          // if the question type is multiple choice
          // calculate score of question
          questionScore =
            sender.data[step.id].length / JSON.parse(step.answers).length
        }
        // calcuate the score of the thematic
        scoreDimension.current.thematics[step.thematic_id].score +=
          (questionScore * step.weight) /
          scoreDimension.current.thematics[step.thematic_id].weight
      })
      // calcualte the score of dimension
      const dimensionScore =
        Object.keys(scoreDimension.current.thematics)
          .map(
            (key) =>
              scoreDimension.current.thematics[key].score *
              scoreDimension.current.thematics[key].weight,
          )
          .reduce((acc, currThematic) => acc + currThematic, 0) /
        scoreDimension.current.sum_questions_weights

      scoreDimension.current.dimensionScore = parseFloat(
        dimensionScore.toFixed(2),
      )

      const currentDimension =
        dimensions[
          dimensions.findIndex(
            (dimension) =>
              dimension.name ===
              dimensionMapper[location.pathname.split('/')[2]],
          )
        ]
      const dataHistory = {
        dimension_id: currentDimension.id,
        company_id: company.id,
        score: scoreDimension.current.dimensionScore,
        date: new Date().toISOString(),
        answers: JSON.stringify(sender.data),
        // answers: JSON.stringify(scoreDimension.current.answers),
      }

      postDimensionScoreHistory(dataHistory)
        .then((res) => {
          navigate(`/myscoreai`)
          handleNewScore({
            ...res,
            dimension: currentDimension,
          })
        })
        .catch(() => {
          showAlert('une erreur est survenue, veuilliez réassayer', 'error')
        })
    },
    [
      company.id,
      dimensions,
      handleNewScore,
      location.pathname,
      navigate,
      showAlert,
      steps,
    ],
  )

  survey.onComplete.add(onSubmit)
  useEffect(() => {
    if (previousAnswers.current && survey) {
      survey.currentPage = survey.pages[steps.length - 1]
    }
  }, [steps.length, survey])

  return (
    <DimensionLayout>
      <Survey model={survey} id="surveyContainer" />
    </DimensionLayout>
  )
}

QuestionForm.propTypes = {
  steps: propTypes.array.isRequired,
}
