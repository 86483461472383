import { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  CircularProgress,
  TextField,
  Typography,
  // InputAdornment,
  // IconButton,
  Button,
  Autocomplete,
  Chip,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { updateClientPrivileges } from '../api'
import { useAlert } from '../../alert/AlertProvider'
import { PrivilegesMapper } from '../../../utils/mappers'

export default function ClientPrivileges({
  handleClose,
  clientDetail,
  setClients,
  setRows,
}) {
  const { showAlert } = useAlert()
  const [loading, setLoading] = useState(false)
  const fixedOptions = ['Evaluation']

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
    setLoading(true)
    const privileges = JSON.stringify(
      data.privileges.map((prev) => PrivilegesMapper[prev]),
    )
    updateClientPrivileges(clientDetail.id, privileges)
      .then(() => {
        setLoading(false)
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === clientDetail?.id
              ? {
                  ...clientDetail,
                  privileges,
                }
              : row,
          ),
        )

        setClients((prevRows) =>
          prevRows.map((row) =>
            row.id === clientDetail?.id
              ? {
                  ...clientDetail,
                  privileges,
                }
              : row,
          ),
        )
        showAlert(
          `Les privilèges du client ${clientDetail['Nom Complet']} ont été modifié avec success`,
          'success',
        )
        handleClose()
      })
      .catch(() => {
        showAlert("Une erreur s'est produite", 'error')
        setLoading(false)
      })
  }

  return (
    <Box
      sx={{
        boxShadow: 24,
        p: 4,
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <>
        <Typography
          variant="h5"
          textAlign="center"
          component="h1"
          gutterBottom
          sx={{
            color: 'black.black75',
          }}
        >
          Modifier les privilèges du client{' '}
          <Typography
            component="span"
            sx={{
              fontWeight: 'bold',
            }}
          >
            {clientDetail['Nom Complet']}
          </Typography>
        </Typography>

        <Box mt={2}>
          <Controller
            control={control}
            name="privileges"
            rules={{
              required: 'Veuillez choisir une réponse',
            }}
            render={({ field: { onChange } }) => (
              <Autocomplete
                defaultValue={
                  clientDetail?.privileges
                    ? JSON.parse(clientDetail.privileges)
                    : [...fixedOptions]
                }
                multiple
                onChange={(event, newValue) => {
                  onChange([
                    ...fixedOptions,
                    ...newValue.filter(
                      (option) => fixedOptions.indexOf(option) === -1,
                    ),
                  ])
                }}
                options={['Evaluation', 'Recommendations', "Cas d'usages"]}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      disabled={fixedOptions.indexOf(option) !== -1}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Privilèges"
                    placeholder="Privilèges"
                    helperText={errors.privileges?.message}
                    error={!!errors.privileges}
                  />
                )}
              />
            )}
          />
        </Box>

        <Button
          startIcon={
            loading ? (
              <CircularProgress size={20} />
            ) : (
              <AddIcon color="white" size="small" />
            )
          }
          type="submit"
          fullWidth
          disabled={loading}
          variant="mysensContained"
          sx={{ mt: 2 }}
        >
          Enregistrer
        </Button>
      </>
    </Box>
  )
}
ClientPrivileges.propTypes = {
  handleClose: PropTypes.func,
  setRows: PropTypes.func,
  setClients: PropTypes.func,
  clientDetail: PropTypes.object,
}
