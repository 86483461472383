import * as React from 'react'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Alert from '@mui/material/Alert'
import PropTypes from 'prop-types'

const AlertContext = React.createContext()
AlertContext.displayName = 'AlertContext'

export default function AlertProvider({ children }) {
  const [state, setState] = React.useState({
    open: false,
    autoHideDuration: 5000,
    message: '',
    severity: 'error',
    horizontal: 'right',
    vertical: 'bottom',
    variant: 'filled',
  })

  // close the alert
  const closeAlert = React.useCallback(() => {
    setState((prevState) => ({ ...prevState, open: false }))
  }, [])

  // open the alert
  const showAlert = React.useCallback(
    (
      message = '',
      severity = 'error',
      {
        autoHideDuration = 6000,
        vertical = 'bottom',
        horizontal = 'left',
        variant = 'filled',
      } = {},
    ) => {
      setState((prev) => ({
        ...prev,
        message,
        severity,
        autoHideDuration,
        vertical,
        horizontal,
        variant,
        open: true,
      }))
    },
    [],
  )

  const action = (
    <>
      <Button color="secondary" size="small" onClick={closeAlert}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeAlert}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  )

  const {
    open,
    autoHideDuration,
    message,
    severity,
    horizontal,
    vertical,
    variant,
  } = state

  const value = React.useMemo(
    () => ({ showAlert, closeAlert }),
    [showAlert, closeAlert],
  )

  return (
    <AlertContext.Provider value={value}>
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={closeAlert}
        message={message}
        action={action}
        anchorOrigin={{ horizontal, vertical }}
      >
        <Alert
          variant={variant}
          severity={severity === 'default' ? 'info' : severity}
          onClose={closeAlert}
          sx={{
            width: '100%',
            ...(severity === 'success' && { backgroundColor: 'primary.main' }),
            ...(severity === 'default' && {
              backgroundColor: 'rgb(50,50,50)',
            }),
          }}
        >
          {message}
        </Alert>
      </Snackbar>
      {children}
    </AlertContext.Provider>
  )
}

AlertProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function useAlert() {
  const context = React.useContext(AlertContext)
  if (context === undefined) {
    throw new Error('useAlert must be used within a AlertProvider')
  }
  return context
}
