import { useNavigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  List,
  ListItemText,
  Typography,
  ListItemButton,
} from '@mui/material'

import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined'
import TrackChangesOutlinedIcon from '@mui/icons-material/TrackChangesOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { useAuth } from '../modules/auth/AuthProvider'

function Items() {
  const navigate = useNavigate()
  const location = useLocation()
  const { account } = useAuth()

  const itemsClient = [
    {
      href: '/profile/personalInformation',
      title: 'Information Personelle',
      icon: (
        <PersonOutlineIcon
          sx={{
            width: '1.25rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
    {
      href: '/profile/companyProfile',
      title: "Profil d'entreprise",
      icon: (
        <ApartmentOutlinedIcon
          sx={{
            width: '1.25rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
    {
      href: '/profile/companyGoal',
      title: "Objectifs d'entreprise",
      icon: (
        <TrackChangesOutlinedIcon
          sx={{
            width: '1.25rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
    {
      href: '/profile/password',
      title: 'Mot de passe',
      icon: (
        <LockOutlinedIcon
          sx={{
            width: '1.25rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
    {
      href: '/profile/deleteAccount',
      title: 'Supprimer Compte',
      icon: (
        <DeleteOutlinedIcon
          sx={{
            width: '1.25rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
  ]

  const itemsPowerUser = [
    {
      href: '/profile/personalInformation',
      title: 'Information Personelle',
      icon: (
        <PersonOutlineIcon
          sx={{
            width: '1.25rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
    {
      href: '/profile/companyProfile',
      title: "Profil d'entreprise",
      icon: (
        <ApartmentOutlinedIcon
          sx={{
            width: '1.25rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
    {
      href: '/profile/password',
      title: 'Mot de passe',
      icon: (
        <LockOutlinedIcon
          sx={{
            width: '1.25rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
    {
      href: '/profile/deleteAccount',
      title: 'Supprimer Compte',
      icon: (
        <DeleteOutlinedIcon
          sx={{
            width: '1.25rem',
            color: 'inherit',
            mr: 2,
          }}
        />
      ),
    },
  ]

  return (
    <List>
      {account.role === 'client' &&
        itemsClient.map((item) => (
          <Box key={item.title} mb={1}>
            <ListItemButton
              selected={item.href === location.pathname}
              onClick={() => {
                navigate(item.href)
              }}
              sx={{
                color: 'black.black50',
                '&:before': {
                  left: '0px',
                  width: item.href === location.pathname ? '4px' : '0px',
                  content: '""',
                  height: '100%',
                  position: 'absolute',
                  transition: 'all 0.3s ease 0s',
                  backgroundColor: 'mysensPalette.lightBlue',
                },
              }}
            >
              {item.icon}

              <ListItemText>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'black.black75',
                  }}
                >
                  {item.title}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </Box>
        ))}
      {(account.role === 'admin' || account.role === 'poweruser') &&
        itemsPowerUser.map((item) => (
          <Box key={item.title} mb={1}>
            <ListItemButton
              selected={item.href === location.pathname}
              onClick={() => {
                navigate(item.href)
              }}
              sx={{
                color: 'black.black50',
                '&:before': {
                  left: '0px',
                  width: item.href === location.pathname ? '4px' : '0px',
                  content: '""',
                  height: '100%',
                  position: 'absolute',
                  transition: 'all 0.3s ease 0s',
                  backgroundColor: 'mysensPalette.lightBlue',
                },
              }}
            >
              {item.icon}

              <ListItemText>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'black.black75',
                  }}
                >
                  {item.title}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </Box>
        ))}
    </List>
  )
}

export default function ProfileLayout({ children }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Box
          sx={{
            backgroundColor: 'white.white100',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            boxShadow:
              'rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px',
            py: 1,
            borderRadius: '8px',
          }}
        >
          <Items />
        </Box>
      </Grid>
      <Grid item xs={9}>
        {children}
      </Grid>
    </Grid>
  )
}

ProfileLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
