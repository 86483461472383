function getRandomUpperCase() {
  return String.fromCharCode(Math.floor(Math.random() * 26) + 65)
}

function getRandomLowerCase() {
  return String.fromCharCode(Math.floor(Math.random() * 26) + 97)
}

function getRandomNumber() {
  return String.fromCharCode(Math.floor(Math.random() * 10) + 48)
}

function getRandomSymbol() {
  const symbol = '!@#$%^&*(){}[]=<>/,.|~?'
  //   const symbol = '!@$&'
  return symbol[Math.floor(Math.random() * symbol.length)]
}

const randomFunc = [
  getRandomUpperCase,
  getRandomLowerCase,
  getRandomNumber,
  getRandomSymbol,
]

function getRandomFunc() {
  return randomFunc[Math.floor(Math.random() * Object.keys(randomFunc).length)]
}

export function generatePassword() {
  let password = ''
  const passwordLength = Math.random() * (32 - 8) + 8

  let i = 0
  while (i <= passwordLength) {
    password += getRandomFunc()()
    i += 1
  }

  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
  if (!password.match(regex)) {
    password = generatePassword()
  }
  return password
}
