import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { useState } from 'react'
import {
  Box,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { StrongText } from '../../utils/customElements'

export default function RecommendationCard({ recommendation }) {
  const [dialog, setDialog] = useState(false)
  const { name, content, prerequisite } = recommendation

  const parsedContent = content && parse(content)
  const div = document.createElement('div')
  div.innerHTML = content
  const shortDescription = div.innerText
    .replace(/<\/?[^>]+(>|$)/g, '')
    .substring(0, 133)

  const handleOpen = () => {
    setDialog(true)
  }
  const handleClose = () => {
    setDialog(false)
  }

  return (
    <>
      <Box
        sx={{
          transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          boxShadow:
            'rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px',
          borderRadius: '8px',
        }}
      >
        {/* Header Content */}
        <Box py={3} px={4}>
          <Typography component="div" variant="h4" color="black.black75">
            {name}
            <Typography mt={2} variant="body1" color="black.black50">
              {`${shortDescription} ...`}
            </Typography>
          </Typography>
        </Box>
        {/* /Header Content */}

        {/* Bottom */}
        <Divider />
        <Box
          py={2}
          px={4}
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              flex: 1,
            }}
          />
          <Typography
            onClick={handleOpen}
            color="black.black75"
            sx={{
              cursor: 'pointer',
              border: '1px solid #e1e5eb',
              fontSize: '1rem',
              p: '.4286rem .875rem',
              borderRadius: '.25rem',
              transition: 'all .25s cubic-bezier(.27,.01,.38,1.06)',
              '&:hover': {
                boxShadow:
                  'rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px',
              },
            }}
          >
            Lire plus ..
          </Typography>
        </Box>
        {/* /Bottom */}
      </Box>

      <Dialog
        // onClose={handleClose}
        open={dialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h3"
            textAlign="center"
            component="p"
            color="black.black75"
          >
            {name}
          </Typography>

          <IconButton onClick={handleClose} sx={{ ml: 'auto' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            mt={2}
            component="div"
            variant="body1"
            color="black.black50"
          >
            {parsedContent}
          </Typography>
          <Typography
            component="div"
            variant="body1"
            color="black.black50"
            sx={{
              mt: 1,
            }}
          >
            <StrongText component="span" color="mysensPalette.orange">
              Prérequis:{' '}
            </StrongText>
            {prerequisite && parse(prerequisite)}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  )
}
RecommendationCard.propTypes = {
  recommendation: PropTypes.object,
}
