import { useState, useEffect, useRef } from 'react'
import {
  Button,
  Dialog,
  Pagination,
  DialogTitle,
  Typography,
  IconButton,
  Grid,
  Box,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import FullPageSpinner from '../../components/FullPageSpinner'
import FullPageErrorFallback from '../../components/FullPageErrorFallback'
import WhiteBox from '../../components/WhiteBox'
import { useAuth } from '../../modules/auth/AuthProvider'
import { escapeRegExp } from '../../utils/utilfc'
import QuickSearchToolbar from '../../components/QuickSearchToolbar'
import { useIndustries } from '../../modules/industry/IndustryProvider'
import EvaluationFilter from '../../modules/evaluation/components/EvaluationFilter'
import EvaluationFrom from '../../modules/evaluation/components/EvaluationForm'
import EvaluationCard from '../../modules/evaluation/components/EvaluationCard'
import { getEvaluations } from '../../modules/evaluation/api'
import EvaluationDetail from './EvaluationDetail'
import EvaluationClients from './EvaluationClients'

export default function Evaluation() {
  const [currentEvaluation, setCurrentEvaluation] = useState(null)
  const { account } = useAuth()
  const { industries } = useIndustries()
  const [evaluations, setEvaluations] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(null)
  const [rows, setRows] = useState([])
  const [searchText, setSearchText] = useState('')
  const [showDetails, setShowDetails] = useState(false)
  const [showClients, setShowClients] = useState(false)
  const [dialog, setDialog] = useState({
    open: false,
    action: 'add',
    data: {},
  })
  const [page, setPage] = useState(1)
  const handlePaginationChange = (event, newValue) => {
    setPage(newValue)
  }

  const filter = useRef({
    industry: null,
    published: null,
  })

  const setFilter = (filterVal) => {
    filter.current = filterVal
  }

  const handleOpen = (action, data) => {
    setDialog({
      action,
      data,
      open: true,
    })
  }
  const handleClose = () => {
    setDialog({
      ...dialog,
      open: false,
    })
  }
  const handleClientsClick = (item) => {
    setShowClients(true)
    setShowDetails(false)
    setCurrentEvaluation(item)
  }

  const handleShowDetailClick = (item) => {
    setShowDetails(true)
    setShowClients(false)
    setCurrentEvaluation(item)
  }

  const handleCloseDetail = () => {
    setShowDetails(false)
  }

  const handleCloseClients = () => {
    setShowClients(false)
  }

  const requestSearch = (searchValue) => {
    setSearchText(searchValue)
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
    const filteredRows = evaluations.filter(
      (row) =>
        searchRegex.test(row.name) || searchRegex.test(row.industry.name),
    )
    setRows(filteredRows)
  }

  useEffect(() => {
    setIsLoading(true)
    setIsError(false)
    getEvaluations(account.id)
      .then((res) => {
        setEvaluations(res)
        setRows(res)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
        setIsError(true)
      })
  }, [account.id])

  if (showClients) {
    return (
      <EvaluationClients
        currentEvaluation={currentEvaluation}
        handleCloseClients={handleCloseClients}
      />
    )
  }
  if (showDetails) {
    return (
      <EvaluationDetail
        currentEvaluation={currentEvaluation}
        handleCloseDetail={handleCloseDetail}
        setEvaluations={setEvaluations}
        setEvaluationsRows={setRows}
        setCurrentEvaluation={setCurrentEvaluation}
      />
    )
  }
  return (
    <>
      <WhiteBox
        title="Evaluations"
        button={
          <Button
            variant="text"
            sx={{
              fontSize: '1.125rem',
              textTransform: 'none',
            }}
            endIcon={<AddIcon />}
            onClick={() => {
              handleOpen('add', {})
            }}
          >
            Ajouter une évaluation
          </Button>
        }
        search={
          <QuickSearchToolbar
            value={searchText}
            onChange={(event) => requestSearch(event.target.value)}
            clearSearch={() => requestSearch('')}
          />
        }
        pagination={
          <Pagination
            count={5}
            page={page}
            onChange={handlePaginationChange}
            shape="rounded"
          />
        }
      >
        {isError && <FullPageErrorFallback />}
        {isLoading && <FullPageSpinner />}
        {!isError && !isLoading && (
          <>
            <Box
              mt={3}
              mb={2}
              sx={{
                px: 3,
              }}
            >
              <EvaluationFilter
                rows={rows}
                setFilter={setFilter}
                industries={industries}
                evaluations={evaluations}
                setRows={setRows}
              />
            </Box>
            <Box
              sx={{
                px: 3,
                mb: 2,
              }}
            >
              <Typography
                variant="body1"
                color="black.black75"
                sx={{
                  textAlign: 'right',
                }}
              >
                ({rows.length}) évaluation
              </Typography>
            </Box>

            <Grid container spacing={2}>
              {rows.length > 0 ? (
                rows.map((item) => (
                  <Grid key={item.id} item xs={12} md={4}>
                    <EvaluationCard
                      item={item}
                      handleOpen={handleOpen}
                      handleClientsClick={handleClientsClick}
                      handleShowDetailClick={handleShowDetailClick}
                    />
                  </Grid>
                ))
              ) : (
                <Box
                  mt={2}
                  sx={{
                    width: '100%',
                  }}
                >
                  <Typography
                    component="div"
                    variant="body1"
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    Pas d'éléments!{' '}
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 'bold',
                        color: 'primary.main',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleOpen('add', {})
                      }}
                    >
                      {' '}
                      insérez un
                    </Typography>
                  </Typography>
                </Box>
              )}
            </Grid>
          </>
        )}
      </WhiteBox>
      <Dialog open={dialog.open} fullWidth maxWidth="md" disableEnforceFocus>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            component="p"
            variant="h5"
            textAlign="center"
            gutterBottom
            sx={{
              pl: 2,
            }}
          >
            {dialog.action === 'add'
              ? 'Ajouter une évaluation'
              : "Editer l'évaluation"}
          </Typography>

          <IconButton onClick={handleClose} sx={{ ml: 'auto' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <EvaluationFrom
          industries={industries}
          action={dialog.action}
          evaluationData={dialog.data}
          handleClose={handleClose}
          setEvaluations={setEvaluations}
          setRows={setRows}
        />
      </Dialog>
    </>
  )
}
