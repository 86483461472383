import api from '../../utils/api'

export async function getScoreDimension(companyId) {
  const url = `dimensions/DimensionScoreAll/${companyId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getScoreDimensionHistory(companyId) {
  const url = `dimensions/DimensionScoreHistoryAll/${companyId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getDimensions() {
  const url = `dimensions/Dimension`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error)
  }
}
