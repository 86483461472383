import { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  Grid,
  Box,
  Button,
  Pagination,
  Typography,
  IconButton,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import UnpublishedIcon from '@mui/icons-material/Unpublished'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import AddIcon from '@mui/icons-material/Add'
import FullPageSpinner from '../../../../components/FullPageSpinner'
import FullPageErrorFallback from '../../../../components/FullPageErrorFallback'
import WhiteBox from '../../../../components/WhiteBox'
import QuestionCard from './questions/QestionCard'

import { escapeRegExp } from '../../../../utils/utilfc'
import QuickSearchToolbar from '../../../../components/QuickSearchToolbar'
import QuestionFilter from '../../../adminQuestion/components/QuestionFilter'
import { postEvaluationDimension, updateEvaluationDimension } from '../../api'
import { useAlert } from '../../../alert/AlertProvider'

export default function EvaluationQuestions({
  thematics,
  questions,
  rows,
  setRows,
  dimensionId,
  currentEvaluation,
  page,
  handlePaginationChange,
  handleOpen,
  isError,
  isLoading,
  setFilter,
  evaluationDimensions,
  setEvaluationDimensions,
  selectedQuestions,
  handleSelectedQuestions,
  handleCloseDetail,
}) {
  const { showAlert } = useAlert()

  const [searchText, setSearchText] = useState('')
  const { handleSubmit, control } = useForm()

  const handleDisableSave = () => {
    if (currentEvaluation.published || selectedQuestions === 0) {
      return true
    }
    return false
  }

  const addEvaluationDimension = (sendData) => {
    postEvaluationDimension(sendData)
      .then((res) => {
        showAlert('La liste sélectionnée est ajoutée avec success', 'success')
        setEvaluationDimensions({
          ...evaluationDimensions,
          [sendData.dimension_id]: {
            ...sendData,
            id: res.id,
            questions: JSON.parse(sendData.questions),
          },
        })
      })
      .catch(() => {
        showAlert('Une erreur est survenue', 'error')
      })
  }

  const editEvaluationDimension = (id, sendData) => {
    updateEvaluationDimension(id, sendData)
      .then((res) => {
        showAlert('La liste sélectionnée est modifiée par success ', 'success')
        setEvaluationDimensions({
          ...evaluationDimensions,
          [sendData.dimension_id]: {
            ...sendData,
            id: res.id,
            questions: JSON.parse(sendData.questions),
          },
        })
      })
      .catch(() => {
        showAlert('Une erreur est survenue', 'error')
      })
  }

  const onSubmit = (data) => {
    const selectedQuests = questions
      .filter((quest) => data[quest.id])
      .map((quest) => quest.id)
    const sendData = {
      dimension_id: dimensionId,
      evaluation_id: currentEvaluation.id,
      questions: JSON.stringify(selectedQuests),
    }

    if (evaluationDimensions[dimensionId]) {
      editEvaluationDimension(evaluationDimensions[dimensionId].id, sendData)
    } else {
      addEvaluationDimension(sendData)
    }
  }

  const requestSearch = (searchValue) => {
    setSearchText(searchValue)
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
    const filteredRows = questions.filter((row) =>
      searchRegex.test(row.question),
    )
    setRows(filteredRows)
  }

  return (
    <WhiteBox
      paddingX={1}
      paddingY={0}
      titlePaddingX={1}
      titlePaddingY={1}
      title={`${currentEvaluation?.name || "Détail d'évaluation"}`}
      startButton={
        <IconButton
          sx={{
            color: 'inherit',
            mr: 0.5,
          }}
          onClick={handleCloseDetail}
        >
          <ArrowBackIcon
            sx={{
              fontSize: '1.266rem',
            }}
          />
        </IconButton>
      }
      endButton={
        currentEvaluation.published ? (
          <CheckCircleIcon
            sx={{
              ml: 0.5,
              color: 'mysensPalette.lightBlue',
              fontSize: '1.266rem',
            }}
          />
        ) : (
          <UnpublishedIcon
            sx={{
              ml: 0.5,
              color: 'mysensPalette.orange',
              fontSize: '1.266rem',
            }}
          />
        )
      }
      button={
        <Button
          variant="text"
          sx={{
            fontSize: '1.035rem',
            textTransform: 'none',
          }}
          endIcon={<AddIcon />}
          onClick={() => {
            handleOpen('add', {})
          }}
        >
          Ajouter une question
        </Button>
      }
      pagination={
        <Pagination
          count={5}
          page={page}
          onChange={handlePaginationChange}
          shape="rounded"
        />
      }
    >
      {/* Questions */}
      <Box
        sx={{
          mt: -1,
          position: 'relative',
        }}
      >
        {isError && <FullPageErrorFallback />}
        {isLoading && <FullPageSpinner />}
        {!isError && !isLoading && (
          <Box>
            <QuickSearchToolbar
              value={searchText}
              onChange={(event) => requestSearch(event.target.value)}
              clearSearch={() => requestSearch('')}
            />
            <Box mt={2}>
              <Box mb={1.5}>
                <QuestionFilter
                  rows={rows}
                  setFilter={setFilter}
                  thematics={thematics}
                  questions={questions}
                  setRows={setRows}
                />
              </Box>
              <Box
                sx={{
                  px: 3,
                  mb: 1.5,
                }}
              >
                <Typography
                  variant="body2"
                  color="black.black75"
                  sx={{
                    textAlign: 'right',
                  }}
                >
                  ({rows.length}) questions / ({selectedQuestions})
                  sélectionnées
                </Typography>
              </Box>
            </Box>

            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              {/* Actions */}
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Button
                  variant="text"
                  type="submit"
                  sx={{
                    position: 'absolute',
                    top: '.5rem',
                    right: 2,
                    fontSize: '1.035rem',
                    textTransform: 'none',
                  }}
                  endIcon={<SaveOutlinedIcon />}
                  disabled={handleDisableSave()}
                >
                  Sauvegarder
                </Button>
              </Box>
              {/* /Actions */}
              <Grid container spacing={2}>
                {rows.length > 0 ? (
                  rows.map((item) => (
                    <Controller
                      key={item.id}
                      control={control}
                      name={`${item.id}`}
                      defaultValue={
                        evaluationDimensions[dimensionId]
                          ? evaluationDimensions[
                              dimensionId
                            ].questions.includes(item.id)
                          : false
                      }
                      render={({ field: { onChange, value } }) => (
                        <Grid key={item.id} item xs={12}>
                          <QuestionCard
                            checked={value || false}
                            item={item}
                            handleOpen={handleOpen}
                            onChange={(event) => {
                              handleSelectedQuestions(event.target.checked)
                              onChange(event)
                            }}
                          />
                        </Grid>
                      )}
                    />
                  ))
                ) : (
                  <Box
                    mt={2}
                    sx={{
                      width: '100%',
                    }}
                  >
                    <Typography
                      component="div"
                      variant="body1"
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      Pas d'éléments!{' '}
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: 'bold',
                          color: 'primary.main',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          handleOpen('add', {})
                        }}
                      >
                        {' '}
                        insérez un
                      </Typography>
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Box>
          </Box>
        )}
      </Box>
      {/* /Questions */}
    </WhiteBox>
  )
}

EvaluationQuestions.propTypes = {
  thematics: PropTypes.array,
  questions: PropTypes.array,
  rows: PropTypes.array,
  setRows: PropTypes.func,
  dimensionId: PropTypes.number,
  page: PropTypes.number,
  handlePaginationChange: PropTypes.func,
  handleOpen: PropTypes.func,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  setFilter: PropTypes.func,
  evaluationDimensions: PropTypes.object,
  setEvaluationDimensions: PropTypes.func,
  selectedQuestions: PropTypes.number,
  handleSelectedQuestions: PropTypes.func,
  currentEvaluation: PropTypes.object,
  handleCloseDetail: PropTypes.func,
}
