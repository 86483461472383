import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Typography,
  TextField,
  Box,
  IconButton,
  InputAdornment,
} from '@mui/material'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { useForm } from 'react-hook-form'

export default function Login({ email, children, submit }) {
  const location = useLocation()
  const [showPassword, setshowPassword] = useState(false)
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: new URLSearchParams(location.search).get('email') || email,
      password: new URLSearchParams(location.search).get('password'),
    },
  })

  useEffect(() => {
    if (
      new URLSearchParams(location.search).get('email') &&
      new URLSearchParams(location.search).get('password')
    ) {
      const username = new URLSearchParams(location.search).get('email')
      const password = new URLSearchParams(location.search).get('password')
      submit({ username, password })
    }
  }, [location.search, submit])

  return (
    <Box py={2} px={3}>
      <Typography
        component="h1"
        variant="h5"
        sx={{ mb: 3, color: 'black.black75' }}
      >
        Se Connecter
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          {children[0]}
        </Box>

        <Box
          component="form"
          // onSubmit={handleSubmit(onSubmit)}
          onSubmit={handleSubmit((data) => submit(data))}
          noValidate
          sx={{
            textAlign: 'center',
          }}
        >
          <TextField
            defaultValue={new URLSearchParams(location.search).get('email')}
            {...register('username', {
              required: {
                value: true,
                message: 'Veuillez entrer votre adresse email',
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Addresse email invalide',
              },
            })}
            fullWidth
            id="username"
            label="Adresse email"
            name="username"
            autoComplete="username"
            autoFocus
            helperText={errors.username?.message}
            error={!!errors.username}
          />

          <TextField
            type={showPassword ? 'text' : 'password'}
            sx={{
              mt: 2,

              '& ::-ms-reveal': {
                display: 'none',
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setshowPassword(!showPassword)}
                    color="primary"
                    edge="end"
                  >
                    {!showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...register('password', {
              required: { value: true, message: 'Password is required' },
            })}
            required
            fullWidth
            name="password"
            label="Mot de passe"
            // type="password"
            id="password"
            autoComplete="current-password"
            helperText={errors.password?.message}
            error={!!errors.password}
          />

          <Box>{children[1]}</Box>
        </Box>
      </Box>
    </Box>
  )
}

Login.propTypes = {
  email: PropTypes.string,
  children: PropTypes.node,
  submit: PropTypes.func,
}
