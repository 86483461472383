import React from 'react'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
// import EditCompany from './EditCompany'
import CompanyForm from '../../modules/company/componenets/companyForm'
import { useCompany } from '../../modules/company/companyProvider'
import FullPageSpinner from '../../components/FullPageSpinner'
import FullPageErrorFallback from '../../components/FullPageErrorFallback'
import { useAuth } from '../../modules/auth/AuthProvider'
import WhiteBox from '../../components/WhiteBox'

export default function Company() {
  const { account } = useAuth()
  const navigate = useNavigate()
  // const id = null
  const [dataEdit, setDataEdit] = React.useState({})
  const { fetchCompanyItemByUser, companyRequest } = useCompany()
  const [companyNotFound, setCompanyNotFound] = React.useState(false)
  React.useEffect(() => {
    fetchCompanyItemByUser(account.id).then((res) => {
      setDataEdit(res)
      if (res !== undefined) {
        setCompanyNotFound(false)
      }
    })
  }, [fetchCompanyItemByUser, account.id])

  React.useEffect(() => {
    if (companyRequest?.error?.message && !companyRequest?.data?.data)
      setCompanyNotFound(!!companyRequest.error.message.split(':')[1].trim())
  }, [companyRequest?.data?.data, companyRequest.error])

  if (companyRequest.isLoading) {
    return <FullPageSpinner />
  }
  if (companyRequest.error) {
    const status = companyRequest.error.message.split(':')[1].trim()
    if (status !== '404') {
      return <FullPageErrorFallback />
    }
  }
  return (
    <WhiteBox title="Profil d'entreprise">
      {companyNotFound && (
        <Button
          mt={2}
          onClick={() => navigate('/registerCompany')}
          variant="mysensContained"
        >
          <AddIcon />
          Ajouter Entreprise
        </Button>
      )}

      {companyRequest?.data?.data && (
        <CompanyForm action="edit" dataEdit={dataEdit} id={dataEdit.id} />
      )}
    </WhiteBox>
  )
}
