import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography, Grid, Stack, Alert } from '@mui/material'
import FullPageErrorFallback from '../../components/FullPageErrorFallback'
import FullPageSpinner from '../../components/FullPageSpinner'
import { useAuth } from '../../modules/auth/AuthProvider'
import { getUseCasesByuserByFaisabilityAndIndusty } from '../../modules/useCases/api'

import { useDimension } from '../../modules/dimension/DimensionProvider'
import { useAssessment } from '../../modules/assessment/AssessmentProvider'
import WhiteBox from '../../components/WhiteBox'
import UseCaseCard from '../../modules/opportunities/UseCaseCard'

export default function TopUseCases() {
  const navigate = useNavigate()
  const [useCases, setUseCases] = useState([])
  const { company } = useAuth()
  const {
    dimensionsScore,

    dimensionScoreLoading,
  } = useDimension()
  const { assessmentDetail, assessmentDetailLoading } = useAssessment()
  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const faisability = useCallback(() => {
    const faisabilityRange = (
      (dimensionsScore.reduce((acc, current) => acc + current.last_score, 0) *
        100) /
      dimensionsScore.length
    ).toFixed(2)

    switch (true) {
      case faisabilityRange <= 33:
        return 3
      case faisabilityRange <= 66:
        return 2
      case faisabilityRange <= 100:
        return 1
      default:
        return 3
    }
  }, [dimensionsScore])

  useEffect(() => {
    setLoading(true)
    setIsError(false)
    if (dimensionsScore.length === assessmentDetail.length) {
      getUseCasesByuserByFaisabilityAndIndusty(
        process.env.REACT_APP_ADMIN_ID,
        faisability(),
        company.industryType.id,
      )
        .then((res) => {
          setLoading(false)
          if (res.data) {
            setUseCases(res.data)
          }
        })
        .catch(() => {
          setIsError(true)
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [
    company.industryType.id,
    assessmentDetail.length,
    dimensionsScore.length,
    faisability,
  ])

  const handleNavigate = () => {
    navigate('/dimensions')
  }

  if (isError) {
    return <FullPageErrorFallback />
  }

  if (loading || assessmentDetailLoading || dimensionScoreLoading) {
    return <FullPageSpinner />
  }
  if (
    (dimensionsScore && dimensionsScore.length === 0) ||
    dimensionsScore.length < assessmentDetail.length
  ) {
    return (
      <WhiteBox title="Cas d'usages">
        <Typography
          color="black.black75"
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
            '&:hover': {
              color: 'mysensPalette.blue',
            },
          }}
          onClick={handleNavigate}
        >
          Répondre à toutes les dimension pour voire les cas d'usages
        </Typography>
      </WhiteBox>
    )
  }

  return (
    <WhiteBox title="Cas d'usages">
      {useCases.length > 0 ? (
        <Box
          sx={{
            backgroundColor: 'white.white100',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            borderRadius: '8px',
            boxShadow:
              'rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px !important',
          }}
        >
          <Grid container spacing={2}>
            {useCases.map((useCase) => (
              <Grid key={useCase.id} item xs={12} md={12}>
                <UseCaseCard useCase={useCase} />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <Stack>
          <Alert severity="info" variant="filled">
            Les cas d&apos;usages arrivent bientôt lorsque Les cas d&apos;usage
            sont prêts
          </Alert>
        </Stack>
      )}
    </WhiteBox>
  )
}
