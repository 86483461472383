import * as React from 'react'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { Box, CircularProgress, TextField, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useAlert } from '../../alert/AlertProvider'
import { useAuth } from '../../auth/AuthProvider'
import { postTag, updateTag } from '../api'

export default function TagsForm({
  action,
  tagData,
  handleClose,
  setTags,
  setRows,
}) {
  const [loading, setLoading] = React.useState(false)
  const { showAlert } = useAlert()

  const { account } = useAuth()

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: tagData?.name || '',
    },
  })

  const onSubmit = (data) => {
    setLoading(true)

    const sendData = {
      ...data,
      created_by: account.id,
    }

    if (action === 'add') {
      postTag(sendData)
        .then((res) => {
          setRows((prevRows) => [
            {
              ...sendData,
              id: res.data.id,
            },
            ...prevRows,
          ])
          setTags((prevRows) => [
            {
              ...sendData,
              id: res.data.id,
            },
            ...prevRows,
          ])
          handleClose()
        })
        .catch((error) => {
          if (error.message.includes('duplicate')) {
            showAlert(
              'Cette valeur existe déjà, Veuillez insérer une nouvelle valeur',
              'error',
            )
          } else {
            showAlert("Une erreur s'est produite", 'error')
          }
        })
    }
    if (action === 'edit') {
      updateTag(tagData.id, sendData)
        .then(() => {
          setRows((prevRows) =>
            prevRows.map((row) =>
              row.id === tagData?.id
                ? {
                    ...sendData,
                    id: tagData?.id,
                  }
                : row,
            ),
          )
          setTags((prevTags) =>
            prevTags.map((row) =>
              row.id === tagData?.id
                ? {
                    ...sendData,
                    id: tagData?.id,
                  }
                : row,
            ),
          )
          handleClose()
        })
        .catch((error) => {
          if (error.message.includes('duplicate')) {
            showAlert(
              'Cette valeur existe déjà, Veuillez insérer une nouvelle valeur',
              'error',
            )
          } else {
            showAlert("Une erreur s'est produite", 'error')
          }
        })
    }
    setLoading(false)
  }

  return (
    <Box
      sx={{
        // width: 400,
        boxShadow: 24,
        p: 4,
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <>
        <Typography variant="h5" textAlign="center" component="h1" gutterBottom>
          {action === 'add' ? 'Ajouter une métadonnée' : 'Editer la métadonnée'}
        </Typography>

        <Box mt={2}>
          <Box>
            <TextField
              {...register('name', {
                required: {
                  value: true,
                  message: 'Veuillez saisir une valeur',
                },
              })}
              margin="normal"
              fullWidth
              id="name"
              label="Nom de la métadonnée"
              name="name"
              autoComplete="name"
              autoFocus
              helperText={errors.name?.message}
              error={!!errors.name}
            />
          </Box>
        </Box>

        <Button
          startIcon={
            loading ? (
              <CircularProgress size={20} />
            ) : (
              <AddIcon color="white" size="small" />
            )
          }
          type="submit"
          fullWidth
          disabled={loading}
          variant="mysensContained"
          sx={{ mt: 2 }}
        >
          Enregistrer
        </Button>
      </>
    </Box>
  )
}
TagsForm.propTypes = {
  action: PropTypes.string,
  tagData: PropTypes.object,
  handleClose: PropTypes.func,
  setTags: PropTypes.func,
  setRows: PropTypes.func,
}
