import * as React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Box,
  FormControl,
  FormHelperText,
  Autocomplete,
  TextField,
} from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import AutocompleteField from '../../../components/AutocompleteField'
import { useIndustries } from '../../industry/IndustryProvider'

import { getCompanyType, getCompanyTypeOfClient } from '../api'

export default function IndustryForm({ children, submit }) {
  const { industries } = useIndustries()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  return (
    <Box
      mt={2}
      component="form"
      onSubmit={handleSubmit((data) => {
        submit(data)
      })}
      noValidate
      sx={{
        textAlign: 'center',
      }}
    >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={12} sm={6}>
          <Box sx={{ flex: 1, mt: 2 }}>
            <Controller
              name="companyType"
              control={control}
              rules={{
                required: "Veuillez sélectionner le type d'entreprise",
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <AutocompleteField
                    value={value}
                    setValue={onChange}
                    request={getCompanyType}
                    label="Type d'entreprise"
                  />
                </FormControl>
              )}
            />
            <FormHelperText
              sx={{
                pl: 2,
              }}
              error={Boolean(errors.companyType)}
            >
              {errors.companyType?.message}
            </FormHelperText>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={{ flex: 1, mt: 2 }}>
            <Controller
              name="industryType"
              control={control}
              rules={{
                required: "Veuillez sélectionner le type d'industrie",
              }}
              render={({ field: { onChange } }) => (
                <FormControl fullWidth>
                  <Autocomplete
                    options={industries}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, values) => {
                      onChange(values || null)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type d'industrie"
                        helperText={errors.industryType?.message}
                        error={Boolean(errors.industryType)}
                      />
                    )}
                  />
                </FormControl>
              )}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={{ flex: 1, mt: 2 }}>
            <Controller
              name="typeOfClient"
              control={control}
              rules={{
                required: 'Veuillez sélectionner le type de clients',
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <AutocompleteField
                    value={value}
                    setValue={onChange}
                    request={getCompanyTypeOfClient}
                    label="Type de clients"
                  />
                </FormControl>
              )}
            />
            <FormHelperText
              sx={{
                pl: 2,
              }}
              error={Boolean(errors.typeOfClient)}
            >
              {errors.typeOfClient?.message}
            </FormHelperText>
          </Box>
        </Grid>
      </Grid>
      {children}
    </Box>
  )
}

IndustryForm.propTypes = {
  children: PropTypes.node.isRequired,
  submit: PropTypes.func.isRequired,
}
