import { useState, useEffect, useRef } from 'react'
import {
  Button,
  Dialog,
  Pagination,
  DialogTitle,
  Typography,
  IconButton,
  Grid,
  Box,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import FullPageSpinner from '../../components/FullPageSpinner'
import FullPageErrorFallback from '../../components/FullPageErrorFallback'
import UseCasesCard from '../../modules/useCases/componenets/UseCasesCard'
import UseCasesForm from '../../modules/useCases/componenets/UseCasesForm'
import WhiteBox from '../../components/WhiteBox'
import { useAuth } from '../../modules/auth/AuthProvider'
import { getUseCasesByuserAndSciigo } from '../../modules/useCases/api'
import { escapeRegExp } from '../../utils/utilfc'
import QuickSearchToolbar from '../../components/QuickSearchToolbar'
import UseCasesFilter from '../../modules/useCases/componenets/UseCasesFilter'
import { useIndustries } from '../../modules/industry/IndustryProvider'
import { getCompanySpecialization } from '../../modules/company/api'

export default function UseCases() {
  const { account } = useAuth()
  const { industries } = useIndustries()
  const [useCases, setUseCases] = useState([])
  const [specializations, setSpecializations] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(null)
  const [rows, setRows] = useState(useCases)
  const [searchText, setSearchText] = useState('')
  const [dialog, setDialog] = useState({
    open: false,
    action: 'add',
    data: {},
  })
  const [page, setPage] = useState(1)
  const handlePaginationChange = (event, newValue) => {
    setPage(newValue)
  }

  const filter = useRef({
    industry: null,
    specialization: null,
    faisability: '',
  })

  const setFilter = (filterVal) => {
    filter.current = filterVal
  }

  const handleOpen = (action, data) => {
    setDialog({
      action,
      data,
      open: true,
    })
  }
  const handleClose = () => {
    setDialog({
      ...dialog,
      open: false,
    })
  }

  const requestSearch = (searchValue) => {
    setSearchText(searchValue)
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
    const filteredRows = useCases.filter(
      (row) =>
        searchRegex.test(row.name) ||
        searchRegex.test(row.description) ||
        searchRegex.test(row.prerequisite) ||
        searchRegex.test(row.benefit),
    )
    setRows(filteredRows)
  }

  useEffect(() => {
    getCompanySpecialization().then((res) => {
      setSpecializations(res.data)
    })
  }, [])

  useEffect(() => {
    setIsLoading(true)
    setIsError(false)
    getUseCasesByuserAndSciigo(account.id)
      .then((res) => {
        const newData = res.data
        setUseCases(newData)
        setRows(newData)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
        setIsError(true)
      })
  }, [account.id])

  if (isError) {
    return <FullPageErrorFallback />
  }
  if (isLoading) {
    return <FullPageSpinner />
  }

  return (
    <>
      <WhiteBox
        title="Cas d'usages"
        button={
          <Button
            variant="text"
            sx={{
              fontSize: '1.125rem',
              textTransform: 'none',
            }}
            endIcon={<AddIcon />}
            onClick={() => {
              handleOpen('add', {})
            }}
          >
            Ajouter un cas d'usage
          </Button>
        }
        search={
          <QuickSearchToolbar
            value={searchText}
            onChange={(event) => requestSearch(event.target.value)}
            clearSearch={() => requestSearch('')}
          />
        }
        pagination={
          <Pagination
            count={5}
            page={page}
            onChange={handlePaginationChange}
            shape="rounded"
          />
        }
      >
        <Box
          mt={3}
          mb={2}
          sx={{
            px: 3,
          }}
        >
          <UseCasesFilter
            setFilter={setFilter}
            industries={industries}
            specializations={specializations}
            useCases={useCases}
            rows={rows}
            setRows={setRows}
          />
        </Box>
        <Box
          sx={{
            px: 3,
            mb: 2,
          }}
        >
          <Typography
            variant="body1"
            color="black.black75"
            sx={{
              textAlign: 'right',
            }}
          >
            ({rows.length}) cas d'usages
          </Typography>
        </Box>

        {rows.length > 0 ? (
          <Grid container spacing={2}>
            {rows.map((item) => (
              <Grid key={item.id} item xs={12}>
                <UseCasesCard
                  item={item}
                  handleOpen={handleOpen}
                  isLoading={isLoading}
                  isError={isError}
                />
              </Grid>
            ))}{' '}
          </Grid>
        ) : (
          <Box
            mt={2}
            sx={{
              width: '100%',
            }}
          >
            <Typography
              component="div"
              variant="body1"
              sx={{
                textAlign: 'center',
              }}
            >
              Pas d'éléments!{' '}
              <Typography
                component="span"
                sx={{
                  fontWeight: 'bold',
                  color: 'primary.main',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleOpen('add', {})
                }}
              >
                {' '}
                insérez un
              </Typography>
            </Typography>
          </Box>
        )}
      </WhiteBox>
      <Dialog open={dialog.open} fullWidth maxWidth="lg">
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h5"
            textAlign="center"
            component="p"
            gutterBottom
            sx={{
              pl: 2,
            }}
          >
            {dialog.action === 'add'
              ? "Ajouter un cas d'usage "
              : "Editer le cas d'usage"}
          </Typography>

          <IconButton onClick={handleClose} sx={{ ml: 'auto' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <UseCasesForm
          specializations={specializations}
          action={dialog.action}
          useCasesData={dialog.data}
          handleClose={handleClose}
          setUseCases={setUseCases}
          setRows={setRows}
        />
      </Dialog>
    </>
  )
}
