import { Box, Grid } from '@mui/material'
import WhiteBox from '../../components/WhiteBox'
import StatsClients from '../../modules/poweruserDashboard/components/StatsClients'
import StatsAssessments from '../../modules/poweruserDashboard/components/StatsAssessments'
import Maturity from '../../modules/poweruserDashboard/components/Maturity'
import Client from '../../modules/poweruserDashboard/components/Clients'

export default function Dashboard() {
  return (
    <Box>
      <WhiteBox title="Clients & évaluations">
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <StatsClients />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <StatsAssessments />
          </Grid>
        </Grid>
      </WhiteBox>
      <Box mt={2}>
        <Maturity />
      </Box>
      <Box mt={2}>
        <Client />
      </Box>
    </Box>
  )
}
