import { useState } from 'react'
import {
  Typography,
  TextField,
  Grid,
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Autocomplete,
} from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import PropTypes from 'prop-types'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useForm, Controller } from 'react-hook-form'
import AutocompleteField from '../../../components/AutocompleteField'
import {
  mustBeEmail,
  mustBePassword,
  mustBeConfirmPassword,
} from '../../../utils/form-validators'

import { getCompanyRole } from '../../company/api'

export default function Register({ children, submit }) {
  const [showPassword, setshowPassword] = useState(false)
  const [showConfirmPassword, setshowConfirmPassword] = useState(false)
  const {
    handleSubmit,
    register,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      repassword: '',
      phone: '',
      role: '',
    },
  })

  return (
    <Box py={2} px={3}>
      <Typography
        component="h1"
        variant="h5"
        sx={{ mb: 3, color: 'black.black75' }}
      >
        Créer Compte
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          {children[0]}
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit((data) => submit(data))}
          noValidate
          sx={{
            textAlign: 'center',
          }}
        >
          <Grid
            container
            rowSpacing={{ xs: 2 }}
            columnSpacing={{ xs: 2 }}
            sx={{
              pb: 0,
            }}
          >
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('firstName', {
                  required: 'Veuillez insérer votre prénom',
                })}
                fullWidth
                id="firstName"
                label="Prénom"
                name="firstName"
                autoFocus
                autoComplete="firstName"
                helperText={errors.firstName?.message}
                error={!!errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('lastName', {
                  required: 'Veuillez insérer votre prénom',
                })}
                fullWidth
                id="lastName"
                label="Nom"
                name="lastName"
                autoComplete="lastName"
                helperText={errors.lastName?.message}
                error={!!errors.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('email', {
                  required: 'Veuillez insérer votre email',
                  validate: {
                    email: (value) => mustBeEmail(value),
                  },
                })}
                fullWidth
                id="email"
                label="Adresse Mail"
                name="email"
                autoComplete="email"
                helperText={errors.email?.message}
                error={!!errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setshowPassword(!showPassword)}
                        color="primary"
                        edge="end"
                      >
                        {!showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register('password', {
                  required: 'Veuillez insirer votre mot de passe',
                  validate: {
                    password: (value) => mustBePassword(value),
                  },
                })}
                fullWidth
                name="password"
                label="Mot de passe"
                id="password"
                autoComplete="password"
                helperText={errors.password?.message}
                error={!!errors.password}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type={showConfirmPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setshowConfirmPassword(!showConfirmPassword)
                        }
                        color="primary"
                        edge="end"
                      >
                        {!showConfirmPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register('repassword', {
                  required: 'Veuillez confirmer votre mot de passe',
                  validate: {
                    repassword: (value) =>
                      mustBeConfirmPassword(getValues().password)(value),
                  },
                })}
                fullWidth
                name="repassword"
                label="Confirmer Mot de passe"
                id="repassword"
                autoComplete="password"
                helperText={errors.repassword?.message}
                error={!!errors.repassword}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box sx={{ flex: 1 }}>
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: 'Veuillez entrer votre téléphone',
                    },
                    // validate: {
                    //   phone: (value) => mustBeAPhoneNumber(value),
                    // },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth>
                      <PhoneInput
                        country="ca"
                        containerStyle={{
                          border: 'none',
                        }}
                        inputStyle={{
                          width: '100%',
                          height: '56px',
                        }}
                        id="phone"
                        value={value}
                        onChange={onChange}
                        defaultCountry="CA"
                        label="Phone"
                      />
                    </FormControl>
                  )}
                />
                <FormHelperText
                  sx={{
                    pl: 2,
                  }}
                  error={Boolean(errors.phone)}
                >
                  {errors.phone?.message}
                </FormHelperText>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box sx={{ flex: 1 }}>
                <Controller
                  name="jobTitle"
                  control={control}
                  rules={{
                    required: "Veuillez sélectionner votre titre d'emploi",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth>
                      <AutocompleteField
                        value={value}
                        setValue={onChange}
                        request={getCompanyRole}
                        label="Titre d'emploi"
                      />
                    </FormControl>
                  )}
                />
                <FormHelperText
                  sx={{
                    pl: 2,
                  }}
                  error={Boolean(errors.jobTitle)}
                >
                  {errors.jobTitle?.message}
                </FormHelperText>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ flex: 1 }}>
                <Controller
                  name="role"
                  control={control}
                  rules={{
                    required:
                      'Veuillez sélectionner le type de profile que vous voulez créer',
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth>
                      <Autocomplete
                        freeSolo
                        options={['Entreprise', 'Conseiller']}
                        onChange={(event, values) => onChange(values)}
                        value={value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Type du profil"
                            variant="outlined"
                            onChange={onChange}
                          />
                        )}
                      />
                    </FormControl>
                  )}
                />
                <FormHelperText
                  sx={{
                    pl: 2,
                  }}
                  error={Boolean(errors.jobTitle)}
                >
                  {errors.jobTitle?.message}
                </FormHelperText>
              </Box>
            </Grid>
          </Grid>

          <Box>{children[1]}</Box>
        </Box>
      </Box>
    </Box>
  )
}

Register.propTypes = {
  children: PropTypes.node,
  submit: PropTypes.func,
}
