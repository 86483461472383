import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Typography, Button } from '@mui/material'
import QuestionForm from '../../../modules/QuestionAnswer/components/QuestionForm'
import { StrongText } from '../../../utils/customElements'
import { useAssessment } from '../../../modules/assessment/AssessmentProvider'

function Description({ handleOpenForm }) {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        px: {
          xs: 2,
          md: 4,
        },
      }}
    >
      <Typography
        component="h1"
        variant="h4"
        sx={{
          textAlign: 'center',
          color: 'black.black75',
        }}
      >
        Dimension Données
      </Typography>

      <Box
        mt={3}
        sx={{
          color: 'black.black75',
        }}
      >
        <Box>
          <Typography component="div" variant="body1">
            La dimension <StrongText component="span">Données </StrongText>
            fait référence à la mesure dans laquelle une organisation a les
            données et l'infrastructure de données en place pour soutenir
            l'utilisation efficace de l'IA. Cela comprend non seulement le
            volume et la qualité des données disponibles, mais aussi les
            processus et systèmes en place pour gérer, stocker et accéder à ces
            données.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 3,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          onClick={() => {
            navigate(`/dimensions`)
          }}
          variant="outlined"
          sx={{
            px: 4,
            borderRadius: 8,
          }}
        >
          Précédent
        </Button>
        <Button
          variant="mysensContained"
          onClick={handleOpenForm}
          sx={{
            borderRadius: 8,
          }}
        >
          Suivant
        </Button>
      </Box>
    </Box>
  )
}
Description.propTypes = {
  handleOpenForm: PropTypes.func.isRequired,
}

export default function DataGov() {
  const [openForm, setOpenForm] = useState(false)
  const handleOpenForm = () => setOpenForm(true)
  const { questions } = useAssessment()
  return openForm ? (
    <QuestionForm steps={questions} />
  ) : (
    <Description handleOpenForm={handleOpenForm} />
  )
}
