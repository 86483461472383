import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  // Typography,
  Autocomplete,
  TextField,
  CircularProgress,
  Grid,
  Box,
  Button,
  FormControl,
  FormHelperText,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  mustBeValidDate,
  mustDateBeLessThan,
} from '../../../utils/form-validators'

import AutocompleteField from '../../../components/AutocompleteField'
import { useAuth } from '../../auth/AuthProvider'

import {
  getCountries,
  getStateByCountry,
  getCitiesByStateAndCountry,
} from '../../../utils/extapi'

import { useCompany } from '../companyProvider'

import {
  getCompanyType,
  // getCompanyIndustryType,
  // getCompanySpecialization,
  getCompanySize,
  getCompanyNumberEmployee,
  getCompanyTypeOfClient,
} from '../api'

import { useIndustries } from '../../industry/IndustryProvider'

export default function CompanyForm({ action, dataEdit, id }) {
  const { industries } = useIndustries()
  const { account, setCompanyData } = useAuth()
  const navigate = useNavigate()
  const { createCompany, updateCompany } = useCompany()
  const [selectedCountry, setSelectedCountry] = useState(
    dataEdit?.country ? JSON.parse(dataEdit?.country) : null,
  )
  const [selectedRegion, setSelectedRegion] = useState(
    dataEdit?.region ? JSON.parse(dataEdit?.region) : null,
  )
  const [countries, setCountries] = useState([])
  const [regionsByCountry, setRegionsByCountry] = useState([])
  const [citiesByRegion, setCitiesByRegion] = useState([])

  const {
    handleSubmit,
    register,
    setValue,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      companyName: '',
      companyType: null,
      industryType: null,
      // specialization: null,
      size: null,
      numberEmployee: null,
      companyAddress: '',
      country: null,
      region: null,
      city: null,
      zipCode: '',
      creationDate: null,
      typeOfClient: null,
    },
  })

  const [loading, setLoading] = useState(false)
  const today = new Date()

  useEffect(() => {
    getCountries().then((res) => {
      if (res.data) {
        setCountries(res.data)
      }
    })
  }, [])

  useEffect(() => {
    if (selectedCountry && selectedCountry.iso2) {
      getStateByCountry(selectedCountry.iso2).then((res) => {
        if (res.data) {
          setRegionsByCountry(res.data)
        }
      })
    }
  }, [selectedCountry])

  useEffect(() => {
    if (
      selectedCountry &&
      selectedCountry.iso2 &&
      selectedRegion &&
      selectedRegion.iso2
    ) {
      getCitiesByStateAndCountry(
        selectedCountry.iso2,
        selectedRegion.iso2,
      ).then((res) => {
        if (res.data) {
          setCitiesByRegion(res.data)
        }
      })
    }
  }, [selectedRegion, selectedCountry])

  useEffect(() => {
    reset({
      companyName: dataEdit?.companyName || '',
      companyType: dataEdit?.companyType || null,
      industryType: dataEdit?.industryType || null,
      size: dataEdit?.size || null,
      numberEmployee: dataEdit?.numberEmployee || null,
      companyAddress: dataEdit?.companyAddress || '',
      country: dataEdit?.country ? JSON.parse(dataEdit?.country) : null,
      region: dataEdit?.region ? JSON.parse(dataEdit?.region) : null,
      city: dataEdit?.city ? JSON.parse(dataEdit?.city) : null,
      zipCode: dataEdit?.zipCode || '',
      creationDate: dataEdit?.creationDate || null,
      typeOfClient: dataEdit?.typeOfClient || null,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEdit])

  const onSubmit = (data) => {
    setLoading(true)

    const creationDate = new Date(data.creationDate).toISOString()
    const sendData = {
      ...data,
      country: JSON.stringify(data.country),
      region: JSON.stringify(data.region),
      city: JSON.stringify(data.city),
      creationDate,
      userId: account?.id,
    }

    if (action === 'edit') {
      updateCompany({ ...sendData, id }).then(() => {
        setLoading(false)
        setCompanyData({ ...data, id: dataEdit.id, userId: account?.id })
        // navigate('/company')
      })
    } else {
      createCompany(sendData).then((res) => {
        setCompanyData({ ...data, id: res.id, userId: account?.id })
        navigate('/profile/companyProfile')
        setLoading(false)
      })
    }
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      p={0}
      m={0}
    >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            {...register('companyName', {
              required: "Veuillez insérer le nom d l'entreprise",
            })}
            fullWidth
            label="Nom d'entreprise"
            name="companyName"
            autoFocus
            InputLabelProps={{ shrink: true }}
            autoComplete="current-companyName"
            helperText={errors.companyName?.message}
            error={!!errors.companyName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ flex: 1 }}>
            <Controller
              control={control}
              name="creationDate"
              rules={{
                validate: {
                  lessThanToday: (value) =>
                    mustDateBeLessThan(value, 'today')(today),
                  isValidDate: (value) => mustBeValidDate(value),
                },
                required: 'Veuillez insérer la data de création',
              }}
              render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date de création"
                    value={value}
                    onChange={onChange}
                    maxDate={today}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                  <FormHelperText
                    sx={{
                      pl: 2,
                    }}
                    error={Boolean(errors.creationDate)}
                  >
                    {errors.creationDate?.message}
                  </FormHelperText>
                </LocalizationProvider>
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ flex: 1, mt: 2 }}>
            <Controller
              name="companyType"
              control={control}
              rules={{
                required: "Veuillez sélectionner le type d'entreprise",
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <AutocompleteField
                    value={value}
                    setValue={onChange}
                    request={getCompanyType}
                    label="Type d'entreprise"
                  />
                </FormControl>
              )}
            />
            <FormHelperText
              sx={{
                pl: 2,
              }}
              error={Boolean(errors.companyType)}
            >
              {errors.companyType?.message}
            </FormHelperText>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={{ flex: 1, mt: 2 }}>
            <Controller
              name="industryType"
              control={control}
              rules={{
                required: "Veuillez sélectionner le type d'industrie",
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <Autocomplete
                    value={value || null}
                    options={industries}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, values) => {
                      onChange(values || null)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type d'industrie"
                        helperText={errors.industryType?.message}
                        error={Boolean(errors.industryType)}
                      />
                    )}
                  />
                </FormControl>
              )}
            />
            <FormHelperText
              sx={{
                pl: 2,
              }}
              error={Boolean(errors.industryType)}
            >
              {errors.industryType?.message}
            </FormHelperText>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={{ flex: 1, mt: 2 }}>
            <Controller
              name="size"
              control={control}
              rules={{
                required: "Veuillez sélectionner la taille de l'entreprise",
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <AutocompleteField
                    value={value}
                    setValue={onChange}
                    request={getCompanySize}
                    label="Taille d'entreprise"
                  />
                </FormControl>
              )}
            />
            <FormHelperText
              sx={{
                pl: 2,
              }}
              error={Boolean(errors.size)}
            >
              {errors.size?.message}
            </FormHelperText>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={{ flex: 1, mt: 2 }}>
            <Controller
              name="numberEmployee"
              control={control}
              rules={{
                required: "Veuillez sélectionner le nombre d'employés",
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <AutocompleteField
                    value={value}
                    setValue={onChange}
                    request={getCompanyNumberEmployee}
                    label="Nombre d'employés"
                  />
                </FormControl>
              )}
            />
            <FormHelperText
              sx={{
                pl: 2,
              }}
              error={Boolean(errors.numberEmployee)}
            >
              {errors.numberEmployee?.message}
            </FormHelperText>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ flex: 1, mt: 2 }}>
            <Controller
              name="typeOfClient"
              control={control}
              rules={{
                required: 'Veuillez sélectionner le type de clients',
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <AutocompleteField
                    value={value}
                    setValue={onChange}
                    request={getCompanyTypeOfClient}
                    label="Type de clients"
                  />
                </FormControl>
              )}
            />
            <FormHelperText
              sx={{
                pl: 2,
              }}
              error={Boolean(errors.typeOfClient)}
            >
              {errors.typeOfClient?.message}
            </FormHelperText>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box sx={{ flex: 1, mt: 2 }}>
            <TextField
              {...register('companyAddress', {
                required: "Veuillez insérer l'adresse de l'entreprise",
              })}
              fullWidth
              label="l'adresse d'entreprise"
              name="companyAddress"
              autoComplete="current-companyAddress"
              InputLabelProps={{ shrink: true }}
              helperText={errors.companyAddress?.message}
              error={!!errors.companyAddress}
            />
          </Box>
        </Grid>

        {/* Country */}
        <Grid item xs={12} sm={6}>
          <Box sx={{ flex: 1, mt: 2 }}>
            <Controller
              control={control}
              name="country"
              rules={{
                required: 'Veuillez choisir une réponse',
              }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  value={value || null}
                  options={countries}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, values) => {
                    onChange(values)

                    setSelectedCountry(values)
                    setValue('region', null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Pays"
                      placeholder="Pays"
                      helperText={errors.country?.message}
                      error={!!errors.country}
                    />
                  )}
                />
              )}
            />
          </Box>
        </Grid>

        {/* /Country */}

        {/* Region */}
        {watch('country')?.name && (
          <Grid item xs={12} sm={6}>
            <Box sx={{ flex: 1, mt: 2 }}>
              <Controller
                control={control}
                name="region"
                rules={{
                  required: 'Veuillez choisir une réponse',
                }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    value={value || null}
                    options={regionsByCountry}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, values) => {
                      onChange(values)

                      setSelectedRegion(values)
                      setValue('city', null)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Région"
                        placeholder="Région"
                        helperText={errors.region?.message}
                        error={!!errors.region}
                      />
                    )}
                  />
                )}
              />
            </Box>
          </Grid>
        )}
        {/* /Region */}

        {/* City */}
        {watch('region')?.name && (
          <Grid item xs={12} sm={6}>
            <Box sx={{ flex: 1, mt: 2 }}>
              <Controller
                name="city"
                control={control}
                rules={{
                  required: 'Veuillez sélectionner une cité',
                }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    value={value || null}
                    options={citiesByRegion}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, values) => {
                      onChange(values)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cité"
                        placeholder="Cité"
                        helperText={errors.city?.message}
                        error={!!errors.city}
                      />
                    )}
                  />
                )}
              />
            </Box>
          </Grid>
        )}
        {/* /City */}

        <Grid item xs={12} sm={6}>
          <Box sx={{ flex: 1, mt: 2 }}>
            <TextField
              {...register('zipCode', {
                required: {
                  value: true,
                  message: 'Veuillez insérer le code postal',
                },
              })}
              fullWidth
              label="Code Postal"
              name="zipCode"
              autoComplete="current-zipCode"
              InputLabelProps={{ shrink: true }}
              helperText={errors.zipCode?.message}
              error={!!errors.zipCode}
            />
          </Box>
        </Grid>
      </Grid>

      <Button type="submit" variant="mysensContained" sx={{ mt: 3 }}>
        {loading && (
          <CircularProgress
            color="secondary"
            size={20}
            sx={{ marginRight: '1rem' }}
          />
        )}
        {action === 'edit' ? 'Enregistrer' : "Créer l'entreprise"}
      </Button>
    </Box>
  )
}

CompanyForm.propTypes = {
  action: PropTypes.string.isRequired,
  dataEdit: PropTypes.object,
  id: PropTypes.number,
}
