import api from '../../utils/api'

/* ################################################################################
# Evaluation 
################################################################################ */

export async function postEvaluation(data) {
  const url = `evaluations/`
  try {
    const result = await api(url, { data }).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getEvaluations(userId) {
  const url = `evaluations/${userId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result.data
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function updateEvaluation(id, data) {
  try {
    const result = await api(`evaluations/${id}`, {
      data,
      method: 'PUT',
    })

    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

/* ################################################################################
# Evaluation Dimension Questions
################################################################################ */
export async function postEvaluationDimension(data) {
  const url = `evaluations/evluationDimensions`
  try {
    const result = await api(url, { data }).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result.data
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getEvaluationDimensions(evaluationId) {
  const url = `evaluations/evluationDimensions/${evaluationId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result.data
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function updateEvaluationDimension(id, data) {
  try {
    const result = await api(`evaluations/evluationDimensionsItem/${id}`, {
      data,
      method: 'PUT',
    })

    return result.data
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

/* ################################################################################
# Evaluation Publish
################################################################################ */
export async function publishEvluation(data) {
  const url = `evaluations/evluationPublish`
  try {
    const result = await api(url, { data, method: 'PATCH' }).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

/* ################################################################################
# Evaluation Clients
################################################################################ */

export async function getEvaluationClientsAutocomplete(userId, industryId) {
  const url = `evaluations/evluationClientsAutocomplete/${userId}/${industryId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result.data
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function postEvluationClient(data) {
  const url = `evaluations/evaluationClients`
  try {
    const result = await api(url, { data }).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getEvaluationClients(adminId, evaluationId) {
  const url = `evaluations/evluationClients/${adminId}/${evaluationId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result.data
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}
