import PropTypes from 'prop-types'
import {
  Autocomplete,
  TextField,
  Grid,
  Slider,
  Typography,
  IconButton,
} from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'
import { useForm, Controller } from 'react-hook-form'
import { useIndustries } from '../../industry/IndustryProvider'

export default function QuestionFilter({
  rows,
  thematics,
  questions,
  setRows,
  setFilter,
}) {
  const { industries } = useIndustries()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  })

  const onSubmit = (data) => {
    setFilter({
      weight: data.weight,
      thematic: data.thematics,
      industry: data.industry,
    })
    if (data.weight > 0 || data.thematic || data.industry) {
      const filteredRows = questions
        .filter((row) => {
          if (data.weight > 0) {
            return row.weight === data.weight
          }
          return row
        })
        .filter((row) => {
          if (data.thematic) {
            return row.thematic_id === data.thematic.id
          }
          return row
        })
      setRows(filteredRows)
    } else if (rows.length < questions.length) {
      setRows(questions)
    }
  }
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Grid item xs={12} sm={11}>
        <Grid container spacing={2} alignItems="center">
          {/* Thematic */}
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              control={control}
              name="thematic"
              render={({ field: { onChange } }) => (
                <Autocomplete
                  options={thematics}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, values) => {
                    onChange(values || null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Thématique"
                      placeholder="Thématique"
                      helperText={errors.thematic?.message}
                      error={!!errors.thematic}
                      size="small"
                    />
                  )}
                />
              )}
            />
          </Grid>
          {/* /Thematic */}

          {/* Weight */}
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              alignSelf: 'end',
            }}
          >
            <Controller
              control={control}
              name="weight"
              render={({ field: { onChange } }) => (
                <Slider
                  defaultValue={0}
                  step={1}
                  marks
                  min={0}
                  max={5}
                  onChange={onChange}
                />
              )}
            />

            <Typography
              variant="body2"
              color="black.black75"
              sx={{
                mt: -2,
                textAlign: 'center',
              }}
            >
              Poids
            </Typography>
          </Grid>
          {/* /Weight */}

          {/* Industry  */}
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              control={control}
              name="industy"
              render={({ field: { onChange } }) => (
                <Autocomplete
                  options={industries}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, values) => {
                    onChange(values || null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Type d'industrie"
                      helperText={errors.industry?.message}
                      error={Boolean(errors.industry)}
                      size="small"
                    />
                  )}
                />
              )}
            />
          </Grid>
          {/* /Industry */}
        </Grid>
      </Grid>

      {/* FilterButton */}
      <Grid item xs={12} sm={1}>
        <IconButton type="submit">
          <TuneIcon />
        </IconButton>
      </Grid>

      {/* /FilterButton */}
    </Grid>
  )
}

QuestionFilter.propTypes = {
  rows: PropTypes.array,
  questions: PropTypes.array,
  setRows: PropTypes.func,
  thematics: PropTypes.array,
  setFilter: PropTypes.func,
}
