import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Dialog, IconButton } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import WhiteBox from '../../components/WhiteBox'
import EvaluationClientForm from '../../modules/evaluation/components/evaluationClients/EvaluationClientForm'
import { getEvaluationClients } from '../../modules/evaluation/api'
import { useAuth } from '../../modules/auth/AuthProvider'
import ClientList from '../../modules/clients/components/ClientList'
import ClientDetail from '../../modules/clients/components/ClientDetail'

export default function EvaluationClients({
  handleCloseClients,
  currentEvaluation,
}) {
  const { account } = useAuth()
  const [clients, setClients] = useState([])
  const [rows, setRows] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(null)
  const [showDetail, setShowDetail] = useState(false)
  const [clientDetail, setClientDetail] = useState({})

  const handleClickDetail = (detail) => {
    setShowDetail(true)
    setClientDetail(detail)
  }

  const handleBackDetail = () => {
    setShowDetail(false)
    setClientDetail({})
  }

  const [dialog, setDialog] = useState(false)
  const handleOpen = () => {
    setDialog(true)
  }
  const handleClose = () => {
    setDialog(false)
  }

  const constructColumns = (columns) => {
    const newColumns = []
    const filteredColumns = columns.filter(
      (item) => !item.toLowerCase().match(/.*id$|company$/gi),
    )
    filteredColumns.forEach((column) => {
      if (column !== 'Status') {
        newColumns.push({
          field: column,
          headerName: column,
          flex: 1,
        })
      }
    })

    newColumns.push({
      field: 'Action',
      headerName: 'Action',
      sortable: false,
      width: 80,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <IconButton
            onClick={() => {
              handleClickDetail(params.row)
            }}
          >
            <VisibilityIcon />
          </IconButton>
        </Box>
      ),
    })
    return newColumns
  }

  useEffect(() => {
    setIsError(false)
    if (currentEvaluation.id) {
      setIsLoading(true)
      getEvaluationClients(account.id, currentEvaluation.id)
        .then((res) => {
          setClients(res)
          setRows(res)
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
  }, [account.id, currentEvaluation.id])

  return (
    <>
      {!showDetail && (
        <>
          <WhiteBox
            titlePaddingX={1}
            titlePaddingY={1}
            title={
              currentEvaluation?.name
                ? `${currentEvaluation?.name} (${currentEvaluation?.industry?.name}): clients`
                : 'liste du clients'
            }
            startButton={
              <IconButton
                sx={{
                  color: 'inherit',
                  mr: 0.5,
                }}
                onClick={handleCloseClients}
              >
                <ArrowBackIcon
                  sx={{
                    fontSize: '1.266rem',
                  }}
                />
              </IconButton>
            }
            button={
              <Button
                variant="text"
                sx={{
                  fontSize: '1.035rem',
                  textTransform: 'none',
                }}
                endIcon={<AddIcon />}
                onClick={() => {
                  handleOpen()
                }}
              >
                Ajouter un client à l'évaluation
              </Button>
            }
          >
            <ClientList
              clients={clients}
              rows={rows}
              setRows={setRows}
              isLoading={isLoading}
              isError={isError}
              columns={
                clients.length > 0
                  ? constructColumns(Object.keys(clients[0]))
                  : []
              }
            />
          </WhiteBox>
          <Dialog onClose={handleClose} open={dialog} fullWidth>
            <EvaluationClientForm
              handleClose={handleClose}
              clients={clients}
              setClients={setClients}
              setRows={setRows}
              currentEvaluation={currentEvaluation}
            />
          </Dialog>
        </>
      )}
      {showDetail && (
        <ClientDetail
          handleBackDetail={handleBackDetail}
          clientDetail={clientDetail}
        />
      )}
    </>
  )
}
EvaluationClients.propTypes = {
  handleCloseClients: PropTypes.func,
  currentEvaluation: PropTypes.object,
}
