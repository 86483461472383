const OPENAPI_URL = 'https://api.openai.com/v1'
const OPENAPI_API_KEY = process.env.REACT_APP_OPENAPI_KEY
const OPENAPI_ORGANIZATION_ID = process.env.REACT_APP_OPENAPI_ORGANIZATION_ID

const headers = new Headers()
headers.append('Content-Type', `application/json`)
headers.append('Authorization', `Bearer ${OPENAPI_API_KEY}`)
headers.append('OpenAI-Organization', OPENAPI_ORGANIZATION_ID)

export async function completion(
  prompt = 'I am a highly intelligent question answering bot. If you ask me a question that is rooted in truth, I will give you the answer. If you ask me a question that is nonsense, trickery, or has no clear answer, I will respond with "Unknown".\n\nQ: What is human life expectancy in the United States?\nA: Human life expectancy in the United States is 78 years.\n\nQ: Who was president of the United States in 1955?\nA: Dwight D. Eisenhower was president of the United States in 1955.\n\nQ: Which party did he belong to?\nA: He belonged to the Republican Party.\n\nQ: What is the square root of banana?\nA: Unknown\n\nQ: How does a telescope work?\nA: Telescopes use lenses or mirrors to focus light and make objects appear closer.\n\nQ: Where were the 1992 Olympics held?\nA: The 1992 Olympics were held in Barcelona, Spain.\n\nQ: How many squigs are in a bonk?\nA: Unknown\n\nQ:',
) {
  const url = `${OPENAPI_URL}/completions`
  const postData = {
    model: 'text-davinci-003',
    prompt,
    temperature: 0,
    max_tokens: 1000,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
    // stop: ['\n'],
  }
  try {
    const data = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(postData),
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then((result) => ({
        data: result,
      }))
    return data
  } catch (error) {
    throw new Error(error)
  }
}
