import PropTypes from 'prop-types'
import {
  Box,
  IconButton,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import StarRateIcon from '@mui/icons-material/StarRate'
import EditIcon from '@mui/icons-material/Edit'
import { mapperQuestionType } from '../../../../../utils/mappers'
import WhiteBox from '../../../../../components/WhiteBox'

export default function QuestionsCard({ item, handleOpen, onChange, checked }) {
  return (
    <WhiteBox paddingX={2} paddingY={1.5}>
      {/* Weight && Thematics */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {[...Array(item.weight).keys()].map((star) => (
            <StarRateIcon
              key={`${item.id}-weight-${star}`}
              sx={{
                fontSize: '1.125rem',
                color: 'black.black50',
              }}
            />
          ))}
        </Box>
        <Box
          sx={{
            py: 0.3,
            px: 1.5,
            border: '1px solid rgba(0,18,27,0.1)',
          }}
        >
          <Typography variant="body2" color="black.black50">
            {item?.thematic?.name}
          </Typography>
        </Box>
      </Box>
      {/* /Weight && Thematics */}

      {/* Question */}
      <Typography mt={1.5} variant="body1" color="black.black75">
        {item.question}
      </Typography>
      {/* /Question */}

      {/* Question Type, answers'number && actions */}
      <Box
        mt={1.5}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Box>
            <Typography variant="body2" color="black.black50">
              {mapperQuestionType[item.type]}{' '}
              {item?.answers
                ? `/ ${JSON.parse(item.answers).length} réponses`
                : ''}
            </Typography>
          </Box>
        </Box>

        <Box>
          <IconButton
            onClick={() => {
              handleOpen('edit', item)
            }}
          >
            <EditIcon
              sx={{
                fontSize: '1.266rem',
              }}
            />
          </IconButton>

          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={onChange}
                sx={{
                  '& svg': {
                    fontSize: '1.266rem',
                  },
                }}
              />
            }
          />
        </Box>
      </Box>
      {/* /Question Type, answers'number && actions */}
    </WhiteBox>
  )
}

QuestionsCard.propTypes = {
  item: PropTypes.object,
  handleOpen: PropTypes.func,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
}
