import {
  useEffect,
  useState,
  memo,
  createContext,
  useMemo,
  useContext,
} from 'react'
import PropTypes from 'prop-types'
import { getMaturityLevels } from './api'

const MaturityContext = createContext()
MaturityContext.displayName = 'MaturityContext'

const MaturityProvider = memo(({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [maturities, setMaturities] = useState([])

  useEffect(() => {
    setIsError(false)
    setIsLoading(true)
    getMaturityLevels()
      .then((res) => {
        setMaturities(res.data)
        setIsLoading(false)
      })
      .catch(() => {
        setIsError(true)
        setIsLoading(false)
      })
  }, [])

  const value = useMemo(
    () => ({ maturities, isError, isLoading }),
    [maturities, isError, isLoading],
  )

  return (
    <MaturityContext.Provider value={value}>
      {children}
    </MaturityContext.Provider>
  )
})

export default MaturityProvider
export function useMaturities() {
  const context = useContext(MaturityContext)
  if (context === undefined) {
    throw new Error('useMaturities must be used within a MaturityProvider')
  }
  return context
}

MaturityProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
