import { useState } from 'react'

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  TextField,
  MenuItem,
  Checkbox,
  ListItemText,
  CircularProgress,
  Autocomplete,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DatePicker from '@mui/lab/DatePicker'
import frLocale from 'date-fns/locale/fr'
import propTypes from 'prop-types'
import {
  mustBeValidDate,
  mustDateBeLessThan,
} from '../../../utils/form-validators'
import { useAuth } from '../../auth/AuthProvider'
import { useAlert } from '../../alert/AlertProvider'
import { postCompanyGoal } from '../api'
import steps from '../gloalsQuestions.json'

const today = new Date()
export default function CompanyGoalForm({ companyGoal }) {
  const navigate = useNavigate()
  const { company } = useAuth()
  const { showAlert } = useAlert()
  const [isLoading, setIsLoading] = useState(false)

  const {
    handleSubmit,
    control,

    formState: { errors },
  } = useForm({
    defaultValues: {},
  })

  const onSubmit = (data) => {
    setIsLoading(true)
    const answers = steps.map((step) => ({
      question_id: step.id,
      question: step.question,
      answer:
        data[`${step.type}-${step.id}`]?.name ||
        data[`${step.type}-${step.id}`],
    }))
    const sendData = {
      company_id: company.id,
      score: 0,
      answers: JSON.stringify(answers),
    }
    setIsLoading(false)
    postCompanyGoal(company.id, sendData)
      .then(() => {
        setIsLoading(false)
        showAlert('Les objectifs sont mis à jour avec succès', 'success')
        navigate('/profile/companyGoal')
      })
      .catch(() => {
        setIsLoading(false)
        showAlert('Une erreur est survenue, veuillez réessayer', 'error')
      })
  }

  const questionField = (step, index) => {
    switch (step.type) {
      case 'date':
        return (
          <Box>
            <Controller
              control={control}
              name={`${step.type}-${step.id}`}
              defaultValue={companyGoal?.answers[index]?.answer || null}
              rules={{
                validate: {
                  lessThanToday: (value) =>
                    mustDateBeLessThan(value, 'today')(today),
                  isValidDate: (value) => mustBeValidDate(value),
                },
                required: 'Veuillez insérer une date',
              }}
              render={({ field: { onChange, value } }) => (
                <LocalizationProvider
                  locale={frLocale}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    value={value}
                    onChange={onChange}
                    label={step.question}
                    maxDate={today}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                  <FormHelperText
                    sx={{
                      pl: 2,
                    }}
                    error={Boolean(errors[`${step.type}-${step.id}`])}
                  >
                    {errors[`${step.type}-${step.id}`]?.message}
                  </FormHelperText>
                </LocalizationProvider>
              )}
            />
          </Box>
        )
      case 'select':
        return (
          <Box>
            <Controller
              name={`${step.type}-${step.id}`}
              control={control}
              rules={{
                required: 'Veuillez choisir une réponse',
              }}
              defaultValue={
                companyGoal?.answers[index]?.answer
                  ? { name: companyGoal?.answers[index]?.answer }
                  : null
              }
              render={({ field: { onChange } }) => (
                <Autocomplete
                  defaultValue={
                    companyGoal?.answers[index]?.answer
                      ? { name: companyGoal?.answers[index]?.answer }
                      : null
                  }
                  options={step.answers}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, values) => {
                    onChange(values)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={step.question}
                      // placeholder="Thématique"
                      helperText={errors[`${step.type}-${step.id}`]?.message}
                      error={Boolean(errors[`${step.type}-${step.id}`])}
                    />
                  )}
                />
              )}
            />
          </Box>
        )

      case 'multiple':
        return (
          <Box>
            <Controller
              control={control}
              name={`${step.type}-${step.id}`}
              defaultValue={companyGoal?.answers[index]?.answer || []}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <TextField
                    select
                    id={`${step.type}-${step.id}`}
                    variant="outlined"
                    label={step.question}
                    SelectProps={{
                      multiple: true,
                      value: value || [],
                      renderValue: (selected) => selected.join(', '),
                      onChange,
                    }}
                  >
                    {step.answers.map((answer) => (
                      <MenuItem key={answer.name} value={answer.name}>
                        <Checkbox
                          checked={(value || []).includes?.(answer.name)}
                        />
                        <ListItemText primary={answer.name} />
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              )}
            />
          </Box>
        )
      default:
        return (
          <Box>
            <Controller
              name={`${step.type}-${step.id}`}
              control={control}
              rules={{
                required: 'Veuillez choisir une réponse',
              }}
              defaultValue={companyGoal?.answers[index]?.answer || ''}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <TextField
                    value={value}
                    onChange={onChange}
                    label={step.question}
                  />
                </FormControl>
              )}
            />
            <FormHelperText
              sx={{
                pl: 2,
              }}
              error={Boolean(errors[`${step.type}-${step.id}`])}
            >
              {errors[`${step.type}-${step.id}`]?.message}
            </FormHelperText>
          </Box>
        )
    }
  }

  if (companyGoal && companyGoal?.answers?.length === 0) {
    return null
  }

  return (
    <Box mt={2} component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      {steps.map((step, index) => (
        <Box mt={4} key={step.id}>
          {questionField(step, index)}
        </Box>
      ))}
      <Box mt={4}>
        <Button variant="mysensContained" type="submit">
          {isLoading && (
            <CircularProgress
              color="secondary"
              size={20}
              sx={{ marginRight: '1rem' }}
            />
          )}
          Enregistrer
        </Button>
      </Box>
    </Box>
  )
}

CompanyGoalForm.propTypes = {
  companyGoal: propTypes.object,
}
