import api from '../../utils/api'

export async function postEvaluation(data) {
  const url = `evaluations/`
  try {
    const result = await api(url, { data }).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function postAssessment(data) {
  const url = `recommendations/assessment`
  try {
    const result = await api(url, { data }).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getAssessment(userId) {
  const url = `evaluations/assessment/${userId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))

    return result.data
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function getClientAdmin(clientId) {
  const url = `clients/clientAdmin/${clientId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result.data
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}
