import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Typography, Button } from '@mui/material'
// import StopIcon from '@mui/icons-material/Stop'
import QuestionForm from '../../../modules/QuestionAnswer/components/QuestionForm'
import { StrongText } from '../../../utils/customElements'
// import questions from '../../../modules/QuestionAnswer/questions/StrategyDimension.json'
// import { useDimension } from '../../../modules/dimension/DimensionProvider'
import { useAssessment } from '../../../modules/assessment/AssessmentProvider'

function Description({ handleOpenForm }) {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        px: {
          xs: 2,
          md: 4,
        },
      }}
    >
      <Typography
        component="h1"
        variant="h4"
        sx={{
          textAlign: 'center',
          color: 'black.black75',
        }}
      >
        Dimension Stratégie
      </Typography>

      <Box
        mt={3}
        sx={{
          color: 'black.black75',
        }}
      >
        <Box>
          <Typography component="div" variant="body1">
            La dimension <StrongText component="span">Stratégie</StrongText>{' '}
            examine l'état et la nature d'un plan d’affaire, d'action commercial
            et d'une feuille de route pour soutenir l'IA. Elle fait référence à
            la mesure dans laquelle une organisation à une stratégie claire et
            bien définie sur la façon dont elle va utiliser l'IA. Cela inclut
            non seulement les objectifs commerciaux spécifiques que
            l'organisation essaie d'atteindre avec l'IA, mais aussi l'approche
            globale qu'elle adopte pour mettre en œuvre et intégrer l'IA dans
            ses opérations.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 3,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          onClick={() => {
            navigate(`/dimensions`)
          }}
          variant="outlined"
          sx={{
            px: 4,
            borderRadius: 8,
          }}
        >
          Précédent
        </Button>
        <Button
          variant="mysensContained"
          onClick={handleOpenForm}
          sx={{
            borderRadius: 8,
          }}
        >
          Suivant
        </Button>
      </Box>
    </Box>
  )
}
Description.propTypes = {
  handleOpenForm: PropTypes.func.isRequired,
}

export default function Strategy() {
  const [openForm, setOpenForm] = useState(false)
  const handleOpenForm = () => setOpenForm(true)
  // const { questions } = useDimension()
  const { questions } = useAssessment()
  return openForm ? (
    <QuestionForm steps={questions} />
  ) : (
    <Description handleOpenForm={handleOpenForm} />
  )
}
