// import { useAsync } from '@react-extra/hooks'
import * as React from 'react'
import PropTypes from 'prop-types'
import { useAsync } from '@react-extra/hooks'
import { useAlert } from '../alert/AlertProvider'
import { postCompany, editCompany, getCompany, getCompanyByUser } from './api'

const CompanyContext = React.createContext()
CompanyContext.displayName = 'CompanyContext'

const CompanyProvider = React.memo(({ children }) => {
  const { run: companyRequestInit, ...companyRequest } = useAsync({
    status: 'pending',
  })

  const [companyData, setCompanyData] = React.useState({})

  const { showAlert } = useAlert()

  const createCompany = React.useCallback(
    // eslint-disable-next-line consistent-return
    async ({
      companyName,
      companyType,
      industryType,
      // specialization,
      size,
      numberEmployee,
      companyAddress,
      country,
      region,
      city,
      zipCode,
      creationDate,
      typeOfClient,
      userId,
      // eslint-disable-next-line consistent-return
    }) => {
      // try {
      const company = {
        companyName,
        company_type_id: companyType.id,
        company_industry_type_id: industryType.id,
        // company_specialization_id: specialization.id,
        company_size_id: size.id,
        company_number_employee_id: numberEmployee.id,
        company_address: companyAddress,
        country,
        region,
        city,
        zipCode,
        creation_date: creationDate,
        type_of_client_id: typeOfClient.id,
        user_id: userId,
      }
      const data = await postCompany(company)
      // showAlert(
      //   `L'entreprise ${company.companyName} a été créé avec success`,
      //   'success',
      // )
      return data
      // } catch (e) {
      //   if (e.message.includes('Ce nom de société existe déjà')) {
      //     showAlert(
      //       'Cette entreprise existe déjà, veuillez introduire un nouvelle entreprise',
      //       'error',
      //     )
      //   } else {
      //     showAlert('Une erreur est survenue, Veuillez réessayer', 'error')
      //   }
      // }
    },
    [],
  )

  const updateCompany = React.useCallback(
    // eslint-disable-next-line consistent-return
    async ({
      id,
      companyName,
      companyType,
      industryType,
      // specialization,
      size,
      numberEmployee,
      companyAddress,
      country,
      region,
      city,
      zipCode,
      creationDate,
      typeOfClient,
      userId,
      // eslint-disable-next-line consistent-return
    }) => {
      try {
        const company = {
          id,
          companyName,
          company_type_id: companyType.id,
          company_industry_type_id: industryType.id,
          // company_specialization_id: specialization.id,
          company_size_id: size.id,
          company_number_employee_id: numberEmployee.id,
          company_address: companyAddress,
          country,
          region,
          city,
          zipCode,
          creation_date: creationDate,
          type_of_client_id: typeOfClient.id,
          user_id: userId,
        }
        const data = await editCompany(company)
        showAlert(`L'entreprise a été mise à jour avec success`, 'success')
        return data
      } catch (e) {
        showAlert('Une erreur est survenue, Veuillez réessayer', 'error')
      }
    },
    [showAlert],
  )

  const fetchCompanyItem = React.useCallback(
    // eslint-disable-next-line consistent-return
    async (id) => {
      try {
        const data = await companyRequestInit(getCompany(id))
        return data.data
      } catch (e) {
        const status = e.message.split(':')[1].trim()
        if (status !== '404') {
          showAlert('Une erreur est survenue, Veuillez réessayer', 'error')
        }
      }
    },
    [showAlert, companyRequestInit],
  )

  const fetchCompanyItemByUser = React.useCallback(
    // eslint-disable-next-line consistent-return
    async (id) => {
      try {
        const data = await companyRequestInit(getCompanyByUser(id))
        return data.data
      } catch (e) {
        const status = e.message.split(':')[1].trim()
        if (status !== '404') {
          showAlert('Une erreur est survenue, Veuillez réessayer', 'error')
        }
      }
    },
    [showAlert, companyRequestInit],
  )

  const value = React.useMemo(
    () => ({
      companyRequestInit,
      companyRequest,
      createCompany,
      updateCompany,
      fetchCompanyItem,
      fetchCompanyItemByUser,
      companyData,
      setCompanyData,
    }),
    [
      companyRequestInit,
      companyRequest,
      createCompany,
      updateCompany,
      fetchCompanyItem,
      fetchCompanyItemByUser,
      companyData,
      setCompanyData,
    ],
  )

  return (
    <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>
  )
})

export default CompanyProvider
export function useCompany() {
  const context = React.useContext(CompanyContext)
  if (context === undefined) {
    throw new Error('useCompany must be used within a CompanyProvider')
  }
  return context
}

CompanyProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
