import * as React from 'react'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import {
  Box,
  CircularProgress,
  TextField,
  // Typography,
  FormHelperText,
  FormControl,
  Autocomplete,
  IconButton,
  Typography,
  // FormControlLabel,
  // Checkbox,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import { useAuth } from '../../auth/AuthProvider'
import {
  postQuestion,
  // getThematics,
} from '../api'
// import { getThematicsByUserAndSciigoByDimension } from '../../thematics/api'
import { useAlert } from '../../alert/AlertProvider'
import {
  mapperQuestionType,
  mapperQuestionTypeInverse,
} from '../../../utils/mappers'

export default function QuestionForm({
  thematics,
  dimensionId,
  action,
  questionData,
  handleClose,
  setQuestions,
  setRows,
}) {
  const { account } = useAuth()
  const [loading, setLoading] = React.useState(false)
  const { showAlert } = useAlert()
  // const [thematics, setThematics] = React.useState([])

  const {
    handleSubmit,
    control,
    watch,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      question: questionData?.question || '',
      weight: questionData?.weight || null,
      score: questionData?.score || null,
      type: questionData?.type ? mapperQuestionType[questionData?.type] : '',
      thematic: questionData?.thematic ? questionData?.thematic : null,
      answers: questionData?.answers ? JSON.parse(questionData?.answers) : [],
      // selected: questionData?.selected || true,
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'answers',
  })

  const addQuestion = (sendData) => {
    const date = new Date()
    const postData = {
      ...sendData,
      dimension_id: dimensionId,
      created_at: date,
      selected_at: date,
      updated_at: date,
      created_by_id: account.id,
      created_by_role: account.role,
    }

    postQuestion(0, postData)
      .then((res) => {
        setRows((prevRows) => [
          {
            ...postData,
            ...res.data,
            id: res.data.id,
          },
          ...prevRows,
        ])
        setQuestions((prevQuestions) => [
          {
            ...postData,
            ...res.data,
            id: res.data.id,
          },
          ...prevQuestions,
        ])
        handleClose()
        showAlert('La question a été ajouté avec success', 'success')
      })
      .catch((error) => {
        if (error.message.includes('duplicate')) {
          showAlert(
            'Cette question existe déjà, veuillez insérer une nouvelle valeur',
            'error',
          )
        } else {
          showAlert("Une erreur s'est produite", 'error')
        }
      })
  }

  const editQuestion = (sendData, data) => {
    const date = new Date()
    const editData = {
      ...sendData,
      updated_at: date,
      answers: JSON.stringify(data.answers),
    }

    postQuestion(questionData?.id, editData)
      .then(() => {
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === questionData?.id
              ? {
                  ...editData,
                  id: questionData?.id,
                }
              : row,
          ),
        )
        setQuestions((prevQuestions) =>
          prevQuestions.map((row) =>
            row.id === questionData?.id
              ? {
                  ...editData,
                  id: questionData?.id,
                }
              : row,
          ),
        )
        handleClose()
        showAlert('La question a été modifié avec success', 'success')
      })
      .catch((error) => {
        if (error.message.includes('duplicate')) {
          showAlert(
            'Cette question existe déjà, veuillez insérer une nouvelle valeur',
            'error',
          )
        } else {
          showAlert("Une erreur s'est produite", 'error')
        }
      })
  }

  const onSubmit = (data) => {
    setLoading(true)
    const sendData = {
      ...questionData,
      question: data.question,
      weight: data.weight,
      type: mapperQuestionTypeInverse[data.type],
      thematic_id: data.thematic.id,
      thematic: data.thematic,
      created_by_role: account.role,
      answers: JSON.stringify(data.answers),
    }

    if (action === 'add') {
      addQuestion(sendData)
    }
    if (action === 'edit') {
      // if (questionData.created_by_id !== account.id) {
      //   addQuestion(sendData)
      // } else {
      editQuestion(sendData, data)
      // }
    }
    setLoading(false)
  }

  return (
    <Box
      sx={{
        // width: 400,
        boxShadow: 24,
        p: 4,
      }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <>
        <Box>
          {/* Question */}
          <Box>
            <Controller
              name="question"
              control={control}
              rules={{
                required: 'Veuillez choisir une réponse',
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <TextField
                    value={value}
                    onChange={onChange}
                    label="Question"
                    name="question"
                    autoFocus
                  />
                </FormControl>
              )}
            />
            <FormHelperText
              sx={{
                pl: 2,
              }}
              error={Boolean(errors.question)}
            >
              {errors.question?.message}
            </FormHelperText>
          </Box>
          {/* Question */}
          {/* Weight */}
          <Box mt={2}>
            <Box>
              <TextField
                {...register('weight', {
                  required: {
                    value: true,
                    message: 'Veuillez saisir une valeur',
                  },
                  validate: {
                    weight: (value) =>
                      value < 1 || value > 5
                        ? 'Le poids doit etre entre 1 et 5'
                        : undefined,
                  },
                })}
                margin="normal"
                fullWidth
                label="Poids"
                name="weight"
                autoComplete="weight"
                helperText={errors.weight?.message}
                error={!!errors.weight}
              />
            </Box>
          </Box>
          {/* Weight */}

          {/* Thematic */}
          <Box mt={2}>
            <Controller
              control={control}
              name="thematic"
              rules={{
                required: 'Veuillez choisir une réponse',
              }}
              render={({ field: { onChange } }) => (
                <Autocomplete
                  defaultValue={
                    questionData?.thematic ? questionData?.thematic : null
                  }
                  options={thematics}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, values) => {
                    onChange(values || null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Thématique"
                      placeholder="Thématique"
                      helperText={errors.thematic?.message}
                      error={!!errors.thematic}
                    />
                  )}
                />
              )}
            />
          </Box>
          {/* /Thematic */}
          {/* Type */}
          <Box mt={2}>
            <Controller
              control={control}
              name="type"
              rules={{
                required: 'Veuillez choisir une réponse',
              }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  // freeSolo
                  // options={['Champ', 'Sélection', 'Choix multiple', 'date']}
                  options={['Champ', 'Sélection', 'Choix multiple']}
                  onChange={(event, values) => onChange(values)}
                  value={value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Type de la question"
                      variant="outlined"
                      onChange={onChange}
                      helperText={errors.type?.message}
                      error={Boolean(errors.type)}
                    />
                  )}
                />
              )}
            />
          </Box>
          {/* /Type */}
          {watch('type') === 'Sélection' && (
            <Box mt={2}>
              {fields.map((item, index) => (
                <Box
                  key={item.id}
                  mt={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    {/* answer */}
                    <Box>
                      <Controller
                        name={`answers.${index}.answer`}
                        control={control}
                        rules={{
                          required: 'Veuillez choisir une réponse',
                        }}
                        render={({ field: { onChange, value } }) => (
                          <FormControl fullWidth>
                            <TextField
                              value={value}
                              onChange={onChange}
                              label="Réponse"
                              name={`answers.${index}.answer`}
                            />
                          </FormControl>
                        )}
                      />
                    </Box>
                    {/* /answer */}

                    {/* Score */}
                    <Box>
                      <Box mt={2}>
                        <Controller
                          name={`answers.${index}.score`}
                          control={control}
                          rules={{
                            required: 'Veuillez choisir une réponse',
                          }}
                          render={({ field: { onChange } }) => (
                            <Autocomplete
                              defaultValue={
                                questionData.type === 'select' &&
                                questionData?.answers &&
                                JSON.parse(questionData?.answers).length > 0 &&
                                JSON.parse(questionData?.answers)[index]?.score
                                  ? JSON.parse(questionData?.answers)[index]
                                      .score
                                  : null
                              }
                              options={[1, 2, 3, 4, 5]}
                              getOptionLabel={(option) => `${option}`}
                              onChange={(event, values) => {
                                onChange(values)
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Score" />
                              )}
                            />
                          )}
                        />
                        <FormHelperText
                          sx={{
                            pl: 2,
                          }}
                          error={Boolean(errors.score)}
                        >
                          {errors.score?.message}
                        </FormHelperText>
                      </Box>
                      <FormHelperText
                        sx={{
                          pl: 2,
                        }}
                        error={Boolean(errors[`answers.${index}.score`])}
                      >
                        {errors[`answers.${index}.score`]?.message}
                      </FormHelperText>
                    </Box>
                    {/* Score */}
                  </Box>
                  <Box>
                    <IconButton
                      sx={{
                        ml: 0.5,
                      }}
                      aria-label="delete"
                      onClick={() => remove(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              ))}

              <Button varient="outlined" onClick={() => append({ answer: '' })}>
                Ajouter une réponse
              </Button>
            </Box>
          )}
          {watch('type') === 'Choix multiple' && (
            <Box mt={2}>
              {fields.map((item, index) => (
                <Box
                  key={item.id}
                  mt={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Box>
                      <Controller
                        name={`answers.${index}.answer`}
                        control={control}
                        rules={{
                          required: 'Veuillez choisir une réponse',
                        }}
                        render={({ field: { onChange, value } }) => (
                          <FormControl fullWidth>
                            <TextField
                              value={value}
                              onChange={onChange}
                              label="Réponse"
                              name={`answers.${index}.answer`}
                            />
                          </FormControl>
                        )}
                      />
                    </Box>

                    <FormHelperText
                      sx={{
                        pl: 2,
                      }}
                      error={Boolean(errors[`answers.${index}.score`])}
                    >
                      {errors[`answers.${index}.score`]?.message}
                    </FormHelperText>
                  </Box>
                  <Box>
                    <IconButton
                      sx={{
                        ml: 0.5,
                      }}
                      aria-label="delete"
                      onClick={() => remove(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              ))}
              <Box>
                <Box>
                  <Button
                    varient="outlined"
                    onClick={() => append({ answer: '' })}
                  >
                    Ajouter une réponse
                  </Button>
                </Box>
                <Box>
                  <Typography
                    variant="caption"
                    sx={{
                      color: 'mysensPalette.orange',
                    }}
                  >
                    **"Aucune de ces réponses" option va s'ajouter
                    automatiquement au liste du choix pour le client, vous ne
                    devez pas l'entrer manuellement
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        <Button
          startIcon={
            loading ? (
              <CircularProgress size={20} />
            ) : (
              <AddIcon color="white" size="small" />
            )
          }
          type="submit"
          fullWidth
          disabled={loading}
          variant="mysensContained"
          sx={{ mt: 2 }}
        >
          Enregistrer
        </Button>
      </>
    </Box>
  )
}
QuestionForm.propTypes = {
  thematics: PropTypes.array,
  dimensionId: PropTypes.number,
  action: PropTypes.string,
  questionData: PropTypes.object,
  handleClose: PropTypes.func,
  setQuestions: PropTypes.func,
  setRows: PropTypes.func,
}
