import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Grid, Alert, Stack, Typography } from '@mui/material'

import { useDimension } from '../../modules/dimension/DimensionProvider'
import { dimensionsIcons } from '../../utils/icons'
import FullPageSpinner from '../../components/FullPageSpinner'
import {
  getGlobalRrecommendationsBySciigoAndUserByMaturityByIndustry,
  getRrecommendationsBySciigoAndUserByDimensionByMaturityByIndustry,
} from '../../modules/recommendation/api'
import WhiteBox from '../../components/WhiteBox'
import RecommendationCard from '../../modules/insights/RecommendationCard'
import { maturityLevelId } from '../../utils/mappers'
import { useMaturities } from '../../modules/maturity/MaturityProvider'
import { useAuth } from '../../modules/auth/AuthProvider'
import { useAssessment } from '../../modules/assessment/AssessmentProvider'

export default function TopRecommendations() {
  const navigate = useNavigate()
  const { maturities, isLoading: maturityLoading } = useMaturities()
  const { company } = useAuth()

  const { dimensionsScore, dimensionScoreLoading } = useDimension()
  const { assessmentDetailLoding, assessmentDetail } = useAssessment()

  const [recommendationsLoading, setRecommendationsLoading] = useState(false)
  const [activeRecommendations, setActiveRecommendations] = useState([])
  const [activeDimension, setActiveDimension] = useState({
    recommendations: [],
  })
  const recommendations = useRef({})
  const [globalRecommendations, setGlobalRecommendations] = useState([])
  const [globalRecommendationsLoading, setGlobalRecommendationsLoading] =
    useState(false)

  const handleDimensionClick = (assess) => {
    setActiveDimension(assess)
    const { dimensionId } = activeDimension
    setActiveRecommendations(recommendations.current[dimensionId])
  }

  useEffect(() => {
    if (
      dimensionsScore.length === assessmentDetail.length &&
      maturities.length > 0
    ) {
      if (assessmentDetail && assessmentDetail.length > 0) {
        setActiveDimension(assessmentDetail[0])
      }
    }
  }, [assessmentDetail, dimensionsScore.length, maturities.length])

  useEffect(() => {
    if (
      dimensionsScore.length === assessmentDetail.length &&
      maturities.length > 0
    ) {
      if (
        activeDimension &&
        activeDimension.dimensionId &&
        !recommendations[activeDimension.dimensionId]
      ) {
        setRecommendationsLoading(true)
        const maturityIQ = (
          (dimensionsScore.reduce(
            (acc, current) => acc + current.last_score,
            0,
          ) *
            100) /
          dimensionsScore.length
        ).toFixed(2)

        const userId = process.env.REACT_APP_ADMIN_ID
        const maturityId = maturityLevelId(maturities, maturityIQ)
        const industryId = company.industryType.id
        const { dimensionId } = activeDimension

        getRrecommendationsBySciigoAndUserByDimensionByMaturityByIndustry(
          userId,
          dimensionId,
          maturityId,
          industryId,
        )
          .then((res) => {
            setActiveRecommendations(res.data)
            recommendations.current[dimensionId] = res.data
            setRecommendationsLoading(false)
          })
          .catch(() => {
            setRecommendationsLoading(false)
          })
      }
    }
  }, [
    activeDimension,
    assessmentDetail.length,
    dimensionsScore,
    maturities,
    company.industryType.id,
  ])

  useEffect(() => {
    if (
      (dimensionsScore.length > 0 && assessmentDetail.length > 0,
      dimensionsScore.length === assessmentDetail.length &&
        maturities.length > 0)
    ) {
      setGlobalRecommendationsLoading(true)

      const maturityIQ = (
        (dimensionsScore.reduce((acc, current) => acc + current.last_score, 0) *
          100) /
        dimensionsScore.length
      ).toFixed(2)

      const userId = process.env.REACT_APP_ADMIN_ID
      const maturityId = maturityLevelId(maturities, maturityIQ)
      const industryId = company.industryType.id

      if (userId && maturityId && industryId) {
        getGlobalRrecommendationsBySciigoAndUserByMaturityByIndustry(
          userId,
          maturityId,
          industryId,
        )
          .then((res) => {
            setGlobalRecommendations(res.data)
            setGlobalRecommendationsLoading(false)
          })
          .catch(() => {
            setGlobalRecommendationsLoading(false)
          })
      }
    }
  }, [
    assessmentDetail.length,
    dimensionsScore.length,
    dimensionsScore,
    maturities,
    company.industryType.id,
  ])

  const handleNavigate = () => {
    navigate('/dimensions')
  }

  if (
    assessmentDetailLoding ||
    maturityLoading ||
    dimensionScoreLoading ||
    globalRecommendationsLoading
  ) {
    return <FullPageSpinner />
  }

  if (
    dimensionsScore.length === 0 ||
    dimensionsScore.length < assessmentDetail.length
  ) {
    return (
      <WhiteBox title="Recommendations">
        <Typography
          color="black.black75"
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
            '&:hover': {
              color: 'mysensPalette.blue',
            },
          }}
          onClick={handleNavigate}
        >
          Répondre à toutes les dimension pour voire les recommendations
        </Typography>
      </WhiteBox>
    )
  }

  return (
    <>
      {globalRecommendations.length > 0 && (
        <Box mt={4}>
          <WhiteBox title="  Recommendations globales">
            <Box
              // mt={4}
              sx={{
                backgroundColor: 'white.white100',
                transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                borderRadius: '8px',
                boxShadow:
                  'rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px !important',
              }}
            >
              <Grid container spacing={2}>
                {globalRecommendations.map((recommendation) => (
                  <Grid key={recommendation.id} item xs={12} sm={12} md={6}>
                    <RecommendationCard recommendation={recommendation} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </WhiteBox>
        </Box>
      )}
      {assessmentDetail.length > 0 ? (
        <WhiteBox title="Recommendations par dimensions">
          <>
            <Grid
              container
              // mt={4}
              justifyContent="center"
            >
              {assessmentDetail.map((assess) => (
                <Grid item key={assess.dimensionId} xs={12} sm={4} md={2} p={1}>
                  <Box
                    sx={{
                      py: 3,
                      px: 3,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'white.white100',
                      transition:
                        'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                      borderRadius: '8px',
                      boxShadow:
                        'rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px',
                      fontSize: '2rem',
                      color:
                        activeDimension.dimensionId === assess.dimensionId
                          ? 'mysensPalette.lightBlue'
                          : 'black.black75',
                      border:
                        activeDimension.dimensionId === assess.dimensionId
                          ? '2px solid #00ACE0'
                          : 'none',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleDimensionClick(assess)
                    }}
                  >
                    {dimensionsIcons[assess.dimensionName]}
                    <Typography
                      mt={1}
                      component="span"
                      variant="h6"
                      color="black.black75"
                      sx={{
                        textAlign: 'center',
                        minHeight: '3.052rem',
                      }}
                    >
                      {assess.dimensionName.replace('&', ' ')}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
            {recommendationsLoading && <FullPageSpinner />}
            {!recommendationsLoading &&
            activeRecommendations &&
            activeRecommendations.length > 0 ? (
              <Box mt={4}>
                <Grid container spacing={2}>
                  {activeRecommendations.map((recommendation) => (
                    <Grid key={recommendation.id} item xs={12} sm={12} md={6}>
                      <RecommendationCard recommendation={recommendation} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : (
              <Stack mt={4}>
                <Alert severity="info" variant="filled">
                  Pas de recommendations pour cette dimension encore
                </Alert>
              </Stack>
            )}
          </>
        </WhiteBox>
      ) : (
        <Stack>
          <Alert severity="info" variant="filled">
            Les recommendations spécifiques arrivent bientôt lorsque
            L&apos;évaluation est prête
          </Alert>
        </Stack>
      )}
    </>
  )
}
