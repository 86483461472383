import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Toolbar, Container } from '@mui/material'
import Copyrights from './Copyrights'
import Appbar from './Appbar'
import Sidebar from './Sidebar'

export default function Layout({ children }) {
  const [open, setOpen] = useState(true)
  const toggleDrawer = () => {
    setOpen(!open)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Appbar open={open} toggleDrawer={toggleDrawer} />
      <Sidebar open={open} toggleDrawer={toggleDrawer} />
      <Box
        component="main"
        sx={{
          backgroundColor: 'background.lightWhite',

          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          pt: 2,
        }}
      >
        <Toolbar />
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Container
            sx={{
              flex: 1,
            }}
          >
            {children}
          </Container>

          <Box
            mt={8}
            sx={{
              boxShadow:
                'rgb(0 0 0 / 6%) 0px 2px 4px -1px, rgb(0 0 0 / 4%) 0px 4px 5px 0px, rgb(0 0 0 / 4%) 0px 1px 10px 0px',

              background:
                'radial-gradient(100% 100% at 100% 50%,  #001F29 10%, #003E4A 100%)',
            }}
          >
            <Copyrights />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
