import api from '../../utils/api'

export async function getClientsByPowerUser(userId) {
  const url = `clients/clientsByPoweruser/${userId}`
  try {
    const result = await api(url).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result.data
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function postClient(clientPassword, client) {
  const url = `clients/registerClient`
  const data = {
    client_password: clientPassword,
    client,
  }

  try {
    const result = await api(url, { data }).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}

export async function updateClientPrivileges(clientId, privileges) {
  const url = `clients/clientPrivileges`
  const data = {
    client_id: clientId,
    privileges,
  }

  try {
    const result = await api(url, { data, method: 'PATCH' }).then((res) => ({
      count: res.data.length,
      data: res.data,
    }))
    return result.data
  } catch (error) {
    throw new Error(error.detail, error.detail)
  }
}
