import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Stack, Stepper, Step, StepLabel, Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector'
import Check from '@mui/icons-material/Check'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useDimension } from '../../dimension/DimensionProvider'
import { useAssessment } from '../../assessment/AssessmentProvider'

import { isAlreadyAnswered } from '../../../utils/utilfc'
import { dimensionsIcons } from '../../../utils/icons'
import { dimensionMapper } from '../../../utils/mappers'

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: theme.palette.mysensPalette.lightBlue,
  }),
  '& .QontoStepIcon-completedIcon': {
    color: theme.palette.mysensPalette.lightBlue,
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}))

function QontoStepIcon({ active, className, alreadyAnswered }) {
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {alreadyAnswered ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  )
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  alreadyAnswered: PropTypes.bool,
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: theme.palette.mysensPalette.lightBlue,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: theme.palette.mysensPalette.lightBlue,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.black.black75,
    borderRadius: 1,
  },
}))

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: '#3f4c53',
  zIndex: 1,
  color: theme.palette.white.white100,
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: theme.palette.mysensPalette.lightBlue,
    boxShadow: '0 4px 10px 0 rgba(56,182,255,1)',
  }),
  ...(ownerState.alreadyAnswered && {
    backgroundColor: theme.palette.mysensPalette.lightBlue,
  }),
}))

function ColorlibStepIcon({ active, alreadyAnswered, className, dimension }) {
  return (
    <ColorlibStepIconRoot
      ownerState={{ alreadyAnswered, active, dimension }}
      className={className}
    >
      <Box
        sx={{
          fontSize: '1.563rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {dimensionsIcons[dimension.dimensionName]}
        {/* {dimensionsIcons[dimension.name]} */}
      </Box>
    </ColorlibStepIconRoot>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  alreadyAnswered: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  dimension: PropTypes.object,
}

export default function DimensionLayout({ children }) {
  const location = useLocation()
  const { dimensionsScore, dimensions } = useDimension()
  const { assessmentDetail } = useAssessment()
  const [currentDimension, setCurrentDimension] = useState({})

  useEffect(() => {
    const dimensionName = location.pathname.split('/')[2]
    const index = dimensions.findIndex(
      (dimension) => dimension.name === dimensionMapper[dimensionName],
    )
    setCurrentDimension(dimensions[index])
  }, [dimensions, location.pathname])

  return (
    <>
      <Typography
        componeent="h1"
        variant="h4"
        sx={{
          textAlign: 'center',
          color: 'black.black75',
        }}
      >
        Commençons à construire votre avenir numérique
      </Typography>
      <Stack mt={4} sx={{ width: '100%' }}>
        <Stepper
          alternativeLabel
          activeStep={dimensions.findIndex(
            (dimension) => dimension.id === currentDimension.id,
          )}
          connector={<ColorlibConnector />}
        >
          {assessmentDetail.map((dimension) => (
            <Step
              key={dimension.dimensionId}
              // alreadyAnswered={isAlreadyAnswered(dimension, dimensionsScore)}
            >
              <StepLabel
                StepIconComponent={ColorlibStepIcon}
                StepIconProps={{
                  alreadyAnswered: isAlreadyAnswered(
                    dimension,
                    dimensionsScore,
                  ),
                  dimension,
                }}
              >
                <Typography
                  variant="body2"
                  color={
                    dimension.dimensionId === currentDimension.id
                      ? 'mysensPalette.lightBlue'
                      : 'black.black75'
                  }
                >
                  {dimension.dimensionName}
                </Typography>
              </StepLabel>
              {dimension.dimensionId === currentDimension.id && (
                <Box sx={{ textAlign: 'center' }}>
                  <KeyboardArrowUpIcon
                    sx={{
                      color: 'mysensPalette.lightBlue',
                    }}
                  />
                </Box>
              )}
            </Step>
          ))}
        </Stepper>
      </Stack>
      <Box>{children}</Box>
    </>
  )
}

DimensionLayout.propTypes = {
  // currentDimension: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}
