import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import Chart from 'react-apexcharts'
import FullPageSpinner from '../../../components/FullPageSpinner'
import { getScoreDimensionHistory } from '../../dimension/api'
import WhiteBox from '../../../components/WhiteBox'
import { useAuth } from '../../auth/AuthProvider'

const options = {
  chart: {
    type: 'line',
  },

  markers: {
    size: 5,
  },
  yaxis: {
    title: {
      text: 'Score',
    },
  },

  xaxis: {
    type: 'datetime',
  },
}

export default function DimensionHistoryChart({ companyId }) {
  const navigate = useNavigate()
  const { account } = useAuth()
  const [series, setSeries] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleNavigate = () => {
    navigate('/dimensions')
  }
  const constructSeriesData = (data) => {
    const dimensionTrace = []
    const seriesObj = {}
    data.forEach((entry) => {
      if (dimensionTrace.includes(entry.dimension.name)) {
        seriesObj[entry.dimension.name].data.push({
          x: entry.date,
          y: parseFloat(entry.score.toFixed(2) * 100),
        })
      } else {
        dimensionTrace.push(entry.dimension.name)
        seriesObj[entry.dimension.name] = {
          data: [
            {
              x: entry.date,
              y: parseFloat(entry.score.toFixed(2)),
            },
          ],
        }
      }
    })
    const seriesData = dimensionTrace.map((name) => ({
      name,
      data: seriesObj[name].data,
    }))
    return seriesData
  }

  useEffect(() => {
    setLoading(true)

    getScoreDimensionHistory(companyId)
      .then((res) => {
        setSeries(constructSeriesData(res.data))
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setError(true)
      })
  }, [companyId])

  if (loading) {
    return <FullPageSpinner />
  }
  if (error) {
    return null
  }
  if (series.length === 0) {
    return (
      <WhiteBox title="Évolution des scores sur le temps">
        {account.role === 'client' ? (
          <Typography
            color="black.black75"
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
              '&:hover': {
                color: 'mysensPalette.blue',
              },
            }}
            onClick={handleNavigate}
          >
            Répondre au questionaire pour voire votre évolution
          </Typography>
        ) : (
          'Répondre au questionaire pour voire votre évolution'
        )}
      </WhiteBox>
    )
  }
  return (
    <WhiteBox title="Évolution des scores sur le temps">
      <Box mt={2}>
        <Chart options={options} series={series} height="400" />
      </Box>
    </WhiteBox>
  )
}
DimensionHistoryChart.propTypes = {
  companyId: PropTypes.number.isRequired,
}
