import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Score from './Score'
import { getScoreDimension } from '../../dimension/api'
import { useAssessment } from '../../assessment/AssessmentProvider'

export default function ScoreForPoweruser({ companyId }) {
  const { assessmentDetail } = useAssessment()
  const [dimensionsScore, setDimensionsScore] = useState([])

  useEffect(() => {
    getScoreDimension(companyId).then((res) => {
      setDimensionsScore(res.data)
    })
  }, [companyId])

  return (
    <Score
      dimensionsScore={dimensionsScore}
      companyId={companyId}
      dimensions={assessmentDetail}
    />
  )
}
ScoreForPoweruser.propTypes = {
  companyId: PropTypes.number.isRequired,
}
