import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import DimensionPercentBoxes from './DimensionPercentBoxes'
import WhiteBox from '../../../components/WhiteBox'
import { useAuth } from '../../auth/AuthProvider'

export default function ScoreEvolution({ dimensionsScore }) {
  const navigate = useNavigate()
  const { account } = useAuth()
  const handleNavigate = () => {
    navigate('/dimensions')
  }
  if (dimensionsScore.length === 0) {
    return (
      <WhiteBox title="Évolution sur les dimensions">
        {account.role === 'client' ? (
          <Typography
            color="black.black75"
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
              '&:hover': {
                color: 'mysensPalette.blue',
              },
            }}
            onClick={handleNavigate}
          >
            Répondre au questionaire pour voire votre évolution
          </Typography>
        ) : (
          <Typography>
            Répondre au questionaire pour voire votre évolution
          </Typography>
        )}
      </WhiteBox>
    )
  }
  return (
    <WhiteBox title="Évolution sur les dimensions">
      <DimensionPercentBoxes dimensionsScore={dimensionsScore} />
    </WhiteBox>
  )
}

ScoreEvolution.propTypes = {
  dimensionsScore: PropTypes.array,
}
