import { dimensionNumberMapperIverse } from './mappers'

export const maturityIndexColor = (value) => {
  if (value <= 25) {
    return 'rgb(255, 61, 87)'
  }
  if (value <= 50) {
    return 'rgb(94, 92, 230)'
  }
  if (value <= 75) {
    return 'rgb(0, 129, 255)'
  }
  return 'rgb(9, 182, 109)'
}

export const statusColor = (value) => {
  if (value <= 25) {
    return 'rgb(255, 61, 87)'
  }
  if (value <= 50) {
    return 'rgb(94, 92, 230)'
  }
  if (value <= 75) {
    return 'rgb(0, 129, 255)'
  }
  return 'rgb(9, 182, 109)'
}

export const statusAugmentationColor = (value) => {
  if (value < 0) {
    return 'rgb(255, 61, 87)'
  }
  if (value < 20) {
    return 'rgb(94, 92, 230)'
  }
  if (value < 50) {
    return 'rgb(0, 129, 255)'
  }
  return 'rgb(9, 182, 109)'
}

export const statusAugmentationColorOpacity = (value) => {
  if (value < 0) {
    return 'rgba(255, 61, 87, 0.17)'
  }
  if (value < 20) {
    return 'rgba(94, 92, 230, 0.17)'
  }
  if (value < 50) {
    return 'rgba(0, 129, 255, 0.17)'
  }
  return 'rgba(9, 182, 109, 0.17)'
}

export const optimalColumnWidthPercent = (series) =>
  20 + 60 / (1 + 30 * Math.exp(-series.length / 3))

export function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
}

export const isAlreadyAnswered = (dimension, dimensionsScore) =>
  !!dimensionsScore.find(
    (dimensionScore) =>
      dimensionScore.dimension_id === dimension.dimensionId ||
      dimensionScore.dimension_id === dimension.id,
  )

export const scoreCharts = (dimensionsScore) => {
  const labels = []
  const series = []
  const raddarLabels = []

  dimensionsScore.forEach((dimension) => {
    const lastScore = dimension.last_score
    labels.push(dimension.dimension.name.replace(' &', '').split(' '))
    raddarLabels.push(dimensionNumberMapperIverse[dimension.dimension.name])
    series.push(parseFloat((lastScore * 100).toFixed(2)))
  })
  // const optimalColumnWidthPercent =
  //   20 + 60 / (1 + 30 * Math.exp(-series.length / 3))
  return {
    donut: {
      options: {
        labels,
        legend: {
          position: 'bottom',
        },
        dataLabels: {
          enabled: false,
        },
      },
      series,
    },
    radar: {
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          // categories: labels,
          categories: raddarLabels,
          labels: {
            show: true,
            style: {
              colors: ['rgba(0,18,27,0.50)'],
              fontSize: '12.80px',
            },
          },
        },
        yaxis: {
          show: false,
          min: 0,
          max: 100,
        },
        fill: {
          opacity: 0,
          colors: ['#00ACE0'],
        },
        stroke: {
          show: true,
          colors: ['#00ACE0'],
        },
        markers: {
          size: 5,
          colors: ['#00ACE0'],
          hover: {
            size: 10,
          },
        },
      },
      series: [
        {
          name: 'Score',
          data: series,
        },
      ],
      labels: raddarLabels,
    },

    bar: {
      series: [
        {
          name: 'Score',
          data: series,
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 8,
            columnWidth: `${optimalColumnWidthPercent(series)}%`,
          },
        },
        dataLabels: {
          enabled: true,
        },
        yaxis: {
          labels: {
            show: false,
            style: {
              fontSize: '12.80px',
              colors: ['rgba(0,18,27,0.50)'],
            },
          },
        },
        xaxis: {
          categories: labels,
          labels: {
            style: {
              fontSize: '12.80px',
              colors: labels.map(() => 'rgba(0,18,27,0.50)'),
            },
          },
          axisTicks: {
            show: false,
          },
        },
        fill: {
          colors: ['#00ACE0'],
        },
      },
    },
  }
}
