import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Typography, Button } from '@mui/material'
import StopIcon from '@mui/icons-material/Stop'
import QuestionForm from '../../../modules/QuestionAnswer/components/QuestionForm'
import { StrongText } from '../../../utils/customElements'
import { useAssessment } from '../../../modules/assessment/AssessmentProvider'

function Description({ handleOpenForm }) {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        px: {
          xs: 2,
          md: 4,
        },
      }}
    >
      <Typography
        component="h1"
        variant="h4"
        sx={{
          textAlign: 'center',
          color: 'black.black75',
        }}
      >
        Products & Services dimension
      </Typography>

      <Box
        mt={3}
        sx={{
          color: 'black.black75',
        }}
      >
        <Box>
          <Typography component="div" variant="body1">
            The{' '}
            <StrongText component="span">
              Products & Services Dimension{' '}
            </StrongText>
            examines the state and nature of a business plan of action and
            roadmap to support AI. This includes an understanding of how AI fits
            within the broader digital Products & Services, AI budgets
            considerations/decisions, agreed metrics/KPIs to measure the success
            of AI deployments.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="body1">
            It allow the assessment of the implements business rules, methods,
            and success metrics to develop and manage an AI Organisation &
            Ecosystem and enable alignment with the digital transformation
            Products & Services to achieve tangible business results.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography component="div" variant="body1">
            The{' '}
            <StrongText component="span">
              Products & Services assessment
            </StrongText>{' '}
            identify future goals and direction on the application of AI in the
            your organization.
          </Typography>
        </Box>
        <Typography component="div" mt={2}>
          <StrongText component="span">AI Products & Services</StrongText>{' '}
          refers to a company vision for how AI will be deployed to help achieve
          company business goals. It must be linked to your data Data
          Gouvernment and therefore to your business objectives. It provides a
          roadmap for performing your business AI priorities.
        </Typography>
        <Typography mt={2}>
          Having the right AI Products & Services in place helps you to:
        </Typography>

        <Box mt={1}>
          <Typography
            component="div"
            variant="body1"
            sx={{
              pl: '48px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <StopIcon
              sx={{
                color: 'mysensPalette.orange',
              }}
            />
            Identify which business goals can be solved with AI.
          </Typography>

          <Typography
            component="div"
            variant="body1"
            sx={{
              pl: '48px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <StopIcon
              sx={{
                color: 'mysensPalette.orange',
              }}
            />
            Adjust the Products & Services and mindset of your company to accept
            and be ready for AI.
          </Typography>
          <Typography
            component="div"
            variant="body1"
            sx={{
              pl: '48px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <StopIcon
              sx={{
                color: 'mysensPalette.orange',
              }}
            />
            Establish clear and achievable plans and goals for their AI
            initiatives.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          onClick={() => {
            navigate(`/dimensions`)
          }}
          variant="outlined"
          sx={{
            px: 4,
            borderRadius: 8,
          }}
        >
          Précédent
        </Button>
        <Button
          variant="mysensContained"
          onClick={handleOpenForm}
          sx={{
            borderRadius: 8,
          }}
        >
          Suivant
        </Button>
      </Box>
    </Box>
  )
}
Description.propTypes = {
  handleOpenForm: PropTypes.func.isRequired,
}

export default function Services() {
  const [openForm, setOpenForm] = useState(false)
  const handleOpenForm = () => setOpenForm(true)
  const { questions } = useAssessment()
  return openForm ? (
    <QuestionForm steps={questions} />
  ) : (
    <Description handleOpenForm={handleOpenForm} />
  )
}
