import { useState, useEffect } from 'react'
import {
  Box,
  Tab,
  Button,
  Dialog,
  Pagination,
  Grid,
  Typography,
} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import AddIcon from '@mui/icons-material/Add'
import FullPageSpinner from '../../components/FullPageSpinner'
import FullPageErrorFallback from '../../components/FullPageErrorFallback'
import { useDimension } from '../../modules/dimension/DimensionProvider'
import ThematicCard from '../../modules/thematics/components/ThematicTagsCard'
import ThematicForm from '../../modules/thematics/components/ThematicForm'
import WhiteBox from '../../components/WhiteBox'
import { useAuth } from '../../modules/auth/AuthProvider'
import { getThematicsByUserByDimension } from '../../modules/thematics/api'
import { escapeRegExp } from '../../utils/utilfc'
import QuickSearchToolbar from '../../components/QuickSearchToolbar'

export default function LabTabs() {
  const { account } = useAuth()
  const [page, setPage] = useState(1)
  const { dimensions, dimensionsLoding, dimensionsError } = useDimension()
  const [value, setValue] = useState('Stratégies')
  const [dimensionId, setDimensionId] = useState()
  const [thematics, setThematics] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(null)
  const [rows, setRows] = useState(thematics)
  const [searchText, setSearchText] = useState('')
  const [dialog, setDialog] = useState({
    open: false,
    action: 'add',
    data: {},
  })

  const requestSearch = (searchValue) => {
    setSearchText(searchValue)
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
    const filteredRows = thematics.filter((row) => searchRegex.test(row.name))
    setRows(filteredRows)
  }

  const handleOpen = (action, data) => {
    setDialog({
      action,
      data,
      open: true,
    })
  }
  const handleClose = () => {
    setDialog({
      ...dialog,
      open: false,
    })
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handlePaginationChange = (event, newValue) => {
    setPage(newValue)
  }

  useEffect(() => {
    if (dimensionId) {
      setIsLoading(true)
      setIsError(false)
      setIsLoading(false)

      getThematicsByUserByDimension(account.id, dimensionId)
        .then((res) => {
          const newData = res.data
          setThematics(newData)
          setRows(newData)
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
          setIsError(true)
        })
    }
  }, [account.id, dimensionId])

  useEffect(() => {
    if (dimensions && dimensions.length > 0) {
      const currentDimension = dimensions.find(
        (dimension) => dimension.name === value,
      )
      setDimensionId(currentDimension.id)
    }
  }, [dimensions, value])

  if (dimensionsError) {
    return <FullPageErrorFallback />
  }
  if (dimensionsLoding) {
    return <FullPageSpinner />
  }

  return (
    <>
      <WhiteBox
        title="Thématiques"
        button={
          <Button
            variant="text"
            sx={{
              fontSize: '1.125rem',
              textTransform: 'none',
            }}
            endIcon={<AddIcon />}
            onClick={() => {
              handleOpen('add', {})
            }}
          >
            Ajouter une thématique
          </Button>
        }
        search={
          <QuickSearchToolbar
            value={searchText}
            onChange={(event) => requestSearch(event.target.value)}
            clearSearch={() => requestSearch('')}
          />
        }
        pagination={
          <Pagination
            count={5}
            page={page}
            onChange={handlePaginationChange}
            shape="rounded"
          />
        }
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} variant="scrollable">
              {dimensions &&
                dimensions.map((dimension) => (
                  <Tab
                    key={dimension.id}
                    value={dimension.name}
                    label={dimension.name}
                    sx={{
                      textTransform: 'none',
                    }}
                  />
                ))}
            </TabList>
          </Box>
          {dimensions &&
            dimensions.map((dimension) => (
              <TabPanel key={dimension.id} value={dimension.name}>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    {rows.length > 0 ? (
                      rows.map((item) => (
                        <Grid key={item.id} item xs={12} sm={4} md={3}>
                          <ThematicCard
                            item={item}
                            handleOpen={handleOpen}
                            isLoading={isLoading}
                            isError={isError}
                          />
                        </Grid>
                      ))
                    ) : (
                      <Box
                        mt={2}
                        sx={{
                          width: '100%',
                        }}
                      >
                        <Typography
                          component="div"
                          variant="body1"
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          Pas d'éléments!{' '}
                          <Typography
                            component="span"
                            sx={{
                              fontWeight: 'bold',
                              color: 'primary.main',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              handleOpen('add', {})
                            }}
                          >
                            {' '}
                            insérez un
                          </Typography>
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Box>
              </TabPanel>
            ))}
        </TabContext>
      </WhiteBox>
      <Dialog onClose={handleClose} open={dialog.open} fullWidth>
        <ThematicForm
          dimensionId={dimensionId}
          action={dialog.action}
          thematicData={dialog.data}
          handleClose={handleClose}
          setThematics={setThematics}
          setRows={setRows}
        />
      </Dialog>
    </>
  )
}
